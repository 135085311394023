// ClaimPaymentForProvider.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import { SP_CONTRACTS, SP_CONTRACT_ABI } from "../ServiceProviderWeb3/ServiceProviderContract"

/**
 * Call the withdrawAmount method to transfer the parcel to investor and money to proposer
 * @param {String} address user wallet address
 * @param {Number} assetData asset's details
 * @param {Function} showSnackbar show success/failure message
 * @param {Function} handleActiveStep handle active step
 * @param {Function} handleModalClose handle modal close
 * @param {Function} handleSuccess handle confirmation of transaction success
 * @returns data or error
 */
async function ClaimPaymentForProvider(address, contractAddress, contractAbi, assetData, showSnackbar, handleActiveStep, handleModalClose, handleSuccess) {
    try {
        // let contract_address = SP_CONTRACTS.SERVICE_MARKETPLACE_PROVIDER;
        let functionName = ''
        let payload = []

        if (assetData?.project_term == 'project') {
            functionName = "claimPaymentForByProject";
            payload = [assetData?.blockchain_id]// _contractId (uint256)
        } else {
            functionName = "claimPaymentForByMilestones";
            payload = [assetData?.blockchain_id, [assetData?.milestoneId]]// _contractId (uint256)
        }
        console.log("payload---", payload);
        const { request: claimPaymentForByProject } = await prepareWriteContract({
            address: contractAddress,
            abi: contractAbi,
            account: address,
            chainId: 6806,
            functionName: functionName,
            args: payload
        })
        const { hash } = await writeContract(claimPaymentForByProject)
        if (hash) {
            const data = await waitForTransaction({
                hash: hash,
            })
            if (data.status === "success") {
                showSnackbar("Transaction Successful", 'success')
                handleActiveStep(2)
                setTimeout(() => {
                    handleSuccess(assetData)
                    handleModalClose();
                    handleActiveStep(-1)
                    return { data }
                }, 1000)
            } else {
                handleModalClose();
                handleActiveStep(-1)
                showSnackbar("Transaction Failed", 'error')
                return
            }
        }
    } catch (error) {
        console.error('Error in register user:', error);
        throw error; // propagate the error
    }
}

export { ClaimPaymentForProvider };