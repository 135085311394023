import DealTerms from "../DealTerms";
import Documents from "../Documents";
import Location from "../Location";
import AssetDetails from "../AssetDetails";
import PriceOverview from "../PriceOverview";
import Offers from "../Offers";
import GallerySlider from "../GallerySlider";
import Description from "../Description";
import OwnerInformation from "../OwnerInformation";
import BaseDetailsAsset from "./BaseDetailsClass";
import Templates from "../Templates";
import RepaymentTerms from "../RepaymentTerms";
import { CAPITAL_TYPE_ID } from "../../constants";
import HurdleRateDetails from "../HurdleRateDetails";
import EquityDistributionDetails from "../EquityDistbutionDetails";
import CapitalInfoInvestor from "../../Pages/Investor/MicroComponents/CapitalInfoInvestor";

export default class Estate extends BaseDetailsAsset {

  rightPanel = [];
  leftPanel = [];
  isParcel = false;

  constructor(_data) {
    super(_data);
    if (!_data) {
      throw new Error(
        "Custom error: Real Estate data is required in Art Details class"
      );
    }

    this.assetDetails = [
      {
        title: "Investment Type",
        value: _data?.investment_type?.investment_name,
      },
      {
        title: "Property Type",
        value: _data.owner_information
          ? _data.asset_sub_category_info?.title
          : "",
      },
      {
        title: "Owned By",
        value: _data.owner_information
          ? _data.owner_information.owner_type
          : "",
      },
    ];

    if (this.isLoan) {
      this.dealData = [
        {
          title: "Loan Duration",
          value: _data?.loan_duration,
        },
        {
          title: "Interest Rate",
          value: _data?.loan_roi + '% APR',
        },
      ];
    } else {
      this.dealData = [
        {
          title: 'Total Project Value',
          value: `$${(_data?.fractionalize_project_size).toLocaleString("en-US")}`,
        },
        {
          title: 'Total Raise',
          value: `$${(_data?.fractionalize_total_price.toLocaleString("en-US"))}`,
        },
        {
          title: 'Duration of Project',
          value: `${_data?.fractionalize_duration_of_project
            } Years`,
        },
        {
          title: "Type of Capital",
          value: _data?.capital_type,
        }
      ];
    }

    this.fromClass = "llcDetails";
    this.docs = _data.asset_document;
    this.description_document = _data.description_document
    this.asset_signing_document = _data.asset_signing_document
    this.investment_type_id = _data.investment_type_id
    this.category_id = _data.category_id
    this.selected_templates = _data?.template_data ? _data?.template_data[0]?.template_ids : []
    this.location = _data?.asset_location?.split(",");
    this.galleryImages = _data?.asset_gallery;
    this.is_onboard = _data?.is_onboard
    this.nft_id = _data?.nft_id
    this.assetData = _data
    this.member_id = _data?.user_details?.member_id
    this.is_debt = this.capitalInfo?.some((item) => [CAPITAL_TYPE_ID.SENIOR_DEBT, CAPITAL_TYPE_ID.JUNIOR_DEBT]?.includes(item?.capital_type?.id))
    this.is_equity = this.capitalInfo?.some((item) => [CAPITAL_TYPE_ID.EQUITY]?.includes(item?.capital_type?.id))

    if (_data.description_document?.length > 0) {
      const documentOnly = _data.description_document.filter(item => item.images && (item.images.type === "mp4"));
      if (documentOnly?.length > 0) {
        this.galleryImages = _data?.asset_gallery.concat(documentOnly);
      }
    }
    this.coverPhoto = _data?.asset_coverphoto;
    this.isParcel = _data?.investment_type?.investment_name === "Parcelling" ? true : false;
    this.descriptionData = {
      title: _data?.asset_title,
      subtitle: _data?.asset_address,
      investmentType: _data?.investment_type?.investment_name,
      assetType: _data?.assets_category?.title,
      assetSubtype: _data?.asset_sub_category_info?.title,
      description: _data?.asset_description,
      image: _data?.asset_gallery[0]?.images?.path,
    };
    this.parcelCardsData = _data?.parcelData;
    this.user_id = _data?.user_id;

    this.setLeftPanelComponents();
    this.setRightPanelComponents();
  }

  setLeftPanelComponents = () => {

    this.leftPanel.push(<GallerySlider images={this.galleryImages} coverPhoto={this.coverPhoto} isOnboard={this.is_onboard} nft_id={this.nft_id} isFraction={this.isFraction} assetData={this.assetData} />);
    this.leftPanel.push(
      <Description
        description={this.descriptionData.description}
        investmentType={this.descriptionData.investmentType}
        title={this.descriptionData.title}
        subtitle={this.descriptionData.subtitle}
        assetType={this.descriptionData.assetType}
        assetSubtype={this.descriptionData.assetSubtype}
        parcelCardsData={this.parcelCardsData}
        assetId={this.asset_Id}
        userId={this.user_id}
        category_id={this.category_id}
      />
    );
  };

  setRightPanelComponents = () => {
    if (!this.isOwner) { // only show below code block components if user is not owner
      this.rightPanel.push(<OwnerInformation owner={this.ownerInfo} totalProject={this.totalProjects} member_id={this.member_id} />);
    } else {
      this.rightPanel.push(<Offers asset_Id={this.asset_Id} category_id={this.category_id} />);
    }
    this.rightPanel.push(<PriceOverview prices={this.prices} capitalInfo={this.capitalInfo} isFraction={this.isFraction} assetData={this.assetData} isOfferAccepted={this.isOfferAccepted} assetId={this.asset_Id} btnTitle={this.descriptionData.title} listingType={this.descriptionData.investmentType} isOnboard={this.is_onboard} isOwner={this.isOwner} />);
    this.rightPanel.push(<CapitalInfoInvestor assetId={this.asset_Id} assetData={this.data} capitalInfo={this.capitalInfo} detailsFor={"proposer"} />)
    // if (!this.isOwner) this.rightPanel.push(<InvestorButtons isOfferAccepted={this.isOfferAccepted} assetId={this.asset_Id} btnTitle={this.descriptionData.title} listingType={this.descriptionData.investmentType} isOnboard={this.is_onboard} />);
    this.rightPanel.push(<AssetDetails details={this.assetDetails} editable={this.isOwner} isFraction={this.isFraction} />);
    this.rightPanel.push(
      <Location lat={this.location[0]} long={this.location[1]} editable={this.isOwner} />
    );
    this.rightPanel.push(<DealTerms dealTerms={this.dealData} fromPage={this.fromClass} editable={this.isOwner} isFraction={this.isFraction} />);
    if (this.is_debt) {
      this.rightPanel.push(<RepaymentTerms assetData={this.assetData} isFraction={this.isFraction} />);
    }
    if (this.is_equity) {
      this.rightPanel.push(<HurdleRateDetails assetData={this.assetData} />);
      this.rightPanel.push(<EquityDistributionDetails assetData={this.assetData} />);
    }
    this.rightPanel.push(<Documents documentsData={this.docs} descriptionData={this.description_document} assetId={this.asset_Id} ownerId={this.ownerInfo} editable={this.isOwner} projectStatus={this.projectStatus} section_title="Asset Documents" document_name="asset_document" investment_type_id={this.investment_type_id} isFraction={this.isFraction} />);
    // this.rightPanel.push(<Documents documentsData={this.asset_signing_document} descriptionData={[]} assetId={this.asset_Id} ownerId={this.ownerInfo} editable={this.isOwner} projectStatus={this.projectStatus} section_title="Agreement Documents" document_name="asset_signing_document" investment_type_id={this.investment_type_id} selected_templates={this.selected_templates} isFraction={this.isFraction} />);
    this.rightPanel.push(<Templates documentsData={this.asset_signing_document} descriptionData={[]} ownerId={this.ownerInfo} assetId={this.asset_Id} editable={this.isOwner} projectStatus={this.projectStatus} investment_type_id={this.investment_type_id} selected_templates={this.selected_templates} isFraction={this.isFraction} />);
  };
}
