import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Avatar, Typography, Stack, Paper, IconButton, Popover } from "@mui/material";
import { ArrowBack, Info, RemoveRedEye } from "@mui/icons-material";
import { User } from "../../Components/Images/Images";
import { CATEGORY_TYPE_ID, INVESTMENT_TYPE_ID } from "../../constants";
import { GetApi, postApi } from "../../Api/Api";
import ChatInput from "./ChatInput";

function ChatContainer({ currentChat, socket, handleBackToChat, noBtn }) {
  const isDic = useRef(JSON.parse(localStorage.getItem("user_data"))?.is_community_member);
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [isTyping, setIsTyping] = useState({
    status: false,
    username: "",
  });
  const [membersAnchorEl, setMembersAnchorEl] = useState(null);
  const showMembers = Boolean(membersAnchorEl);

  // Getting current chat messages
  useEffect(() => {
    const u = JSON.parse(localStorage.getItem("user_data"));
    const getCurrentChatMessages = async () => {
      const response = await GetApi("/chat/message/" + currentChat.id);

      setMessages(response?.data?.data);
    };
    if (currentChat) {
      // socket.emit("join-chat", currentChat?.id);
      getCurrentChatMessages();
    }
  }, [currentChat]);

  // Getting receive message to server
  useEffect(() => {
    const handleReceiveMessage = (data) => {
      if (currentChat?.id === data?.chat.id) {
        setArrivalMessage({
          fromSelf: false,
          ...data,
        });
        let u = JSON.parse(localStorage.getItem("user_data"));
        // message is marked as seen when the chat is open
        socket.emit("seen-msg", { chatId: data?.chat.id, userId: u?.id });
        // scrollToBottom()
      }
    };

    const handleTyping = (data) => {
      setIsTyping({
        status: true,
        username: data.username,
      });
    };

    const handleStopTyping = (data) => {
      setIsTyping({
        status: false,
        username: "",
      });
    };

    if (socket) {
      socket.on("message-recieved", handleReceiveMessage);
      socket.on("typing", handleTyping);
      socket.on("stop-typing", handleStopTyping);
    }

    return () => {
      if (socket) {
        socket.off("message-recieved", handleReceiveMessage);
        socket.off("typing", handleTyping);
        socket.off("stop-typing", handleStopTyping);
      }
    };
  }, [currentChat]);

  useEffect(() => {
    groupMessagesByDate();
    scrollToBottom(); // scroll to bottom when new message arrives
  }, [messages]);

  /**
   * Group messages by date
   * @returns {object} messages grouped by date
   */
  const groupMessagesByDate = () => {
    const groupedMessages = {};

    messages?.length > 0 &&
      messages.forEach((message) => {
        const messageDate = new Date(message.createdAt)
          .toISOString()
          .split("T")[0];
        if (!groupedMessages[messageDate]) {
          groupedMessages[messageDate] = [];
        }
        groupedMessages[messageDate].push(message);
      });
    return groupedMessages;
  };

  const groupedMessages = groupMessagesByDate();

  useEffect(() => {
    arrivalMessage && setMessages((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage]);

  /**
   *  sends message to the server, also send message to the socket
   * @param {string} msg  message to be sent
   */
  const handleSendMsg = async (msg) => {
    if (msg === "") {
      // if message is empty extra check
      return;
    }

    // scrollToBottom()
    const data = await JSON.parse(localStorage.getItem("user_data"));

    //add other users ids to the views array  meaning they have not seen messages
    const views = currentChat?.users?.filter((user_id) => user_id !== data.id);

    const response = await postApi("/chat/message", {
      sender: data.id,
      chat: currentChat?.id,
      content: msg,
      views: views,
    });


    socket.emit("new-message", response.data.data);

    const msgs = [...messages];
    msgs.push({ fromSelf: true, ...response.data.data });
    setMessages(msgs);
  };

  /**
   *  converts date format to human readable format
   * @param {string} inputDate  date to be formatted in yyyy-mm-dd format
   * @returns {string} formatted date eg: 12th October 2021
   */
  const convertDateFormat = (inputDate) => {
    // Split the input date into day, month, and year
    const formattedDate = new Date(`${inputDate}`);
    // Get the current date and create a date for yesterday
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    // Check if the input date is the same as today's date
    if (formattedDate.toDateString() === today.toDateString()) {
      return "Today";
    }
    // Check if the input date is the same as yesterday's date
    else if (formattedDate.toDateString() === yesterday.toDateString()) {
      return "Yesterday";
    }
    // If it's not today or yesterday, format and return the full date
    else {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return formattedDate.toLocaleDateString(undefined, options);
    }
  };

  /**
   * scrolls to the bottom of the chat
   */
  const scrollToBottom = () => {
    const chat = document.querySelector(".ctMsgArea");
    // chat.scrollTop = chat.scrollHeight;
    chat?.scrollTo({
      top: chat.scrollHeight,
      // behavior: "smooth",
    });
  };

  /**
   * if use is DIC open the porject details in DIC view else open in Investor view
   */
  const viewProject = () => {
    if (isDic.current === true) {
      navigate(`/dic/project-details/${currentChat?.asset_id}`);
    } else {
      navigate(`/project-details/${currentChat?.asset_id}`);
    }
  };

  const showMembersPopover = (event) => {
    setMembersAnchorEl(event.currentTarget);
  };

  const closeMembersPopover = () => {
    setMembersAnchorEl(null);
  };

  const linkToProject = (
    <Typography
      onClick={viewProject}
      component={"span"}
      style={{
        fontSize: "14px",
        fontWeight: 500,
        textDecoration: "underline",
        cursor: "pointer",
      }}
    >
      here.
    </Typography>
  );

  return (
    <>
      {currentChat === undefined ? (
        <Typography variant="h6" my={5} textAlign={"center"}>
          Select a chat to start messaging
        </Typography>
      ) : (
        <>
          <Paper elevation={2} className="ctUNav">
            <Stack
              justifyContent={"space-between"}
              alignItems={"center"}
              sx={{
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "row",
                },
              }}
            >
              <Box className="ctNav-box">
                {!noBtn ? <IconButton className="back-btn-chat" onClick={handleBackToChat}><ArrowBack /></IconButton> : null}
                <Box className="">
                  <Avatar
                    src={`${process.env.REACT_APP_IMAGE_URL}${currentChat?.asset_data.asset_gallery[0]?.images?.path}`}
                    alt={currentChat.asset_data?.asset_title}
                  />
                </Box>
                <Box className="ctUIts">
                  <Typography className="ctUImage-title">
                    {currentChat?.asset_data?.asset_title}
                  </Typography>
                  <Box>
                    <Typography className="ctUImage-subtitle">
                      Members: {currentChat?.user_details?.length}
                      <IconButton
                        sx={{
                          width: "16px",
                          height: "16px",
                          marginLeft: "6px",
                        }}
                        onClick={showMembersPopover}
                      >
                        <Info
                          sx={{ width: "14px", height: "14px", color: "white" }}
                        />
                      </IconButton>
                      {isTyping.status && isTyping.username && (
                        <>
                          <span className="username">
                            &nbsp;{isTyping.username}
                          </span>{" "}
                          is typing
                          <svg
                            height="8"
                            width="24"
                            className="typing-indicator"
                          >
                            <circle
                              className="dot"
                              cx="5"
                              cy="4"
                              r="2"
                              style={{ fill: "grey" }}
                            />
                            <circle
                              className="dot"
                              cx="12"
                              cy="4"
                              r="2"
                              style={{ fill: "grey" }}
                            />
                            <circle
                              className="dot"
                              cx="20"
                              cy="4"
                              r="2"
                              style={{ fill: "grey" }}
                            />
                          </svg>
                        </>
                      )}
                    </Typography>
                    <Popover
                      open={showMembers}
                      anchorEl={membersAnchorEl}
                      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                      onClose={closeMembersPopover}
                      sx={{}}
                    >
                      <Box sx={{ p: 2, backgroundColor: "#C5E4FF" }}>
                        {currentChat?.user_details?.map((user, index) => {
                          return (
                            <Box
                              key={index}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                mb: 1,
                              }}
                            >
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Avatar
                                  src={user?.persona_picture ?? User}
                                  sx={{ width: "24px", height: "24px", mr: 1 }}
                                />
                                <Typography
                                  sx={{ fontSize: "14px", fontWeight: 500 }}
                                >
                                  {user?.first_name + " " + user?.last_name}
                                </Typography>
                              </Box>
                              <Typography
                                sx={{ fontSize: "14px", fontWeight: 500 }}
                              >
                                {user?.role}
                              </Typography>
                            </Box>
                          );
                        })}
                      </Box>
                    </Popover>
                  </Box>
                </Box>
              </Box>
              <Stack
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                spacing={2}
                className=""
                sx={{
                  flexWrap: {
                    xs: "wrap", // for extra small screens and up
                    sm: "wrap", // for small screens and up
                    md: "wrap", // for medium screens and up
                  },
                }}
              >
                <Box className="ctNavChip">
                  <Typography className="ctSale-text">
                    {currentChat?.asset_data?.investment_type?.display_name ===
                      "Collateralize"
                      ? "Loan"
                      : currentChat?.asset_data?.investment_type?.display_name}
                  </Typography>
                </Box>
                {currentChat?.asset_data?.investment_type?.display_name !==
                  "Collateralize" &&
                  currentChat?.asset_data?.assets_category?.title !== "Funds" &&
                  currentChat?.asset_data?.assets_category.id ===
                  CATEGORY_TYPE_ID.WATCH &&
                  currentChat?.asset_data?.investment_type?.id !==
                  INVESTMENT_TYPE_ID.SALE ? (
                  <Box className="ctNavChip">
                    <Typography className="ctSale-text">
                      {currentChat?.asset_data?.asset_sub_category_info?.title}
                    </Typography>
                  </Box>
                ) : (
                  ""
                )}
                <Box className="ctNavChip">
                  <Typography className="ctSale-text">
                    {currentChat?.asset_data?.assets_category?.title === "Asset" ? "Luxury Cars" : currentChat?.asset_data?.assets_category?.title}
                  </Typography>
                </Box>
                <Box className="ctEyes">
                  <IconButton aria-label="view project" onClick={viewProject}>
                    <RemoveRedEye sx={{ color: "#ffffff" }} />
                  </IconButton>
                </Box>
              </Stack>
            </Stack>
          </Paper>

          <Box className="ctMsgArea chatBoxContainer" id="chat-scroller-area">
            {Object.keys(groupedMessages).map((date) => (
              <Box key={date} className="ctMsgDate">
                <Box className="ctDateChip">
                  <Typography>{convertDateFormat(date)}</Typography>
                </Box>

                {groupedMessages[date].map((message, index) => {
                  if (
                    message?.sender ===
                    JSON.parse(localStorage.getItem("user_data"))?.id
                  ) {
                    message.fromSelf = true;
                  }

                  return (
                    // Document updated message
                    message.type === "document_updated" ? (
                      <Box className="ctAlertMsgBox">
                        {message.fromSelf ? (
                          <>
                            <Avatar src={User}></Avatar>
                            <Typography className="ctMsgText">
                              {" "}
                              You updated documents. View documents{" "}
                              {linkToProject}{" "}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Avatar
                              src={
                                message?.user_details?.persona_picture ?? User
                              }
                            ></Avatar>
                            <Typography className="ctMsgText">
                              {" "}
                              Proposer has updated documents. View documents{" "}
                              {linkToProject}{" "}
                            </Typography>
                          </>
                        )}
                      </Box>
                    ) : // New Member joined or New chat created
                      message.type === "new_chat" ||
                        message.type === "new_user" ? (
                        <Box className="ctAlertMsgBox">
                          <Typography className="ctMsgText">
                            {" "}
                            {message.content}
                          </Typography>
                        </Box>
                      ) : (
                        <Box
                          key={index}
                          className={`ctMsgBox ${message.fromSelf ? "selfBox" : ""
                            }`}
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          <Avatar className="profile">
                            {" "}
                            {message?.user_details?.first_name[0] +
                              message?.user_details?.last_name[0]}
                          </Avatar>
                          <Box
                            className={`ctMsg ${message.fromSelf ? "self" : ""}`}
                          >
                            <Typography
                              className="ctMsgText"
                              // updated code to handle big string or message, break to next if message length more than 32
                              // preserves the spaces and line breaks by whiteSpace: 'pre-line'
                              style={{
                                whiteSpace: "pre-line",
                                overflowWrap: "anywhere",
                                textAlign: "left",
                                width: message.content.length > 32 ? "276px" : "",
                              }}
                            >
                              {message.content}
                            </Typography>
                          </Box>
                          <Typography className="ctMsgTime">
                            {!message.fromSelf &&
                              message?.user_details?.first_name +
                              " " +
                              message?.user_details?.last_name +
                              " - "}
                            {new Date(message.createdAt).toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                          </Typography>
                        </Box>
                      )
                  );
                })}
              </Box>
            ))}
          </Box>
          <ChatInput handleSendMsg={handleSendMsg} currentChat={currentChat} />
        </>
      )}
    </>
  );
}

export default ChatContainer;
