import React, { useEffect, useState } from "react";
import { useAccount, useNetwork } from "wagmi";
import { useDispatch } from "react-redux";
import { Close, Done } from "@mui/icons-material";
import { Box, Button, Typography, IconButton, Modal, Backdrop, CircularProgress, Chip, Stepper, Step, StepLabel, StepContent } from "@mui/material";
import ConnectWalletInvestor from "../../Web3/ConnectWalletInvestor";
import SnackbarAlert from "../Common/SnackbarAlert";
import { useSnackbar } from "../../Contexts/SnackbarContext";
import { GetApi, postApi, updateApi } from "../../Api/Api";
import { CATEGORY_TYPE_ID, INVESTMENT_TYPE_ID, SUB_CATEGORY_TYPE_ID, CONTRACT_NAME, CAPITAL_TYPE_ID } from "../../constants";
import { update } from "../../features/auth/authSlice";
import { race } from "../../Web3/race-chain";

// smart contract methods
import * as methods from "./methods";  // Import all methods from a single file
import '../../Pages/ProjectProposer/Questionnaire/Components/ProposerStakeRaceTokens.css'
import { GetContractAddress } from "../Common/GetContractAddress";
import { GetTokenAddress } from "../Common/GetTokenAddress";
import { createTokenizeAsset } from "./createTokenizeAsset";
import getCurrencyTypeDecimal from "../Common/getCurrencyTypeDecimal";

// Actions grouped into a single object
const { checkBalance, checkAllowance, approveToken, proposerStake, dicStake, completeReview, proposerApproveSuggestion, completeVote, assetListing, makeOffer, cancelOffer, changingOffer, acceptRejectOffer, directBuy, makeRemainingPayment, createEMI, dicSuggestions, payPrepayment, buyParcel, safeMint, approveMintAsset, getMintAssetApproved, withdrawAmountSale, withdrawAmountParcel, withdrawAmountSPVEquity, withdrawAmountSPVDebt, withdrawalAmountFunds, payEMI, payMinimumPrepayment, payDividendAmount, storeCallData, payCommitmentPayment, withdrawDICStake, releasedAsset, paySpaceXFundDistribution, closeFundSpaceX, withdrawalEscrowAmountSpaceX, payOpenFundDistribution, withdrawalEscrowAmountOpenFund, setRepaymentConfigs, withdrawDICCommission, payExitAmount } = methods;

// Main
export default function TransactionProgressModal(props) {
    const dispatch = useDispatch();
    const { chain } = useNetwork();
    console.log('transaction progress modal props...', props)

    const identity = props?.identity; // variable to identify the method type

    // Getting user wallet address
    const { address } = useAccount(); // wagmi (Hook) for accessing user account information

    // Wallet disconnect function
    const { showSnackbar } = useSnackbar(); // Hook for displaying snackbar or notifications

    // State variables initialization
    const [activeStep, setActiveStep] = useState(-1); // State variable for tracking active step in a progress modal
    const [associatedWallet, setAssociatedWallet] = useState(null); // State variable to hold associate wallet address in a progress modal
    const [isAssociated, setIsAssociated] = useState(null); // State variable to hold associate wallet address in a progress modal

    // State to check the token status
    const [TokenStatus, SetTokenStatus] = useState('')
    const [openSnackbar, setOpenSnackbar] = useState(false)

    // Default steps for a process
    const [steps, setSteps] = useState([
        { label: 'Wallet Connected', description: `You have to connect your wallet.` }, // Step indicating that the wallet is connected
        { label: 'Confirm Transaction', description: `You need to pay a transaction fee.` } // Step waiting for transaction confirmation
    ]);

    // Retrieving data from local storage based on certain conditions(please add the identity here for proposer side integration)
    const proposerSideIdentities = [
        'proposer-stake', 'approve-suggestion', 'listing', 'make-offer', 'cancel-offer',
        'changing-offer', 'accept-reject-offer', 'give-space-x-fund-token', 'direct-buy',
        'withdrawal-asset-sale', 'remaining-payment', 'create-emi', 'claim-token',
        'pay-for-closure', 'buy-parcel', 'withdrawal-parcel', 'withdraw-spv',
        'withdraw-spv-debt', 'pay-emi', 'prepayment', 'minimum-prepayment', 'pay-dividend', 'pay-exit-amount',
        'space-x-funds-distribution', 'open-funds-distribution', 'store-call-data', 'withdraw-funds',
        'space-x-withdraw-escrow', 'open-fund-withdraw-escrow', 'close-space-x-fund', 'released-asset', 'tokenize-mint'
    ];

    const localData = proposerSideIdentities.includes(identity) ? JSON.parse(localStorage.getItem("user_data")) : JSON.parse(localStorage.getItem("user_data_dic"));

    // monitor changes in wallet address
    useEffect(() => {
        console.log("wallet address", address); // Logging wallet address when it changes
    }, [address]); // Dependency array to trigger effect on changes to 'address'

    // Extracting necessary data from props
    let stakeAmt = props?.stakeAmt; // Amount to stake
    let offerAmount = identity === 'changing-offer' ? props?.propData?.editedData[0].value : props?.propData?.offer_amount;
    let changingAmt = identity === 'changing-offer' ? (parseFloat(props?.propData?.editedData[0].value) - parseFloat(props?.propData?.offerDetails?.offer_amount) > 0 ? parseFloat(props?.propData?.editedData[0].value) - parseFloat(props?.propData?.offerDetails?.offer_amount) : 0) : props?.propData?.offer_amount;
    let projectAmt = props?.projectAmt; // Amount of the project
    let proposalId = props?.propData?.blockchain_id; // Blockchain ID of the project
    let dicVoteType = props?.dicVoteType; // Type of vote from DIC
    let proposerDecision = props?.proposerDecision; // Decision made by the proposer
    let assetData = props?.propData; // Asset Data
    let depositAmount = props?.propData?.depositAmount ? props?.propData?.depositAmount : 0; // sale deposit amount
    let remainingAmount = props?.propData?.remaining_amount ? props?.propData?.remaining_amount : 0; // sale remaining payment

    /**
     * Function to set the disclaimer message for each step
     * @param {string} val The identity string
     * @returns {string[]} Array of disclaimer messages
     */
    const disclaimer = (val) => {
        switch (val) {
            case "proposer-stake":
                return [
                    "You have to connect your wallet.",
                    "Confirmation to approve tokens.",
                    "You need to pay the stake amount."
                ];
            case "dic-stake":
                return [
                    "You have to connect your wallet.",
                    "Confirmation to approve tokens.",
                    "You need to pay the stake amount."
                ];
            case "make-offer":
                return [
                    "You have to connect your wallet.",
                    "Confirmation to approve tokens.",
                    "You have to confirm the transaction."
                ];
            case "changing-offer":
                return [
                    "You have to connect your wallet.",
                    "Confirmation to approve tokens.",
                    "You have to confirm the transaction."
                ];
            case "remaining-payment":
                return [
                    "You have to connect your wallet.",
                    "Confirmation to approve tokens.",
                    "You have to confirm the transaction."
                ];
            case "direct-buy":
                return [
                    "You have to connect your wallet.",
                    "Confirmation to approve tokens.",
                    "You have to confirm the transaction."
                ];
            case "prepayment":
                return [
                    "You have to connect your wallet.",
                    "Confirmation to approve tokens.",
                    "You have to confirm the transaction."
                ];
            case "minimum-prepayment":
                return [
                    "You have to connect your wallet.",
                    "Confirmation to approve tokens.",
                    "You have to confirm the transaction."
                ];
            case "pay-emi":
                return [
                    "You have to connect your wallet.",
                    "Confirmation to approve tokens.",
                    "You have to confirm the transaction."
                ];
            case "pay-dividend":
                return [
                    "You have to connect your wallet.",
                    "Confirmation to approve tokens.",
                    "You have to confirm the transaction."
                ];
            case "pay-exit-amount":
                return [
                    "You have to connect your wallet.",
                    "Confirmation to approve tokens.",
                    "You have to confirm the transaction."
                ];
            case "space-x-funds-distribution":
                return [
                    "You have to connect your wallet.",
                    "Confirmation to approve tokens.",
                    "You have to confirm the transaction."
                ];
            case "open-funds-distribution":
                return [
                    "You have to connect your wallet.",
                    "Confirmation to approve tokens.",
                    "You have to confirm the transaction."
                ];
            case "pay-for-closure":
                return [
                    "You have to connect your wallet.",
                    "Confirmation to approve tokens.",
                    "You have to confirm the transaction."
                ];
            case "buy-parcel":
                return [
                    "You have to connect your wallet.",
                    "Confirmation to approve tokens.",
                    "You have to confirm the transaction."
                ];
            case "listing":
                return [
                    "You have to connect your wallet.",
                    "Minting asset.",
                    "Approve minted asset.",
                    "You need to pay a transaction fee."
                ];
            default:
                return ["", "", ""];
        }
    };


    /**
     * Function to set the description text
     */
    useEffect(() => {
        if (identity === "proposer-stake" || identity === "dic-stake" || identity === "make-offer" || identity === "changing-offer" || identity === "remaining-payment" || identity === "direct-buy" || identity === "prepayment" || identity === 'buy-parcel' || identity === 'pay-emi' || identity === 'minimum-prepayment' || identity === 'pay-dividend' || identity === 'space-x-funds-distribution' || identity === 'open-funds-distribution' || identity === 'pay-exit-amount') {
            if (assetData?.payment_method === 'fiat') {
            } else {
                // Setting new steps for the process
                const newSteps = [
                    { label: "Wallet Connected", description: disclaimer(identity)[0] },
                    { label: "Approve Tokens", description: disclaimer(identity)[1] },
                    { label: "Confirm Payment", description: disclaimer(identity)[2] }
                ];
                setSteps(newSteps);
            }
        } else if (identity === 'listing') {
            // Setting new steps for the process
            const newSteps = [
                { label: "Wallet Connected", description: disclaimer(identity)[0] },
                { label: "Mint Asset", description: disclaimer(identity)[1] },
                { label: "Approve Minted Asset", description: disclaimer(identity)[2] },
                { label: "Confirm Transaction", description: disclaimer(identity)[3] }
            ];
            setSteps(newSteps);
        }
    }, [identity]);

    /**
     * Calling Api to check the Token Current Status
     */
    useEffect(() => {
        async function CheckToken() {
            try {
                const checkTokenRes = await GetApi("/user/checkToken")
                if (checkTokenRes?.data?.code === 200 && checkTokenRes?.data?.status) {
                    SetTokenStatus(checkTokenRes?.data)
                }
            } catch (error) {
                console.log(error);
            }
        }
        CheckToken();
    }, []);

    // smart contract -- start
    useEffect(() => {
        if (TokenStatus && parseFloat(chain?.id) === parseFloat(race?.id)) {
            if (TokenStatus?.status) {
                if (address) {
                    // Function to make transaction to blockchain
                    async function makeTransaction() {
                        try {
                            // Set active step to 1
                            setActiveStep(1);

                            let balanceOf = 0;
                            let tokenAddress = null;
                            let tokenAbi = null;
                            if (assetData?.feeTokenType) {
                                // const responseToken = await GetTokenAddress(identity === 'proposer-stake' ? 'current' : assetData?.contract_version, assetData?.feeTokenType);
                                const responseToken = await GetTokenAddress(assetData?.contract_version ? assetData?.contract_version : assetData?.assetDetails?.contract_version ? assetData?.assetDetails?.contract_version : assetData?.val?.asset_data?.contract_version ? assetData?.val?.asset_data?.contract_version : 'current', assetData?.feeTokenType);
                                if (responseToken && responseToken?.length) {
                                    tokenAddress = responseToken[0].address;
                                    tokenAbi = responseToken[0].abi;
                                }
                                // Fetching balance of connected wallet address
                                balanceOf = await checkBalance(address, tokenAddress, tokenAbi)
                                console.log('balanceOf', balanceOf)
                            }

                            // Set the allowance amount and spender contract address
                            let allowanceAmt = null;
                            let spenderAddress = null;
                            let stakingContractAddress = null;
                            let stakingContractAbi = null;
                            let contractAddress = null;
                            let contractAbi = null;
                            let nftAddress = null;
                            let nftAbi = null;

                            // setting contract address, contract abi, staking address,staking abi, spender address and amount
                            if ((identity === 'proposer-stake' || identity === 'dic-stake')) {
                                if (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS && assetData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS) {
                                    const response = await GetContractAddress(assetData, identity === 'dic-stake' ? assetData?.contract_version : 'current');
                                    const contracts = response.reduce((acc, item) => {
                                        if (item.contract_name === CONTRACT_NAME.CREATE_PROPOSAL_CLOSE_FUND) {
                                            acc.createProposal = item;
                                        } else if (item.contract_name === CONTRACT_NAME.STAKE_CONTRACT_CLOSE_FUND) {
                                            acc.stakingPool = item;
                                        }
                                        return acc;
                                    }, {});
                                    if (contracts.createProposal) {
                                        contractAddress = contracts.createProposal.address;
                                        contractAbi = contracts.createProposal.abi;
                                    }
                                    if (contracts.stakingPool) {
                                        spenderAddress = contracts.stakingPool.address;
                                        stakingContractAddress = contracts.stakingPool.address;
                                        stakingContractAbi = contracts.stakingPool.abi;
                                    }
                                } else if (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS) {
                                    const response = await GetContractAddress(assetData, identity === 'dic-stake' ? assetData?.contract_version : 'current');
                                    const contracts = response.reduce((acc, item) => {
                                        if (item.contract_name === CONTRACT_NAME.CREATE_PROPOSAL_OPEN_FUND) {
                                            acc.createProposal = item;
                                        } else if (item.contract_name === CONTRACT_NAME.STAKE_CONTRACT_OPEN_FUND) {
                                            acc.stakingPool = item;
                                        }
                                        return acc;
                                    }, {});
                                    if (contracts.createProposal) {
                                        contractAddress = contracts.createProposal.address;
                                        contractAbi = contracts.createProposal.abi;
                                    }
                                    if (contracts.stakingPool) {
                                        spenderAddress = contracts.stakingPool.address;
                                        stakingContractAddress = contracts.stakingPool.address;
                                        stakingContractAbi = contracts.stakingPool.abi;
                                    }
                                } else if (assetData?.category_id === CATEGORY_TYPE_ID.ART && assetData?.investment_type_id === INVESTMENT_TYPE_ID.SALE) {
                                    const response = await GetContractAddress(assetData, identity === 'dic-stake' ? assetData?.contract_version : 'current');
                                    const contracts = response.reduce((acc, item) => {
                                        if (item.contract_name === CONTRACT_NAME.CREATE_PROPOSAL_SALE) {
                                            acc.createProposal = item;
                                        } else if (item.contract_name === CONTRACT_NAME.STAKE_CONTRACT_SALE) {
                                            acc.stakingPool = item;
                                        }
                                        return acc;
                                    }, {});
                                    if (contracts.createProposal) {
                                        contractAddress = contracts.createProposal.address;
                                        contractAbi = contracts.createProposal.abi;
                                    }
                                    if (contracts.stakingPool) {
                                        spenderAddress = contracts.stakingPool.address;
                                        stakingContractAddress = contracts.stakingPool.address;
                                        stakingContractAbi = contracts.stakingPool.abi;
                                    }
                                } else if (assetData?.category_id === CATEGORY_TYPE_ID.ART && assetData?.investment_type_id === INVESTMENT_TYPE_ID.PARCEL) {
                                    const response = await GetContractAddress(assetData, identity === 'dic-stake' ? assetData?.contract_version : 'current');
                                    const contracts = response.reduce((acc, item) => {
                                        if (item.contract_name === CONTRACT_NAME.CREATE_PROPOSAL_PARCEL) {
                                            acc.createProposal = item;
                                        } else if (item.contract_name === CONTRACT_NAME.STAKE_CONTRACT_PARCEL) {
                                            acc.stakingPool = item;
                                        }
                                        return acc;
                                    }, {});
                                    if (contracts.createProposal) {
                                        contractAddress = contracts.createProposal.address;
                                        contractAbi = contracts.createProposal.abi;
                                    }
                                    if (contracts.stakingPool) {
                                        spenderAddress = contracts.stakingPool.address;
                                        stakingContractAddress = contracts.stakingPool.address;
                                        stakingContractAbi = contracts.stakingPool.abi;
                                    }
                                } else if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN) {
                                    const response = await GetContractAddress(assetData, identity === 'dic-stake' ? assetData?.contract_version : 'current');
                                    const contracts = response.reduce((acc, item) => {
                                        if (item.contract_name === CONTRACT_NAME.CREATE_PROPOSAL_LOAN) {
                                            acc.createProposal = item;
                                        } else if (item.contract_name === CONTRACT_NAME.STAKE_CONTRACT_LOAN) {
                                            acc.stakingPool = item;
                                        }
                                        return acc;
                                    }, {});
                                    if (contracts.createProposal) {
                                        contractAddress = contracts.createProposal.address;
                                        contractAbi = contracts.createProposal.abi;
                                    }
                                    if (contracts.stakingPool) {
                                        spenderAddress = contracts.stakingPool.address;
                                        stakingContractAddress = contracts.stakingPool.address;
                                        stakingContractAbi = contracts.stakingPool.abi;
                                    }
                                } else if (assetData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
                                    const response = await GetContractAddress(assetData, identity === 'dic-stake' ? assetData?.contract_version : 'current');
                                    const contracts = response.reduce((acc, item) => {
                                        if (item.contract_name === CONTRACT_NAME.CREATE_PROPOSAL_SPV) {
                                            acc.createProposal = item;
                                        } else if (item.contract_name === CONTRACT_NAME.STAKE_CONTRACT_SPV) {
                                            acc.stakingPool = item;
                                        }
                                        return acc;
                                    }, {});
                                    if (contracts.createProposal) {
                                        contractAddress = contracts.createProposal.address;
                                        contractAbi = contracts.createProposal.abi;
                                    }
                                    if (contracts.stakingPool) {
                                        spenderAddress = contracts.stakingPool.address;
                                        stakingContractAddress = contracts.stakingPool.address;
                                        stakingContractAbi = contracts.stakingPool.abi;
                                    }
                                }
                                allowanceAmt = stakeAmt
                            } else if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.SALE || assetData?.assetDetails?.investment_type_id === INVESTMENT_TYPE_ID.SALE || assetData?.val?.asset_data?.investment_type?.id === INVESTMENT_TYPE_ID.SALE) {
                                if (identity === 'add-review' || identity === 'vote-on-proposal') {
                                    const response = await GetContractAddress(assetData, assetData?.contract_version, CONTRACT_NAME.DIC_PROPOSAL_SALE);
                                    if (response && response?.length) {
                                        contractAddress = response[0].address;
                                        contractAbi = response[0].abi;
                                    }
                                } else if (identity === 'withdraw-stake-dic' || identity === 'withdraw-commission-dic') {
                                    const response = await GetContractAddress(assetData?.assetDetails, assetData?.assetDetails?.contract_version, CONTRACT_NAME.STAKE_CONTRACT_SALE);
                                    if (response && response?.length) {
                                        contractAddress = response[0].address;
                                        contractAbi = response[0].abi;
                                    }
                                } else if (identity === 'dic-suggestions' || identity === 'approve-suggestion') {
                                    const response = await GetContractAddress(assetData, assetData?.contract_version, CONTRACT_NAME.CREATE_PROPOSAL_SALE);
                                    if (response && response?.length) {
                                        contractAddress = response[0].address;
                                        contractAbi = response[0].abi;
                                    }
                                } else if (identity === 'listing') {
                                    const response = await GetContractAddress(assetData?.assetDetails, assetData?.assetDetails?.contract_version);
                                    const contracts = response.reduce((acc, item) => {
                                        if (item.contract_name === CONTRACT_NAME.ART_SALE_MARKETPLACE) {
                                            acc.marketplace = item;
                                        } else if (item.contract_name === CONTRACT_NAME.NFT) {
                                            acc.nft = item;
                                        }
                                        return acc;
                                    }, {});
                                    if (contracts.marketplace) {
                                        contractAddress = contracts.marketplace.address;
                                        contractAbi = contracts.marketplace.abi;
                                    }
                                    if (contracts.nft) {
                                        nftAddress = contracts.nft.address;
                                        nftAbi = contracts.nft.abi;
                                    }
                                } else {
                                    if (identity === 'accept-reject-offer' && parseInt(assetData?.offer_status) === 3) {
                                        const response = await GetContractAddress({ ...assetData?.val?.asset_data }, assetData?.val?.asset_data?.contract_version, CONTRACT_NAME.ART_SALE_MARKETPLACE);
                                        if (response && response?.length) {
                                            contractAddress = response[0].address;
                                            contractAbi = response[0].abi;
                                        }
                                    } else {
                                        const response = await GetContractAddress({ ...assetData, category_id: CATEGORY_TYPE_ID.ART }, assetData?.contract_version, CONTRACT_NAME.ART_SALE_MARKETPLACE);
                                        if (response && response?.length) {
                                            contractAddress = response[0].address;
                                            contractAbi = response[0].abi;
                                            if (identity === 'make-offer' || identity === 'direct-buy' || identity === 'remaining-payment') {
                                                spenderAddress = response[0].address
                                            }
                                        }
                                    }
                                    if (identity === 'make-offer') {
                                        allowanceAmt = depositAmount
                                    } else if (identity === 'direct-buy') {
                                        allowanceAmt = (assetData?.amount)
                                    } else if (identity === 'remaining-payment') {
                                        allowanceAmt = remainingAmount;
                                    }
                                }
                            } else if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.PARCEL || assetData?.assetDetails?.investment_type_id === INVESTMENT_TYPE_ID.PARCEL) {
                                if (identity === 'add-review' || identity === 'vote-on-proposal') {
                                    const response = await GetContractAddress(assetData, assetData?.contract_version, CONTRACT_NAME.DIC_PROPOSAL_PARCEL);
                                    if (response && response?.length) {
                                        contractAddress = response[0].address;
                                        contractAbi = response[0].abi;
                                    }
                                } else if (identity === 'dic-suggestions' || identity === 'approve-suggestion') {
                                    const response = await GetContractAddress(assetData, assetData?.contract_version, CONTRACT_NAME.CREATE_PROPOSAL_PARCEL);
                                    if (response && response?.length) {
                                        contractAddress = response[0].address;
                                        contractAbi = response[0].abi;
                                    }
                                } else if (identity === 'withdraw-stake-dic') {
                                    const response = await GetContractAddress(assetData?.assetDetails, assetData?.assetDetails?.contract_version, CONTRACT_NAME.STAKE_CONTRACT_PARCEL);
                                    if (response && response?.length) {
                                        contractAddress = response[0].address;
                                        contractAbi = response[0].abi;
                                    }
                                } else if (identity === 'listing') {
                                    const response = await GetContractAddress(assetData?.assetDetails, assetData?.assetDetails?.contract_version);
                                    const contracts = response.reduce((acc, item) => {
                                        if (item.contract_name === CONTRACT_NAME.ART_PARCEL_MARKETPLACE) {
                                            acc.marketplace = item;
                                        } else if (item.contract_name === CONTRACT_NAME.NFT) {
                                            acc.nft = item;
                                        }
                                        return acc;
                                    }, {});
                                    if (contracts.marketplace) {
                                        contractAddress = contracts.marketplace.address;
                                        contractAbi = contracts.marketplace.abi;
                                    }
                                    if (contracts.nft) {
                                        nftAddress = contracts.nft.address;
                                        nftAbi = contracts.nft.abi;
                                    }
                                } else {
                                    const response = await GetContractAddress({ ...assetData, category_id: CATEGORY_TYPE_ID.ART }, assetData?.contract_version, CONTRACT_NAME.ART_PARCEL_MARKETPLACE);
                                    if (response && response?.length) {
                                        contractAddress = response[0].address;
                                        contractAbi = response[0].abi;
                                        if (identity === 'buy-parcel') {
                                            spenderAddress = response[0].address
                                        }
                                    }
                                    if (identity === 'buy-parcel') {
                                        allowanceAmt = Math.ceil(assetData?.amount * 10) / 10
                                    }
                                }
                            } else if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN || assetData?.assetDetails?.investment_type_id === INVESTMENT_TYPE_ID.LOAN || assetData?.val?.asset_data?.investment_type?.id === INVESTMENT_TYPE_ID.LOAN) {
                                if (identity === 'add-review' || identity === 'vote-on-proposal' || identity === 'set-repayment-config') {
                                    const response = await GetContractAddress(assetData, assetData?.contract_version, CONTRACT_NAME.DIC_PROPOSAL_LOAN);
                                    if (response && response?.length) {
                                        contractAddress = response[0].address;
                                        contractAbi = response[0].abi;
                                    }
                                } else if (identity === 'dic-suggestions' || identity === 'approve-suggestion') {
                                    const response = await GetContractAddress(assetData, assetData?.contract_version, CONTRACT_NAME.CREATE_PROPOSAL_LOAN);
                                    if (response && response?.length) {
                                        contractAddress = response[0].address;
                                        contractAbi = response[0].abi;
                                    }
                                } else if (identity === 'withdraw-stake-dic') {
                                    const response = await GetContractAddress(assetData?.assetDetails, assetData?.assetDetails?.contract_version, CONTRACT_NAME.STAKE_CONTRACT_LOAN);
                                    if (response && response?.length) {
                                        contractAddress = response[0].address;
                                        contractAbi = response[0].abi;
                                    }
                                } else if (identity === 'listing') {
                                    const response = await GetContractAddress(assetData?.assetDetails, assetData?.assetDetails?.contract_version);
                                    const contracts = response.reduce((acc, item) => {
                                        if (item.contract_name === CONTRACT_NAME.WATCH_MARKETPLACE) {
                                            acc.marketplace = item;
                                        } else if (item.contract_name === CONTRACT_NAME.NFT) {
                                            acc.nft = item;
                                        }
                                        return acc;
                                    }, {});
                                    if (contracts.marketplace) {
                                        contractAddress = contracts.marketplace.address;
                                        contractAbi = contracts.marketplace.abi;
                                    }
                                    if (contracts.nft) {
                                        nftAddress = contracts.nft.address;
                                        nftAbi = contracts.nft.abi;
                                    }
                                } else {
                                    if (identity === 'accept-reject-offer' && parseInt(assetData?.offer_status) === 3) {
                                        let contract_version = assetData?.val?.asset_data?.contract_version;
                                        const response = await GetContractAddress({ ...assetData?.val?.asset_data }, contract_version, CONTRACT_NAME.WATCH_DEBT_OFFER_ADDRESS);
                                        if (response && response?.length) {
                                            contractAddress = response[0].address;
                                            contractAbi = response[0].abi;
                                        }
                                    } else if (identity === 'make-offer' || identity === 'changing-offer' || identity === 'cancel-offer' || identity === 'accept-reject-offer' || identity === 'create-emi') {
                                        let contract_version = assetData?.contract_version;
                                        const response = await GetContractAddress({ ...assetData }, contract_version, CONTRACT_NAME.WATCH_DEBT_OFFER_ADDRESS);
                                        if (response && response?.length) {
                                            contractAddress = response[0].address;
                                            contractAbi = response[0].abi;
                                            if (identity === 'changing-offer' || identity === 'make-offer') {
                                                spenderAddress = response[0].address
                                            }
                                        }
                                    } else if (identity === 'prepayment' || identity === 'pay-emi' || identity === 'minimum-prepayment') {
                                        let contract_version = assetData?.contract_version;
                                        const response = await GetContractAddress({ ...assetData }, contract_version, CONTRACT_NAME.WATCH_REPAYMENT_ADDRESS);
                                        if (response && response?.length) {
                                            contractAddress = response[0].address;
                                            contractAbi = response[0].abi;
                                            spenderAddress = response[0].address
                                        }
                                    }
                                    if (identity === 'changing-offer') {
                                        allowanceAmt = changingAmt
                                    } else if ((identity === 'make-offer')) {
                                        allowanceAmt = offerAmount;
                                    } else if (identity === 'prepayment') {
                                        allowanceAmt = Math.ceil(assetData?.prepayment_amount)
                                    } else if (identity === 'pay-emi') {
                                        allowanceAmt = (assetData?.emi_amount)
                                    } else if (identity === 'minimum-prepayment') {
                                        allowanceAmt = (assetData?.min_prepayment_amount)
                                    }
                                }
                            } else if (assetData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE || assetData?.assetDetails?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE || assetData?.val?.asset_data?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE || assetData?.assetData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
                                if (identity === 'add-review' || identity === 'vote-on-proposal' || identity === 'set-repayment-config') {
                                    const response = await GetContractAddress(assetData, assetData?.contract_version, CONTRACT_NAME.DIC_PROPOSAL_SPV);
                                    if (response && response?.length) {
                                        contractAddress = response[0].address;
                                        contractAbi = response[0].abi;
                                    }
                                } else if (identity === 'dic-suggestions' || identity === 'approve-suggestion' || identity === 'store-call-data') {
                                    const response = await GetContractAddress(identity === 'store-call-data' ? assetData?.assetData : assetData, identity === 'store-call-data' ? assetData?.assetData?.contract_version : assetData?.contract_version, CONTRACT_NAME.CREATE_PROPOSAL_SPV);
                                    if (response && response?.length) {
                                        contractAddress = response[0].address;
                                        contractAbi = response[0].abi;
                                    }
                                } else if (identity === 'withdraw-stake-dic') {
                                    const response = await GetContractAddress(assetData?.assetDetails, assetData?.assetDetails?.contract_version, CONTRACT_NAME.STAKE_CONTRACT_SPV);
                                    if (response && response?.length) {
                                        contractAddress = response[0].address;
                                        contractAbi = response[0].abi;
                                    }
                                } else if (identity === 'listing') {
                                    const response = await GetContractAddress(assetData?.assetDetails, assetData?.assetDetails?.contract_version);
                                    const contracts = response.reduce((acc, item) => {
                                        if (item.contract_name === CONTRACT_NAME.SPV_MARKETPLACE) {
                                            acc.marketplace = item;
                                        } else if (item.contract_name === CONTRACT_NAME.NFT) {
                                            acc.nft = item;
                                        }
                                        return acc;
                                    }, {});
                                    if (contracts.marketplace) {
                                        contractAddress = contracts.marketplace.address;
                                        contractAbi = contracts.marketplace.abi;
                                    }
                                    if (contracts.nft) {
                                        nftAddress = contracts.nft.address;
                                        nftAbi = contracts.nft.abi;
                                    }
                                } else {
                                    if (identity === 'accept-reject-offer' && parseInt(assetData?.offer_status) === 3) {
                                        let contract_version = assetData?.val?.asset_data?.contract_version;
                                        const response = await GetContractAddress({ ...assetData?.val?.asset_data }, contract_version, (assetData?.capital_type_id === CAPITAL_TYPE_ID.EQUITY ? CONTRACT_NAME.SPV_EQUITY_INVESTMENT : CONTRACT_NAME.SPV_DEBT_INVESTMENT));
                                        if (response && response?.length) {
                                            contractAddress = response[0].address;
                                            contractAbi = response[0].abi;
                                        }
                                    } else if (identity === 'make-offer' || identity === 'changing-offer' || identity === 'cancel-offer' || identity === 'accept-reject-offer' || identity === 'withdraw-spv' || identity === 'withdraw-spv-debt') {
                                        let contract_version = assetData?.contract_version;
                                        const response = await GetContractAddress({ ...assetData }, contract_version, (assetData?.capital_type_id === CAPITAL_TYPE_ID.EQUITY ? CONTRACT_NAME.SPV_EQUITY_INVESTMENT : CONTRACT_NAME.SPV_DEBT_INVESTMENT));
                                        if (response && response?.length) {
                                            contractAddress = response[0].address;
                                            contractAbi = response[0].abi;
                                            if (identity === 'changing-offer' || identity === 'make-offer') {
                                                spenderAddress = response[0].address
                                            }
                                        }
                                    } else if (identity === 'prepayment' || identity === 'pay-emi' || identity === 'minimum-prepayment' || identity === 'pay-dividend' || identity === 'pay-exit-amount') {
                                        let contract_version = assetData?.contract_version;
                                        const response = await GetContractAddress({ ...assetData }, contract_version, (assetData?.capital_type_id === CAPITAL_TYPE_ID.EQUITY ? CONTRACT_NAME.SPV_EQUITY_DISTRIBUTION : CONTRACT_NAME.SPV_DEBT_REPAYMENT));
                                        if (response && response?.length) {
                                            contractAddress = response[0].address;
                                            contractAbi = response[0].abi;
                                            spenderAddress = response[0].address
                                        }
                                    }
                                    if (identity === 'changing-offer') {
                                        allowanceAmt = changingAmt
                                    } else if ((identity === 'make-offer')) {
                                        allowanceAmt = offerAmount;
                                    } else if (identity === 'prepayment') {
                                        allowanceAmt = assetData?.prepayment_amount
                                    } else if (identity === 'pay-emi') {
                                        allowanceAmt = (assetData?.emi_amount)
                                    } else if (identity === 'minimum-prepayment') {
                                        allowanceAmt = (assetData?.min_prepayment_amount)
                                    } else if (identity === 'pay-dividend') {
                                        allowanceAmt = assetData?.amount
                                    } else if (identity === 'pay-exit-amount') {
                                        allowanceAmt = assetData?.amount
                                    }
                                }
                            } else if (((assetData?.category_id === CATEGORY_TYPE_ID.FUNDS && assetData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS) || (assetData?.assetDetails?.category_id === CATEGORY_TYPE_ID.FUNDS && assetData?.assetDetails?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS) || (assetData?.val?.asset_data?.category_id === CATEGORY_TYPE_ID.FUNDS && assetData?.val?.asset_data?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS) || (assetData?.assetData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS))) {
                                if (identity === 'add-review' || identity === 'vote-on-proposal' || identity === 'set-repayment-config') {
                                    const response = await GetContractAddress(assetData, assetData?.contract_version, CONTRACT_NAME.DIC_PROPOSAL_CLOSE_FUND);
                                    if (response && response?.length) {
                                        contractAddress = response[0].address;
                                        contractAbi = response[0].abi;
                                    }
                                } else if (identity === 'dic-suggestions' || identity === 'approve-suggestion' || identity === 'store-call-data') {
                                    const response = await GetContractAddress(identity === 'store-call-data' ? assetData?.assetData : assetData, identity === 'store-call-data' ? assetData?.assetData?.contract_version : assetData?.contract_version, CONTRACT_NAME.CREATE_PROPOSAL_CLOSE_FUND);
                                    if (response && response?.length) {
                                        contractAddress = response[0].address;
                                        contractAbi = response[0].abi;
                                    }
                                } else if (identity === 'withdraw-stake-dic') {
                                    const response = await GetContractAddress(assetData?.assetDetails, assetData?.assetDetails?.contract_version, CONTRACT_NAME.STAKE_CONTRACT_CLOSE_FUND);
                                    if (response && response?.length) {
                                        contractAddress = response[0].address;
                                        contractAbi = response[0].abi;
                                    }
                                } else if (identity === 'listing') {
                                    const response = await GetContractAddress(assetData?.assetDetails, assetData?.assetDetails?.contract_version);
                                    const contracts = response.reduce((acc, item) => {
                                        if (item.contract_name === CONTRACT_NAME.CLOSE_FUNDS_MARKETPLACE) {
                                            acc.marketplace = item;
                                        } else if (item.contract_name === CONTRACT_NAME.NFT) {
                                            acc.nft = item;
                                        }
                                        return acc;
                                    }, {});
                                    if (contracts.marketplace) {
                                        contractAddress = contracts.marketplace.address;
                                        contractAbi = contracts.marketplace.abi;
                                    }
                                    if (contracts.nft) {
                                        nftAddress = contracts.nft.address;
                                        nftAbi = contracts.nft.abi;
                                    }
                                } else {
                                    if (identity === 'accept-reject-offer' && parseInt(assetData?.offer_status) === 3) {
                                        const response = await GetContractAddress({ ...assetData?.val?.asset_data }, assetData?.val?.asset_data?.contract_version, CONTRACT_NAME.CLOSE_FUNDS_INVESTMENT);
                                        if (response && response?.length) {
                                            contractAddress = response[0].address;
                                            contractAbi = response[0].abi;
                                        }
                                    } else if (identity === 'make-offer' || identity === 'cancel-offer' || identity === 'close-space-x-fund' || identity === 'accept-reject-offer' || identity === 'withdraw-funds') {
                                        const response = await GetContractAddress(identity === 'cancel-offer' ? { ...assetData?.val?.asset_data } : { ...assetData }, assetData?.contract_version, CONTRACT_NAME.CLOSE_FUNDS_INVESTMENT);
                                        if (response && response?.length) {
                                            contractAddress = response[0].address;
                                            contractAbi = response[0].abi;
                                            if (identity === 'make-offer') {
                                                spenderAddress = response[0].address
                                            }
                                        }
                                    } else if (identity === 'space-x-funds-distribution' || identity === 'space-x-withdraw-escrow') {
                                        const response = await GetContractAddress({ ...assetData }, assetData?.contract_version, CONTRACT_NAME.CLOSE_FUNDS_DISTRIBUTION);
                                        if (response && response?.length) {
                                            contractAddress = response[0].address;
                                            contractAbi = response[0].abi;
                                            spenderAddress = response[0].address
                                        }
                                    }
                                    if (identity === 'make-offer') {
                                        if (assetData?.payment_method === 'fiat') { } else {
                                            allowanceAmt = offerAmount
                                        }
                                    } else if (identity === 'remaining-payment') {
                                        allowanceAmt = remainingAmount;
                                    } else if (identity === 'space-x-funds-distribution') {
                                        allowanceAmt = assetData?.amount
                                    }
                                }
                            } else if (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS || assetData?.assetDetails?.category_id === CATEGORY_TYPE_ID.FUNDS || assetData?.val?.asset_data?.category_id === CATEGORY_TYPE_ID.FUNDS || assetData?.assetData?.category_id === CATEGORY_TYPE_ID.FUNDS) {
                                if (identity === 'add-review' || identity === 'vote-on-proposal' || identity === 'set-repayment-config') {
                                    const response = await GetContractAddress(assetData, assetData?.contract_version, CONTRACT_NAME.DIC_PROPOSAL_OPEN_FUND);
                                    if (response && response?.length) {
                                        contractAddress = response[0].address;
                                        contractAbi = response[0].abi;
                                    }
                                } else if (identity === 'dic-suggestions' || identity === 'approve-suggestion' || identity === 'store-call-data') {
                                    const response = await GetContractAddress(identity === 'store-call-data' ? assetData?.assetData : assetData, identity === 'store-call-data' ? assetData?.assetData?.contract_version : assetData?.contract_version, CONTRACT_NAME.CREATE_PROPOSAL_OPEN_FUND);
                                    if (response && response?.length) {
                                        contractAddress = response[0].address;
                                        contractAbi = response[0].abi;
                                    }
                                } else if (identity === 'withdraw-stake-dic') {
                                    const response = await GetContractAddress(assetData?.assetDetails, assetData?.assetDetails?.contract_version, CONTRACT_NAME.STAKE_CONTRACT_OPEN_FUND);
                                    if (response && response?.length) {
                                        contractAddress = response[0].address;
                                        contractAbi = response[0].abi;
                                    }
                                } else if (identity === 'listing') {
                                    const response = await GetContractAddress(assetData?.assetDetails, assetData?.assetDetails?.contract_version);
                                    const contracts = response.reduce((acc, item) => {
                                        if (item.contract_name === CONTRACT_NAME.FUNDS_MARKETPLACE) {
                                            acc.marketplace = item;
                                        } else if (item.contract_name === CONTRACT_NAME.NFT) {
                                            acc.nft = item;
                                        }
                                        return acc;
                                    }, {});
                                    if (contracts.marketplace) {
                                        contractAddress = contracts.marketplace.address;
                                        contractAbi = contracts.marketplace.abi;
                                    }
                                    if (contracts.nft) {
                                        nftAddress = contracts.nft.address;
                                        nftAbi = contracts.nft.abi;
                                    }
                                }
                                else {
                                    if (identity === 'accept-reject-offer' && parseInt(assetData?.offer_status) === 3) {
                                        const response = await GetContractAddress({ ...assetData?.val?.asset_data }, assetData?.val?.asset_data?.contract_version, CONTRACT_NAME.FUNDS_INVESTMENT);
                                        if (response && response?.length) {
                                            contractAddress = response[0].address;
                                            contractAbi = response[0].abi;
                                        }
                                    } else if (identity === 'make-offer' || identity === 'accept-reject-offer' || identity === 'remaining-payment' || identity === 'withdraw-funds') {
                                        const response = await GetContractAddress({ ...assetData }, assetData?.contract_version, CONTRACT_NAME.FUNDS_INVESTMENT);
                                        if (response && response?.length) {
                                            contractAddress = response[0].address;
                                            contractAbi = response[0].abi;
                                            if (identity === 'make-offer' || identity === 'remaining-payment') {
                                                spenderAddress = response[0].address
                                            }
                                        }
                                    } else if (identity === 'open-funds-distribution' || identity === 'open-fund-withdraw-escrow') {
                                        const response = await GetContractAddress({ ...assetData }, assetData?.contract_version, CONTRACT_NAME.FUNDS_DISTRIBUTION);
                                        if (response && response?.length) {
                                            contractAddress = response[0].address;
                                            contractAbi = response[0].abi;
                                            if (identity === 'open-funds-distribution') {
                                                spenderAddress = response[0].address
                                            }
                                        }
                                    }
                                    if (identity === 'make-offer') {
                                        allowanceAmt = offerAmount
                                    } else if (identity === 'remaining-payment') {
                                        allowanceAmt = remainingAmount;
                                    } else if (identity === 'give-space-x-fund-token') {
                                        spenderAddress = ''
                                    } else if (identity === 'open-funds-distribution') {
                                        allowanceAmt = assetData?.amount
                                    }
                                }
                            }

                            console.log('allowanceAmt', allowanceAmt);
                            console.log('spenderAddress', spenderAddress);
                            console.log('stakingContractAddress', stakingContractAddress);
                            console.log('stakingContractAbi', stakingContractAbi);
                            console.log("contractAddress", contractAddress);
                            console.log("contractAbi", contractAbi);
                            console.log("nftAddress", nftAddress);
                            console.log("nftAbi", nftAbi);
                            console.log("tokenAddress", tokenAddress);
                            console.log("tokenAbi", tokenAbi);

                            // check if allowance amount and spender address exist
                            if (allowanceAmt > 0 && spenderAddress) {

                                // Return and Exit if insufficient RACE/USDT Tokens balance in the wallet
                                if ((balanceOf) < getCurrencyTypeDecimal(assetData?.feeTokenType, allowanceAmt)) {
                                    showSnackbar(`Insufficient ${assetData?.feeTokenType ? assetData?.feeTokenType : 'USDT'} Tokens`, 'error');
                                    props?.handleModalClose();
                                    return
                                }

                                // Now check for allowance RACE/USDT Tokens
                                const allowance = await checkAllowance(address, tokenAddress, tokenAbi, spenderAddress);

                                // If not enough allowance available then, call approve method
                                if ((allowance) < getCurrencyTypeDecimal(assetData?.feeTokenType, allowanceAmt)) {
                                    const data = await approveToken(
                                        address, // user wallet address
                                        tokenAddress,
                                        tokenAbi,
                                        spenderAddress, // spender address
                                        parseFloat(allowanceAmt), // allowance amount
                                        assetData?.feeTokenType, // fee token type
                                    )
                                    if (data.status === "success") {
                                        console.log('data', data)
                                        showSnackbar("Transaction Successful", 'success')
                                    } else {
                                        props?.handleModalClose();
                                        setActiveStep(-1)
                                        showSnackbar("Transaction Failed", 'error')
                                        return
                                    }
                                }

                                // Now check for new allowance RACE/USDT Tokens
                                const newAllowance = await checkAllowance(address, tokenAddress, tokenAbi, spenderAddress);
                                console.log('newAllowance', getCurrencyTypeDecimal(assetData?.feeTokenType, allowanceAmt));

                                // Return and Exit if insufficient RACE/USDT Tokens allowance to spender contract
                                if (newAllowance < getCurrencyTypeDecimal(assetData?.feeTokenType, allowanceAmt)) {
                                    showSnackbar('Insufficient allowance', 'error');
                                    props?.handleModalClose();
                                    return
                                }

                                // Return and Exit if project is not live
                                if ((identity === 'make-offer' || identity === "direct-buy" || identity === "buy-parcel") && !assetData?.listing_id) {
                                    showSnackbar("This project is currently not live.", 'error')
                                    props?.handleModalClose();
                                    return
                                }
                            }

                            // mint asset and approve minted asset
                            let mintedAssetId = '';
                            if (identity === 'listing') {
                                // listing mint asset 
                                if (assetData?.assetDetails?.mint_token_id) {
                                    mintedAssetId = assetData?.assetDetails?.mint_token_id;
                                    setActiveStep(2);
                                    console.log('mintedAssetId exist', assetData?.assetDetails?.mint_token_id);
                                } else {
                                    const newAssetData = await postApi("/proposer/assetsDetails", { "asset_id": assetData?.assetDetails?.id });
                                    if (newAssetData?.data?.data && newAssetData?.data?.data[0] && newAssetData?.data?.data[0].mint_token_id) {
                                        mintedAssetId = newAssetData?.data?.data[0]?.mint_token_id;
                                    } else {
                                        mintedAssetId = await safeMint(address, nftAddress, nftAbi, assetData);
                                        const res = await updateApi('proposer/updateMintTokenId/' + assetData?.assetDetails?.id, { mint_token_id: mintedAssetId });
                                        if (res?.data?.code === 200) {
                                            console.log('Updated Token ID')
                                        }
                                        setActiveStep(2);
                                        console.log('new mintedAssetId', mintedAssetId);
                                    }
                                }

                                // listing approve minted asset (sale)
                                if (mintedAssetId) {
                                    const newAssetData = await postApi("/proposer/assetsDetails", { "asset_id": assetData?.assetDetails?.id });
                                    if (newAssetData?.data?.data && newAssetData?.data?.data[0] && newAssetData?.data?.data[0].approved_token_id) {
                                        await getMintAssetApproved(address, nftAddress, nftAbi, mintedAssetId);
                                        setActiveStep(3);
                                    } else {
                                        let escrowAddress = contractAddress;
                                        await approveMintAsset(address, nftAddress, nftAbi, mintedAssetId, escrowAddress);
                                        const res = await updateApi('proposer/updateMintTokenId/' + assetData?.assetDetails?.id, { approved_token_id: true });
                                        if (res?.data?.code === 200) {
                                            console.log('Updated minted asset status')
                                        }
                                        setActiveStep(3);
                                    }
                                }
                            }

                            // Now call the method as required
                            switch (identity) {
                                case 'dic-stake':
                                    await handleDicStake();
                                    break;
                                case 'tokenize-mint':
                                    await handleTokenizeMint();
                                    break;
                                case 'dic-suggestions':
                                    await handleDicSuggestions();
                                    break;
                                case 'add-review':
                                    await handleAddReview();
                                    break;
                                case 'set-repayment-config':
                                    await handleSetRepaymentConfig();
                                    break;
                                case 'released-asset':
                                    await handleReleasedAsset();
                                    break;
                                case 'vote-on-proposal':
                                    await handleVoteOnProposal();
                                    break;
                                case 'proposer-stake':
                                    await handleProposerStake();
                                    break;
                                case 'approve-suggestion':
                                    await handleApproveSuggestion();
                                    break;
                                case 'listing':
                                    await handleListing(mintedAssetId);
                                    break;
                                case 'make-offer':
                                    await handleMakeOffer();
                                    break;
                                case 'cancel-offer':
                                    await handleCancelOffer();
                                    break;
                                case 'changing-offer':
                                    await handleChangingOffer();
                                    break;
                                case 'accept-reject-offer':
                                    await handleAcceptRejectOffer();
                                    break;
                                case 'direct-buy':
                                    await handleDirectBuy();
                                    break;
                                case 'remaining-payment':
                                    await handleRemainingPayment();
                                    break;
                                case 'withdrawal-asset-sale':
                                    await handleWithdrawSaleAsset();
                                    break;
                                case 'withdrawal-parcel':
                                    await handleWithdrawParcelAsset();
                                    break;
                                case 'withdraw-spv':
                                    await handleWithdrawAmountSPV();
                                    break;
                                case 'withdraw-spv-debt':
                                    await handleWithdrawAmountSPVDebt();
                                    break;
                                case 'withdraw-funds':
                                    await handleWithdrawAmountFunds();
                                    break;
                                case 'space-x-withdraw-escrow':
                                    await handleWithdrawEscrowSpaceX();
                                    break;
                                case 'open-fund-withdraw-escrow':
                                    await handleWithdrawEscrowOpenFunds();
                                    break;
                                case 'create-emi':
                                    await handleCreateEMI();
                                    break;
                                case 'prepayment':
                                    await handlePrepayment();
                                    break;
                                case 'minimum-prepayment':
                                    await handleMinimumPrepayment();
                                    break;
                                case 'pay-emi':
                                    await handlePayEMI();
                                    break;
                                case 'pay-dividend':
                                    await handlePayDividend();
                                    break;
                                case 'pay-exit-amount':
                                    await handlePayExitAmount();
                                    break;
                                case 'space-x-funds-distribution':
                                    await handleSpaceXFundDistribution();
                                    break;
                                case 'open-funds-distribution':
                                    await handleOpenFundDistribution();
                                    break;
                                case 'buy-parcel':
                                    await handleBuyParcel();
                                    break;
                                case 'store-call-data':
                                    await handleStoreCallData();
                                    break;
                                case 'withdraw-stake-dic':
                                    await handleWithdrawStakeDic();
                                    break;
                                case 'withdraw-commission-dic':
                                    await handleWithdrawCommissionDic();
                                    break;
                                case 'close-space-x-fund':
                                    await handleCloseFundSpaceX();
                                    break;
                                default:
                                    // handle default case if needed
                                    break;
                            }

                            /**
                            * handle proposer stack amount for project propose
                            */
                            async function handleProposerStake() {
                                await proposerStake(address, contractAddress, contractAbi, tokenAddress, props?.propData?.user_id, props?.propData?.asset_id, props?.propData?.blockchain_category_id, props?.propData?.blockchain_investment_type_id, Number(projectAmt), Number(stakeAmt), showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, props?.propData);
                            }

                            /**
                            * handle pay dic stake amount 
                            */
                            async function handleDicStake() {
                                await dicStake(address, stakingContractAddress, stakingContractAbi, tokenAddress, props?.propData?.user_id, props?.propData?.id, proposalId, stakeAmt, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, props?.propData);
                            }

                            /**
                            * handle pay dic stake amount 
                            */
                            async function handleTokenizeMint() {
                                await createTokenizeAsset(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle pay dic stake amount 
                            */
                            async function handleStoreCallData() {
                                await storeCallData(address, contractAddress, contractAbi, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle pay dic stake amount 
                            */
                            async function handleWithdrawStakeDic() {
                                await withdrawDICStake(address, contractAddress, contractAbi, assetData, props?.propData?.user_id, props?.propData?.id, stakeAmt, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle pay dic stake amount 
                            */
                            async function handleWithdrawCommissionDic() {
                                await withdrawDICCommission(address, contractAddress, contractAbi, assetData, props?.propData?.user_id, props?.propData?.id, stakeAmt, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle pay dic stake amount 
                            */
                            async function handleCloseFundSpaceX() {
                                await closeFundSpaceX(address, contractAddress, contractAbi, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle dic suggestion terms for all assets 
                            */
                            async function handleDicSuggestions() {
                                await dicSuggestions(address, contractAddress, contractAbi, props?.suggestedDataPayload, assetData, proposalId, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle complete review by dic  
                            */
                            async function handleAddReview() {
                                await completeReview(address, contractAddress, contractAbi, proposalId, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, props?.propData);
                            }
                            /**
                            * handle complete review by dic  
                            */
                            async function handleSetRepaymentConfig() {
                                await setRepaymentConfigs(address, contractAddress, contractAbi, props?.propData, props?.propData?.user_id, props?.propData?.asset_id, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle complete review by dic  
                            */
                            async function handleReleasedAsset() {
                                await releasedAsset(address, contractAddress, contractAbi, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle complete vote by dic  
                            */
                            async function handleVoteOnProposal() {
                                await completeVote(address, contractAddress, contractAbi, proposalId, dicVoteType, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, props?.propData);
                            }

                            /**
                            * handle update deal terms by dic 
                            */
                            async function handleApproveSuggestion() {
                                await proposerApproveSuggestion(address, contractAddress, contractAbi, proposalId, proposerDecision, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, props?.propData);
                            }

                            /**
                            * handle listing of project or go publish 
                            */
                            async function handleListing(tokenId) {
                                await assetListing(address, contractAddress, contractAbi, nftAddress, assetData, props?.propData?.assetDetails, props?.propData?.parcelNFTs, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, tokenId);
                            }

                            /**
                            * handle create offer by investor
                            */
                            async function handleMakeOffer() {
                                await makeOffer(address, contractAddress, contractAbi, tokenAddress, assetData, offerAmount, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle cancel the offer by investor  
                            */
                            async function handleCancelOffer() {
                                await cancelOffer(address, contractAddress, contractAbi, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle edit the offer by investor  
                            */
                            async function handleChangingOffer() {
                                await changingOffer(address, contractAddress, contractAbi, tokenAddress, assetData, offerAmount, Number(props?.propData?.editedData[2]?.value), Number(props?.propData?.editedData[1]?.value), showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle offer accept/reject by proposer
                            */
                            async function handleAcceptRejectOffer() {
                                await acceptRejectOffer(address, contractAddress, contractAbi, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle make full payment on direct buy sale asset 
                            */
                            async function handleDirectBuy() {
                                await directBuy(address, contractAddress, contractAbi, tokenAddress, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle make full payment on direct buy sale asset 
                            */
                            async function handleBuyParcel() {
                                await buyParcel(address, contractAddress, contractAbi, tokenAddress, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle pay remaining payment of the offer on sale asset 
                            */
                            async function handleRemainingPayment() {
                                if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.FRACTION) {
                                    await payCommitmentPayment(address, contractAddress, contractAbi, tokenAddress, assetData, remainingAmount, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                                } else {
                                    await makeRemainingPayment(address, contractAddress, contractAbi, tokenAddress, assetData, remainingAmount, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                                }
                            }

                            /**
                            * handle withdrawal of asset  
                            */
                            async function handleWithdrawSaleAsset() {
                                await withdrawAmountSale(address, contractAddress, contractAbi, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle withdrawal of asset  
                            */
                            async function handleWithdrawParcelAsset() {
                                await withdrawAmountParcel(address, contractAddress, contractAbi, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle withdrawal of asset  
                            */
                            async function handleWithdrawAmountSPV() {
                                await withdrawAmountSPVEquity(address, contractAddress, contractAbi, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle withdrawal of asset  
                            */
                            async function handleWithdrawAmountSPVDebt() {
                                await withdrawAmountSPVDebt(address, contractAddress, contractAbi, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle withdrawal of asset  
                            */
                            async function handleWithdrawAmountFunds() {
                                await withdrawalAmountFunds(address, contractAddress, contractAbi, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle withdrawal of asset  
                            */
                            async function handleWithdrawEscrowSpaceX() {
                                await withdrawalEscrowAmountSpaceX(address, contractAddress, contractAbi, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle withdrawal of asset  
                            */
                            async function handleWithdrawEscrowOpenFunds() {
                                await withdrawalEscrowAmountOpenFund(address, contractAddress, contractAbi, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle create EMI for proposer by investor   
                            */
                            async function handleCreateEMI() {
                                await createEMI(address, contractAddress, contractAbi, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle pay prepayment amount
                            */
                            async function handlePrepayment() {
                                await payPrepayment(address, contractAddress, contractAbi, tokenAddress, assetData, props?.propData?.user_id, props?.propData?.asset_id, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle pay prepayment amount
                            */
                            async function handlePayDividend() {
                                await payDividendAmount(address, contractAddress, contractAbi, tokenAddress, assetData, props?.propData?.user_id, props?.propData?.asset_id, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }
                            /**
                            * handle pay prepayment amount
                            */
                            async function handlePayExitAmount() {
                                await payExitAmount(address, contractAddress, contractAbi, tokenAddress, assetData, props?.propData?.user_id, props?.propData?.asset_id, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }
                            /**
                            * handle pay close fund distribution
                            */
                            async function handleSpaceXFundDistribution() {
                                await paySpaceXFundDistribution(address, contractAddress, contractAbi, tokenAddress, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle pay open fund distribution
                            */
                            async function handleOpenFundDistribution() {
                                await payOpenFundDistribution(address, contractAddress, contractAbi, tokenAddress, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle pay minimum prepayment amount   
                            */
                            async function handleMinimumPrepayment() {
                                await payMinimumPrepayment(address, contractAddress, contractAbi, tokenAddress, assetData, props?.propData?.user_id, props?.propData?.asset_id, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle pay emi  
                            */
                            async function handlePayEMI() {
                                await payEMI(address, contractAddress, contractAbi, tokenAddress, assetData, props?.propData?.user_id, props?.propData?.asset_id, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }
                        } catch (error) {
                            console.log('error', error)
                            if (error?.cause?.reason && error?.cause?.reason?.split(":")?.length) {
                                let reason = error.cause.reason;
                                let message = reason?.split(":")
                                showSnackbar(message[1] ? message[1] === " Minimum Pre-Payment under Min-Max % of Fullfilled Amount!" ? "You've either exceeded or fallen short of the minimum prepayment percentage as set by admin!" : message[1] : message[0], 'error')
                            } else {
                                showSnackbar("Transaction Failed", 'error')
                            }
                            props?.handleModalClose({ warning: identity === 'remaining-payment' ? true : false });
                        }
                    }
                    // Function to check associate wallet with the account
                    async function checkAssociatedWallet() {
                        try {
                            // const checkRes = await postApi(`/user/checkWallet`, { wallet_address: address?.toLowerCase(), user_id: localData?.id });
                            const checkRes = await postApi(`/user/checkWallet`, { user_id: localData?.id });
                            if (checkRes?.data?.status && checkRes?.data?.data?.wallet_address?.toLowerCase() === address?.toLowerCase()) {
                                setActiveStep(0);
                                setAssociatedWallet(address);
                                makeTransaction();
                                handleCloseDialog();
                            } else if (checkRes?.data?.data?.wallet_address) {
                                handleCloseDialog();
                                setActiveStep(0);
                                showSnackbar('This wallet is not associated with the account', 'error');
                            } else {
                                handleCloseDialog();
                                setActiveStep(0);
                                setTimeout(() => {
                                    // disconnect();
                                    // props?.handleModalClose();
                                }, 1000)
                                return
                            }
                        } catch (error) {
                            showSnackbar('There was an error', 'error');
                            console.log('There was an error')
                        }
                    }
                    // Call the function checkAssociatedWallet
                    checkAssociatedWallet()
                } else {
                    setActiveStep(0);
                }
            } else {
                setOpenSnackbar(true)
                setTimeout(() => {
                    window.location.href = '/';
                }, 4000);
                handleCloseDialog()
            }
        }

    }, [address, TokenStatus, chain, isAssociated])
    //  smart contract -- end

    /**
     * Set the active step value
     * @param {Number} value 
     */
    const handleActiveStep = (value) => {
        setActiveStep(value)
    }

    //Wallet connection code start
    const [walletDialogOpen, setWalletDialogOpen] = useState(false);

    /**
     * Function to handle wallet modal open
     */
    const handleClickOpen = async () => {
        setWalletDialogOpen(true);
    };

    /**
     * Function to handle wallet modal close
     */
    const handleCloseDialog = () => {
        setWalletDialogOpen(false);
    };

    /**
     * gets called when a wallet is connect successfully
     * @param {*} acc
     */
    const walletLogin = async (acc, isAssociate) => {
        dispatch(update({ wallet_address: acc?.toLowerCase() }));
        setAssociatedWallet(acc?.toLowerCase())
        if (isAssociate)
            setIsAssociated(true)
    };
    //Wallet connection code end

    /**
     * Function to  handle custom icons
     * @returns custom icons for progress steps
     */
    const CustomStepIcon = ({ active, completed, index }) => {
        if (completed) {
            // You can customize the completed state icon here
            return <Chip label={<Done />} color="primary" className="item-completed" size="small" />;
        }

        // Customize the active state icon with CircularProgress loader
        return active ? <CircularProgress color="inherit" size={20} /> :
            <Chip label={index + 1} color="primary" className="item-indexing" size="small" />; // Change the icon based on your preference
    };

    /**
     * Function to handle modal close
     * @param {Event} reason 
     * @returns 
     */
    const handleWalletTransactionModal = (reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown' || reason?.code === "Escape") {
            return; //do nothing basically do not close modal
        } else {
            props?.handleModalClose({ warning: true });
        }
    };

    /**
    * handles snackbar close
    * @param {*} event 
    * @param {*} reason 
    * @returns 
    */
    const handleClose = (event, reason) => {
        setOpenSnackbar(false);
    };

    return (
        <>
            {/* Stepper modal for transaction flow for abi*/}
            <Modal
                open={props?.openTransactionModal}
                onClose={handleWalletTransactionModal}
                className="kyc-modal wallet-transaction-flow"
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                BackdropComponent={Backdrop}
                BackdropProps={{ open: false }}
                sx={{ backdropFilter: 'blur(2px)' }}
            >
                <Box className="set-stack-rts common-modal-design">
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
                        {/* {activeStep >= steps?.length - 1 ? */}
                        {activeStep > 0 ?
                            <></>
                            :
                            <IconButton >
                                <Close style={{ color: '#fff' }} onClick={handleWalletTransactionModal} />
                            </IconButton>
                        }
                    </Box>
                    <Box sx={{ maxWidth: 400 }}>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps?.map((step, index) => (
                                <Step key={step.label}>
                                    <StepLabel StepIconComponent={(props) => <CustomStepIcon {...props} index={index} />}>
                                        {!associatedWallet && index === 0 ? <Button className="btn-rounded" onClick={handleClickOpen}>Connect Wallet</Button> : index === 0 ? <Button className="btn-rounded btn-green-400">Wallet connected</Button> : step.label}
                                    </StepLabel>
                                    <StepContent>
                                        <Typography className={associatedWallet && index === 0 ? "wallet-address-text" : ''}>{associatedWallet && index === 0 ? associatedWallet : step.description}</Typography>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                </Box>
            </Modal >

            <SnackbarAlert open={openSnackbar} message={"Your Token has been expire , please login again"} severity={"info"} onClose={handleClose} />

            {/* wallet connect component */}
            <ConnectWalletInvestor
                open={walletDialogOpen}
                handleCloseDialog={handleCloseDialog}
                handleConnectedSuccess={walletLogin}
                closeTransactionModal={props?.handleModalClose}
            />
        </>
    );
}
