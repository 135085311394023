import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Grid, Typography, Button, TextField, InputAdornment, Switch } from "@mui/material";
import { Add, KeyboardArrowRight, Remove } from "@mui/icons-material";
import { useForm } from "react-hook-form";

// Import custom components for this page
import { NewLogo } from "../../../Components/Images/Images";
import LinearProgressCompo from "./Components/LinearProgressCompo";
import "./Components/Questionnaire.css";

function formatDecimalNumber(number) {
    if (Number.isInteger(number)) {
        return number.toString(); // Return the number without decimals
    } else {
        const parts = number.toString().split('.');
        if (parts[1] && parts[1].length > 2) {
            return number.toFixed(2); // Fix to 2 decimal places if more than 2 decimals
        }
        return number.toString(); // Keep the number as-is if decimals are already <= 2
    }
}


export default function SetHurdleDetails(props) {
    // Destructuring props to get functions and data required for the component
    const { handleNext, handleback, getProposalData, updateProposalData, saveProposal, getProgress } = props;

    // Retrieve current proposal data and progress
    var propData = getProposalData();
    let currentProgress = getProgress();

    let spv_rate_of_return = propData?.spv_rate_of_return?.length ? JSON.parse(propData?.spv_rate_of_return) : [];
    let spv_partners = propData?.spv_partners?.length ? JSON.parse(propData?.spv_partners) : [];


    const [toggleState, setToggleState] = useState(propData?.is_hurdle_rate ? propData?.is_hurdle_rate : false);
    const [irrArray, setIrrArray] = useState(spv_rate_of_return?.length ? spv_rate_of_return?.map((_, index) => index + 1) : [1]); // Initialize with one item
    const [isSubmitted, setIsSubmitted] = useState('')

    // useForm hook to handle form validation and submission
    const { register, unregister, handleSubmit, trigger, setValue, getValues, formState: { errors }, control, watch } = useForm({
        mode: 'onChange',
        defaultValues: {
            irr: spv_rate_of_return?.length ? spv_rate_of_return : [{ min: 0, max: 100 }], // Initial values for irr
            partners: spv_partners?.length ? spv_partners : [{ lp: 100, gp: 0 }], // Initial values for partners
            carried_interest: propData?.carried_interest ? parseFloat(propData?.carried_interest) : 0, // Initial values for partners
            gp_percentage: parseFloat(propData?.spv_lp_percentage) ? formatDecimalNumber(parseFloat(propData?.spv_lp_percentage)) : 0, // Initial values for partners
        }
    });

    const addIRRField = () => {
        // Reapply current values to all existing fields
        irrArray.forEach((_, index) => {
            setValue(`irr[${index}].min`, getValues(`irr[${index}].min`));
            setValue(`irr[${index}].max`, getValues(`irr[${index}].max`));
            setValue(`partners[${index}].lp`, getValues(`partners[${index}].lp`));
            setValue(`partners[${index}].gp`, getValues(`partners[${index}].gp`)); // If needed
            trigger(`irr[${index}].max`);
        });

        // Add a new IRR field with default values
        setValue(`irr[${irrArray.length}].min`, getValues(`irr[${irrArray.length - 1}].max`));
        setValue(`irr[${irrArray.length}].max`, 100);
        setValue(`partners[${irrArray.length}].lp`, 100);
        setValue(`partners[${irrArray.length}].gp`, 0); // If needed

        if (irrArray?.length > 0) {
            irrArray.forEach((_, index) => {
                trigger(`irr[${index}].max`);
            })
        }
        setIsSubmitted('')
        setIrrArray((prev) => [...prev, prev.length + 1]);
    };

    const removeIRRField = () => {
        if (irrArray.length > 1) { // Ensure at least one field remains
            const updatedArray = [...irrArray];
            const lastIndex = irrArray.length - 1;

            // Reapply current values to all existing fields before removing the last one
            updatedArray.forEach((_, index) => {
                setValue(`irr[${index}].min`, getValues(`irr[${index}].min`));
                setValue(`irr[${index}].max`, getValues(`irr[${index}].max`));
                setValue(`partners[${index}].lp`, getValues(`partners[${index}].lp`));
                setValue(`partners[${index}].gp`, getValues(`partners[${index}].gp`)); // If needed
            });

            // Remove the last field from the array
            updatedArray.pop();
            setIrrArray(updatedArray);

            // if (updatedArray?.length > 0) {
            //     setValue(`irr[${updatedArray?.length - 1}].max`, 100);
            //     trigger(`irr[${updatedArray?.length - 1}].max`);
            // }

            // Unregister the removed field to clean up its state
            unregister(`irr[${lastIndex}].min`);
            unregister(`irr[${lastIndex}].max`);
            unregister(`partners[${lastIndex}].lp`);
            unregister(`partners[${lastIndex}].gp`);
        }
    };
    const irrFields = watch("irr"); // Watch the entire irr array

    const handleMaxChange = (index, value) => {
        if (value) {
            const maxValue = value || 0;
            // Update the current max value
            setValue(`irr[${index}].max`, maxValue);

            if ((index + 1 === irrArray?.length) && maxValue >= 100) {
                setIsSubmitted('')
            }
            // Automatically update the next IRR's min value if applicable
            if (index + 1 < irrFields.length) {
                // setValue(`irr[${index + 1}].min`, maxValue);

                // Trigger revalidation for the next field's max value
                trigger(`irr[${index + 1}].max`);
                trigger(`irr[${index}].max`);
            } else {
                trigger(`irr[${index}].max`);
            }
        }
    };

    const handleLPChange = (index, lpValue) => {
        if ((lpValue)) {
            // Parse the LP value as a float (fallback to 0 if invalid)
            const lp = lpValue || 0;

            // Set the LP value for the current index
            setValue(`partners[${index}].lp`, lp);

            // Automatically set the GP value for the current index
            const gp = formatDecimalNumber(100 - lp);
            setValue(`partners[${index}].gp`, gp);

            trigger(`partners[${index}].lp`);
            trigger(`partners[${index}].gp`);
        }
    };


    /**
     * Function to handle changes in toggle button
     */
    const handleToggleChange = () => {
        setToggleState(!toggleState);
    };

    /**
     * Function to handle form submission, updating 'propData' with the form data, and proceeding to the next step or saving and exiting.
     * @param {Object} data - The form data.
     * @param {Object} event - The form submission event.
     */
    const onSubmit = (data, event) => {
        const buttonText = event.nativeEvent.submitter.innerText;
        propData.is_hurdle_rate = toggleState;
        if (toggleState) {
            let irrData = []
            data.irr?.forEach((irr, index) => {
                if (index > 0) {
                    irrData.push({ min: data.irr[index - 1].max, max: irr.max })
                } else {
                    irrData.push({ min: 0, max: irr.max })
                }
            })

            if (parseFloat(data.irr[data.irr?.length - 1].max) < 100) {
                setIsSubmitted('Last Max IRR Percentage must be equal to 100.');
                return
            } else {
                setIsSubmitted('')
            }

            propData.spv_rate_of_return = JSON.stringify(irrData);
            propData.spv_partners = JSON.stringify(data.partners);
            propData.carried_interest = parseFloat(data.carried_interest);
            propData.spv_lp_percentage = ((parseFloat(data.gp_percentage) ? parseFloat(data.gp_percentage) : 0))
        }


        // Update the main page 
        updateProposalData(propData);

        // Proceed to the next step or save and exit based on the button clicked
        if (buttonText.includes("Save and Exit")) {
            SaveAndExit();
        } else {
            handleNext();
        }
    }

    /**
     * Function to save the current proposal data and exit the current step/page.
     */
    const SaveAndExit = () => {
        saveProposal();
    }

    const createValidation = (fieldName) => ({
        required: `${fieldName} is required.`,
        validate: (value) => {
            if (value < 0) return `${fieldName} cannot be less than 0.`;
            if (value > 100) return `${fieldName} cannot be greater than 100.`;
            // if (fieldName === 'LP Percentage' && value == 0) return `${fieldName} must be greater than 0.`;
            return true;
        },
    });

    const getNumericInputProps = () => ({
        pattern: "[0-9]*\\.?[0-9]{0,2}", // Allow up to two decimal places
        onInput: (e) => {
            const value = e.target.value;
            // Allow only numeric input with at most one dot and two decimal places
            e.target.value = value.match(/^\d*\.?\d{0,2}$/) ? value : value.slice(0, -1);
        },
    });

    const onKeyDownHandler = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
        }
    };

    useEffect(() => {
        setValue(`irr[${0}].min`, 0);
    }, [])

    return (
        <Box className="projectProposer questionnaire-page">
            <Box className="questionnaire-section">
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Box className="auth-left">
                            {/* left-title */}
                            <Box className="quests-left-title">
                                <Typography component="h1" className="title">
                                    Set Hurdle Rate
                                </Typography>
                            </Box>
                            {/* Logo */}
                            <Box className="auth-logo">
                                <Link to="/">
                                    <Box component="img" src={NewLogo} alt="Race logo" />
                                </Link>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Box className="auth-right quests-right-contents-box" component="form"
                            onSubmit={handleSubmit(onSubmit)} onKeyDown={onKeyDownHandler}>
                            <Box textAlign={"right"}>
                                <Box component="span" className="icon" ml={1}>
                                    <Button id="my-button" className="btn-rounded btn-text-white btn-blue-600 saveAndExit" type="submit">
                                        Save and Exit
                                    </Button>
                                </Box>
                            </Box>
                            {/*Watch information Form starts from here */}
                            <Box className="questionnaire6 qsn-middle-content" sx={{ pt: 4, mb: 5 }} >
                                <Box className="auth-form" mt={4} >
                                    <Box className="form-group label-field">
                                        <Box mb={1} sx={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                                            <Typography className="text-black font-22" style={{ marginRight: '20px' }}>
                                                Is Hurdle Rate
                                            </Typography>
                                            <Switch
                                                checked={toggleState}
                                                onChange={handleToggleChange}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </Box>
                                    </Box>
                                    {toggleState ?
                                        <>
                                            <Grid container spacing={2}>
                                                {irrArray.map((_, index) => (
                                                    <React.Fragment key={index}>
                                                        <Grid item md={9} xs={9}>
                                                            <Typography
                                                                className="text-black font-18"
                                                                sx={{ fontWeight: '500', marginY: '8px' }}
                                                            >
                                                                IRR Percentage (Tier {index + 1})
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item md={3} xs={3}>
                                                            <Box style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', gap: '16px' }}>
                                                                {irrArray?.length === index + 1 && (getValues(`irr[${index}].max`) < 100) ?
                                                                    <Button
                                                                        className="btn-rounded btn-blue-300 text-white"
                                                                        onClick={addIRRField}
                                                                    >
                                                                        <Add />
                                                                    </Button>
                                                                    : null
                                                                }
                                                                {((irrArray?.length === index + 1) && (index !== 0)) ?
                                                                    <Button
                                                                        className="btn-rounded btn-blue-300 text-white"
                                                                        onClick={removeIRRField}
                                                                    >
                                                                        <Remove />
                                                                    </Button>
                                                                    : null
                                                                }

                                                            </Box>
                                                        </Grid>
                                                        <Grid item md={6} xs={12}>
                                                            <TextField
                                                                // disabled // Disable 
                                                                type="text"
                                                                value={index === 0 ? 0 : getValues(`irr[${index - 1}].max`)}
                                                                InputLabelProps={{ shrink: true }}
                                                                label={'Min IRR Percentage'}
                                                                id={`min-irr-${index}`}
                                                                placeholder={`Enter Min IRR Percentage`}
                                                                {...register(`irr[${index}].min`, {
                                                                    ...createValidation("Min IRR Percentage"),
                                                                })}
                                                                error={Boolean(errors.irr?.[index]?.min)}
                                                                helperText={errors.irr?.[index]?.min?.message}
                                                                InputProps={{
                                                                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                                                    inputMode: "decimal", // Optimizes for numeric input with decimals on mobile devices
                                                                    readOnly: true, // Makes the field read-only
                                                                }}
                                                                inputProps={getNumericInputProps()}
                                                            />
                                                        </Grid>
                                                        <Grid item md={6} xs={12}>
                                                            <TextField
                                                                type="text"
                                                                InputLabelProps={{ shrink: true }}
                                                                label={'Max IRR Percentage'}
                                                                id={`max-irr-${index}`}
                                                                placeholder={`Enter Max IRR Percentage`}
                                                                {...register(`irr[${index}].max`, {
                                                                    required: `Max IRR Percentage is required.`,
                                                                    validate: (value) => {
                                                                        let minValue = getValues(`irr[${index}].min`)

                                                                        if (parseFloat(value) < 0) return `Max IRR Percentage cannot be less than 0.`;
                                                                        if (parseFloat(value) > 100) return `Max IRR Percentage cannot be greater than 100.`;
                                                                        if (parseFloat(minValue) >= parseFloat(value)) return `Max IRR Percentage cannot be less than or equal to Min IRR Percentage.`;
                                                                        // if ((index + 1) === irrArray?.length && (getValues(`irr[${index}].max`) < 100)) {
                                                                        //     return `Last Max IRR Percentage must be equal to 100.`
                                                                        // };

                                                                        if (index + 1 < irrArray?.length) {
                                                                            setValue(`irr[${index + 1}].min`, value)
                                                                        }
                                                                        return true;
                                                                    },
                                                                })}
                                                                onChange={(e) => handleMaxChange(index, e.target.value)}
                                                                error={((index + 1) === irrArray?.length && isSubmitted?.length > 0) ? true : Boolean(errors.irr?.[index]?.max)}
                                                                helperText={((index + 1) === irrArray?.length && isSubmitted?.length > 0) ? isSubmitted : errors.irr?.[index]?.max?.message}
                                                                InputProps={{
                                                                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                                                    inputMode: "decimal", // Optimizes for numeric input with decimals on mobile devices
                                                                    // readOnly: Boolean(index + 1 === irrArray.length), // Makes the field read-only
                                                                }}
                                                                inputProps={getNumericInputProps()}
                                                            />
                                                        </Grid>
                                                        <Grid item md={12} xs={12}>
                                                            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                <Typography
                                                                    fontWeight={"500"}
                                                                    className="font-16"
                                                                    sx={{ color: "black" }}>
                                                                    Partners
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item md={6} xs={12}>
                                                            {/* <Typography
                                                                fontWeight={"500"}
                                                                sx={{ color: "black", textAlign: "center" }}>
                                                                Investor Percentage
                                                            </Typography> */}
                                                            <Box style={{ display: 'flex', justifyContent: 'center' }}>
                                                                <TextField
                                                                    className="hurdle-rate-partners-field"
                                                                    type="text"
                                                                    InputLabelProps={{ shrink: true }}
                                                                    label={'LP Percentage'}
                                                                    id={`partners-${index}`}
                                                                    placeholder={`Enter LP Percentage`}
                                                                    {...register(`partners[${index}].lp`, createValidation("LP Percentage"))}
                                                                    error={Boolean(errors.partners?.[index]?.lp)}
                                                                    helperText={errors.partners?.[index]?.lp?.message}
                                                                    InputProps={{
                                                                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                                                        inputMode: "decimal", // Optimizes for numeric input with decimals on mobile devices
                                                                    }}
                                                                    onChange={(e) => handleLPChange(index, e.target.value)}
                                                                    inputProps={getNumericInputProps()}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid item md={6} xs={12}>
                                                            {/* <Typography
                                                                fontWeight={"500"}
                                                                sx={{ color: "black", textAlign: "center" }}>
                                                                Investor Percentage
                                                            </Typography> */}
                                                            <Box style={{ display: 'flex', justifyContent: 'center' }}>
                                                                <TextField
                                                                    disabled
                                                                    className="hurdle-rate-partners-field"
                                                                    type="text"
                                                                    InputLabelProps={{ shrink: true }}
                                                                    label={'GP Percentage'}
                                                                    id={`partners-${index}-gp`}
                                                                    placeholder={`Enter GP Percentage`}
                                                                    {...register(`partners[${index}].gp`)}
                                                                    value={getValues(`partners[${index}].gp`) || 0} // Retrieve from form state
                                                                    InputProps={{
                                                                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                                                        inputMode: 'decimal',
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    </React.Fragment>
                                                ))}
                                                <Grid item md={12} xs={12}>
                                                    <Box style={{ display: 'flex' }}>
                                                        <Typography
                                                            fontWeight={"500"}
                                                            className="font-18"
                                                            sx={{ color: "black", mt: 2.5, mb: 1 }}>
                                                            After Exit
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} md={12} lg={6}>
                                                    {/* <Typography
                                                        fontWeight={"500"}
                                                        className="font-16 text-black"
                                                        sx={{ textAlign: "center", mb: 2.5 }}
                                                    >
                                                        Carried Interest
                                                    </Typography> */}
                                                    <TextField
                                                        type="text"
                                                        id="carried_interest"
                                                        InputLabelProps={{ shrink: true }}
                                                        label={'Carried Interest'}
                                                        placeholder={`Enter Carried Interest`}
                                                        {...register(`carried_interest`, createValidation("Carried Interest"))}
                                                        error={Boolean(errors.carried_interest)}
                                                        helperText={errors.carried_interest?.message}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                                            inputMode: "decimal", // Optimizes for numeric input with decimals on mobile devices
                                                        }}
                                                        inputProps={getNumericInputProps()}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={12} lg={6}>
                                                    {/* <Typography
                                                        fontWeight={"500"}
                                                        className="font-16 text-black"
                                                        sx={{ textAlign: "center", mb: 2.5 }}
                                                    >
                                                        GP Percentage
                                                    </Typography> */}
                                                    <TextField
                                                        type="text"
                                                        id="gp_percentage"
                                                        InputLabelProps={{ shrink: true }}
                                                        label={'GP Percentage'}
                                                        placeholder={`Enter GP Percentage`}
                                                        {...register(`gp_percentage`, createValidation("GP Percentage"))}
                                                        error={Boolean(errors.gp_percentage)}
                                                        helperText={errors.gp_percentage?.message}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                                            inputMode: "decimal", // Optimizes for numeric input with decimals on mobile devices
                                                        }}
                                                        inputProps={getNumericInputProps()}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </>
                                        : null
                                    }
                                </Box>
                            </Box>
                            <Box>
                                <Box className="questionnaire-progress" >
                                    <LinearProgressCompo value={currentProgress} />
                                </Box>
                                <Box
                                    sx={{ display: "flex", justifyContent: "space-between" }}
                                    mt={3}
                                >
                                    <Box textAlign={"left"} className="quests6-btn-box">
                                        <Button className='btn-rounded back-btn' onClick={handleback}>Back</Button>
                                    </Box>
                                    <Box textAlign={"right"} className="quests-btn-box">
                                        <Button
                                            id="my-button"
                                            // onClick={handleNext}
                                            endIcon={<KeyboardArrowRight />}
                                            className="btn-rounded btn-text-white btn-blue-600"
                                            type="submit"
                                        >
                                            Next
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box >
    );
}
