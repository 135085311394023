import { Box, Button, Grid, IconButton, InputAdornment, Paper, Stack, TextField, Typography, styled } from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import GridImageView from "../Parceling/GridImageView";

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiFilledInput-root': {
    backgroundColor: '#06487E',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: '#06487E',
      cursor: 'default',
    },
    '&.Mui-focused': {
      backgroundColor: '#06487E',
      cursor: 'default',
    },
  },
  '& .MuiInputBase-input.MuiFilledInput-input': {
    color: '#ffffff',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '16px',
    '&:hover': {
      cursor: 'default',
    },
  },
  '& .MuiFilledInput-underline:before': {
    display: 'none',
  },
  '& .MuiFilledInput-underline:after': {
    display: 'none',
  },
  '& .MuiFilledInput-underline:hover:before': {
    display: 'none',
  },
  '& .MuiFilledInput-underline:hover:after': {
    display: 'none',
  },
}));

const Parcel = forwardRef(({ assetData, assetTitle, selectedParcelsArr, showParcelSelectionPage, changeAmount }, ref) => {
  const [investAmount, setInvestAmount] = useState(0);
  const CanvasImageRef = useRef(null);

  const handleBackBtn = () => {
    showParcelSelectionPage();
  };

  useEffect(() => {
    if (selectedParcelsArr.length === 0) {
      showParcelSelectionPage();
    }
    let investAmountCalc = 0;
    selectedParcelsArr?.forEach((parcel) => {
      investAmountCalc += ((parcel.price))
    });
    setInvestAmount(parseFloat(investAmountCalc).toFixed(2));
    changeAmount(parseFloat(investAmountCalc).toFixed(2));

  }, [selectedParcelsArr])

  useEffect(() => {
    if (selectedParcelsArr && selectedParcelsArr.length > 0) {
      setTimeout(() => {
        CanvasImageRef.current?.initialRegionSelection(selectedParcelsArr);
      }, 300);
    }
  }, [selectedParcelsArr])

  const handleEditParcels = () => {
    showParcelSelectionPage();
  }

  // Expose the child function through the ref
  useImperativeHandle(ref, () => ({
    getData() {
      let parcelIdArray = selectedParcelsArr.map(parcel => parseInt(parcel?.title?.split('#')[1]));
      if (parcelIdArray.length === 0) {
        return false;
      }
      return {
        offer_amount: investAmount,
        parcel_id: parcelIdArray
      }
    }
  }));
  const containerStyle = {
    pointerEvents: 'none',
    width: "-webkit-fill-available", /* For WebKit browsers */
    width: "fill-available",     /* For Firefox and other browsers */
    width: '100%'
  };
  return (
    <Paper elevation={3} sx={{ p: 2, mb: 2 }} className="rc-basic-container">
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"flex-start"}
      >
        <IconButton onClick={handleBackBtn} sx={{ color: "#ffffff" }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Invest in {assetTitle}
        </Typography>
      </Stack>
      <Box component={"hr"} className="rc-hr" />

      <Grid mt={1} container spacing={2} justifyContent={'center'}>
        <Grid item xs={12} sm={8} md={7} className="rc-project-details-left-img" style={containerStyle}>
          <GridImageView readOnly ref={CanvasImageRef} rows={parseInt(assetData?.number_of_rows)} cols={parseInt(assetData?.number_of_columns)} artImage={`${process.env.REACT_APP_IMAGE_URL}${assetData?.asset_gallery[0]?.images.path}`} />
        </Grid>
      </Grid>

      <Typography variant="subtitle2" sx={{ mt: 2, mb: 2 }} gutterBottom>
        Investment Amount
      </Typography>
      <Box className="payment-enter-investor-ro">
        <CustomTextField
          hiddenLabel
          placeholder="Amount"
          variant="filled"
          value={investAmount}
          FormHelperTextProps={{ sx: { color: "white" } }}
          helperText={`This amount will be charged immediately to your payment method of choice`}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AttachMoneyIcon sx={{ color: '#C5E4FF' }} />
              </InputAdornment>
            ),
            readOnly: true
          }}
          sx={{ input: { color: 'white !important' } }}
        />
      </Box>
      <Typography variant="subtitle2" mt={3} mb={3}>Parcels Selected</Typography>
      <Grid container spacing={2}>
        {
          selectedParcelsArr.map((parcel, index) => {
            return (
              <Grid key={index} item xs={12} sm={6} md={6} lg={4}>
                <Box className="rc-parcel-selected-payment-box">
                  <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Box display={'flex'}>
                      <Box mr={1} className='rc-icon-fab-secondary-box'>
                        <IconButton className='rc-icon-fab-secondary'><AttachMoneyIcon /></IconButton>
                      </Box>
                      <Box>
                        <Typography className="rc-xsmall-secondary-text" variant="caption" >{parcel?.title}</Typography>
                        <Typography variant="subtitle2" >${parseFloat(parcel.price).toFixed(2)}</Typography>
                      </Box>
                    </Box>
                    <Box height={'38px'} width={'38px'} borderRadius={'8px'} component={'img'} src={`${process.env.REACT_APP_IMAGE_URL}${parcel.imagess.path}`} />
                  </Stack>
                </Box>
              </Grid>
            )
          })
        }
      </Grid>
      <Button onClick={handleEditParcels} className="btn-rounded btn-blue-500 btn-text-white" sx={{ mt: 3 }} >{selectedParcelsArr?.length === 0 ? 'Select Parcels' : 'Edit Parcels'}</Button>
    </Paper>
  );
});

export default Parcel;
