import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Grid, Stack, Typography, Chip, IconButton, Tabs, Tab, TableContainer, TableHead, Table, TableRow, TableBody, TableCell, styled, tableCellClasses, Paper, Tooltip, AccordionSummary, Accordion, AccordionDetails } from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ShareIcon from '@mui/icons-material/Share';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import ParcelCardGrid from './ParcelCardGrid';
import { postApi, GetApiParam } from '../Api/Api';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PaymentsIcon from '@mui/icons-material/Payments';
import PieChartIcon from '@mui/icons-material/PieChart';
import { Bookmark, ExpandMore, Paid } from '@mui/icons-material';
import { useSnackbar } from '../Contexts/SnackbarContext';
import './Description.css'
import { CAPITAL_TYPE_ID, CATEGORY_TYPE_ID, CONTRACT_NAME, INVESTMENT_TYPE_ID, LOAN_INSTALLMENT_STATUS, OFFER_STATUS, SUB_CATEGORY_TYPE_ID } from '../constants';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, EmailShareButton } from 'react-share';
import Popover from '@mui/material/Popover'
import purify from "dompurify";
import { formatNumber } from '../Components/Common/USFormat';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { viewListing } from '../Components/SmartContract/viewListing';
import { GetContractAddress } from '../Components/Common/GetContractAddress';
import moment from "moment/moment";
import SelectLoanAmortizationDropdown from '../Components/Common/SelectLoanAmortizationDropdown';

export default function Description({ description, title, subtitle, address, investmentType, assetType, assetSubtype, parcelCardsData, assetId, userId, category_id, is_onboard, listing_id, investment_type_id, contract_version }) {

    // code for popover
    const [anchorEl, setAnchorEl] = useState(null);
    // Function to handle share button click
    const handleShareClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    // Function to handle popover close
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'share-popover' : undefined;
    // Getting project url
    const projectUrl = window.location.href

    const data = JSON.parse(description);
    const { showSnackbar } = useSnackbar();
    const location = useLocation()
    const [value, setValue] = useState('content-0');
    const [scrollArr, setScrollArr] = useState([]);
    const [bookMark, setBookMark] = useState(0);
    const [isAmortization, setIsAmortization] = useState(false);
    const [isCapTable, setIsCapTable] = useState(false);
    const [offerData, setOfferData] = useState('');
    const [parcelTokenAddress, setParcelTokenAddress] = useState('');

    const maxHeight = useSelector((state) => {
        return state.heightSlice.height
    });
    const leftHeight = 650;

    // state to hold is offer accepted
    const [isOfferAccepted, setIsOfferAccepted] = useState(false);

    var localData = localStorage.getItem("login") ? JSON.parse(localStorage.getItem("login")) : ""; // Getting login status from local storage
    var userData = localStorage.getItem("user_data") ? JSON.parse(localStorage.getItem("user_data")) : ""; //Getting  user data from local storage

    const handleTabChange = (event, newValue) => {
        let id = newValue.charAt(8);
        document.getElementById('scroller-wrap').scrollTo({
            top: scrollArr[id],
            behavior: 'smooth'
        });
    }

    useEffect(() => {
        const scrollerArea = document.getElementById('scroller-wrap')
        const handleScroll = () => {
            let scrollAreaTop = scrollerArea.getBoundingClientRect().top;
            const contentSections = document.querySelectorAll('.content-section');
            contentSections.forEach((contentSection) => {
                let contentSectionTop = contentSection.getBoundingClientRect().top - 14;  //minus 14 to adjust the initial padding
                let contentSectionBottom = contentSectionTop + contentSection.offsetHeight;
                if (scrollAreaTop >= contentSectionTop && scrollAreaTop < contentSectionBottom) {
                    let contentSectionId = contentSection.getAttribute('id');
                    setValue(contentSectionId);
                    return;
                }
            });
        };

        scrollerArea.addEventListener('scroll', handleScroll);
        // Cleanup the event listener when the component unmounts
        return () => {
            scrollerArea.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        let arr = [];
        const contentSections = document.querySelectorAll('.content-section');
        let elementTop = document.getElementById('scroller-wrap').getBoundingClientRect().top;
        contentSections.forEach((contentSection) => {
            let contentTop = contentSection.getBoundingClientRect().top;
            arr.push(contentTop - elementTop)
        });
        setScrollArr(arr)
    }, [isAmortization, isCapTable])

    useEffect(() => {
        try {
            if (assetId) {
                async function getOfferData() {
                    try {
                        let payload = { "asset_id": assetId, status: OFFER_STATUS.COMPLETED }
                        if (userId !== userData?.id) {
                            payload = { ...payload, investor_id: userData?.id }
                        }
                        const res = await GetApiParam("/invest/offer", payload);
                        if (res?.data?.code === 200 && res?.data?.data && res?.data?.data?.length) {
                            if (category_id === CATEGORY_TYPE_ID.FUNDS) {
                                // setTimeout(() => {
                                setIsCapTable(true);
                                // }, 1000)
                                setOfferData(res?.data?.data)
                            } else {
                                if (investment_type_id !== INVESTMENT_TYPE_ID.SALE) {
                                    setIsAmortization(true);
                                }
                            }
                        }
                    } catch (error) {
                        console.log(error)
                    }
                }
                // Function to fetch asset data
                const getRestateInvestment = async () => {
                    try {
                        let payload = { asset_id: assetId, category_id: CATEGORY_TYPE_ID.REAL_ESTATE }
                        // if (userId !== userData?.id) {
                        //     payload = { ...payload, investor_id: userData?.id }
                        // }
                        const res = await GetApiParam("/transaction/getRestateInvestment", payload);
                        if (res?.data?.data) {
                            if (res?.data?.data?.length && res?.data?.data?.some(item => item?.is_equity_withdraw && (item?.user_id === userData.id || item?.add_asset_owner_id === userData.id) && item?.add_capital_type_id === CAPITAL_TYPE_ID.EQUITY)) {
                                // setTimeout(() => {
                                setIsCapTable(true);
                                // }, 1000)
                            }
                            let newData = res?.data?.data?.filter(data => data.add_capital_type_id !== CAPITAL_TYPE_ID.EQUITY)
                            newData = newData?.filter(data => (data.user_id === userData?.id || data.add_asset_owner_id === userData?.id) && data?.is_equity_withdraw)
                            if (newData?.length) {
                                setTimeout(() => {
                                    setIsAmortization(true);
                                }, 1000)
                            }
                        }
                    } catch (error) {
                        console.error(error)
                    }
                }
                const timer = setTimeout(() => {
                    if (category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
                        getRestateInvestment();
                    } else {
                        getOfferData();
                    }
                }, 100);
                return () => clearTimeout(timer);
            }
        } catch (error) {
            console.log(error)
        }
    }, [assetId])

    useEffect(() => {

        if (is_onboard && listing_id && investment_type_id && contract_version) {
            async function getParcelTokenAddress() {
                try {
                    const response = await GetContractAddress({ category_id: category_id, investment_type_id: investment_type_id }, contract_version, CONTRACT_NAME.ART_PARCEL_MARKETPLACE);
                    let contractAddress = null;
                    let contractAbi = null;
                    if (response && response?.length) {
                        contractAddress = response[0].address;
                        contractAbi = response[0].abi;
                    }
                    let listing = await viewListing(userData?.wallet_address, contractAddress, contractAbi, { listing_id, investment_type_id });
                    if (listing) {
                        setParcelTokenAddress(listing?.tokenAddress)
                    }
                } catch (error) {

                }
            }
            getParcelTokenAddress()
        }
    }, [is_onboard, listing_id])

    //  Getting initial Bookmark status from Api
    useEffect(() => {
        if (localData) {
            async function getBookmarkData() {
                try {
                    const payloadDataBookmark = {
                        "asset_id": assetId ? assetId : "",
                        "user_id": userData.id ? userData.id : "",
                    }

                    const res = await GetApiParam("/proposer/getBookmark", payloadDataBookmark);
                    if (res?.data?.code === 200) {
                        setBookMark(res?.data?.data[0]?.status)
                    }
                } catch (error) {
                    console.log(error)
                }
            }
            getBookmarkData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookMark])

    // Handle change function for the BookMark click 
    const handleBookMark = async () => {
        try {
            const bookmarkData =
            {
                "asset_id": (assetId ? assetId : ""),
                "user_id": userData.id ? userData.id : "",
                "status": !bookMark
            }
            const res = await postApi("/proposer/bookmarkAdd", bookmarkData)
            if (res?.data?.code === 200) {
                setBookMark(res?.data?.status)
            }
        } catch (error) {
            console.log(error);
        }
    }

    // Function for setting Message in Snackbar
    const handleBookMarkNotification = async () => {
        showSnackbar("Please login to add bookmark.", "error");
    }

    const checkAmortization = (data) => {
        setIsAmortization(data);
    }
    const checkCapTable = (data) => {
        setIsCapTable(data);
    }

    return (
        <>
            {/* Top Title Bar of Description */}
            <Box className="ProjectDetail-titleBar-wrap" px={1} mt={2.8} mb={3}>
                <Grid container>
                    <Grid item sm={5}>
                        <Box className="pd-titleBar">
                            <Typography component={'h4'} className="title">{title ? title : "No Title"}</Typography>
                            <Typography className="subtitle">{subtitle ? subtitle : address ? address : ""}</Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={7}>
                        <Box className='pd-titleBar-action' mt={{ xs: 2, md: 0 }}>
                            <Stack direction={{ xs: "column", sm: 'row', md: 'row' }} spacing={{ xs: 1, md: 3.8 }} alignItems={'center'} justifyContent={'flex-end'}>
                                <Box className="tage-wrap">
                                    <Stack direction="row" spacing={2} alignItems={'center'} justifyContent={'flex-end'} flexWrap={'wrap'}>
                                        <Box>
                                            {assetType && <Chip className="square-chip" label={assetType === "Asset" ? "Luxury Cars" : assetType} sx={{ my: 0.2 }} />}
                                        </Box>
                                        <Box>
                                            {investmentType && <Chip className="square-chip" label={investmentType} sx={{ my: 0.2 }} />}
                                        </Box>
                                        <Box>
                                            {assetSubtype && <Chip className="square-chip" label={assetSubtype} sx={{ my: 0.2 }} />}
                                        </Box>
                                    </Stack>
                                </Box>
                                <Box className="btn-wrap">
                                    <Stack direction="row" spacing={1.5} alignItems={'center'}>
                                        <IconButton
                                            aria-label="Share"
                                            size="large"
                                            className='square-icon-btn share-popover-button'
                                            onClick={handleShareClick}
                                        >
                                            <ShareIcon />
                                        </IconButton>
                                        <div>
                                            <Popover
                                                id={id}
                                                open={open}
                                                anchorEl={anchorEl}
                                                onClose={handlePopoverClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                }}
                                                style={{ marginTop: '7px' }}
                                            >
                                                <div className="share-popover">
                                                    <FacebookShareButton style={{ padding: '4px 2px 0px 2px' }} url={projectUrl} quote={title}>
                                                        <FacebookIcon size={32} round style={{ color: '#1877F2', width: '32px', height: '32px' }} />
                                                    </FacebookShareButton>
                                                    <TwitterShareButton style={{ padding: '4px 2px 0px 2px' }} url={projectUrl} title={title}>
                                                        <TwitterIcon size={32} round style={{ color: '#1DA1F2', width: '32px', height: '32px' }} />
                                                    </TwitterShareButton>
                                                    <LinkedinShareButton style={{ padding: '4px 2px 0px 2px' }} url={projectUrl} title={title}>
                                                        <LinkedInIcon size={32} round style={{ color: '#0077B5 ', width: '32px', height: '32px' }} />
                                                    </LinkedinShareButton>
                                                    <EmailShareButton style={{ padding: '4px 2px 0px 2px' }} url={projectUrl} subject={title} >
                                                        <EmailIcon size={32} round style={{ color: "#2ECC71", width: '32px', height: '32px' }} />
                                                    </EmailShareButton>
                                                    {/* Add more share options as needed */}
                                                </div>
                                            </Popover>
                                        </div>
                                        {localData ?
                                            <IconButton aria-label="View" size="large" className={"square-icon-btn"} onClick={() => handleBookMark()}>
                                                {/* <RemoveRedEyeIcon /> */}
                                                {bookMark === 1 ?
                                                    <Bookmark /> :
                                                    <BookmarkBorderIcon />}
                                            </IconButton>
                                            :
                                            <IconButton aria-label="View" size="large" className={"square-icon-btn"} onClick={() => handleBookMarkNotification()}>
                                                {/* <RemoveRedEyeIcon /> */}
                                                <BookmarkBorderIcon />
                                            </IconButton>
                                        }

                                    </Stack>
                                </Box>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            {/* Main Description Tabs and Panels */}
            <Box className="verticalTab-wrap" mb={1} sx={{ flexGrow: 1, display: 'flex', flexDirection: { xs: 'column', md: 'row', sm: 'row' } }} >
                <Tabs orientation='vertical' value={value} onChange={handleTabChange} >
                    {
                        data?.map((val, ind) => {
                            return (
                                <Tab key={ind} value={`content-${ind}`} label={<Tooltip title={val.title} placement="right"><span className='des-tab-title'>{val.title}</span> </Tooltip>}
                                    {...a11yProps(ind)} className='desc-tab-max-width' />
                            )
                        })
                    }
                    {
                        parcelCardsData && parcelCardsData.length > 0 ?
                            <Tab key={data?.length} value={`content-${data?.length}`} label={'Parcel Details'}
                                {...a11yProps(data?.length)} /> : null
                    }
                    {/* {(isAmortization) ?
                        <Tab key={data?.length} value={`content-${data?.length}`} label={
                            'Amortization Table'
                        }
                            {...a11yProps(data?.length)} />
                        : null
                    }
                    {isCapTable ?
                        <Tab key={isAmortization ? data?.length + 1 : data?.length} value={`content-${isAmortization ? data?.length + 1 : data?.length}`} label={'Cap Table'}
                            {...a11yProps(isAmortization ? data?.length + 1 : data?.length)} /> : null
                    } */}
                    {isCapTable ?
                        <Tab key={data?.length} value={`content-${data?.length}`} label={'Cap Table'}
                            {...a11yProps(data?.length)} /> : null
                    }
                    {(isAmortization) ?
                        <Tab key={isCapTable ? data?.length + 1 : data?.length} value={`content-${isCapTable ? data?.length + 1 : data?.length}`} label={'Amortization Table'}
                            {...a11yProps(isCapTable ? data?.length + 1 : data?.length)} />
                        : null
                    }


                </Tabs>
                <TabPanel className="verticalTab-content" >
                    {/* 136-41 or 55 */}
                    <Box id='scroller-wrap' sx={{ mt: { xs: 3 } }} style={{ width: '98%', maxHeight: `${(maxHeight - leftHeight) < leftHeight ? leftHeight : (maxHeight - leftHeight - 21)}px` }}>
                        {
                            data?.map((val, ind) => {
                                return (
                                    <Box className="content-section  Parcels-scrollbar text-sections" id={`content-${ind}`} key={ind} pb={1}>
                                        <Typography variant={'h5'} className="title" my={2}>{val.title}</Typography>
                                        <Box dangerouslySetInnerHTML={{ __html: purify.sanitize(val.description) }} />
                                    </Box>
                                )
                            })
                        }
                        {
                            parcelCardsData && parcelCardsData.length > 0 ?
                                <Box className="content-section  Parcels-scrollbar" id={`content-${data?.length}`} key={data?.length} >
                                    <Typography variant={'h5'} className="title" my={2}>Parcels</Typography>
                                    <ParcelCardGrid cardsData={parcelCardsData} is_onboard={is_onboard} parcelTokenAddress={parcelTokenAddress} />
                                </Box>
                                : null
                        }
                        {/* {(isAmortization) ?
                            <Box className="content-section  Parcels-scrollbar" id={`content-${data?.length}`} key={data?.length} >
                                <Typography variant={'h5'} className="title" my={2}>Amortization Table</Typography>
                                <AmortizationTable checkAmortization={checkAmortization} assetId={assetId} proposer_id={userId} investor_id={userData?.id} />
                            </Box>
                            : null
                        }
                        {isCapTable ?
                            <Box className="content-section  Parcels-scrollbar" id={`content-${isAmortization ? data?.length + 1 : data?.length}`} key={isAmortization ? data?.length + 1 : data?.length} >
                                <Typography variant={'h5'} className="title" my={2}>Cap Table</Typography>
                                <CapTable assetId={assetId} checkCapTable={checkCapTable} proposer_id={userId} investor_id={userData?.id} category_id={category_id} offerData={offerData} />
                            </Box> : null
                        } */}
                        {isCapTable ?
                            <Box className="content-section  Parcels-scrollbar" id={`content-${data?.length}`} key={data?.length} >
                                <Typography variant={'h5'} className="title" my={2}>Cap Table</Typography>
                                <CapTable assetId={assetId} checkCapTable={checkCapTable} proposer_id={userId} investor_id={userData?.id} category_id={category_id} offerData={offerData} />
                            </Box> : null
                        }
                        {(isAmortization) ?
                            <Box className="content-section  Parcels-scrollbar" id={`content-${isCapTable ? data?.length + 1 : data?.length}`} key={isCapTable ? data?.length + 1 : data?.length} >
                                <Typography variant={'h5'} className="title" my={2}>Amortization Table</Typography>
                                <AmortizationTable checkAmortization={checkAmortization} assetId={assetId} proposer_id={userId} investor_id={userData?.id} />
                            </Box>
                            : null
                        }

                        {/* empty box to make sure content is scrolled till last */}
                        <Box id={`empty-content`} style={{ minHeight: `${(maxHeight - leftHeight) < 470 ? 470 : maxHeight - leftHeight - 100}px` }} />
                    </Box>
                </TabPanel >
            </Box >
        </>
    )
}

Description.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    investmentType: PropTypes.string,
    assetType: PropTypes.string,
    assetSubtype: PropTypes.string,
}

function a11yProps(index) {
    return {
        icon: <KeyboardArrowRightIcon />,
        iconPosition: "end",
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {children}

        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number,
    value: PropTypes.number,
};

const TableContainerStyle = {
    padding: "10px",
    borderRadius: "16px",
    backgroundColor: "#05131B !important"
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#032744',
        color: '#CBD5E1',
        borderBottom: '0px',
        padding: '10px',
        '&:first-of-type': {
            borderRadius: '16px 0px 0px 16px',
        },
        '&:last-of-type': {
            borderRadius: '0px 16px 16px 0px',
        },
    },

    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: theme.palette.common.white,
        borderBottom: '0px'
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
}));


function AmortizationTable({ assetId, proposer_id, investor_id, checkAmortization }) {
    const [amortizationTableData, setAmortizationTableData] = useState([])
    const [amortizationMainData, setAmortizationMainData] = useState({
        totalPrincipal: 0,
        totalInterest: 0,
        monthlyPayment: 0,
    })
    const [apiData, setApiData] = useState([]);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0)

    useEffect(() => {
        if (!assetId) return;
        async function getAmortizationData() {
            try {
                if (investor_id) {
                    let payload = { asset_id: assetId, user_id: investor_id };
                    const res = await GetApiParam("/loan/getLoan", payload);
                    if (res?.data?.code === 200) {
                        setApiData(res?.data?.data);
                        if (res?.data?.data?.length) {
                            if (res?.data?.data?.every((item) => item?.loan_installment?.every((it) => it.status === LOAN_INSTALLMENT_STATUS.PAID || it.status === LOAN_INSTALLMENT_STATUS.FORECLOSED))) {
                                checkAmortization(false)
                            }
                        }
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }
        const timer = setTimeout(() => {
            getAmortizationData();
        }, 100);
        return () => clearTimeout(timer)
    }, [assetId, investor_id])

    useEffect(() => {
        if (apiData?.length > 0) {
            let totalPrincipal = 0;
            let totalPaidEmiAmt = 0;
            let totalInterest = 0;
            let monthlyPayment = 0;

            apiData.forEach((el, index) => {
                if (el?.loan_installment?.length) {
                    let data = el.loan_installment;
                    if (data?.length) {
                        for (let i = 0; i < data.length; i++) {
                            const element = data[i];
                            if (element.status === LOAN_INSTALLMENT_STATUS.PENDING || element.status === LOAN_INSTALLMENT_STATUS.PARTIALLY_PAID) {
                                if (element.status === LOAN_INSTALLMENT_STATUS.PARTIALLY_PAID) {
                                    monthlyPayment += (parseFloat(element?.monthly_interest) + parseFloat(element?.monthly_principal) - parseFloat(element?.paid_amount || 0));
                                } else {
                                    monthlyPayment += parseFloat(element?.monthly_interest) + parseFloat(element?.monthly_principal);
                                }
                                break;
                            }
                        }
                        data.forEach((install) => {
                            if (install.status === LOAN_INSTALLMENT_STATUS.PENDING) {
                                totalPrincipal += parseFloat(install?.monthly_principal);
                                totalInterest += parseFloat(install.monthly_interest);
                            } else if (install.status === LOAN_INSTALLMENT_STATUS.PARTIALLY_PAID) {
                                totalPrincipal += parseFloat(install?.monthly_principal);
                                totalPaidEmiAmt = parseFloat(install?.paid_amount ? install?.paid_amount : 0);
                                totalInterest += parseFloat(install.monthly_interest);
                            }
                        })
                    }
                } else {
                    if ((apiData?.length - 1) >= (index + 1)) {
                        setSelectedTabIndex(index + 1)
                    }
                }
            })

            setAmortizationMainData({
                totalPrincipal: totalPrincipal,
                totalInterest: totalInterest,
                totalPaidEmiAmt: totalPaidEmiAmt,
                monthlyPayment: monthlyPayment
            })
        }

    }, [apiData])

    useEffect(() => {
        if (apiData?.length) {
            // let balance = apiData[selectedTabIndex].balance;
            // if (balance === 0) return;
            let table = []
            let loan_installment = apiData[selectedTabIndex].loan_installment;
            if (loan_installment && loan_installment?.length) {
                for (let index = 0; index < loan_installment?.length; index++) {
                    table.push({
                        month: index,
                        payment: (parseFloat(loan_installment[index].monthly_principal) + (parseFloat(loan_installment[index].monthly_interest))),
                        interest: loan_installment[index].monthly_interest ? loan_installment[index].monthly_interest : 0,
                        principal: loan_installment[index].monthly_principal ? loan_installment[index].monthly_principal : 0,
                        paid_amount: loan_installment[index].paid_amount ? loan_installment[index].paid_amount : 0,
                        endingBalance: loan_installment[index].repayment_balance ? loan_installment[index].repayment_balance : 0,
                        status: loan_installment[index].status,
                        investor_id: apiData[selectedTabIndex]?.investor_id,
                        emi_date: loan_installment[index].emi_date,
                    });
                }
            }
            setAmortizationTableData(table);
        }
    }, [apiData, selectedTabIndex])

    const groupInstallmentsByYear = (installments) => {
        return installments.reduce((acc, curr) => {
            const year = new Date(curr.emi_date).getFullYear(); // Assuming each installment has a `date` field.
            if (!acc[year]) acc[year] = [];
            acc[year].push(curr);
            return acc;
        }, {});
    };

    const handleTabChange = (event, newValue) => {
        setSelectedTabIndex(newValue);
    };
    function TabPanel(props) {
        const { children, value, index, ...other } = props

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ pt: 3 }}>
                        {children}
                    </Box>
                )}
            </div >
        )
    }

    function a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            "aria-controls": `full-width-tabpanel-${index}`
        }
    }
    return (
        <>
            {amortizationTableData?.length > 0 ?
                <Box>
                    <Box my={3}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                                <Paper className="info-card-box" elevation={0}>
                                    <Box className="pfIf-card">
                                        <Box className="pfIf-card-icon-box">
                                            <Box className="pfIf-card-icon">
                                                <DateRangeIcon />
                                            </Box>
                                        </Box>
                                        <Box className="font-22 text-black monthly-payment">
                                            ${amortizationMainData.monthlyPayment.toLocaleString('en-US')}
                                        </Box>
                                        <Box className="font-14 text-black">
                                            Monthly Payment
                                        </Box>
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Paper className="info-card-box" elevation={0}>
                                    <Box className="pfIf-card">
                                        <Box className="pfIf-card-icon-box">
                                            <Box className="pfIf-card-icon">
                                                <PaymentsIcon />
                                            </Box>
                                        </Box>
                                        <Box className="font-22 text-black  monthly-payment">
                                            ${(Math.abs(amortizationMainData.totalPrincipal + amortizationMainData.totalInterest - amortizationMainData.totalPaidEmiAmt)).toLocaleString('en-US')}
                                        </Box>
                                        <Box className="font-14 text-black">
                                            Total Payment
                                        </Box>
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Paper className="info-card-box" elevation={0}>
                                    <Box className="pfIf-card">
                                        <Box className="pfIf-card-icon-box">
                                            <Box className="pfIf-card-icon">
                                                <PieChartIcon />
                                            </Box>
                                        </Box>
                                        <Box className="font-22 text-black monthly-payment">${amortizationMainData.totalInterest.toLocaleString('en-US')}</Box>
                                        <Box className="font-14 text-black">
                                            Total Interest
                                        </Box>
                                    </Box>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box className='amortization'>
                        <SelectLoanAmortizationDropdown apiData={apiData} handleTabChange={handleTabChange} tabIndex={selectedTabIndex} />
                        {/* <Tabs
                            value={selectedTabIndex}
                            onChange={handleTabChange}
                            indicatorColor="secondary"
                            textColor="inherit"
                            variant="fullWidth"
                            aria-label="full width tabs example"

                            TabIndicatorProps={{
                                sx: {
                                    height: '3px',
                                    background: '#EDE4C5',
                                    borderRadius: '3px 3px 0px 0px',
                                }
                            }}
                        >
                            {apiData?.map((loanData, index) => <Tab key={index} label={`Loan ID: ${loanData.id}`} className={selectedTabIndex === index ? 'am-active tab-scroll' : 'am-inactive'}   {...a11yProps(index)} />)}
                        </Tabs> */}

                        {/* Year-wise Accordions */}
                        <Box className='year-wise-accordions'>
                            {/* Table Header */}
                            {/* <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>No.</StyledTableCell>
                                            <StyledTableCell>EMI</StyledTableCell>
                                            <StyledTableCell>Interest</StyledTableCell>
                                            <StyledTableCell>Principal</StyledTableCell>
                                            <StyledTableCell>Paid</StyledTableCell>
                                            <StyledTableCell>Balance</StyledTableCell>
                                            <StyledTableCell>Status</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </TableContainer> */}
                            {Object.entries(groupInstallmentsByYear(amortizationTableData)).map(([year, data]) => (
                                <Accordion key={year}>
                                    <AccordionSummary expandIcon={<ExpandMore style={{ color: 'white' }} />}>
                                        <Typography className='year-name'>{year}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell>No.</StyledTableCell>
                                                        <StyledTableCell>EMI</StyledTableCell>
                                                        <StyledTableCell>Interest</StyledTableCell>
                                                        <StyledTableCell>Principal</StyledTableCell>
                                                        <StyledTableCell>{amortizationTableData[0].investor_id === investor_id ? "Received" : "Paid"}</StyledTableCell>
                                                        <StyledTableCell>Balance</StyledTableCell>
                                                        <StyledTableCell>Due date</StyledTableCell>
                                                        <StyledTableCell>Status</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {data.map((row, i) => (
                                                        <TableRow key={i}>
                                                            <StyledTableCell>{row.month + 1}</StyledTableCell>
                                                            <StyledTableCell> ${formatNumber(parseFloat(row?.payment))}</StyledTableCell>
                                                            <StyledTableCell>${formatNumber(parseFloat(row.interest))}</StyledTableCell>
                                                            <StyledTableCell>${formatNumber(parseFloat(row.principal))}</StyledTableCell>
                                                            <StyledTableCell>${formatNumber(parseFloat(row.paid_amount))}</StyledTableCell>
                                                            <StyledTableCell>${formatNumber(parseFloat(row.endingBalance))}</StyledTableCell>
                                                            <StyledTableCell>
                                                                <Box sx={{
                                                                    whiteSpace: 'nowrap', // Ensures text stays on one line
                                                                    display: 'flex',
                                                                    alignItems: 'center',

                                                                }}>
                                                                    {moment(row.emi_date).format('ll')}
                                                                </Box >
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                <Box sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'space-evenly',
                                                                    borderRadius: '7px',
                                                                    backgroundColor: row.status === LOAN_INSTALLMENT_STATUS.FORECLOSED ? "#808080" : row.status === LOAN_INSTALLMENT_STATUS.PAID ? '#059669' : '#FFE8A3'
                                                                }}>
                                                                    <Chip
                                                                        sx={{
                                                                            fontSize: '14px',
                                                                            fontWeight: '600',
                                                                            color: row.status === LOAN_INSTALLMENT_STATUS.FORECLOSED || row.status === LOAN_INSTALLMENT_STATUS.PAID ? '#FFFFFF' : '#CCA829',
                                                                            backgroundColor: row.status === LOAN_INSTALLMENT_STATUS.FORECLOSED ? "#808080" : row.status === LOAN_INSTALLMENT_STATUS.PAID ? '#059669' : '#FFE8A3'
                                                                        }}
                                                                        icon={<Paid color='white' />}
                                                                        label={row.status === LOAN_INSTALLMENT_STATUS.FORECLOSED ? (row.investor_id === investor_id ? "Foreclosed" : 'Foreclosure') : row.status === LOAN_INSTALLMENT_STATUS.PARTIALLY_PAID ? (row.investor_id === investor_id ? "Partially Paid" : 'Partially Paid')
                                                                            : row.status === LOAN_INSTALLMENT_STATUS.PAID ? (row.investor_id === investor_id ? "Received" : 'Paid') : (row.investor_id === investor_id ? 'Remaining' : 'Pending')}
                                                                    />
                                                                </Box >
                                                            </StyledTableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </Box>
                    </Box>
                </Box>
                : null}
        </>
    )
}

function CapTable({ assetId, proposer_id, investor_id, checkCapTable, category_id, offerData }) {
    const [apiData, setApiData] = useState([]);
    const [ownership, setOwnership] = useState({ equity: 0, total_raise: 1 });

    useEffect(() => {
        var userData = localStorage.getItem("user_data") ? JSON.parse(localStorage.getItem("user_data")) : ""; //Getting  user data from local storage

        if (!assetId || !userData.id) return;
        async function getCapTableData() {
            try {
                let payload = { "asset_id": assetId, user_id: userData.id }
                const res = await GetApiParam("/invest/capitalizationData", payload);


                if (res?.data?.code === 200 && res?.data?.data?.length) {
                    let data = res?.data?.data
                    // let data = res?.data?.data?.filter((item) => item?.is_equity_withdraw && (item?.user_id === userData.id || item?.add_asset_owner_id === userData.id))
                    if (data?.length === 0) {
                        // checkCapTable(false)
                    } else if (data?.length > 0) {
                        let capTableData = []
                        data?.forEach(item => {
                            let tableRow = {
                                investor_name: `${item['member_id']}`,
                                share_type: item.shareType === CAPITAL_TYPE_ID.EQUITY ? "Equity" : (item.shareType === CAPITAL_TYPE_ID.SENIOR_DEBT ? "Senior Debt" : "Junior Debt"),
                                amount: item.totalInvestment.toLocaleString("en-US"),
                                id: item.shareType
                            }
                            capTableData.push(tableRow)
                        })
                        setApiData(capTableData);
                        const newAssetData = await postApi("/proposer/assetsDetails", { "asset_id": assetId });
                        if (newAssetData?.data?.data && newAssetData?.data?.data[0]) {
                            let data = newAssetData?.data?.data[0];
                            let capital_info = data?.capital_info?.find((item) => item.capital_type.id === CAPITAL_TYPE_ID.EQUITY)
                            if (capital_info) {
                                setOwnership({ equity: capital_info?.equity, total_raise: capital_info?.total_raise });
                            }
                        }
                    }
                } else {
                    // checkCapTable(false)
                }
            } catch (error) {
                console.log(error)
            }
        }
        const timer = setTimeout(() => {
            if (category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
                getCapTableData();
            }
        }, 100);
        return () => clearTimeout(timer)
    }, [assetId])

    return (
        <>
            {category_id === CATEGORY_TYPE_ID.REAL_ESTATE && apiData?.length ?
                <Box>
                    <Box className='amortization'>
                        <TableContainer component={Paper} sx={TableContainerStyle}>
                            <Table aria-label="customized table">
                                <TableHead className='TableHeader'>
                                    <TableRow>
                                        <StyledTableCell align="left">Member Id</StyledTableCell>
                                        <StyledTableCell align="left">Type</StyledTableCell>
                                        <StyledTableCell align="left">Ownership</StyledTableCell>
                                        <StyledTableCell align="left">Amount</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {apiData?.map((row, i) => (
                                        <StyledTableRow className='TableRow' key={`${i}`}>
                                            <StyledTableCell align="left">{(row.investor_name)}</StyledTableCell>
                                            <StyledTableCell align="left">{row.share_type}</StyledTableCell>
                                            <StyledTableCell align="left"> {row.id === CAPITAL_TYPE_ID.EQUITY ? parseFloat((row?.amount ? row?.amount : 0) / (ownership?.total_raise ? ownership?.total_raise : 1) * ownership?.equity).toFixed(2) + '%' : '-'} </StyledTableCell>
                                            <StyledTableCell align="left">${parseFloat(row.amount).toLocaleString("en-US")}</StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
                : category_id === CATEGORY_TYPE_ID.FUNDS && offerData?.length ? <Box>
                    <Box className='amortization'>
                        <TableContainer component={Paper} sx={TableContainerStyle}>
                            <Table aria-label="customized table">
                                <TableHead className='TableHeader'>
                                    <TableRow>
                                        <StyledTableCell align="left">Member Id</StyledTableCell>
                                        {offerData[0]?.asset_data?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ? <StyledTableCell align="left">Fund Type</StyledTableCell> :
                                            <StyledTableCell align="left">Class Type</StyledTableCell>
                                        }
                                        <StyledTableCell align="left">Amount</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {offerData?.map((row, i) => (
                                        <StyledTableRow className='TableRow' key={`${i}`}>
                                            <StyledTableCell align="left">{(row?.investor_details?.member_id)}</StyledTableCell>
                                            {row?.asset_data?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ?
                                                <StyledTableCell align="left">{row?.asset_data?.asset_sub_category_info?.title}</StyledTableCell> :
                                                <StyledTableCell align="left">{row.class == 1 ? "Class A" : "Class B"}</StyledTableCell>
                                            }
                                            <StyledTableCell align="left"> ${formatNumber(row.offer_amount)} </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box> : null}
        </>
    )
}