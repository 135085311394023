import React, { useEffect, useState } from "react";
import { GoogleMap, MarkerF, StandaloneSearchBox, LoadScript, InfoWindow } from "@react-google-maps/api";
import axios from "axios";
const lib = ['places'];

const mapOptions = {
  fullscreenControl: false // Hide the full-screen control
  // Add other map options as needed
};

function Map({ mapEdit, height, updateAssetLocation, addMarker, asset_address, searchBoxHide }) {
  const [defaultAddress, setDefaultAddress] = useState(asset_address ? asset_address : '');
  const [center, setCenter] = useState(addMarker ? {
    lat: addMarker[0],
    lng: addMarker[1],
  } : {
    lat: 22.7196,
    lng: 75.8577,
  })

  const [marker, setMarker] = useState([addMarker ? {
    lat: addMarker[0],
    lng: addMarker[1],
  } : {}]);

  const containerStyle = {
    width: "100%",
    height: height ? height : "283px"
  };
  const onMapClick = (e) => {
    setMarker((current) => [{ lat: e.latLng.lat(), lng: e.latLng.lng() }]);
    // updateAssetLocation([e.latLng.lat(), e.latLng.lng()])
    fetchPlaceName(e.latLng.lat(), e.latLng.lng())
  };
  const [searchBox, setSearchBox] = useState(null);

  const onPlacesChanged = () => {
    let places = searchBox.getPlaces();
    let locations = []
    for (let i = 0; i < places.length; i++) {
      let location = places[i]?.geometry?.location
      locations.push({ address: places[i]?.formatted_address, lat: location?.lat(), lng: location?.lng() })
    }
    setDefaultAddress(locations[0]?.address)
    setMarker(locations)
    let location = places[0]?.geometry?.location
    setCenter({ lat: location?.lat(), lng: location?.lng() })
  };
  const onSBLoad = ref => {
    setSearchBox(ref);
  };

  const [locationName, setLocationName] = useState("")
  const handleToggleOpen = (markerId, address, position) => {
    setLocationName({
      openInfoWindowMarkerId: markerId
    });
    if (address) {
      setDefaultAddress(address)
      updateAssetLocation(address, [position.lat, position.lng])
    } else {
      fetchPlaceName(position.lat, position.lng)
    }
  }

  // Setting default address during marker click
  useEffect(() => {
    if (defaultAddress) {
      setDefaultAddress(defaultAddress)
    }
  }, [defaultAddress])

  // code to get from coordinates
  // const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });
  const [placeName, setPlaceName] = useState("");

  const fetchPlaceName = (lat, lng) => {
    const apiKey = process.env.REACT_APP_MAP_KEY;
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;

    axios
      .get(apiUrl)
      .then((response) => {
        const results = response.data.results;
        if (results.length > 0) {
          updateAssetLocation(results[0].formatted_address, [lat, lng])
          // setPlaceName(results[0].formatted_address);
          setDefaultAddress(results[0].formatted_address)
        } else {
          setPlaceName("No results found");
        }
      })
      .catch((error) => {
        console.error("Error fetching place name:", error);
        setPlaceName("Error fetching place name");
      });
  };
  // end --here

  return (
    <LoadScript googleMapsApiKey={`${process.env.REACT_APP_MAP_KEY}`} libraries={lib}>
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={13} onClick={mapEdit ? onMapClick : ""} options={mapOptions}>
        {!searchBoxHide &&
          <StandaloneSearchBox
            onPlacesChanged={onPlacesChanged}
            onLoad={onSBLoad}
          >
            <input
              type="text"
              placeholder="Search Google Maps"
              value={defaultAddress ? defaultAddress : ""}
              onChange={(e) => setDefaultAddress(e.target.value)}
              style={{
                boxSizing: 'border-box',
                border: `1px solid transparent`,
                width: `270px`,
                height: `40px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                margin: 'center',
                textOverflow: `ellipses`,
                position: 'absolute',
                top: '10px',
                marginLeft: '32%'
              }}
            />
          </StandaloneSearchBox>
        }

        {marker.map((marker, i) => (
          <MarkerF
            key={i}
            position={{
              lat: marker.lat,
              lng: marker.lng
            }}
            onClick={() => handleToggleOpen(i, marker?.address, {
              lat: marker.lat,
              lng: marker.lng
            })}
          >
            {(i === locationName?.openInfoWindowMarkerId && marker?.address) && (
              <InfoWindow>
                <span className="text-black">{marker?.address}</span>
              </InfoWindow>
            )}
          </MarkerF>
        ))}
      </GoogleMap>
    </LoadScript>
  );
}

export default Map;
