import { GetApiParam } from "../../Api/Api";

export const GetContractAddressAbi = async (version, contractName) => {
    try {
        const payload = {
            version,
            contractName,
        };
 
        const res = await GetApiParam(`/services/getSpContractAddressAbi`, payload);

        if (res?.data?.code === 200 && res?.data?.data) {
            return res.data.data;
        }

        throw new Error('Address and ABI not available.');
    } catch (error) {
        throw error; // propagate the error
    }
};
