import { Close } from '@mui/icons-material';
import { Box, Modal } from '@mui/material';
import React from 'react'

const style = {
    position: "relative",
    // boxShadow: 24,
    borderRadius: "24px",
    width: "90%",
    maxWidth: "600px",
    backdropFilter: "blur(10px)"
};
const AuctionBackdropModal = () => {
    return (
        <Modal
            open={true}
            className="kyc-modal blurBackground-modal"
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            disableBackdropClick
        >
            <Box className="modal-body" sx={{
                ...style,
                padding: "24px",
                background: "linear-gradient(135deg, #96cbda,#acaded)",
                boxShadow: "0 8px 20px rgba(0, 0, 0, 0.2)",
                '&:focus': {
                    outline: 'none',
                },
            }} >
                <Box className="modal-body-inner">
                    <Box className="auction-alert-modal" sx={{
                        padding: "20px",
                        background: "#fff",
                        borderRadius: "16px",
                        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                    }}>
                        <Box className='auction-text' sx={{
                            fontSize: "1.5rem",
                            fontWeight: "bold",
                            color: "#333",
                            // marginBottom: "16px",
                        }}>
                            🏷️ This Project is Under Auction
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default AuctionBackdropModal