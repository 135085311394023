import React, { useState, useEffect } from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

const SelectLoanAmortizationDropdown = ({ apiData, handleTabChange, tabIndex }) => {
    const [selectedTabIndex, setSelectedTabIndex] = useState(tabIndex); // Default to the first loan

    // useEffect(() => {
    //     if (apiData && apiData.length > 0) {
    //         handleTabChange(null, 0); // Trigger tab change for the default selection
    //     }
    // }, [apiData]);

    const handleChange = (event) => {
        const value = event.target.value;
        setSelectedTabIndex(value);
        handleTabChange(null, value);
    };

    return (
        <FormControl fullWidth className="select-loan-amortization">
            {/* <InputLabel id="loan-select-label">Select Loan</InputLabel> */}
            <Select
                labelId="loan-select-label"
                value={selectedTabIndex}
                onChange={handleChange}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 48 * 4, // Approx height for 6 items (48px each + padding)
                            overflowY: "auto",
                        },
                    },
                }}
            // label="Select Loan"
            >
                {apiData?.map((loanData, index) => (
                    <MenuItem key={index} value={index}>
                        Loan ID: {loanData.id}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SelectLoanAmortizationDropdown;
