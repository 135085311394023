import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Grid, IconButton, Radio, TextField, Tooltip, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

// Import custom components for this page
import { NewLogo, Quest4 } from "../../Components/Images/Images";
import { postApi } from "../../Api/Api";
import { Add, Info, KeyboardArrowLeft, Remove } from "@mui/icons-material";
import NumericFormatCustomDollar from "../../Components/Common/NumericFormatCustomDollar";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import TransactionProgressModal from "../../Components/SmartContract/TransactionProgressModal";

export default function PayAuctionAmountPage() {
    const navigate = useNavigate();
    const location = useLocation();
    let asset_id = location?.state?.asset_id;

    // states
    const [assetData, setAssetData] = useState();
    const [openModal, setOpenModal] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    // state to handle all inputs and data
    const [formData, setFormData] = useState({
        bidAmount: 0,
        expenses: 0,
        net_proceeds: 0,
    });

    // get asset data
    useEffect(() => {
        if (asset_id) {
            async function getAssetData() {
                const res = await postApi("/proposer/assetsDetails", { asset_id: parseInt(asset_id) });
                if (res?.data?.data && res?.data?.data[0]) {
                    setAssetData(res?.data?.data[0])
                }
            }
            setTimeout(() => {
                getAssetData();
            }, 100);
        }
    }, [])

    useEffect(() => {
        // check null values
        if (formData.bidAmount === '' || formData.expenses === '') {
            setIsError(true);
        } else {
            setIsError(false);
        }
        if (formData.bidAmount > 0) {
            setFormData({ ...formData, net_proceeds: (formData.bidAmount - (formData.expenses || 0)) })
        }
    }, [formData])

    const handleNavigate = () => {
        navigate(`/dic/project`, {
            replace: true, // This will replace the current entry in the history stack
        })
    }

    // Style for left-half background image
    const styles = {
        backgroundImage: `url(${Quest4})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        minHeight: '100vh',
        marginRight: '0px',
        // backgroundPosition: "center",
        backgroundAttachment: "fixed",
        boxShadow: '10px white',  // Adds a 2px blue border
        // filter: 'blur(2px)', // Adjust the blur level as needed
    }

    /**
    * Handle set repayment config modal save button
    */
    const handleSave = () => {
        setIsSubmitted(true);
        // check all validation before contract call
        console.log('formData---------', formData);
        if (isError) return
        setOpenModal(true)
    }

    /**
    * Handle set repayment config modal close
    */
    const handleModalClose = () => {
        setOpenModal(false)
    }

    /**
    * Handle set repayment confirmation
    */
    const handleConfirmTransaction = () => {
        setOpenModal(false)
        navigate(`/dic/project`, {
            replace: true, // This will replace the current entry in the history stack
        })
    }

    const handleChange = (key) => (e) => {
        setFormData({ ...formData, [key]: e.target.value });
    };

    const handleKeyDown = (event) => {
        let keyArr = ["-", "+", "."]
        if (keyArr.includes(event.key)) {
            event.preventDefault();
        }
    };

    return (
        <Box className="projectProposer questionnaire-page">
            <Box className="questionnaire-section">
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={5} md={5}>
                        <Box className="auth-left" style={styles}>
                            <Box className="quests-left-title">
                                <Typography component="h1" className="title">
                                    Distribute Auction Amount
                                </Typography>
                            </Box>
                            <Box className="auth-logo">
                                <Link to="/">
                                    <Box component="img" src={NewLogo} alt="Race logo" />
                                </Link>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={7} md={7}>
                        <Box className="auth-right quests-right-contents-box" sx={{ gap: 6 }}>
                            <Box textAlign={"right"} className="alreadyAc-des">
                                <Button startIcon={<KeyboardArrowLeft />} onClick={handleNavigate} className='btn-rounded btn-text-white btn-blue-600'>
                                    Back
                                </Button>
                            </Box>
                            <Box className="quests-right-contents">
                                <OverlayScrollbarsComponent defer
                                    style={{ width: '100%', minHeight: '340px', maxHeight: '60vh', margin: '10px 24px 0 10px' }}
                                >
                                    <Grid container spacing={{ md: 3, sm: 2.5, xs: 2 }}>
                                        {/* Penalty Amount */}
                                        <CustomTextField
                                            label="Bid Amount"
                                            inputComponent={NumericFormatCustomDollar}
                                            value={formData.bidAmount}
                                            name="bidAmount"
                                            onChange={handleChange('bidAmount')}
                                            onKeyDown={handleKeyDown}
                                            tooltipText="This is the bid amount in USD."
                                        />

                                        {/* Expenses Amount */}
                                        <CustomTextField
                                            label="Expenses"
                                            inputComponent={NumericFormatCustomDollar}
                                            value={formData.expenses}
                                            name="expenses"
                                            onChange={handleChange('expenses')}
                                            onKeyDown={handleKeyDown}
                                            tooltipText="This is the expenses amount in USD."
                                        />

                                        {/* Net Proceeds/Net Profit */}
                                        <CustomTextField
                                            label="Net Proceeds"
                                            inputComponent={NumericFormatCustomDollar}
                                            value={formData.net_proceeds}
                                            name="net_proceeds"
                                            // onChange={handleChange('net_proceeds')}
                                            // onKeyDown={handleKeyDown}
                                            tooltipText="This is the net proceeds amount in USD."
                                            readOnly={true}
                                        />
                                    </Grid>
                                </OverlayScrollbarsComponent>
                            </Box>
                            <Box mt={2}>
                                <Box textAlign={"right"} className='quests-btn-box' mt={4.7}>
                                    <Button endIcon={<KeyboardArrowRightIcon />} onClick={handleSave} className='btn-rounded btn-text-white btn-blue-600'>
                                        Save
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            {/* {
                openModal
                    ? <TransactionProgressModal identity={'set-repayment-config'} confirmStake={handleConfirmTransaction} propData={{ ...assetData, repaymentData: formData }} handleModalClose={handleModalClose} openTransactionModal={openModal} />
                    : null
            } */}
        </Box>
    );
}

// Show error message
const ShowErrorMessage = ({ message }) => {
    return (<Typography className="text-red font-12" mt={'-2px'}>
        {message}
    </Typography>)
}

// Reusable CustomTextField Component
const CustomTextField = ({ label, inputComponent, value, name, onChange, onKeyDown, type, tooltipText, readOnly }) => (
    <Grid item xs={11.6} sm={5.8} md={5.8}>
        {label ?
            <Box display="flex">
                <Typography className="font-16 text-black" sx={{ display: 'flex', gap: '2px' }}>
                    {label}
                    <Tooltip title={tooltipText} placement="top" arrow>
                        <IconButton size="small" style={{ padding: '0 5px' }}>
                            <Info fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Typography>
            </Box> : null
        }
        <Box my={1}>
            <Box width="100%">
                <TextField
                    id="outlined-number"
                    type="text"
                    defaultValue={0}
                    className="loan-text"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ inputComponent, readOnly: readOnly }}
                    name={name}
                    value={value}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                />
            </Box>
        </Box>
        {value === '' ? <ShowErrorMessage message={`${label} cannot be empty.`} /> : null}
    </Grid>
);
