import DealTerms from "../DealTerms";
import Documents from "../Documents";
import AssetDetails from "../AssetDetails";
import PriceOverview from "../PriceOverview";
import GallerySlider from "../GallerySlider";
import Description from "../Description";
import OwnerInformation from "../OwnerInformation";
import BaseDetailsAsset from "./BaseDetailsClass";
import CheckReview from "../CheckReview";
import Templates from "../Templates";
import { formatNumber } from "../../../Components/Common/USFormat";
import RepaymentTerms from "../../../ProjectDetails/RepaymentTerms";

export default class Gold extends BaseDetailsAsset {

  rightPanel = [];
  leftPanel = [];
  isParcel = false;

  constructor(_data) {

    super(_data);
    if (!_data) {
      throw new Error(
        "Custom error: Funds data is required in Art Details class"
      );
    }

    this.offersData = [
      {
        user_name: "John Doe",
        offer_amount: "12850.98",
      },
      {
        user_name: "John Doe",
        offer_amount: "12850.98",
      },
      {
        user_name: "John Doe",
        offer_amount: "12850.98",
      },
    ];


    // Determine the string for months (singular or plural)
    const loanDurationMonths =
      _data?.loan_duration_month > 1
        ? `${_data?.loan_duration_month} Months`
        : `${_data?.loan_duration_month} Month`;

    // Determine the string for days (singular or plural)
    // const loanDurationDays =
    //   _data?.loan_duration > 1
    //     ? `${_data?.loan_duration} Days`
    //     : `${_data?.loan_duration} Day`;

    // Concatenate the month and day strings
    // const result = `${loanDurationMonths} ${loanDurationDays}`;
    const loanDurationFrom = _data?.suggestReviewData?.length ? formatNumber(parseFloat((_data?.suggestReviewData[0]?.loan_duration_from))) : 1;

    // const loanDurationFromMonts = loanDurationFrom > 1
    //   ? `${loanDurationFrom} Months`
    //   : `${loanDurationFrom} Month`;

    const result = `${this.data?.is_publish ? loanDurationFrom + ' - ' : ''} ${loanDurationMonths}`;

    const loanRoiFrom = _data?.suggestReviewData?.length ? formatNumber(parseFloat(_data?.suggestReviewData[0]?.loan_roi_from)) : '1';

    this.dealData = [
      {
        title: "Loan Duration",
        value: result,
      },
      {
        title: "Interest Rate",
        value: `${this.data?.is_publish ? loanRoiFrom + ' - ' : ''} ${_data?.loan_roi}` + ' % APR',
      }
      // ];
      // {
      //   title: "Loan Type",
      //   value: _data?.loan_type,
      // },
      // {
      //   title: "Type of Capital",
      //   value: 'Equity',
      // },

    ];



    this.assetDetails = [
      {
        title: "Investment Type",
        value: _data?.investment_type?.investment_name,
      },
      {
        title: "Asset Type",
        value: _data?.assets_category?.title ?? "",
      },
      {
        title: "Quantity",
        value: _data?.gold_weight + "gm",
      },
      {
        title: "Quality",
        value: _data?.gold_quality + "K",
      },
      {
        title: "Condition",
        value: _data?.gold_condition ?? "",
      },
      {
        title: "Estimated value at purchase",
        value: `$${_data?.gold_estimated}` ?? "",
      },
      {
        title: "Owned By",
        value: _data.owner_information
          ? _data.owner_information.owner_type
          : "",
      },
    ];


    this.checkReview = [
      {
        title: "All required documents uploaded",
        value: false,
      },
      {
        title: "Deal terms reviewed",
        value: false,
      },
      {
        title: "Asset Details provided are accurate",
        value: false,
      },
      {
        title: "Project Description and Title reviewed",
        value: false,
      },
      {
        title: "Images uploaded are clear and accurate",
        value: false,
      },
    ];
    this.data = _data;
    this.assetData = _data;
    this.blockchain_id = _data.blockchain_id
    this.fromClass = "goldDetails";
    this.docs = _data.asset_document;
    this.description_document = _data.description_document
    this.asset_signing_document = _data.asset_signing_document
    this.selected_templates = _data?.template_data ? _data?.template_data[0]?.template_ids : []
    this.location = _data?.asset_location?.split(",");
    this.galleryImages = _data?.asset_gallery;
    this.member_id = _data?.user_details?.member_id

    if (_data.description_document?.length > 0) {
      const documentOnly = _data.description_document.filter(item => item.images && (item.images.type === "mp4"));
      if (documentOnly?.length > 0) {
        this.galleryImages = _data?.asset_gallery.concat(documentOnly);
      }
    }
    this.coverPhoto = _data?.asset_coverphoto;
    this.descriptionData = {
      title: _data?.asset_title,
      subtitle: _data?.asset_address,
      investmentType: _data?.investment_type?.investment_name,
      assetType: _data?.assets_category?.title,
      assetSubtype: "",
      description: _data?.asset_description,
    };

    this.setLeftPanelComponents();
    this.setRightPanelComponents();
  }

  setLeftPanelComponents = () => {
    this.leftPanel.push(<GallerySlider images={this.galleryImages} coverPhoto={this.coverPhoto} />);
    this.leftPanel.push(
      <Description
        description={this.descriptionData.description}
        investmentType={this.descriptionData.investmentType}
        title={this.descriptionData.title}
        subtitle={this.descriptionData.subtitle}
        assetType={this.descriptionData.assetType}
        assetSubtype={this.descriptionData.assetSubtype}
        userId={this.user_id}
        assetId={this.asset_Id}
      />
    );
  };

  setRightPanelComponents = () => {
    this.rightPanel.push(<OwnerInformation owner={this.ownerInfo} totalProject={this.totalProjects} member_id={this.member_id} />);
    this.rightPanel.push(<PriceOverview prices={this.prices} />);
    this.rightPanel.push(<AssetDetails details={this.assetDetails} editable={this.isOwner} />);
    this.rightPanel.push(<DealTerms dealTerms={this.dealData} fromPage={this.fromClass} editable={this.isOwner} />);
    this.rightPanel.push(<RepaymentTerms assetData={this.assetData} isLoan={this.isLoan} />);
    this.rightPanel.push(<Documents documentsData={this.docs} descriptionData={this.description_document} assetId={this.asset_Id} ownerId={this.ownerInfo} editable={this.isOwner} section_title="Asset Documents" />);
    // this.rightPanel.push(<Documents documentsData={this.asset_signing_document} descriptionData={[]} assetId={this.asset_Id} ownerId={this.ownerInfo} editable={this.isOwner} section_title="Agreement Documents" />);
    this.rightPanel.push(<Templates selected_templates={this.selected_templates} />);
    this.rightPanel.push(<CheckReview data={this.data} blockchain_id={this.blockchain_id} assetId={this.asset_Id} details={this.checkReview} editable={this.isOwner} reviewList={this.reviewList} readOnly={this.readOnly} />);
  };
}
