import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Backdrop, Box, CircularProgress, Divider, Stack, Typography } from '@mui/material'
import DropdownBox from '../InvestorOnly/Component/DropdownBox'
import { useSelector, useDispatch } from "react-redux";
import { GetApiParam, updateApi } from '../Api/Api';
import { updateStep } from "../features/auth/tooltipSlice"
import { repaymentConfigDetail } from '../Components/SmartContract/repaymentConfigDetail';
import { formatNumber } from '../Components/Common/USFormat';
import { GetContractAddress } from '../Components/Common/GetContractAddress';
import { CONTRACT_NAME } from '../constants';

export default function RepaymentTerms({ assetData, isLoan, isFraction, isFundFraction }) {
    const [tolPosition, setTolPosition] = useState(0);
    const [termsList, setTermsList] = useState();
    const step = useSelector((state) => {
        if (isLoan) {
            return state.tooltipSlice.loan_step_number
        } else if (isFraction) {
            return state.tooltipSlice.re_step_number
        } else if (isFundFraction) {
            return state.tooltipSlice.funds_step_number
        }
    }
    );
    const isActive = useSelector((state) => state.tooltipSlice.active_how_it_work);

    const [loading, setLoading] = useState(false);
    const [loadingApi, setLoadingApi] = useState(false);
    const [loadingForRepayTerms, setLoadingForRepayTerms] = useState(false);

    // Get the dispatch function from the Redux store
    const dispatch = useDispatch();

    const localData = localStorage.getItem("user_data")
        ? JSON.parse(localStorage.getItem("user_data"))
        : "";

    // getting repayment data by listing id
    useEffect(() => {
        async function getRepaymentConfig(assetData) {
            try {
                const response = await GetContractAddress(assetData, assetData?.contract_version, isLoan ? CONTRACT_NAME.WATCH_REPAYMENT_ADDRESS : CONTRACT_NAME.SPV_DEBT_REPAYMENT);
                let contractAddress = null;
                let contractAbi = null;
                if (response && response?.length) {
                    contractAddress = response[0].address;
                    contractAbi = response[0].abi;
                }
                let repaymentData = await repaymentConfigDetail(contractAddress, contractAbi, assetData?.listing_id)

                // let repaymentData = await repaymentConfigDetail(listing_id);
                if (repaymentData && repaymentData?.length) {
                    let min_prepayment_fees = `${formatNumber(Number(repaymentData[10]) / 100)}% or $${formatNumber(Number(repaymentData[11]) / 1e18)}`;
                    let default_penalty = `${formatNumber(Number(repaymentData[0]) / 100)}% or $${formatNumber(Number(repaymentData[1]) / 1e18)}`;
                    let foreclosure_fees = `${formatNumber(Number(repaymentData[4]) / 100)}% or $${formatNumber(Number(repaymentData[5]) / 1e18)}`;


                    setTermsList([
                        {
                            'title': 'Penalty Fees',
                            'value': default_penalty
                        },
                        {
                            'title': 'Minimum Prepayment Fees',
                            'value': min_prepayment_fees
                        },
                        {
                            'title': 'Prepayment Fees',
                            'value': foreclosure_fees
                        }
                    ])
                } else {
                    setTermsList([])
                }
                setLoadingForRepayTerms(false)
            } catch (error) {
                setTermsList([])
                setLoadingForRepayTerms(false)
            }
        }
        if (assetData?.listing_id) {
            getRepaymentConfig(assetData);
        }
    }, [assetData])

    useEffect(() => {
        if ((tolPosition > 14 || tolPosition <= 13) && isLoan) {
            setLoading(false)
        } else if ((tolPosition > 11 || tolPosition <= 10) && isFraction) {
            setLoading(false)
        } else if ((tolPosition > 9 || tolPosition <= 8) && isFundFraction) {
            setLoading(false)
        }
    }, [tolPosition])

    useEffect(() => {
        async function getStep() {
            try {
                setLoadingApi(true)
                const data = await GetApiParam("/user/currentTooltipPosition", { "user_id": localData.id });
                if (data?.data?.data && isActive) {
                    if (data.data?.data?.loan_step_number == 14 && isLoan) {
                        setTolPosition(data.data?.data?.loan_step_number == null ? 0 : data.data.data.loan_step_number)
                        setLoading(true)
                        scrollToPosition("scroll-to-dealterm");
                    }
                    else if ((data.data?.data?.re_step_number == 11) && isFraction) {
                        setTolPosition(data.data?.data?.re_step_number == null ? 0 : data.data.data.re_step_number)
                        setLoading(true)
                        scrollToPosition("scroll-to-dealterm");
                    }
                    else if ((data.data?.data?.funds_step_number == 9) && isFundFraction) {
                        setTolPosition(data.data?.data?.funds_step_number == null ? 0 : data.data.data.funds_step_number)
                        setLoading(true)
                        scrollToPosition("scroll-to-dealterm");
                    }
                }
                setLoadingApi(false)
            } catch (error) {
                console.log('error', error)
                setLoadingApi(false)
            }
        }
        // Call getStep initially
        const timer = setTimeout(() => {
            if (localData && localData?.id && isLoan) {
                getStep();
            }
        }, 100);
        return () => clearTimeout(timer)
    }, [step, isActive])

    async function setStep(tolPositionData) {
        try {
            let body = { "loan_step_number": tolPositionData };
            if (isFraction) {
                body = { "re_step_number": tolPositionData };
            } else if (isFundFraction) {
                body = { "funds_step_number": tolPositionData };
            }
            const data = await updateApi("/user/updateUser/" + localData?.id, body);
            if (data?.data?.data) {
                if (data.data?.data?.loan_step_number == 14 && isLoan) {
                    setTolPosition(data.data?.data?.loan_step_number == null ? 0 : data.data.data.loan_step_number)
                    setLoading(true)
                }
                else if ((data.data?.data?.re_step_number == 11) && isFraction) {
                    setTolPosition(data.data?.data?.re_step_number == null ? 0 : data.data.data.re_step_number)
                    setLoading(true)
                } else if ((data.data?.data?.funds_step_number == 9) && isFundFraction) {
                    setTolPosition(data.data?.data?.funds_step_number == null ? 0 : data.data.data.funds_step_number)
                    setLoading(true)
                }
            }
        } catch (error) {
            console.log('error', error)
        }
    }
    const scrollToPosition = (findId) => {
        setTimeout(() => {
            const element = document.getElementById(`${findId}`);
            if (element) {
                const offset = 0;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth"
                });
            }
        }, 200);
    }
    const handleTolPosition = (data) => {
        setTolPosition(pre => pre + data)
        setStep(tolPosition + data)
        if (isLoan)
            dispatch(updateStep({ key: 'loan_step_number', value: tolPosition + data }));
        if (isFraction)
            dispatch(updateStep({ key: 're_step_number', value: tolPosition + data }));
        if (isFundFraction)
            dispatch(updateStep({ key: 'funds_step_number', value: tolPosition + data }));
        if (data < 0) {
            scrollToPosition("scroll-to-dealterm");
        } else {
            scrollToPosition("scroll-to-document");
        }
    }

    return (
        <>
            {loading ?
                <Box className="pd-sidebar-dealTerms" id="scroll-to-repayment-term" sx={{
                    zIndex: 10, position: 'relative', backgroundColor: "#06487E",
                    color: "white", borderRadius: "8px", zIndex: "3",
                    padding: '4px'
                }}>
                    <DropdownBox
                        render={
                            <Box>
                                <Stack className="pd-sidebar-heading" direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                                    <Typography className="title" component={'h4'}>Repayment Terms</Typography>
                                </Stack>
                                <Box className="pd-sidebar-detailsList" mt={0.5}>
                                    {
                                        termsList && termsList?.length > 0 ?
                                            termsList?.map((item, index) => {
                                                return (
                                                    <Stack className="detailsList" direction="row" spacing={1} justifyContent="space-between" alignItems="center" key={index}>
                                                        <Typography className='title'>{item?.title}</Typography>
                                                        <Typography className='subtitle'>{item?.value}</Typography>
                                                    </Stack>
                                                )
                                            })
                                            : 'No repayment terms are available.'
                                    }
                                </Box>
                            </Box>
                        }
                        setState={handleTolPosition}
                        description={`The key terms of the repayment of the loan`} position={"left"}
                    />
                </Box>
                :
                <Box className="pd-sidebar-dealTerms" id="scroll-to-dealterm">
                    <Stack className="pd-sidebar-heading" direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                        <Typography className="title" component={'h4'}>Repayment Terms</Typography>
                    </Stack>
                    <Box className="pd-sidebar-detailsList" mt={0.5}>
                        {
                            termsList && termsList?.length > 0 ?
                                termsList?.map((item, index) => {
                                    return (
                                        <Stack className="detailsList" direction="row" spacing={1} justifyContent="space-between" alignItems="center" key={index}>
                                            <Typography className='title'>{item?.title}</Typography>
                                            <Typography className='subtitle'>{item?.value}</Typography>
                                        </Stack>
                                    )
                                })
                                : <>
                                    <Typography>No repayment terms are available.</Typography>
                                </>
                        }
                        <Divider style={{ marginTop: '24px' }} />
                    </Box>
                </Box>
            }
            {loading &&
                <Backdrop
                    sx={{ color: '#fff', zIndex: () => 2 }}
                    open={loading}
                >
                </Backdrop>
            }
            {loadingApi &&
                <Backdrop
                    sx={{ color: '#fff', zIndex: () => 2 }}
                    open={loadingApi}
                >
                    <CircularProgress />
                </Backdrop>
            }
            {loadingForRepayTerms &&
                <Backdrop
                    sx={{ color: '#fff', zIndex: () => 2 }}
                    open={loadingForRepayTerms}
                >
                    <CircularProgress />
                </Backdrop>
            }
        </>
    )
}

RepaymentTerms.propTypes = {
    editable: PropTypes.bool,
    dealTerms: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        value: PropTypes.string || PropTypes.number,
    }))
}