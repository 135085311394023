import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Grid, Typography, Button, TextField } from "@mui/material";
import { KeyboardArrowRight } from "@mui/icons-material";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { Controller, useForm, useWatch } from "react-hook-form";

// Import custom components for this page
import { NewLogo } from "../../../Components/Images/Images";
import LinearProgressCompo from "./Components/LinearProgressCompo";
import NumericFormatCustomDollar from "../../../Components/Common/NumericFormatCustomDollar";
import NumericFormatCustomPercent from "../../../Components/Common/NumericFormatCustomPercent";
import NumericFormatCustom from "../../../Components/Common/NumericFormatCustom";
import "./Components/Questionnaire.css";
import { CAPITAL_TYPE_DISPLAY, CAPITAL_TYPE_ID } from "../../../constants";
import { useSnackbar } from "../../../Contexts/SnackbarContext";

// list of capital type
const CapitalTypeList = [
    {
        title: 'Equity',
        data: ["totalRaiseEquity_Equity", "minimumInvestment_Equity", "preferredRateOfReturn_Equity", 'equity', 'equity_token_name', 'equity_token_symbol']
    },
    {
        title: 'Senior Debt',
        data: ["totalRaiseEquity_Senior", "minimumInvestment_Senior", "preferredRateOfReturn_Senior", 'iop_senior', 'senior_duration', 'senior_amortization_duration', 'senior_token_name', 'senior_token_symbol']
    },
    {
        title: 'Junior Debt',
        data: ["totalRaiseEquity_Junior", "minimumInvestment_Junior", "preferredRateOfReturn_Junior", 'iop_junior', 'junior_duration', 'junior_amortization_duration', 'junior_token_name', 'junior_token_symbol']
    }
];

const arrayData = ["totalRaiseEquity_Equity", "minimumInvestment_Equity", "preferredRateOfReturn_Equity", 'equity', "totalRaiseEquity_Senior", "minimumInvestment_Senior", "preferredRateOfReturn_Senior", 'senior_duration', 'senior_amortization_duration', "totalRaiseEquity_Junior", "minimumInvestment_Junior", "preferredRateOfReturn_Junior", 'junior_duration', 'junior_amortization_duration']
export default function RealEstateCapitalDetails(props) {
    // Destructuring props to extract specific functions
    const { handleNext, handleback, getProposalData, updateProposalData, saveProposal, getProgress } = props;

    // State variables to manage capital type details and error messages
    const [capitalTypeDetails, setCapitalTypeDetails] = useState([]);
    const [errorMsg, setErrorMsg] = useState(false);
    const [errorMsgNew, setErrorMsgNew] = useState(false);
    const [errorMsgEquity, setErrorEquity] = useState(false);
    const [errorMsgRor, setErrorRor] = useState(false);
    const [errorMsgDuration, setErrorDuration] = useState(false);
    const [errorMsgIOPDuration, setErrorIOPDuration] = useState(false);

    const { showSnackbar } = useSnackbar()

    // Variable to track the count
    let count = -1;

    // Get current proposal and progress data
    var propData = getProposalData();
    let currentProgress = getProgress();

    // Destructure the useForm hook and extract the 'register', 'handleSubmit', and 'errors' properties
    const { control, handleSubmit, trigger, setValue, formState: { errors }, getValues } = useForm({ mode: "onBlur" });

    /**
    * Function to handle form submission.
    * @param {Object} data - Form data submitted by the user.
    * @param {Event} event - The form submission event.
    */
    const onSubmit = (data, event) => {
        // Extract the button text from the submitted event
        const buttonText = event.nativeEvent.submitter.innerText;
        // Convert string values in form data to numbers
        for (let i in data) {

            if ((i === 'senior_token_name' || i === 'junior_token_name' || i === 'senior_token_symbol' || i === 'junior_token_symbol' || i === 'equity_token_name' || i === 'equity_token_symbol')) {
                continue;
            } else if (typeof data[i] === 'string') {
                const stringWithoutCurrency = data[i].replace(/[^0-9.]/g, ''); // Removes non-numeric characters
                const integerValue = parseFloat(stringWithoutCurrency, 10); // Parses the string as an float
                data[i] = integerValue;
            }
        }

        // ready data for api
        var arrayObj = [];
        var checker = 0;
        var checkerNew = true;
        var checkerEquity = false;
        var checkerRor = false;
        var checkDuration1 = false;
        var checkDuration2 = false;
        var checkIOPDuration1 = false;
        var checkIOPDuration2 = false;
        // Iterate through CapitalTypeList to check and prepare data
        CapitalTypeList?.forEach((items) => {
            if (propData?.capital_type?.includes(items?.title)) {
                let newData = {};
                checker += parseFloat(data[items?.data[0]]);

                newData['total_raise'] = data[items?.data[0]];
                if (items.title === 'Equity') {
                    newData['equity'] = parseFloat(parseFloat(data[items?.data[0]]) * 100) / parseFloat(propData?.fractionalize_total_price).toFixed(2);
                    newData['tokenName'] = data[items?.data[4]]
                    newData['tokenSymbol'] = data[items?.data[5]]
                    if (parseFloat(data[items?.data[3]]) > 100) {
                        checkerEquity = true
                    }
                } else {
                    if (items.title === 'Senior Debt') {
                        newData['senior_duration'] = data[items?.data[4]]
                        if (parseFloat(data[items?.data[4]]) > parseFloat(propData?.fractionalize_duration_of_project)) {
                            checkDuration1 = true;
                        } else {
                            checkDuration1 = false;
                        }
                        if (parseFloat(data[items?.data[4]]) * 12 <= parseFloat(data[items?.data[3]])) {
                            checkIOPDuration1 = true;
                        } else {
                            checkIOPDuration1 = false;
                        }
                        newData['iop_senior'] = data[items?.data[3]]
                        newData['amortization_duration'] = data[items?.data[5]]
                        newData['tokenName'] = data[items?.data[6]]
                        newData['tokenSymbol'] = data[items?.data[7]]
                    }
                    if (items.title === 'Junior Debt') {
                        newData['junior_duration'] = data[items?.data[4]]
                        if (parseFloat(data[items?.data[4]]) > parseFloat(propData?.fractionalize_duration_of_project)) {
                            checkDuration2 = true
                        } else {
                            checkDuration2 = false;
                        }
                        if (parseFloat(data[items?.data[4]]) * 12 <= parseFloat(data[items?.data[3]])) {
                            checkIOPDuration2 = true;
                        } else {
                            checkIOPDuration2 = false;
                        }
                        newData['iop_junior'] = data[items?.data[3]]
                        newData['amortization_duration'] = data[items?.data[5]]
                        newData['tokenName'] = data[items?.data[6]]
                        newData['tokenSymbol'] = data[items?.data[7]]
                    }
                    newData['rate_of_return'] = data[items?.data[2]];
                    if (parseFloat(data[items?.data[2]]) > 100) {
                        checkerRor = true
                    }
                }
                if (data[items?.data[0]] < data[items?.data[1]]) {
                    checkerNew = false
                }
                newData['minimum_investment'] = data[items?.data[1]];
                let newKey = {}
                newKey[items?.title] = newData;
                if (propData?.capital_type?.includes(items?.title)) {
                    arrayObj.push(newKey);
                }
            }

        })

        let checkDuration = false;
        let checkIOPDuration = false;
        if (checkDuration1 || checkDuration2) {
            checkDuration = true;
        } else {
            checkDuration = false;
        }
        if (checkIOPDuration1 || checkIOPDuration2) {
            checkIOPDuration = true;
        } else {
            checkIOPDuration = false;
        }

        // Check conditions and update errors accordingly
        let checkVal = checkTotalRaise(checker);
        if (checkVal === true && checkerNew === true && checkerEquity == false && checkerRor === false && checkDuration === false && checkIOPDuration === false) {
            setErrorMsg(false)
            setErrorMsgNew(false)
            setErrorEquity(false)
            setErrorRor(false)
            setErrorDuration(false)
            setErrorIOPDuration(false);
            propData.capitalTypeDetails = arrayObj
            // update it to main page
            updateProposalData(propData)
            if (buttonText.includes("Save and Exit")) {
                SaveAndExit()
            } else {
                handleNext();
            }
        } else {
            if (checkerRor === true) {
                setErrorRor(true)
            } else if (checkerEquity === true) {
                setErrorEquity(true)
            } else if (checkVal === false) {
                setErrorMsg(true);
            } else if (checkerNew === false) {
                setErrorMsgNew(true)
            }
            if (checkDuration === false) {
                setErrorDuration(false)
            } else {
                setErrorDuration(true)
            }
            if (checkIOPDuration === false) {
                setErrorIOPDuration(false);
            } else {
                setErrorIOPDuration(true);
            }
            showSnackbar('Please ensure all fields are filled out and validated.', 'error')
        }
    }

    let fieldName = ['Total Raise', 'Minimum Investment', 'Interest Rate', 'Equity',]
    let fieldKey = ['total_raise', 'minimum_investment', 'rate_of_return', 'equity']

    /**
     * Function to save the current proposal and exit the current step/page.
     */
    const SaveAndExit = () => {
        saveProposal(); // Call the 'saveProposal' function to save the proposal data
    }

    const checkTotalRaise = (checkValue) => {
        return parseFloat(checkValue).toFixed(2) == parseFloat(propData?.fractionalize_total_price).toFixed(2);
    };

    // useEffect to handle clearing error messages after a delay
    useEffect(() => {
        // Use setTimeout to delay setting the message state
        const timeoutId = setTimeout(() => {
            setErrorMsg(false);
            setErrorMsgNew(false);
            setErrorEquity(false);
            setErrorRor(false);
            setErrorDuration(false)
            setErrorIOPDuration(false)
        }, 3000); // 3000 milliseconds = 3 seconds

        // Cleanup the setTimeout if the component unmounts or the effect re-runs
        return () => {
            clearTimeout(timeoutId);
        };
    }, [errorMsg, errorMsgNew, errorMsgEquity, errorMsgRor, errorMsgDuration, errorMsgIOPDuration]); // Empty dependency array to run the effect only once

    // useEffect to update capitalTypeDetails when propData changes
    useEffect(() => {
        if (propData?.capitalTypeDetails?.length) {
            let capitalTypeData = propData.capitalTypeDetails;
            CapitalTypeList?.forEach((capitalType, i) => {
                if (propData?.capital_type?.includes(capitalType?.title)) {
                    count++;
                    if (capitalTypeData[count] && capitalTypeData[count][capitalType?.title]) {
                    } else {
                        count--;
                        if (propData?.capital_type?.includes('Senior Debt')) {
                            setValue('senior_token_name', 'Senior Debt Token')
                            setValue('senior_token_symbol', 'SDT')
                            setValue('senior_amortization_duration', 30)
                        }
                        if (propData?.capital_type?.includes('Junior Debt')) {
                            setValue('junior_token_name', 'Junior Debt Token')
                            setValue('junior_token_symbol', 'JDT')
                            setValue('junior_amortization_duration', 30)
                        }
                        if (propData?.capital_type?.includes('Equity')) {
                            setValue('equity_token_name', 'Limited Partners')
                            setValue('equity_token_symbol', 'LP')
                        }
                    }
                    capitalType?.data?.forEach((item, index) => {
                        if ((index === 4)) {
                            if (capitalType?.title === 'Senior Debt') {
                                if ((capitalTypeData[count] && capitalTypeData[count][capitalType?.title]) && capitalTypeData[count][capitalType?.title].senior_duration) {
                                    setValue('senior_duration', capitalTypeData[count][capitalType?.title]['senior_duration'])
                                }
                            } else if (capitalType?.title === 'Junior Debt') {
                                if ((capitalTypeData[count] && capitalTypeData[count][capitalType?.title]) && capitalTypeData[count][capitalType?.title]['junior_duration']) {
                                    setValue('junior_duration', capitalTypeData[count][capitalType?.title]['junior_duration'])
                                }
                            } else if (capitalType?.title === 'Equity') {
                                if ((capitalTypeData[count] && capitalTypeData[count][capitalType?.title]) && capitalTypeData[count][capitalType?.title]['tokenName']) {
                                    setValue('equity_token_name', capitalTypeData[count][capitalType?.title]['tokenName'])
                                }
                            }
                        } else if (index === 3) {
                            if (capitalType?.title === 'Senior Debt') {
                                if ((capitalTypeData[count] && capitalTypeData[count][capitalType?.title]) && (capitalTypeData[count][capitalType?.title]['iop_senior'] || capitalTypeData[count][capitalType?.title]['iop_senior'] === 0)) {
                                    setValue('iop_senior', capitalTypeData[count][capitalType?.title]['iop_senior'])
                                }
                            } else if (capitalType?.title === 'Junior Debt') {
                                if ((capitalTypeData[count] && capitalTypeData[count][capitalType?.title]) && (capitalTypeData[count][capitalType?.title]['iop_junior'] || capitalTypeData[count][capitalType?.title]['iop_junior'] === 0)) {
                                    setValue('iop_junior', capitalTypeData[count][capitalType?.title]['iop_junior'])
                                }
                            } else if (capitalType?.title === 'Equity') {
                                if ((capitalTypeData[count] && capitalTypeData[count][capitalType?.title]) && capitalTypeData[count][capitalType?.title]['equity']) {
                                    setValue('equity', capitalTypeData[count][capitalType?.title]['equity'])
                                }
                            }
                        } else if (index === 0) {
                            if ((capitalTypeData[count] && capitalTypeData[count][capitalType?.title]) && capitalTypeData[count][capitalType?.title][fieldKey[index]]) {
                                setValue(item, capitalTypeData[count][capitalType?.title][fieldKey[index]])
                            } else if (propData?.capital_type?.length === 1) {
                                setValue(item, propData?.fractionalize_total_price)
                            }
                        } else if (index === 5) {
                            if (capitalType?.title === 'Senior Debt') {
                                if ((capitalTypeData[count] && capitalTypeData[count][capitalType?.title]) && capitalTypeData[count][capitalType?.title]['amortization_duration']) {
                                    setValue('senior_amortization_duration', capitalTypeData[count][capitalType?.title]['amortization_duration'])
                                } else {
                                    setValue('senior_amortization_duration', 30)
                                }
                            } else if (capitalType?.title === 'Junior Debt') {
                                if ((capitalTypeData[count] && capitalTypeData[count][capitalType?.title]) && capitalTypeData[count][capitalType?.title]['amortization_duration']) {
                                    setValue('junior_amortization_duration', capitalTypeData[count][capitalType?.title]['amortization_duration'])
                                } else {
                                    setValue('junior_amortization_duration', 30)
                                }
                            } else if (capitalType?.title === 'Equity') {
                                if ((capitalTypeData[count] && capitalTypeData[count][capitalType?.title]) && capitalTypeData[count][capitalType?.title]['tokenSymbol']) {
                                    setValue('equity_token_symbol', capitalTypeData[count][capitalType?.title]['tokenSymbol'])
                                }
                            }
                        } else if (index === 6) {
                            if (capitalType?.title === 'Senior Debt') {
                                if ((capitalTypeData[count] && capitalTypeData[count][capitalType?.title]) && capitalTypeData[count][capitalType?.title]['tokenName']) {
                                    setValue('senior_token_name', capitalTypeData[count][capitalType?.title]['tokenName'])
                                }
                            } else if (capitalType?.title === 'Junior Debt') {
                                if ((capitalTypeData[count] && capitalTypeData[count][capitalType?.title]) && capitalTypeData[count][capitalType?.title]['tokenName']) {
                                    setValue('junior_token_name', capitalTypeData[count][capitalType?.title]['tokenName'])
                                }
                            }
                        } else if (index === 7) {
                            if (capitalType?.title === 'Senior Debt') {
                                if ((capitalTypeData[count] && capitalTypeData[count][capitalType?.title]) && capitalTypeData[count][capitalType?.title]['tokenSymbol']) {
                                    setValue('senior_token_symbol', capitalTypeData[count][capitalType?.title]['tokenSymbol'])
                                }
                            } else if (capitalType?.title === 'Junior Debt') {
                                if ((capitalTypeData[count] && capitalTypeData[count][capitalType?.title]) && capitalTypeData[count][capitalType?.title]['tokenSymbol']) {
                                    setValue('junior_token_symbol', capitalTypeData[count][capitalType?.title]['tokenSymbol'])
                                }
                            }
                        } else {
                            if ((capitalTypeData[count] && capitalTypeData[count][capitalType?.title]) && capitalTypeData[count][capitalType?.title][fieldKey[index]]) {
                                setValue(item, capitalTypeData[count][capitalType?.title][fieldKey[index]])
                            }
                        }
                    })
                }
                return;
            })
            if (propData?.capital_type?.length === 1) {
                switch (propData?.capital_type[0]) {
                    case CAPITAL_TYPE_DISPLAY[CAPITAL_TYPE_ID.EQUITY]:
                        setValue('totalRaiseEquity_Equity', propData?.fractionalize_total_price)
                        break
                    case CAPITAL_TYPE_DISPLAY[CAPITAL_TYPE_ID.SENIOR_DEBT]:
                        setValue('totalRaiseEquity_Senior', propData?.fractionalize_total_price)
                        break
                    case CAPITAL_TYPE_DISPLAY[CAPITAL_TYPE_ID.JUNIOR_DEBT]:
                        setValue('totalRaiseEquity_Junior', propData?.fractionalize_total_price)
                        break
                }
            }
        } else {
            if (propData?.capital_type?.includes('Senior Debt')) {
                setValue('senior_token_name', 'Senior Debt Token')
                setValue('senior_token_symbol', 'SDT')
                setValue('senior_amortization_duration', 30)
            }
            if (propData?.capital_type?.includes('Junior Debt')) {
                setValue('junior_token_name', 'Junior Debt Token')
                setValue('junior_token_symbol', 'JDT')
                setValue('junior_amortization_duration', 30)
            }
            if (propData?.capital_type?.includes('Equity')) {
                setValue('equity_token_name', 'Limited Partners')
                setValue('equity_token_symbol', 'LP')
            }
            // set total of capital as project total raise in case of single capital type
            if (propData?.capital_type?.length === 1) {
                switch (propData?.capital_type[0]) {
                    case CAPITAL_TYPE_DISPLAY[CAPITAL_TYPE_ID.EQUITY]:
                        setValue('totalRaiseEquity_Equity', propData?.fractionalize_total_price)
                        break
                    case CAPITAL_TYPE_DISPLAY[CAPITAL_TYPE_ID.SENIOR_DEBT]:
                        setValue('totalRaiseEquity_Senior', propData?.fractionalize_total_price)
                        break
                    case CAPITAL_TYPE_DISPLAY[CAPITAL_TYPE_ID.JUNIOR_DEBT]:
                        setValue('totalRaiseEquity_Junior', propData?.fractionalize_total_price)
                        break
                }
            }
        }
    }, []);

    const validateFunction = (item, value, index) => {
        return (arrayData?.includes(item) ?
            (value > 0 ||
                ((item === 'senior_amortization_duration' || item === 'junior_amortization_duration') ? 'Amortization Period must be greater than 0.' : (item === 'senior_duration' || item === 'junior_duration') ? "Duration of Debt must be greater than 0." : `${fieldName[index]} must be greater than 0.`)
            )
            : true)
    }

    return (
        <Box className="projectProposer questionnaire-page">
            <Box className="questionnaire-section">
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Box className="auth-left">
                            <Box className="quests-left-title">
                                {propData?.capital_type_ids?.length <= 1 ?
                                    <Typography component="h1" className="title">
                                        Provide details for the type of capital selected
                                    </Typography>
                                    :
                                    <Typography component="h1" className="title">
                                        Provide details for the type(s) of capital selected
                                    </Typography>
                                }
                            </Box>
                            <Box className="auth-logo">
                                <Link to="/">
                                    <Box component="img" src={NewLogo} alt="Race logo" />
                                </Link>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Box className="auth-right quests-right-contents-box" component="form"
                            onSubmit={handleSubmit(onSubmit)}>
                            <Box textAlign={"right"}>
                                <Box component="span" className="icon" ml={1}>
                                    <Button id="my-button" className="btn-rounded btn-text-white btn-blue-600 saveAndExit" type="submit">
                                        Save and Exit
                                    </Button>
                                </Box>
                            </Box>
                            {/*Capital type information Form starts from here */}
                            <Box className="questionnaire6 qsn-middle-content spv-capital-details" sx={{ pt: 4, mb: 5 }} >
                                <OverlayScrollbarsComponent defer
                                    style={{ width: '100%', minHeight: '340px', maxHeight: '56vh' }}
                                >
                                    <Box className="auth-form" mt={4} mr={3}>
                                        <Grid container spacing={2}>
                                            {CapitalTypeList?.map((capitalType, i) => {
                                                if (propData?.capital_type?.includes(capitalType?.title)) {
                                                    count++;
                                                    if (capitalTypeDetails[count] && capitalTypeDetails[count][capitalType?.title]) {

                                                    } else {
                                                        count--;
                                                    }
                                                    return (
                                                        <React.Fragment key={capitalType.title}>
                                                            <Typography className="inputFieldTitle" style={{ width: '100%' }}>
                                                                {capitalType?.title}
                                                            </Typography>
                                                            {capitalType?.data?.map((item, index) => (
                                                                <React.Fragment key={`${item}+${index + 4}`}>
                                                                    {
                                                                        capitalType?.title == 'Equity' && (item === 'preferredRateOfReturn_Equity' || item === 'equity') ? null
                                                                            :
                                                                            <Grid key={`${item}+${index + 4}`} item xs={12} sm={12} md={6}>
                                                                                <Controller
                                                                                    name={item}
                                                                                    control={control}
                                                                                    rules={{
                                                                                        required: (item === 'senior_token_name' || item === 'junior_token_name' || item === 'equity_token_name') ? "Token Name is required." : (item === 'senior_token_symbol' || item === 'junior_token_symbol' || item === 'equity_token_symbol') ? "Token Symbol is required." : (item === 'senior_amortization_duration' || item === 'junior_amortization_duration') ? 'Amortization Period is required.' : (item === "iop_senior" || item === "iop_junior") ? 'Interest-only Period is required.' : (item === 'senior_duration' || item === 'junior_duration') ? "Duration of Debt is required." : `${fieldName[index]} is required.`,
                                                                                        validate: (value) => validateFunction(item, value, index)
                                                                                    }}
                                                                                    render={({ field }) => (
                                                                                        <Box className="form-group label-field" style={{ marginBottom: '10px' }}>
                                                                                            {(item === 'senior_token_name' || item === 'junior_token_name' || item === 'equity_token_name' || item === 'senior_token_symbol' || item === 'junior_token_symbol' ||
                                                                                                item === 'equity_token_symbol') ?
                                                                                                <TextField
                                                                                                    autoComplete="off"
                                                                                                    label={(item === 'senior_token_name' || item === 'junior_token_name' || item === 'equity_token_name') ? 'Token Name' : 'Token Symbol'}
                                                                                                    value={field.value}
                                                                                                    onChange={(e) => field.onChange(e.target.value)}
                                                                                                    onBlur={field.onBlur}
                                                                                                    error={!!errors[item]}
                                                                                                    helperText={errors[item] && errors[item].message}
                                                                                                />
                                                                                                :
                                                                                                <TextField
                                                                                                    autoComplete="off"
                                                                                                    // disabled={}
                                                                                                    label={(item === 'senior_duration' || item === 'junior_duration') ? "Duration of Debt (in years)" : (item === "iop_senior" || item === "iop_junior") ? 'Interest-only Period (in months)' : (item === 'senior_amortization_duration' || item === 'junior_amortization_duration') ? 'Amortization Period (in years)' :
                                                                                                        fieldName[index]}
                                                                                                    value={field.value}
                                                                                                    onChange={(e) => field.onChange(e.target.value)}
                                                                                                    onBlur={field.onBlur}
                                                                                                    error={!!errors[item]}
                                                                                                    helperText={errors[item] && errors[item].message}
                                                                                                    InputProps={
                                                                                                        {
                                                                                                            inputComponent: (index === 0 || index === 1) ? NumericFormatCustomDollar : ((index === 2) || (index === 3 && capitalType?.title === 'Equity' && item !== 'senior_duration' && item !== 'junior_duration')) ? NumericFormatCustomPercent : NumericFormatCustom,
                                                                                                            readOnly: ((item === 'senior_amortization_duration' || item === 'junior_amortization_duration') || ((item === 'totalRaiseEquity_Equity' || item === 'totalRaiseEquity_Senior' || item === 'totalRaiseEquity_Junior') && propData?.capital_type?.length === 1))
                                                                                                        }}
                                                                                                    onKeyDown={(event) => {
                                                                                                        if (item === 'senior_duration' || item === 'junior_duration' || item === 'iop_senior' || item === 'iop_junior' || item === 'senior_amortization_duration' || item === 'junior_amortization_duration'
                                                                                                            // || index === 0 || index === 2
                                                                                                        ) {
                                                                                                            if (event?.key === '.') {
                                                                                                                event.preventDefault();
                                                                                                            }
                                                                                                        }
                                                                                                        if (event?.key === '-' || event?.key === '+') {
                                                                                                            event.preventDefault();
                                                                                                        }
                                                                                                    }}
                                                                                                />
                                                                                            }
                                                                                        </Box>
                                                                                    )}
                                                                                />
                                                                            </Grid>
                                                                    }
                                                                </React.Fragment>
                                                            )
                                                            )
                                                            }
                                                        </React.Fragment>
                                                    )
                                                }
                                                // return null; // Render nothing if capital_type doesn't match
                                            })}
                                        </Grid>
                                    </Box>
                                </OverlayScrollbarsComponent>
                                <Typography sx={{ color: 'red', fontSize: '11px', textAlign: 'center', marginTop: 1.5 }}>{errorMsg === true ? "Total raise value must be equal to sum of all the capital." : errorMsgNew === true ? "Minimum investment can not be greater than total raise." : errorMsgEquity === true ? 'Equity cannot be greater than 100%.' : errorMsgRor == true ? 'Interest Rate cannot be greater than 100%.' : errorMsgDuration ? "Duration of debt can not be greater than project duration." : errorMsgIOPDuration ? "Interest only period can not be greater than or equal to duration of debt." : ''}</Typography>
                            </Box>
                            <Box>
                                <Box className="questionnaire-progress" >
                                    <LinearProgressCompo value={currentProgress} />
                                </Box>
                                <Box
                                    sx={{ display: "flex", justifyContent: "space-between" }}
                                    mt={3}
                                >
                                    <Box textAlign={"left"} className="quests6-btn-box">
                                        <Button className="btn-rounded back-btn" onClick={handleback}>Back</Button>
                                    </Box>
                                    <Box textAlign={"right"} className="quests-btn-box">
                                        <Button
                                            id="my-button"
                                            // onClick={handleNext}
                                            endIcon={<KeyboardArrowRight />}
                                            className="btn-rounded btn-text-white btn-blue-600"
                                            type="submit"
                                        >
                                            Next
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box >
    );
}
