import React, { useEffect, useState } from 'react';
import { Button, Modal, Box, Typography, IconButton, Grid, TextField,
    RadioGroup, FormControlLabel,  } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Cancel, Upload, Add } from "@mui/icons-material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CommomRadio from "../../../../Components/CommonRadio/CommonRadio";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { validAmountCheck } from '../../../../Utils/utils';
import convertToDecimal from '../../../../Components/Common/convertToDecimal';
import './ModifyContractOfferModal.css';
import SpTransactionProgressModal from '../../../SmartContracts/SpTransactionProgressModal';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "var(--blue-900)",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderWidth: "0px",
    "@media (max-width: 600px)": {
        width: "350px", // Set a different height for small screens
    },
    // Additional media queries for other screen sizes if needed
    "@media (min-width: 601px) and (max-width: 900px)": {
        // Custom styles for devices between 601px and 900px width
        width: "580px",
    },
    "@media (min-width: 901px) and (max-width: 1200px)": {
        // Custom styles for devices between 901px and 1200px width
        width: "750px",
        maxHeight: "300px",
        overflow: "auto",
    },
    // Specify the maximum width for large screens
    "@media (min-width: 1201px)": {
        maxWidth: "1043px",
        maxHeight: "525px",
        overflow: "auto",
    },
};

const ModifyContractOfferModal = ({ isOpen, setIsOpenModal, contractData, projectTerm, setProjectTerm, projectBudget, setProjectBudget, milestoneData, setMilestoneData, handleAction }) => {
    const [openModal, setOpenModal] = useState(false);
    const [errors, setErrors] = useState([]);
    const [totalMilestoneAmount, setTotalMilestoneAmount] = useState(null);
    const [openTransactionModal, setOpenTransactionModal] = useState(false);
    const [propData, setPropData] = useState({});

console.log("milestoneData---",milestoneData);
    useEffect(() => {
        if (isOpen) {
            setOpenModal(true);
            setIsOpenModal(true);
        } else {
            setOpenModal(false);
            setIsOpenModal(false);
        }
    }, [isOpen]);

    const handleChange = (event) => {
        if (event.target.value === 'milestone') {
          
        }
        if (event.target.value === 'project') {
          
        }
        setProjectTerm(event.target.value);
    };
    useEffect(() => {
        if(milestoneData.length > 0){
            let sum = 0;
            const err = milestoneData.map((i) => {
                sum = sum + Number(i.Amount);
                let amtErr = validAmountCheck(i.Amount);
                let dscErr = '';
                if(i.description){
                    if(i.description && i.description.trim()){
                    if(i.description && i.description.length > 80){
                        dscErr = 'Please enter 80 characters max';
                    }
                    }else{
                    dscErr = 'Please enter valid description';
                    }
                }
                return { ...i, descriptionError: dscErr, amountError: amtErr };
            });
            setTotalMilestoneAmount(sum);
            setProjectBudget(sum);
            setErrors(err);
        }
    },[milestoneData]);

    const handleAddMilestone = () => {
        const defaultMilestone = { description: null, dueDate: null, Amount: "", descriptionError: '', amountError: '' };
        if (milestoneData.length > 0) {
            let tempArr = [...milestoneData, defaultMilestone];
            if (milestoneData[milestoneData.length - 1].Amount) {
                setErrors(tempArr);
                setMilestoneData(tempArr);
            }
        } else {
            let tempArr = [...milestoneData, defaultMilestone];
            setErrors(tempArr);
            setMilestoneData(tempArr);
        }
    }
    
    const handleMilestonechange = (key, value, index) => {
        let tempArr = [...milestoneData];
        if(tempArr && tempArr.length > 0){
            tempArr = tempArr.map((milestone, idx)=>{
                if(index === idx){
                return {
                    ...milestone,
                    [key]: value,
                }
                }
                return milestone;
            })
            let sum = 0;
            tempArr.map((itm) => {
                if(itm.Amount === '' || isNaN(itm.Amount))return itm;
                sum = sum + Number(itm.Amount);
                return itm;
            });
            setTotalMilestoneAmount(sum);
            setProjectBudget(sum);
            setMilestoneData(tempArr);
        }
    }

    const handleDeleteMilestone = (index) => {
        let tempArr = [...milestoneData];
        tempArr.splice(index, 1);
        let sum = 0;
        tempArr.map((itm) => {
          if (itm.Amount === '' || isNaN(itm.Amount)) return itm;
          sum = sum + Number(itm.Amount);
          return itm;
        });
        setTotalMilestoneAmount(sum);
        setProjectBudget(sum);
        setMilestoneData(tempArr);
        setErrors(tempArr);
    }
    const handleModalClose = () => {
        setOpenTransactionModal(false);
    }
    const confirmStake = async () => {
        handleAction()
    }
    const submitModifyRequest = () => {
        let milestoneArr = [];
        milestoneArr = milestoneData?.map((item) => {
        return {
            expectedSubmissionDate: (item?.dueDate ? new Date(item?.dueDate).getTime() : 0),  // uint256
            amount: convertToDecimal(item?.Amount ? item?.Amount : 0), // uint256
            submittedDate: 0, // uint256
            approvedDate: 0, // uint256
            paymentClearDate: 0, // uint256
            milestoneStatus: 0 // uint256
        }
        })
        let contractBudget = projectBudget;
        let contractPayload = {
            _contractId: contractData?.blockchain_id, // uint256
            _ct: {
                termType: projectTerm, // enum
                milestones: milestoneArr,
                projectBudget: convertToDecimal(contractBudget ? contractBudget : 0), // uint256
                hourlyRate: 0, // uint256
                estimatedHours: 0, // uint256
                isAcceptedByClient: 0 // uint256
            },
            contract_version: contractData?.contract_version
        }
        setPropData(contractPayload);
        setOpenTransactionModal(true);
        return
    }
    return (
        <div>
            <Modal
                open={openModal}
                onClose={() => {
                    setOpenModal(false);
                    setIsOpenModal(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="send-custom-offer-container alert-modal"
            >
                <Box sx={{...style, width: milestoneData.length ? 800 : 500}}>
                    <Box className="header">
                        <Box className="alert-icon">
                            <ErrorOutlineIcon className='font-14'/>
                        </Box>
                        <Typography id="modal-modal-title font-28" component="h2">
                            Request For Modify Contract
                        </Typography>
                        <IconButton className='close-btn' onClick={() => {setOpenModal(false); setIsOpenModal(false);}}>
                            <CloseIcon className="cross-icon-modal"/>
                        </IconButton>
                    </Box>
                    <Box className="body">
                        <Box textAlign={'center'}>
                            <Typography component="p" className="description">
                                {`Offer Amount ($${projectBudget})`}
                            </Typography>
                        </Box>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={6} sm={12}>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={projectTerm}
                                onChange={handleChange}
                            >
                                <FormControlLabel value="milestone" control={<CommomRadio color="#fff" />} label="By milestone" />
                                <FormControlLabel value="project" control={<CommomRadio color="#fff" />} label="By project" />
                            </RadioGroup>
                            </Grid>
                            {/* <Grid item xs={6} md={6} sm={12}>
                            <Box onClick={() => switchToHourly()}
                                className={`card-main`}
                            >
                                <Typography component="h4" className="title">
                                <SwapHorizIcon sx={{ margin: "0px 10px -8px 10px" }} />  Switch to hourly
                                </Typography>
                            </Box>
                            </Grid> */}
                        </Grid>
                        {projectTerm === 'milestone' && (
                            <>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Box className="title-box">
                                    <Typography component="h4" className="title">
                                        How many milestones do you want to include?
                                    </Typography>
                                    <br />
                                    </Box>
                                    {milestoneData && milestoneData.map((milestone, index) => (
                                    <><Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                        <Grid item xs={2} sm={4} md={4}>
                                        <Box className="input-box">
                                            <TextField
                                            id="outlined-basic"
                                            label="Milestone Description"
                                            InputLabelProps={{ shrink: true }}
                                            variant="outlined"
                                            value={milestone.description}
                                            onChange={(e) => handleMilestonechange('description', e.target.value, index)} />
                                            {/* {milestone?.description ? null : <Typography variant="body2" color="error">Description is required</Typography>} */}
                                            {errors?.length > 0 && errors[index]?.descriptionError && <Typography variant="body2" color="error">{errors[index]?.descriptionError}</Typography>}
                                        </Box>
                                        </Grid>
                                        <Grid item xs={2} sm={4} md={4}>
                                        <Box className="input-box DatePicker SPAddEducation">
                                            {index === 0 ? <DatePicker
                                            id="outlined-basic"
                                            label="Due Date(optional)"
                                            placeholder="MM/DD/YYYY"
                                            variant="outlined"
                                            value={milestone.dueDate}
                                            onChange={(e) => handleMilestonechange('dueDate', e, index)}
                                            disablePast
                                            // maxDate={deliveryDate}
                                            renderInput={(params) => (
                                                <TextField {...params} required={false} />
                                            )}
                                            /> :
                                            <DatePicker
                                                id="outlined-basic"
                                                label="Due Date(optional)"
                                                placeholder="MM/DD/YYYY"
                                                variant="outlined"
                                                value={milestone.dueDate}
                                                onChange={(e) => handleMilestonechange('dueDate', e, index)}
                                                disablePast
                                                minDate={milestoneData[index - 1]?.dueDate ? milestoneData[index - 1]?.dueDate : new Date()}
                                                // maxDate={deliveryDate}
                                                renderInput={(params) => (
                                                <TextField {...params} required={false} />
                                                )}
                                            />}
                                        </Box>
                                        </Grid>
                                        <Grid item xs={2} sm={3} md={3}>
                                        <Box className="input-box">
                                            <TextField
                                            id="outlined-basic"
                                            label="Amount"
                                            InputLabelProps={{ shrink: true }}
                                            variant="outlined"
                                            placeholder="$"
                                            value={"$" + milestone.Amount}
                                            onChange={(e) => handleMilestonechange('Amount', e.target.value.substring(1), index)} />
                                            {/* {milestone?.Amount ? null : <Typography variant="body2" color="error">Amount is required</Typography>} */}
                                            {errors.length > 0 && errors[index]?.amountError && <Typography variant="body2" color="error">{errors[index]?.amountError}</Typography>}
                                        </Box>
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1} className="cross-icon-contract">
                                            <Box className="input-box ">
                                                <Cancel
                                                onClick={() => handleDeleteMilestone(index)}
                                                className="removeIcon " />
                                            </Box>
                                        </Grid>
                                    </Grid><br /></>
                                    ))}
                                    <Grid item xs={2} sm={12} md={12}>
                                    <Box>
                                        <Typography onClick={() => handleAddMilestone()} sx={{ cursor: "pointer" }} component="h4" className="title">
                                        <Add sx={{ margin: "10px 10px -8px 10px" }} />  Add milestone
                                        </Typography>
                                    </Box>
                                    </Grid>
                                </LocalizationProvider>
                            </>
                        )}
                        {(projectTerm === 'project' || projectTerm === 'hourly') && (
                        <>
                            <Box className="title-box">
                                <Typography component="h4" className="title">
                                    Offer amount<br />
                                    {/* {offerData && <Link to={'#'} style={{fontWeight:"bold"}} className="text-golden font-16 text-underline view-invoice-text">
                                    Offer Amount - ${offerData?.offer_amount}
                                    </Link>} */}
                                </Typography>
                            </Box>
                            <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <Box className="input-box">
                                <TextField
                                    id="outlined-basic"
                                    // label={projectTerm === 'project' ? "Amount" : "/hr"}
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    placeholder="/hr"
                                    value={"$" + projectBudget} // Concatenating "$" with input value
                                    onChange={(e) => {
                                    setProjectBudget(
                                        e.target.value.substring(1)
                                    )
                                    const tempError = errors;
                                    const err = validAmountCheck(e.target.value.substring(1));
                                    if (err) setErrors({ ...tempError, projectBudget: err });
                                    else setErrors({ ...tempError, projectBudget: '' });
                                    }} // Removing "$" before setting state
                                />
                                {errors.step3ProjectBudget && <Typography variant="body2" color="error">{errors.step3ProjectBudget}</Typography>}
                                </Box>
                            </Grid>
                            </Grid>
                        </>
                        )}
                    </Box>
                    <Box className="footer">
                        <Button 
                            onClick={()=> setIsOpenModal(false)} 
                            variant="contained" 
                            startIcon={<CloseIcon />}
                        >
                            Cancel
                        </Button>
                        <Button 
                            onClick={submitModifyRequest} 
                            variant="contained" 
                            startIcon={<CheckIcon />}
                        >
                            Send Request
                        </Button>
                    </Box>
                </Box>
            </Modal>
            {openTransactionModal ? <SpTransactionProgressModal identity={'modify-contract-offer-request'} confirmStake={confirmStake} propData={propData} handleModalClose={handleModalClose} openTransactionModal={openTransactionModal} /> : null}
        </div>
    );
};

export default ModifyContractOfferModal;