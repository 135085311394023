import React, { useEffect, useState } from 'react';
import { Button, Modal, Box, Typography, IconButton } from '@mui/material';
import { Check, Close, ErrorOutline, DeleteForever } from '@mui/icons-material';
import './Confirm.css';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "var(--blue-900)",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderWidth: "0px",
    "@media (max-width: 600px)": {
        width: "350px", // Set a different height for small screens
    },
    // Additional media queries for other screen sizes if needed
    "@media (min-width: 601px) and (max-width: 900px)": {
        // Custom styles for devices between 601px and 900px width
        width: "580px",
    },
    "@media (min-width: 901px) and (max-width: 1200px)": {
        // Custom styles for devices between 901px and 1200px width
        width: "750px",
        maxHeight: "300px",
        overflow: "auto",
    },
    // Specify the maximum width for large screens
    "@media (min-width: 1201px)": {
        maxWidth: "1043px",
        maxHeight: "525px",
        overflow: "auto",
    },
};

const ConfirmModal = ({ type, title, description, isOpen, setIsOpenModal, handleAction }) => {
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setOpenModal(true);
            setIsOpenModal(true);
        } else {
            setOpenModal(false);
            setIsOpenModal(false);
        }
    }, [isOpen]);

    return (
        <div>
            <Modal
                open={openModal}
                onClose={() => {
                    setOpenModal(false);
                    setIsOpenModal(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="send-custom-offer-container alert-modal"
            >
                <Box sx={style}>
                    <Box className="header">
                        {
                            type === 'delete' ?
                                <Box className="delete-icon">
                                    <DeleteForever className='confirm-icon' />
                                </Box>
                                :
                                <Box className="alert-icon">
                                    <ErrorOutline className='confirm-icon' />
                                </Box>
                        }
                        <Typography id="modal-modal-title font-28" component="h2">
                            {title}
                        </Typography>
                        <IconButton className='close-btn' onClick={() => { setOpenModal(false); setIsOpenModal(false); }}>
                            <Close className="cross-icon-modal" />
                        </IconButton>
                    </Box>
                    <Box className="body">
                        <Box textAlign={'center'}>
                            <Typography component="p" className="description">
                                {description}
                            </Typography>
                        </Box>
                    </Box>
                    <Box className="footer">
                        <Button onClick={() => { handleAction('confirm') }} variant="contained" startIcon={<Check />}>
                            Confirm
                        </Button>&nbsp;
                        <Button onClick={() => {
                            setOpenModal(false);
                            setIsOpenModal(false);
                            handleAction('cancel');
                        }} variant="contained" startIcon={<Close />}>
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default ConfirmModal;