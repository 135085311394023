import React, { useEffect, forwardRef } from "react";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import { Chip, Box, Avatar, Typography, Tooltip } from "@mui/material";
import HouseRoundedIcon from "@mui/icons-material/HouseRounded";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import WatchIcon from "@mui/icons-material/Watch";
import PaidIcon from "@mui/icons-material/Paid";
import BalanceIcon from "@mui/icons-material/Balance";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import { TxSuccessBadge } from "../Images/Images";
import ParcelGridImage from "../../Pages/Portfolio/ParcelGridImage";
import "./Card.css";

const PortfolioCard = forwardRef((props, ref) => {
    const CanvasImageRef = ref;

    /**
     * Generating Props for sending full data
     */
    const projectData = {
        project: props,
    };

    /**
     * Generating State for the inReview Projects
     */
    const projectState = {
        proState: props.state,
    };

    const DraftEditData = {
        category_id: props.category_id,
        id: props.id,
        EditMode: true,
        investment_type_id: props.investment_type_id
            ? props.investment_type_id
            : "",
    };

    // Rendring Icons According to condition
    const iconPack = {
        "Real Estate": <HouseRoundedIcon />,
        Art: <ColorLensIcon />,
        NFT: <PhotoSizeSelectActualIcon />,
        Energy: <LightModeRoundedIcon />,
        Collection: <WatchIcon />,
        Watch: <WatchIcon />,
        Funds: <PaidIcon />,
        LLC: <BalanceIcon />,
        Gold: <ViewWeekIcon />,
    };

    useEffect(() => {
        if (props.matchedParcels && props.matchedParcels.length > 0 && CanvasImageRef) {
            setTimeout(() => {
                CanvasImageRef?.current?.initialRegionSelection(props.matchedParcels);
            }, 500);
        }
    }, [props.matchedParcels, CanvasImageRef, props?.sidebarOpen]);

    const containerStyle = {
        pointerEvents: 'none',
        width: "-webkit-fill-available", /* For WebKit browsers */
        width: "fill-available",     /* For Firefox and other browsers */
        width: '100%'
    };

    return (
        <>
            <Box className="product-commonCard">
                <Box className="pc-card-body" position={"relative"}>
                    <Box className="pc-thumbnail-wrap">
                        {props.imgSrc.length > 0 && (
                            <Carousel
                                showThumbs={false}
                                showStatus={false}
                                showArrows={false}
                            >
                                {(props.imgSrc || []).map((img, ind) =>
                                    props.id && (
                                        <Link
                                            to={props.link}
                                            state={
                                                props.isDraft
                                                    ? DraftEditData
                                                    : projectState.proState
                                                        ? projectState
                                                        : projectData
                                            }
                                            key={ind}
                                        >
                                            {props?.investment_type_id === 3 && ind === 0 ?

                                                <Box className="pc-slide" sx={{ cursor: "pointer" }}>
                                                    <Box className={"rc-project-details-left-img"} style={containerStyle}>
                                                        <ParcelGridImage ref={CanvasImageRef} id={`grid-canvas-${props?.index}`} rows={parseInt(props?.assetData?.number_of_rows)} cols={parseInt(props?.assetData?.number_of_columns)} artImage={`${process.env.REACT_APP_IMAGE_URL}${props?.assetData?.asset_gallery[0]?.images.path}`} sidebarOpen={props?.sidebarOpen} />
                                                    </Box>
                                                </Box>
                                                :
                                                <Box className="pc-slide" sx={{ cursor: "pointer" }}>
                                                    <img src={img} alt="" />
                                                </Box>
                                            }
                                        </Link>
                                    )
                                )}
                            </Carousel>
                        )}
                    </Box>
                    <Box className="pc-content" position={"relative"}>
                        <Tooltip title={props.title} arrow>
                            <Typography component={"h5"} className="pc-title">
                                {props.title ? props.title : "No Title"}
                            </Typography>
                        </Tooltip>
                        <Typography className="pc-subtitle">
                            {props.subTitle ? props.subTitle : "No Address"}
                        </Typography>
                        <Box className="pc-chip-wrap" mt={1}>
                            <Chip className="pc-chip" label={props.investType} />
                            {
                                props.loanType && (
                                    <Chip className="pc-chip" label={props.loanType} />
                                )
                            }
                            <Chip
                                className="pc-chip with-icon"
                                avatar={
                                    <Avatar aria-label="recipe">
                                        {iconPack[props.isLoan] ?? iconPack["Real Estate"]}
                                    </Avatar>
                                }
                                label={props.isLoan === "Asset" ? "Luxury Cars" : props.isLoan}
                            />
                        </Box>
                        {props?.portfolio && (
                            <Box
                                className="pc-user-wrap"
                                display={"flex"}
                                alignItems={"center"}
                                mt={3}
                            >
                                <Box
                                    className="user-thumbnail"
                                    display={"flex"}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                >
                                    <img src={TxSuccessBadge} alt='' />
                                </Box>
                                <Box className="user-details" ml={1.8}>
                                    <Typography className="user-title">
                                        Successful
                                    </Typography>
                                    <Link to="/user/transactions" style={{ textDecoration: 'none' }}>
                                        <Typography className="user-subtitle" sx={{ cursor: 'pointer' }}>
                                            View Tx Details
                                        </Typography>
                                    </Link>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
        </>
    );
});
export default PortfolioCard;