import React, { useRef, useState, useEffect, createRef } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { Business, Payments, Soap, AttachMoney } from "@mui/icons-material";
import TokenIcon from '@mui/icons-material/Token';
import { Box, Card, Typography, Grid, Stack } from "@mui/material";
import { GetApi } from "../../Api/Api";
// import Charts from "./Charts";
import CardSkeleton from "../../Components/Cards/CardSkeleton";
import Charts from "./Charts";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Portfolio.css";
import PortfolioCard from "../../Components/Cards/PortfolioCard";
import { useAccount } from "wagmi";
import { spaceXFundTokenBalanceOf } from "../../Components/SmartContract/spaceXFundTokenBalanceOf";
import { viewListing } from "../../Components/SmartContract/viewListing"
import { CATEGORY_TYPE_ID, CONTRACT_NAME, SUB_CATEGORY_TYPE_ID } from "../../constants";
import { formatNumber } from "../../Components/Common/USFormat"
import CommonBackdropLoader from "../../Components/Common/CommonBackdropLoader";
import { GetContractAddress } from "../../Components/Common/GetContractAddress";
import { spaceXFundTokenTotalSupply } from "../../Components/SmartContract/spaceXFundTokenTotalSupply";

const Portfolio = () => {
  const navigate = useNavigate();

  const [elRefs, setElRefs] = React.useState([]);
  const searchBarRef = useRef(null); //search bar
  const sidebarOpen = useOutletContext();
  let userData = JSON.parse(localStorage.getItem("user_data"));
  const [assetData, setAssetData] = useState([]); //used to save original data from api
  const [filteredData, setFilteredData] = useState([]); //this is the data which is displayed and altered by filters
  const [filterValues, setFilterValues] = useState({
    category_id: 0,
    investment_type_id: 0,
    investment_val: null,
  });
  const [searchString, setSearchString] = useState("");
  const [totalInvestments, setTotalInvestment] = useState({});
  const [totalSpaceXToken, setTotalSpaceXToken] = useState([]);
  const [sortBy, setSortBy] = useState("Default");
  const [categoryData, setCategoryData] = useState();
  const [investmentTypeData, setInvestmenTypeData] = useState([]);
  const [anchorElSort, setAnchorElSort] = React.useState(null); //sort
  const open = Boolean(anchorElSort); //sort
  const [loading, setLoading] = useState(false);
  const [seeAll, setSeeAll] = useState(false);
  const { address } = useAccount()

  const settings = {
    slidesToShow: 1,
    rows: 1,
    slidesPerRow: 4,
    arrows: true,
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
    className: "center",
    centerPadding: "0px",
    centerMode: true,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 1,
          slidesPerRow: 4,
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 1,
          slidesPerRow: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 1,
          slidesPerRow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 1,
          slidesPerRow: 1
        }
      }
    ]
  };

  useEffect(() => {

    async function getMyInvestments() {
      try {
        const res = await GetApi(
          `/user/getMyInvestment?user_id=${userData && userData?.id}`
        );
        if (res?.data?.data) {
          let myInvestmentData = res?.data?.data;
          // setAssetData(myInvestmentData);
          // setFilteredData(myInvestmentData);
        }
      } catch (error) {
        console.log(error);
      }
    }
    async function getTotalInvestmentData() {
      try {
        setLoading(true);
        const res = await GetApi(
          `/user/getInvestmentData?user_id=${userData && userData?.id}`
        );
        if (res?.data?.data) {
          let myInvestmentsData = res?.data?.data;
          setTotalInvestment(myInvestmentsData);
          setAssetData(myInvestmentsData?.assetData?.map((item) => ({ "asset_data": item })));
          setFilteredData(myInvestmentsData?.assetData?.map((item) => ({ "asset_data": item })));
        }
        setTimeout(() => {
          setLoading(false);
        }, 200);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
    async function getCategoryData() {
      try {
        const res = await GetApi("/proposer/getCategory/");
        setCategoryData(res?.data?.data);
      } catch (error) {
        console.log(error);
      }
    }
    async function getInvestMentTypeData() {
      try {
        const res = await GetApi("/proposer/investmentType/");
        setInvestmenTypeData(res?.data?.data);
      } catch (error) {
        console.log(error);
      }
    }
    let timer = setTimeout(() => {
      // getMyInvestments();
      getCategoryData();
      getInvestMentTypeData();
      getTotalInvestmentData();
    }, 100);
    return () => {
      clearTimeout(timer);
    };
  }, [sidebarOpen]);

  /**
   * searches the String in [title] and sets the filtered data
   * @param {string} value  search string
   * @returns {void}
   */
  const suggestionSearch = async (value) => {
    setSearchString(value);

    //minimum 2 character to search resets the data to original if search string is less than 2
    if (value.length < 2) {
      const filterData = assetData.filter((item) => {
        if (
          filterValues.category_id > 0 &&
          filterValues.investment_type_id > 0
        ) {
          return (
            filterValues.category_id === item?.asset_data?.assets_category.id &&
            filterValues.investment_type_id ===
            item?.asset_data?.investment_type.id
          );
        } else if (filterValues.category_id > 0) {
          return (
            filterValues.category_id === item?.asset_data?.assets_category.id
          );
        } else if (filterValues.investment_type_id > 0) {
          return (
            filterValues.investment_type_id ===
            item?.asset_data?.investment_type.id
          );
        } else {
          return true;
        }
      });
      setFilteredData(filterData);
      return;
    } //minimum 2 character to search

    const dataFromSearch = assetData.filter((item) => {
      if (filterValues.category_id > 0 && filterValues.investment_type_id > 0) {
        return (
          item?.asset_data?.asset_title
            .toLowerCase()
            .includes(value.toLowerCase()) &&
          filterValues.category_id === item?.asset_data?.assets_category.id &&
          filterValues.investment_type_id ===
          item?.asset_data?.investment_type.id
        );
      } else if (filterValues.category_id > 0) {
        return (
          item?.asset_data?.asset_title
            .toLowerCase()
            .includes(value.toLowerCase()) &&
          filterValues.category_id === item?.asset_data?.assets_category.id
        );
      } else if (filterValues.investment_type_id > 0) {
        return (
          item?.asset_data?.asset_title
            .toLowerCase()
            .includes(value.toLowerCase()) &&
          filterValues.investment_type_id ===
          item?.asset_data?.investment_type.id
        );
      } else {
        return item?.asset_data?.asset_title
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    });

    setFilteredData(dataFromSearch);
  };

  const handleChangeCategory = (event) => {
    setFilterValues({
      ...filterValues,
      category_id: event.target.value,
    });
  };

  const handleChangeInvestmentType = (event) => {
    setFilterValues({
      ...filterValues,
      investment_type_id: event.target.value,
    });
  };

  useEffect(() => {
    if (
      filterValues.category_id === 0 &&
      filterValues.investment_type_id === 0
    ) {
      setFilteredData(assetData);
      return;
    }

    const dataFromFilter = assetData.filter((item) => {
      return (
        (filterValues.category_id === 0 ||
          item?.asset_data?.assets_category?.id === filterValues.category_id) &&
        (filterValues.investment_type_id === 0 ||
          item?.asset_data?.investment_type?.id ===
          filterValues.investment_type_id)
      );
    });

    setFilteredData(dataFromFilter);
  }, [filterValues]);

  const handleClick = (event) => {
    setAnchorElSort(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElSort(null);
  };

  const handleSortChange = (sortBy) => {
    setSortBy(sortBy);
    if (assetData || assetData.length !== 0) {
      if (sortBy === "Name (A-Z)") {
        let filtered = [...assetData]?.sort((a, b) =>
          a?.asset_data?.asset_title?.localeCompare(b?.asset_data?.asset_title)
        );
        setFilteredData(filtered);
      } else if (sortBy === "Name (Z-A)") {
        let filtered = [...assetData]?.sort((a, b) =>
          b?.asset_data?.asset_title?.localeCompare(a?.asset_data?.asset_title)
        );
        setFilteredData(filtered);
      } else if (sortBy === "Default") {
        setFilteredData(assetData);
      }
    }
  };

  useEffect(() => {
    // add or remove refs
    if (assetData?.length) {
      setElRefs((elRefs) =>
        Array(assetData?.length)
          .fill()
          .map((_, i) => elRefs[i] || createRef())
      );
    }
  }, [assetData]);


  const getSpaceXToken = async (address, data) => {
    const testData = data?.filter((item) =>
      item?.asset_data?.asset_sub_category_info?.id === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ||
      item?.asset_data?.assets_category?.id === CATEGORY_TYPE_ID.REAL_ESTATE)

    const groupData = testData?.reduce((acc, item) => {
      const listingId = item?.asset_data?.listing_id;

      if (!listingId) return acc;

      // Check if the listing ID already exists in the accumulator
      if (!acc[listingId]) {
        acc[listingId] = [];
      }

      // Push the current item to the corresponding listing ID group
      acc[listingId].push(item);

      return acc;
    }, {})

    // Convert the object into an array of arrays
    const transactionsArray = Object.values(groupData);

    try {
      setLoading(true);
      let tokenDetails = [];
      // Cache object to store results from GetContractAddress
      const cache = {};

      for (let i = 0; i < transactionsArray.length; i++) {
        const data = transactionsArray[i];
        if (data?.length) {
          let newData = data[0]?.asset_data;
          if (newData) {
            const cacheKey = `${newData?.assets_category?.id}_${newData?.contract_version}`;

            let contractAddress = null;
            let contractAbi = null;
            let spaceXTokenAbi = null;
            let contracts;

            // Check if the contract details are already in the cache
            if (cache[cacheKey]) {
              // Use the cached contract details
              contracts = cache[cacheKey].contracts;
              contractAddress = contracts.marketplace?.address;
              contractAbi = contracts.marketplace?.abi;
              spaceXTokenAbi = contracts.token?.abi;
            } else {
              // Fetch the contract details using GetContractAddress if not in cache
              const response = await GetContractAddress({
                category_id: newData?.assets_category?.id,
                asset_sub_category: newData?.asset_sub_category_info?.id ? newData?.asset_sub_category_info?.id : ''
              }, newData?.contract_version);

              if (newData?.assets_category?.id === CATEGORY_TYPE_ID.REAL_ESTATE) {
                // Cache the contract details for future use
                contracts = response.reduce((acc, item) => {
                  if (item.contract_name === CONTRACT_NAME.SPV_MARKETPLACE) {
                    acc.marketplace = item;
                  } else if (item.contract_name === CONTRACT_NAME.SPV_FUND_TOKEN) {
                    acc.token = item;
                  }
                  return acc;
                }, {});

              } else {
                contracts = response.reduce((acc, item) => {
                  if (item.contract_name === CONTRACT_NAME.CLOSE_FUNDS_MARKETPLACE) {
                    acc.marketplace = item;
                  } else if (item.contract_name === CONTRACT_NAME.CLOSE_FUND_TOKEN) {
                    acc.token = item;
                  }
                  return acc;
                }, {});
              }
              // Cache the contracts
              cache[cacheKey] = { contracts };

              contractAddress = contracts.marketplace?.address;
              contractAbi = contracts.marketplace?.abi;
              spaceXTokenAbi = contracts.token?.abi;
            }

            if (contractAddress && contractAbi && spaceXTokenAbi) {
              // Always fetch fresh data for listing, total supply, and balances
              let listingData = await viewListing(address, contractAddress, contractAbi, {
                listing_id: newData?.listing_id,
                category_id: newData?.assets_category?.id,
                asset_category_id: newData?.asset_sub_category_info?.id,
              });

              if (listingData) {
                if (newData?.assets_category?.id === CATEGORY_TYPE_ID.REAL_ESTATE) {
                  let tokenDetailsSpv = []
                  let equityToken = listingData?.equityToken;
                  if (Number(equityToken?.totalRaise) > 0) {
                    let totalSupply = await spaceXFundTokenTotalSupply(address, equityToken?.tokenAddress, spaceXTokenAbi);
                    let spaceXToken = await spaceXFundTokenBalanceOf(address, equityToken?.tokenAddress, spaceXTokenAbi);

                    if (spaceXToken > 0 && totalSupply > 0) {
                      let holding = (spaceXToken / (totalSupply || 1)) * 100;
                      tokenDetailsSpv.push({ spaceXToken, fundType: equityToken, holdingPercent: holding ? holding : 0 });
                    }
                  }
                  let debtToken = listingData?.debtToken;
                  if (debtToken?.length > 0) {
                    let senior = debtToken[0]
                    if (Number(senior?.totalRaise) > 0) {
                      let totalSupply = await spaceXFundTokenTotalSupply(address, senior?.tokenAddress, spaceXTokenAbi);
                      let spaceXToken = await spaceXFundTokenBalanceOf(address, senior?.tokenAddress, spaceXTokenAbi);

                      if (spaceXToken > 0 && totalSupply > 0) {
                        let holding = (spaceXToken / (totalSupply || 1)) * 100;
                        tokenDetailsSpv.push({ spaceXToken, fundType: senior, holdingPercent: holding ? holding : 0 });
                      }
                    }
                    if (debtToken?.length > 1) {
                      let junior = debtToken[1];
                      if (Number(junior?.totalRaise) > 0) {
                        let totalSupply = await spaceXFundTokenTotalSupply(address, junior?.tokenAddress, spaceXTokenAbi);
                        let spaceXToken = await spaceXFundTokenBalanceOf(address, junior?.tokenAddress, spaceXTokenAbi);

                        if (spaceXToken > 0 && totalSupply > 0) {
                          let holding = (spaceXToken / (totalSupply || 1)) * 100;
                          tokenDetailsSpv.push({ spaceXToken, fundType: junior, holdingPercent: holding ? holding : 0 });
                        }
                      }
                    }
                  }
                  tokenDetails = ([...tokenDetails, ...tokenDetailsSpv])
                } else {
                  let totalSupply = await spaceXFundTokenTotalSupply(address, listingData?.fundType?.tokenAddress, spaceXTokenAbi);
                  let spaceXToken = await spaceXFundTokenBalanceOf(address, listingData?.fundType?.tokenAddress, spaceXTokenAbi);

                  if (spaceXToken > 0 && totalSupply > 0) {
                    let holding = (spaceXToken / (totalSupply || 1)) * 100;
                    tokenDetails.push({ spaceXToken, fundType: listingData?.fundType, holdingPercent: holding ? holding : 0 });
                  }
                }
              }
            }
          }
          setTotalSpaceXToken(tokenDetails);
        }
      }

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (assetData && userData?.wallet_address) {
      getSpaceXToken(userData?.wallet_address, assetData)
    }
  }, [assetData, userData?.wallet_address])


  return (
    <Box sx={{ marginTop: "20px" }}>
      <Box className="pf-page">
        {/*<Box className='pf-name-id'>
               <Typography className='pf-id'>Member ID: {userData?.member_id ? userData?.member_id : ''}</Typography>
               <Typography className='pf-name'>
                  Hi {userData?.first_name} 👋🏼
               </Typography>
            </Box>
            */}
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} sm={12}>
            <Box className="pfInfo">
              <Box className="pfInfo-flex">
                <Box className="pfIf">Information</Box>
                {/* {totalSpaceXToken?.length > 2 &&
                  <Box className='pfSa' onClick={() => setSeeAll(!seeAll)} style={{ cursor: 'pointer' }}>
                    {seeAll ? "View less" : "View more"}
                  </Box>
                } */}
              </Box>
              <Grid container spacing={1}>
                <Grid item md={3} sm={6} xs={12}>
                  <Box className="pfIf-card">
                    <Box className="pfIf-card-icon-box">
                      <Box className="pfIf-card-icon">
                        <Business />
                      </Box>
                    </Box>
                    <Box>
                      <Box className="pfIf-card-title">
                        {parseFloat(totalInvestments?.total_project) > 0 ? formatNumber(parseFloat(totalInvestments?.total_project)) : 0}
                      </Box>
                      <Box className="pfIf-card-subtitle">Projects Invested</Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <Box className="pfIf-card">
                    <Box className="pfIf-card-icon-box">
                      <Box className="pfIf-card-icon">
                        <Payments />
                      </Box>
                    </Box>
                    <Box>
                      <Box className="pfIf-card-title">
                        ${parseFloat(totalInvestments?.totalOfferAmount) > 0 ? formatNumber(parseFloat(totalInvestments?.totalOfferAmount)) : 0}
                      </Box>
                      <Box className="pfIf-card-subtitle">Total Investments</Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <Box className="pfIf-card">
                    <Box className="pfIf-card-icon-box">
                      <Box className="pfIf-card-icon">
                        <Soap />
                      </Box>
                    </Box>
                    <Box>
                      <Box className="pfIf-card-title">
                        ${parseFloat(totalInvestments?.totalLoanAmount) > 0 ? formatNumber(parseFloat(totalInvestments?.totalLoanAmount)) : 0}
                      </Box>
                      <Box className="pfIf-card-subtitle">Loans Provided</Box>
                    </Box>
                  </Box>

                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <Box className="pfIf-card">
                    <Box className="pfIf-card-icon-box">
                      <Box className="pfIf-card-icon">
                        <AttachMoney />
                      </Box>
                    </Box>
                    <Box>
                      <Box className="pfIf-card-title">
                        ${parseFloat(totalInvestments?.totalReceivedAmount?.total_received_amount
                        ) > 0 ? formatNumber(parseFloat(totalInvestments?.totalReceivedAmount
                          ?.total_received_amount)) : 0}
                      </Box>
                      <Box className="pfIf-card-subtitle">Total Returns</Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              {totalSpaceXToken && totalSpaceXToken.length ? (
                <Box className="portfolio-slider">
                  <div className="slider-container" >
                    <Slider {...settings}>
                      {totalSpaceXToken.map((item, index) => (
                        <Grid item md={3} sm={6} xs={12} key={index}>
                          <Box className="pfIf-card token-details">
                            <Box className="pfIf-card-icon-box">
                              <Box className="pfIf-card-icon">
                                <TokenIcon />
                              </Box>
                            </Box>
                            <Box mt={{ xs: -3, sm: -4, md: -4 }}>
                              <Box className="pfIf-card-subtitle">
                                Token Name: {item?.fundType?.tokenName}
                              </Box>
                              <Box className="pfIf-card-subtitle">
                                Token Symbol: {item?.fundType?.tokenSymbol}
                              </Box>
                              <Box className="pfIf-card-subtitle">
                                Token Balance: {formatNumber(parseFloat(item?.spaceXToken ? item?.spaceXToken : 0))}
                              </Box>
                              <Box className="pfIf-card-subtitle">
                                Token Holding(%): {formatNumber(parseFloat(item?.holdingPercent))}
                              </Box>
                              <Box className="pfIf-card-subtitle">
                                Token Address:{" "}
                                <Link
                                  style={{ color: 'blue', textDecoration: 'underline' }}
                                  target="_blank"
                                  to={`https://testnet.racescan.io/address/${item?.fundType?.tokenAddress}`}
                                >
                                  {item?.fundType?.tokenAddress?.slice(0, 6)}{"..."}{item?.fundType?.tokenAddress?.slice(-6)}
                                </Link>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                    </Slider>
                  </div>
                </Box>
              ) : null}
            </Box>
          </Grid>
          {/*  <Grid item xs={12} sm={12} md={4}>
                  <Box
                     sx={{
                        background: '#032744',
                        display: "block",
                        boxSizing: "border-box",
                        width: "100%",
                        height: "100%", // Set the height to 100%
                        maxHeight: '355px', // Set a maximum height if needed
                        borderRadius: '12px',
                        margin: "auto", // Center the chart within the Grid item
                        overflow: "hidden", // Adjust overflow as needed
                     }}
                  >
                     <Charts />
                  </Box>
               </Grid>
            */}
        </Grid>
        {/* <Box sx={{ marginTop: "35px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Box
                sx={{
                  background: "#032744",
                  display: "block",
                  boxSizing: "border-box",
                  width: "100%",
                  height: "100%", // Set the height to 100%
                  maxHeight: "355px", // Set a maximum height if needed
                  borderRadius: "12px",
                  margin: "auto", // Center the chart within the Grid item
                  overflow: "hidden", // Adjust overflow as needed
                  padding: "18px",
                }}
              >
                <Charts />
              </Box>
            </Grid>
          </Grid>
        </Box> */}
        <Box sx={{ margin: "20px 0px" }}>
          <Card className="project-card">
            <Grid container></Grid>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-end"
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                useFlexGap
                flexWrap="wrap"
                width={"100%"}
                spacing={{ xs: 1.5 }}
              ></Stack>
            </Grid>
          </Card>
        </Box>
        {/* Search bar filter */}
        {/* <Box className="filters-wrap" mb={5.5}>
          <Grid spacing={1} container>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="filterSearch-form">
                <Box component={"span"} className="search-icon">
                  <Search />
                </Box>
                <InputBase
                  ref={searchBarRef}
                  className="filterSearch"
                  placeholder="Search Investments"
                  value={searchString}
                  onChange={(e) => suggestionSearch(e.target.value)}
                  inputProps={{ "aria-label": "search transactions" }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <Box className="filterCategory-menu-wrap">
                <Box className="filters-group-wrap">
                  {/* Category filter */}
        {/*<Box className="select-group" ml={1.5}>
                    <FormControl
                      className="select-rounded"
                      sx={{ minWidth: "150px" }}
                      size="small"
                    >
                      <InputLabel id="demo-controlled-open-select-label">
                        Category
                        <ViewComfy />
                      </InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        value={filterValues.category_id}
                        onChange={handleChangeCategory}
                        label="Category"
                        variant="outlined"
                      >
                        <MenuItem value={0}>All</MenuItem>
                        {categoryData?.map((value, ind) => {
                          return (
                            <MenuItem key={ind} value={value.id}>
                              <ListItemText>{value.title}</ListItemText>
                              <Typography className="menu-icon">
                                <ImageListItem>
                                  <img
                                    src={`${process.env.REACT_APP_IMAGE_URL}${value.category_icon?.path}`}
                                    srcSet={`${process.env.REACT_APP_IMAGE_URL}${value.category_icon?.path}`}
                                    alt={value.category_icon?.original_name}
                                    loading="lazy"
                                  />
                                </ImageListItem>
                              </Typography>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>

                  {/* Investment Type Filter */}
        {/*<Box className="select-group" ml={1.5}>
                    <FormControl
                      className="select-rounded"
                      sx={{ minWidth: 160 }}
                      size="small"
                    >
                      <InputLabel id="demo-controlled-open-select-label">
                        Investment Type
                        <BarChart />
                      </InputLabel>

                      <Select
                        labelId="demo-controlled-open-select-label"
                        value={filterValues.investment_type_id}
                        label="Investment Type"
                        onChange={handleChangeInvestmentType}
                      >
                        <MenuItem value={0}>All</MenuItem>
                        {investmentTypeData?.map((value, ind) => {
                          return (
                            <MenuItem key={ind} value={value.id}>
                              {value.investment_name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>

                  {/* Sorting  */}
        {/* <Box className="dropdown-menu-wrap" ml={1.5}>
                    <Button
                      endIcon={<SwapVert />}
                      // onClick={() => sortByName()}
                      aria-controls={open ? "fade-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                      className="btn-rounded btn-outline"
                    >
                      Sort By: {sortBy}
                    </Button>
                    <Menu
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      anchorEl={anchorElSort}
                      open={open}
                      onClose={handleClose}
                      className="dropdown-menu filterCategory-menu"
                    >
                      {sortByOptions.map((value, ind) => {
                        return (
                          <MenuItem
                            key={ind}
                            selected={sortBy === value ? true : false}
                            onClick={() => {
                              handleSortChange(value);
                              setAnchorElSort(null); // done seperately to close the menu after click
                            }}
                          >
                            {value}
                          </MenuItem>
                        );
                      })}
                    </Menu>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box> */}

        <Grid container spacing={{ xs: 1, sm: 1, md: 2 }}>
          {!loading &&
            filteredData &&
            filteredData?.length > 0 &&
            elRefs?.length
            ? filteredData.map((asset, ind) => {
              const galleryimg = [];
              if (asset?.asset_data?.investment_type?.id === 3) {
                // Adding Cover Photo into slider for art parcel only
                if (asset?.asset_data?.asset_gallery) {
                  galleryimg.push(
                    `${process.env.REACT_APP_IMAGE_URL}${asset?.asset_data?.asset_gallery[0]?.images.path}`
                  );
                }

                // Inserting gellery Images Into Slider
                asset?.asset_data?.parcelData?.forEach((val, ind) => {
                  if (val.is_sold && val.investor_id === userData?.id) {
                    galleryimg.push(
                      `${process.env.REACT_APP_IMAGE_URL}${val.imagess.path}`
                    );
                  }
                });
              } else {
                // Inserting gellery Images Into Slider
                asset?.asset_data?.asset_gallery.map((val, ind) => {
                  if (val.title === "gellary") {
                    galleryimg.push(
                      `${process.env.REACT_APP_IMAGE_URL}${val.images.path}`
                    );
                  }
                });
              }

              let newParcelTitle = "";
              let matchedParcels = [];
              // Creating a new title for the art parcels
              if (asset?.asset_data?.investment_type?.id === 3) {
                if (asset?.asset_data?.parcelData?.length) {
                  let parcels = asset?.asset_data?.parcelData;
                  // let parcelIdsToMatch = asset?.parcel_ids;
                  matchedParcels = parcels.filter(
                    (item) =>
                      item.investor_id === userData?.id &&
                      item.is_sold === true
                  );
                  let newParcelIds = [];
                  if (matchedParcels?.length) {
                    matchedParcels?.forEach((parcel) => {
                      let parcelNo = parcel.title.split("#")[1];
                      newParcelIds.push(parcelNo);
                    });
                  }
                  // Using map to create an array of elements
                  const resultElements = newParcelIds.map((id) => `#${id}`);

                  // Using join to concatenate the array elements into a string
                  newParcelTitle = resultElements.join(", ");
                }
              }
              return (
                <Grid key={ind} item xs={12} sm={6} md={3} lg={3}>
                  <PortfolioCard
                    id={asset?.asset_data?.id}
                    title={
                      newParcelTitle
                        ? `Parcel ${newParcelTitle}`
                        : asset?.asset_data?.asset_title
                    }
                    subTitle={
                      newParcelTitle
                        ? asset?.asset_data?.asset_title
                        : asset?.asset_data?.asset_address
                    }
                    investType={
                      asset?.asset_data?.investment_type?.investment_name
                    }
                    investment_type_id={
                      asset?.asset_data?.investment_type?.id
                    }
                    isLoan={asset?.asset_data?.assets_category?.title}
                    imgSrc={galleryimg}
                    link={`/project-details/${asset?.asset_data?.id}`}
                    portfolio={true}
                    matchedParcels={matchedParcels}
                    assetData={asset?.asset_data}
                    ref={elRefs[ind]}
                    index={ind}
                    sidebarOpen={sidebarOpen}
                  />
                </Grid>
              );
            })
            : assetData?.length === 0 && (
              <Typography className="title" component={"h4"} ml={3}>
                No Projects Found
              </Typography>
            )}
        </Grid>
      </Box>

      {/* full page loader */}
      <CommonBackdropLoader loading={loading} />
    </Box>
  );
};

export default Portfolio;
