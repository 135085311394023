// payEMI.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import getCurrencyTypeDecimal from "../Common/getCurrencyTypeDecimal";
import { CAPITAL_TYPE_ID, CATEGORY_TYPE_ID } from "../../constants";
import { SignalCellular1BarSharp } from "@mui/icons-material";

/**
 * Call payEMI method to repay the emi
 * @param {String} address user wallet address
 * @param {Number} assetData asset's details
 * @param {Function} showSnackbar show success/failure message
 * @param {Function} handleActiveStep handle active step
 * @param {Function} handleModalClose handle modal close
 * @param {Function} handleSuccess handle confirmation of transaction success
 * @returns data or error
 */
async function payEMI(address, contract_address, contract_abi, token_address, assetData, user_id, asset_id, showSnackbar, handleActiveStep, handleModalClose, handleSuccess) {
    try {
        // set active step to 2
        handleActiveStep(2);

        let args = [
            assetData?.listing_id, // listing id / ipfs code id Integer
            token_address,// _feeToken
            getCurrencyTypeDecimal(assetData?.feeTokenType, assetData?.emi_amount) //  emi amount
        ]
        if (assetData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
            let emi_amount = [];
            let c1 = [];
            let c2 = [];

            const data = JSON.parse(assetData?.data);
            const data1 = JSON.parse(assetData?.data1);

            if (data && data.length > 0) {
                let totalAmount = 0;
                let defaultMonths = 0;
                let monthDifference = [0];
                let tokenAmount = [0];
                let principalAmount = [0];
                let interestAmount = [0];
                let penaltyAmount = [0];
                data.map((itm) => {
                    totalAmount = totalAmount + Number(itm.tokenAmount);
                    if (defaultMonths < Number(itm.defaultMonths)) {
                        defaultMonths = Number(itm.defaultMonths);
                    }
                    monthDifference.push(Number(itm.monthDifference));
                    tokenAmount.push(Number(itm.tokenAmount));
                    principalAmount.push(Number(itm.principalAmount));
                    interestAmount.push(Number(itm.interestAmount));
                    penaltyAmount.push(Number(itm.penaltyAmount));
                })
                c1 = [
                    totalAmount, //     uint totalAmount;
                    defaultMonths, //     uint defaultMonths;
                    monthDifference, //     uint[] monthDifference;
                    tokenAmount, //     uint[] tokenAmount;
                    principalAmount, //     uint[] principalAmount;
                    interestAmount, //     uint[] interestAmount;
                    penaltyAmount, //     uint[] penaltyAmount;
                ]
                let emiAmt = getCurrencyTypeDecimal(assetData?.feeTokenType, (Math.ceil((totalAmount) / 1e16) * 100)) //  emi amount
                emi_amount.push(emiAmt);
            }
            if (data1 && data1.length > 0) {
                let totalAmount = 0;
                let defaultMonths = 0;
                let monthDifference = [0];
                let tokenAmount = [0];
                let principalAmount = [0];
                let interestAmount = [0];
                let penaltyAmount = [0];
                data1.map((itm) => {
                    totalAmount = totalAmount + Number(itm.tokenAmount);
                    if (defaultMonths < Number(itm.defaultMonths)) {
                        defaultMonths = Number(itm.defaultMonths);
                    }
                    monthDifference.push(Number(itm.monthDifference));
                    tokenAmount.push(Number(itm.tokenAmount));
                    principalAmount.push(Number(itm.principalAmount));
                    interestAmount.push(Number(itm.interestAmount));
                    penaltyAmount.push(Number(itm.penaltyAmount));
                })
                c2 = [
                    totalAmount, //     uint totalAmount;
                    defaultMonths, //     uint defaultMonths;
                    monthDifference, //     uint[] monthDifference;
                    tokenAmount, //     uint[] tokenAmount;
                    principalAmount, //     uint[] principalAmount;
                    interestAmount, //     uint[] interestAmount;
                    penaltyAmount, //     uint[] penaltyAmount;
                ]
                let emiAmt = getCurrencyTypeDecimal(assetData?.feeTokenType, (Math.ceil((totalAmount) / 1e16) * 100)) //  emi amount
                emi_amount.push(emiAmt);
            }

            let payloadData = [];

            if (data) {
                payloadData = [c1]
            }
            if (data1) {
                payloadData = [c2]
            }
            if (data && data1) {
                payloadData = [c1, c2]
            }
            let debtTypeId = assetData?.emi_amount_array?.map((item) => item?.capital_type_id === CAPITAL_TYPE_ID.SENIOR_DEBT ? 0 : 1);
            let token_address_array = assetData?.emi_amount_array?.map(() => token_address);

            args = [
                assetData?.listing_id, // listing id / ipfs code id Integer
                debtTypeId,
                payloadData,
                token_address_array,// _feeToken
                emi_amount //  emi amount
            ]

        }
        // make offer by investor
        const { request: payEMI } = await prepareWriteContract({
            address: contract_address,
            abi: contract_abi,
            account: address,
            chainId: 6806,
            functionName: "payEMI",
            args: args
        })
        const { hash } = await writeContract(payEMI)
        if (hash) {
            const data = await waitForTransaction({
                hash: hash,
            })
            if (data.status === "success") {
                showSnackbar("Transaction Successful", 'success')
                handleActiveStep(3)
                setTimeout(() => {
                    handleSuccess(data, assetData, assetData?.amount, asset_id, user_id)
                    handleModalClose();
                    handleActiveStep(-1)

                    return { data }
                }, 1000)
            } else {
                handleModalClose();
                handleActiveStep(-1)
                showSnackbar("Transaction Failed", 'error')
                return
            }
        }
    } catch (error) {
        console.error('Error calling pay emi:', error);
        throw error; // propagate the error
    }
}

export { payEMI };