// checkAllowance.js

import { readContract } from "@wagmi/core";

/**
 * Fetch user wallet allowance for given spender
 * @param {String} contract_address contract_address
 * @param {String} contract_abi contract_abi
 * @returns epoch
 */
async function getDueDate(contract_address, contract_abi, details) {
    try {

        // Now check for DueDate
        const data = await readContract({
            address: contract_address,
            abi: contract_abi,
            functionName: "calculateNextPaymentDate",
            chainId: 6806,
            args: [
                details[0],
                details[1],
                details[2]
            ]
        })
        return Number(data[2] || 0);
    } catch (error) {
        console.error('Error fetching getDueDate:', error);
        throw error; // propagate the error
    }
}

export { getDueDate };
