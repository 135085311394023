// for slider
import "react-responsive-carousel/lib/styles/carousel.min.css";
import React, { useState, useEffect, useRef } from "react";
import { useLocation, useLoaderData, Link, } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import TypeIt from "typeit";
import Slider from "react-slick";
import "react-multi-carousel/lib/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Backdrop, Box, Grid, Typography, Button, Stack } from "@mui/material";
import { useSpring, animated } from "react-spring";
import { EarthVideo } from "../../Components/Images/Images";
import { GetApi, GetApiParam, updateApi } from "../../Api/Api";
import { CATEGORY_TYPE_ID, PROJECT_STATUS } from "../../constants";
import Cards from "../../Components/Cards/Cards";
import Popup from "../../ServiceProvider/Popup/Popup";
import SouthIcon from '@mui/icons-material/South';
import ShowChartIcon from "@mui/icons-material/ShowChart";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import DropdownBox from "./DropdownBox";
import EcosystemSlider from "./Ecosystem/EcosystemSlider";
import TokenizedAssetsSection from "./TokenzedAssetsSection";
import Launch from "../LaunchPopUp/Launch";
import { updateStep } from "../../features/auth/tooltipSlice";
import "../../Pages/Home/Home.css";
import "./InvestorOnly.css";
import HomeAboutRace from "./HomeAboutRace";
// import FooterInvestorOnly from "../../Layouts/Footer/FooterInvestorOnly";
import CommonBackdropLoader from "../../Components/Common/CommonBackdropLoader";

const HeaderBox = styled(Box)(({ }) => ({
  transition: 'padding 0.3s',
}));

const InvestorHome = () => {
  const location = useLocation();
  const serverTimeInterval = useRef(null);

  const step = useSelector((state) => state.tooltipSlice.loan_step_number);
  const dataFromLoader = useLoaderData(); //getting data first hand when page loads from Routes using loader
  // Get the dispatch function from the Redux store
  const dispatch = useDispatch();

  // State variable for the dynamic inputs
  // const [categoryData, setCategoryData] = useState();
  const [topInvestment, setTopInvestment] = useState();

  // const [serverTime, setServerTime] = useState(dataFromLoader.assetData.server_time);
  // const [serverDate, setServerDate] = useState(dataFromLoader.assetData.server_date);
  const [serverClockTime, setServerClock] = useState();
  const [bookmark, setBookmark] = useState(); // for Bookmark
  const [serviceClick, setServiceClick] = useState(false);
  const [tolPosition, setTolPosition] = useState(0)
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [launchClick, setLaunchClick] = useState(false)

  useEffect(() => {
    if (tolPosition > 5 || tolPosition <= 4) {
      setLoading(false)
    }
  }, [tolPosition])

  const localData = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : "";

  /**
   * Code for setting the text animation
   */

  const headingRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 3000);

    // Clean up the timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (isVisible && headingRef.current) {
      new TypeIt(headingRef.current, {
        strings: "RACE",
        cursor: false,
        speed: 1200,
        loop: false,
        waitUntilVisible: true,
        afterComplete: function (instance) {
          instance.destroy();
        },
      }).go();
    } else if (!headingRef.current) {
      console.warn("Element with ref 'headingRef' not found.");
    }
  }, [isVisible]);

  useEffect(() => {
    async function getStep() {
      try {
        const data = await GetApiParam("/user/currentTooltipPosition", { "user_id": localData.id });
        if (data?.data?.data) {
          setTolPosition(data.data?.data?.loan_step_number === null ? 0 : data.data.data.loan_step_number)
          if (data.data?.data?.loan_step_number === 5) {
            setLoading(true)
          }
        }
      } catch (error) {
        console.log('error', error)
      }
    }

    const timer = setTimeout(() => {
      if (localData && localData?.id) {
        getStep();
      }
    }, 100);
    return () => clearTimeout(timer)
  }, [step, localData?.id])

  async function setStep(tolPositionData) {
    try {
      const data = await updateApi("/user/updateUser/" + localData?.id, {
        "loan_step_number": tolPositionData,
        "sale_step_number": tolPositionData, "parcel_step_number": tolPositionData, "re_step_number": tolPositionData, "funds_step_number": tolPositionData
      });
      if (data?.data?.data) {
        setTolPosition(data.data?.data?.loan_step_number ? data.data.data.loan_step_number : 0)
        if (data.data?.data?.loan_step_number === 5) {
          setLoading(true)
        }
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const animationProps = useSpring({
    from: { opacity: 0, transform: "translateY(-50px)" },
    to: { opacity: 1, transform: "translateY(0)" },
    config: { duration: 4000 },
  });

  // useEffect for calling api's
  useEffect(() => {
    // Api to get categoryData
    async function getCategoryData() {
      try {
        // setLoadingPage(true);
        const res = await GetApi("/proposer/getCategory/");
        if (res?.data?.data && res.data?.data?.length) {
          // setLoadingPage(false)
          // setCategoryData(res?.data?.data);
        } else {
          // setLoadingPage(false)
          // setCategoryData([]);
        }
      } catch (error) {
        // setLoadingPage(false)
        console.log(error);
      }
    }
    if (localData) {
      getCategoryData();
    }
    getAssetsData();
    // getInvestMentTypeData();
  }, []);

  // get all assets
  async function getAssetsData(categoryId) {
    try {
      // Create a payload for the API request
      let payload = {
        status: PROJECT_STATUS.SUBMITTED,
        is_onboard: 1,
        is_publish: 1
      };
      if (categoryId) {
        payload.category_id = categoryId === CATEGORY_TYPE_ID.WATCH ? `${CATEGORY_TYPE_ID.WATCH},${CATEGORY_TYPE_ID.ASSET},${CATEGORY_TYPE_ID.TOKENIZED_ASSET}` : categoryId
      }
      setLoadingPage(true)
      const res = await GetApiParam("/proposer/getAssetsHomePage/", payload);
      if (res?.data?.data) {
        setTopInvestment(res?.data?.data);
      }
      setTimeout(() => {
        setLoadingPage(false)
      }, 100);
    } catch (error) {
      setLoadingPage(false)
      console.log(error);
    }
  }

  // Sending data to setTimer when data comes from loader from Routes where we've called Api
  // useEffect(() => {
  //   setTimer(dataFromLoader);
  // }, [dataFromLoader]);

  useEffect(() => {
    if (localData?.id) {
      // Api to get InvestmentTypeData
      async function getBookmarkData() {
        try {
          const payloadData = {
            user_id: localData.id,
          };
          const res = await GetApiParam("/proposer/getBookmark/", payloadData);
          setBookmark(res?.data?.data ? res?.data?.data : []);
          // setInvestmentTypeData(res?.data?.data ? res?.data?.data : []);
        } catch (error) {
          console.log(error);
        }
      }
      const timer = setTimeout(() => {
        getBookmarkData()
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [localData.id]);

  // functionality for Timer
  function setTimer(res) {
    // initialize server clock
    if (res?.assetData?.data?.length > 0) {
      const serverClockTime = new Date(res.assetData.server_date);
      var timestamp = res.assetData.server_time.split(":");
      serverClockTime.setHours(timestamp[0]);
      serverClockTime.setMinutes(timestamp[1]);
      serverClockTime.setSeconds(timestamp[2]);
      setServerClock(serverClockTime);
    }
  }

  useEffect(() => {
    serverTimeInterval.current = setInterval(() => {
      if (serverClockTime) {
        serverClockTime.setSeconds(serverClockTime.getSeconds() + 1);
        setServerClock(serverClockTime);
        const event = new CustomEvent("clocktime", {
          detail: serverClockTime,
          bubbles: true,
        });
        document.dispatchEvent(event);
      }
    }, 1000);
  }, [serverClockTime]);
  // start clock interval

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  // functionality for Bookmark
  const bookmarkVal = (asset_id) => {
    return bookmark?.some(
      (item) => item.asset_id === asset_id && item.status === 1
    );
  };

  // const [openBluePrintSelection, setOpenBluePrintSelection] = useState(false);
  // const [openKYCForm, setOpenKYCForm] = useState(false);

  useEffect(() => {
    const currentLocation = location.state?.from;
    const loggedIn = localStorage.getItem("login");
    const kyc_modal_closed = localStorage.getItem("kyc_modal_closed");

    if (currentLocation === "/login" && loggedIn && !kyc_modal_closed) {
      if (!localData.persona_picture) {
        // setOpenBluePrintSelection(true);
      }

      if (localData.kycStatus === false) {
        // setOpenKYCForm(true);
      }
    }
  }, [location, localData]);

  /**
   * For the service provider Modal
   */
  const handleCancel = () => {
    setServiceClick(false);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (location?.state?.from === "HeaderProvideService") {
      setServiceClick(true);
    }
  }, [location]);

  useEffect(() => {
    if (serviceClick === false) document.documentElement.scrollTop = 0;
  }, [serviceClick]);

  const handleCategory = (category_id) => {
    getAssetsData(category_id);
  }

  // const [isShrunk, setIsShrunk] = useState(false);
  const [positionFromTop, setPositionFromTop] = useState(0);

  const handleScroll = () => {
    // const shrinkHeader = 0.1;
    const scroll = window.pageYOffset || document.documentElement.scrollTop;
    // setIsShrunk(scroll >= shrinkHeader);
    setPositionFromTop(scroll);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleTolPosition = async (data) => {
    // setTolPosition(pre => pre + data)
    await setStep(tolPosition + data)

    dispatch(updateStep({ key: 'loan_step_number', value: tolPosition + data }));
    dispatch(updateStep({ key: 'sale_step_number', value: tolPosition + data }));
    dispatch(updateStep({ key: 'parcel_step_number', value: tolPosition + data }));
    dispatch(updateStep({ key: 're_step_number', value: tolPosition + data }));
    dispatch(updateStep({ key: 'funds_step_number', value: tolPosition + data }));
  }

  const handleClick1 = (event) => {
    // setAnchorEl1(event.currentTarget);
    setLaunchClick(true)
  };

  const handleLaunchCancel = () => {
    setLaunchClick(false);
    window.scrollTo(0, 0);
  }

  const goToProject = () => {
    const element = document.getElementById("scroll-to-project-list");
    if (element) {
      const offset = 0;
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementRect = element.getBoundingClientRect().top;
      const elementPosition = elementRect - bodyRect;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  };
  const sliderRef = useRef(null);
  const settings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 0, // Set this to a positive value like 2000 for a delay between slides
    speed: 5000, // Adjust the speed to make the transition slower
    cssEase: 'linear',
    infinite: true, // Ensure infinite is set to true
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  const handleMouseEnter = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPause();
      const slickTrack = sliderRef.current.innerSlider.list.querySelector('.slick-track');
      if (slickTrack) {
        slickTrack.style.transition = 'none';
      }
    }
  };

  const handleMouseLeave = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPlay();
      const slickTrack = sliderRef.current.innerSlider.list.querySelector('.slick-track');
      if (slickTrack) {
        slickTrack.style.transition = '';
      }
    }
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [cardLength, setCardLength] = useState(4);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener to update windowWidth state when window is resized
    window.addEventListener('resize', handleResize);

    // Remove event listener when component unmounts to prevent memory leaks
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures that this effect runs only once after component mounts

  useEffect(() => {
    if (windowWidth > 1024) {
      setCardLength(4)
    } else if (windowWidth > 768) {
      setCardLength(3)
    }
    else if (windowWidth > 480) {
      setCardLength(2)
    }
    else if (windowWidth <= 480) {
      setCardLength(1)
    }
  }, [windowWidth])

  return (
    <Box>
      {localData ?
        // tooltip code start
        tolPosition === 5 ?
          <Box className='tokenized-sticky-top' style={{ zIndex: 98 }}>
            <Box className={`container`}>
              <Typography className="font-45" mb={'41px'} pt={'40px'}>
                <DropdownBox render={
                  <span>Tokenized Assets</span>}
                  setState={handleTolPosition}
                  description={"Explore different types of tokenized assets available for investment."}
                  tolPosition={tolPosition}
                  position={'top'}
                  data={true}
                  // from={true}
                  last={true}
                />
              </Typography>
              <TokenizedAssetsSection setSelectedCategory={handleCategory} tolPosition={tolPosition} />
            </Box>
          </Box>
          :
          <Box className='tokenized-sticky-top'>
            <HeaderBox className={`container`}>
              <Typography className="featured-token-assets-text" mb={'41px'} pt={'40px'}>
                Tokenized Assets
              </Typography>
              <TokenizedAssetsSection setSelectedCategory={handleCategory} positionFromTop={positionFromTop} />
            </HeaderBox>
          </Box>
        // tooltip code end
        :
        <Box className="container globe-home">
          <animated.div className="video-animation" style={animationProps}>
            <video
              src={EarthVideo} // Replace with your actual background video file
              autoPlay
              loop
              muted
              playsInline
              className="video-main-div"
              controlsList="nodownload"
            />
            <div className="video-content">
              <div className="video-main container">
                <div className="left-section">
                  {isVisible && <p className="font-24" ref={headingRef}></p>}
                  <p className="unlock-text" id="headingHomeTwo">
                    Unlock <span className="text-golden">Global</span> Liquidity
                  </p>
                  <div className="page-down" onClick={goToProject}>
                    {/* <img src={ArrowDown} /> */}
                    <SouthIcon />
                  </div>
                </div>
                <div className="right-section">
                  <Stack
                    direction={{ base: "column", md: "row" }}
                    spacing={{ base: 0, md: 2 }}
                  >
                    <Link to="https://bridge.race.foundation/" target="_blank">
                      <Button className="btn-rounded btn-large bridge-btn" style={{ cursor: 'pointer' }}>
                        <ShowChartIcon />
                        <span>Bridge to RACE</span>
                      </Button>
                    </Link>
                    <Button className="btn-rounded btn-large bridge-btn btn-text-white btn-blue-600" onClick={handleClick1} style={{ cursor: 'pointer' }}>
                      <AttachMoneyIcon />
                      <span> Explore Marketplace</span>
                    </Button>
                  </Stack>
                </div>
              </div>
            </div>
          </animated.div>
        </Box>

      }

      {/* About Race */}
      {localData ? null :
        <Box className="container" pb={7}>
          <HomeAboutRace />
        </Box>
      }

      {/* Top Investments start */}
      <Box className="investor-only-project" sx={{ mb: 6, pt: 1 }} id="scroll-to-project-list">
        <Box className="container">
          {localData ? null :
            <Box className="" mb={'30px'}>
              <Box className="fp-left">
                <Box className="">
                  <Typography className="featured-token-assets-text" mb={3}>
                    Invest In Our First Probe-Test Asset & Gain Points
                  </Typography>
                  <Typography className="font-18 explore-subtext">Lend against a tokenized and collateralized Rolex-backed loan. {(topInvestment?.length === 0 || !topInvestment) ? "(Coming Soon)" : ''}</Typography>
                </Box>
              </Box>
            </Box>
          }

          {/*Top Investment starts*/}
          {localData ?
            <Box className="HomeTopInvestments-wrap">
              <Grid spacing={{ xs: 1, sm: 1, md: 2 }} container>
                {(() => {
                  const topInvested = [];
                  for (
                    let i = 0;
                    i < (topInvestment?.length);
                    i++
                  ) {
                    if (!topInvestment[i].other_category && topInvestment[i].status === PROJECT_STATUS.SUBMITTED || topInvestment[i].status === "inrevew" || topInvestment[i].status === "accept") {
                      const galleryimg = [];
                      // Adding Cover Photo into slider
                      if (topInvestment[i]?.asset_coverphoto) {
                        galleryimg.unshift(
                          `${process.env.REACT_APP_IMAGE_URL}${topInvestment[i].asset_coverphoto?.path}`
                        );
                      }

                      topInvestment[i]?.asset_gallery.forEach((val, ind) => {
                        if (val.title === "gellary" && val.image_order === 1) {
                          galleryimg.push(
                            `${process.env.REACT_APP_IMAGE_URL}${val.images.path}`
                          );
                        }
                      });
                      // Inserting gellery Images Into Slider
                      topInvestment[i]?.asset_gallery.forEach((val, ind) => {
                        if (val.title === "gellary" && ind > 0) {
                          galleryimg.push(
                            `${process.env.REACT_APP_IMAGE_URL}${val.images.path}`
                          );
                        }
                      });

                      topInvested.push(
                        <Grid key={i} item xs={12} sm={6} md={3} lg={3}>
                          {/* <Link to={data[i]?.project_link} state={{ data: data[i].imgSrc }}> */}
                          <Cards
                            id={topInvestment[i]?.id}
                            title={topInvestment[i]?.asset_title}
                            category_id={
                              topInvestment[i]?.category_id
                            }
                            subTitle={topInvestment[i]?.asset_address}
                            investType={
                              topInvestment[i]?.investment_type?.investment_name
                            }
                            address={topInvestment[i]?.owner_information.owner_address}
                            isLoan={topInvestment[i]?.assets_category?.title}
                            isDraft={false}
                            isClock={true}
                            investment_expiry_time={topInvestment[i]?.investment_expiry_time}
                            isPending={
                              topInvestment[i]?.status === "Pending" ||
                                topInvestment[i]?.status === "inreview"
                                ? true
                                : false
                            }
                            isDelete={
                              topInvestment[i]?.status === "Rejected" ? true : false
                            }
                            isSaved={false}
                            imgSrc={galleryimg}
                            isSaveDeal={true}
                            ownerName={
                              topInvestment[i]?.user_details?.first_name
                            }
                            ownerImage={topInvestment[i]?.user_details?.persona_picture}
                            // server_date={serverDate}
                            // server_time={serverTime}
                            link={`/project-details/${topInvestment[i]?.id}`}
                            from='/'
                            loanType={topInvestment[i]?.loan_type}
                            bookMarkStatus={bookmarkVal(topInvestment[i]?.id)}
                            assetDataDetail={topInvestment[i]}
                          />
                        </Grid>
                      );
                    }
                  }
                  return topInvested;
                })()}
              </Grid>
            </Box>
            :
            <Box className={`myPorject-submittedPro-wrap ${topInvestment?.length < cardLength ? "less-then-four" : ""}`}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}>
              <Slider {...settings}>
                {(() => {
                  const topInvested = [];
                  for (
                    let i = 0;
                    i < topInvestment?.length;
                    i++
                  ) {
                    if (
                      (!topInvestment[i].other_category &&
                        topInvestment[i].status === PROJECT_STATUS.SUBMITTED) ||
                      topInvestment[i].status === "inrevew" ||
                      topInvestment[i].status === "accept"
                    ) {
                      const galleryimg = [];
                      // Adding Cover Photo into slider
                      if (topInvestment[i]?.asset_coverphoto) {
                        galleryimg.unshift(
                          `${process.env.REACT_APP_IMAGE_URL}${topInvestment[i].asset_coverphoto?.path}`
                        );
                      }

                      topInvestment[i]?.asset_gallery.forEach((val, ind) => {
                        if (val.title === "gellary" && val.image_order === 1) {
                          galleryimg.push(
                            `${process.env.REACT_APP_IMAGE_URL}${val.images.path}`
                          );
                        }
                      });
                      // Inserting gellery Images Into Slider
                      topInvestment[i]?.asset_gallery.forEach((val, ind) => {
                        if (val.title === "gellary" && ind > 0) {
                          galleryimg.push(
                            `${process.env.REACT_APP_IMAGE_URL}${val.images.path}`
                          );
                        }
                      });

                      topInvested.push(
                        <Box key={i} className="slide-item">
                          <Box sx={{ margin: '0 10px' }}>
                            <Cards
                              id={topInvestment[i]?.id}
                              title={topInvestment[i]?.asset_title}
                              category_id={
                                topInvestment[i]?.category_id
                              }
                              subTitle={topInvestment[i]?.asset_address}
                              investType={
                                topInvestment[i]?.investment_type?.investment_name
                              }
                              address={
                                topInvestment[i]?.owner_information.owner_address
                              }
                              isLoan={topInvestment[i]?.assets_category?.title}
                              isDraft={false}
                              isClock={true}
                              investment_expiry_time={
                                topInvestment[i]?.investment_expiry_time
                              }
                              isPending={
                                topInvestment[i]?.status === "Pending" ||
                                  topInvestment[i]?.status === "inreview"
                                  ? true
                                  : false
                              }
                              isDelete={
                                topInvestment[i]?.status === "Rejected"
                                  ? true
                                  : false
                              }
                              isSaved={false}
                              imgSrc={galleryimg}
                              isSaveDeal={true}
                              ownerName={topInvestment[i]?.user_details?.first_name}
                              ownerImage={
                                topInvestment[i]?.user_details?.persona_picture
                              }
                              // server_date={serverDate}
                              // server_time={serverTime}
                              link={`/onboarding`}
                              from="/"
                              loanType={topInvestment[i]?.loan_type}
                              bookMarkStatus={bookmarkVal(topInvestment[i]?.id)}
                              assetDataDetail={topInvestment[i]}
                            />
                          </Box>
                        </Box>
                      );
                    }
                  }
                  return topInvested;
                })()}
              </Slider>
            </Box>
          }
        </Box>
      </Box>

      <Box className="container">
        <EcosystemSlider />
      </Box>

      <Box>
        {serviceClick ? (
          <Popup handleCancel={handleCancel} openModal={true} />
        ) : null}
      </Box>

      {loading &&
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => 97 }}
          open={loading}
        >
        </Backdrop>
      }

      {loadingPage &&
        <CommonBackdropLoader loading={loadingPage} zIndex={1000} />
      }

      {launchClick ? <Launch handleCancel={handleLaunchCancel} openModal={true} /> : ""}
    </Box>
  );
};
export default InvestorHome;