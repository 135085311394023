// PostNewProject.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import { SP_CONTRACTS, SP_CONTRACT_ABI } from "../ServiceProviderWeb3/ServiceProviderContract";
import convertToDecimal from "../../Components/Common/convertToDecimal";

// Enumerations for project attributes
const projectSizeEnum = ['Small', 'Medium', 'Large'];
const experienceLevelEnum = ['Entry', 'Intermediate', 'Expert'];
const budgetTypeEnum = ['hourlyRate', 'projectBudget'];

// Function to convert months to seconds
const monthToSeconds = (month) => Math.floor(month * 30.44 * 24 * 60 * 60);

/**
 * Posts a new project to the smart contract.
 * 
 * @param {String} address - User wallet address.
 * @param {Object} assetData - Details about the project to be posted.
 * @param {Function} showSnackbar - Function to show success or failure messages.
 * @param {Function} handleActiveStep - Function to manage the active step in a multi-step process.
 * @param {Function} handleModalClose - Function to close the modal.
 * @param {Function} handleSuccess - Function to handle successful transaction confirmation.
 * 
 * @returns {Object|undefined} - Returns data if successful, or undefined in case of an error.
 */
async function PostNewProject(address, contractAddress, contractAbi, assetData, showSnackbar, handleActiveStep, handleModalClose, handleSuccess) {
    try {
        // Define the contract address and the function name
        // const contractAddress = SP_CONTRACTS.SERVICE_MARKETPLACE_CLIENT;
        const functionName = "postNewProject";

        // Determine the budget type index from the asset data
        const budgetType = budgetTypeEnum.indexOf(assetData?.projectBudget);

        // Create an object to define the project scope
        const scope = {
            // minTime: monthToSeconds(0), // Minimum time set to 0 seconds
            // maxTime: monthToSeconds(assetData?.ProjectTime), // Convert project time to seconds
            projectSize: projectSizeEnum.indexOf(assetData?.ProjectSize), // Enum for project size
            experienceLevel: experienceLevelEnum.indexOf(assetData?.ExperienceLevel), // Enum for experience level
        };

        // Create an object to define the budget details
        const budget = {
            budgetType,
            // minHourlyRate: budgetType === 0 ? convertToDecimal(assetData?.HourlyRateFrom) : 0, // Set min hourly rate if budget type is hourly
            // maxHourlyRate: budgetType === 0 ? convertToDecimal(assetData?.HourlyRateTo) : 0, // Set max hourly rate if budget type is hourly
            minProjectBudget: budgetType === 1 ? convertToDecimal(assetData?.ProjectBudgetFrom) : 0, // Set min project budget if budget type is project budget
            maxProjectBudget: budgetType === 1 ? convertToDecimal(assetData?.ProjectBudgetTo) : 0, // Set max project budget if budget type is project budget
        };

        // Extract skills required from asset data
        const skillsRequired = assetData?.Skills?.map(item => item.skill_name); // Map skill objects to skill names

        // Prepare the contract call for posting a new project
        const { request: postNewProject } = await prepareWriteContract({
            address: contractAddress,
            abi: contractAbi,
            account: address,
            chainId: 6806, // Specify the blockchain network ID
            functionName,
            args: [
                assetData?.Category, // Project category
                skillsRequired, // Skills required for the project
                scope, // Scope object containing time and size
                budget, // Budget object containing financial details
                // '_descriptionUri', // Description URI placeholder
                assetData?.newprojectId ? assetData?.newprojectId?.id : 0, // Existing web2.5 project id, if applicable
                assetData?.projectId, // Existing _dbProjectId, if applicable
            ],
        });

        // Write the contract and get the transaction hash
        const { hash } = await writeContract(postNewProject);
        if (hash) {
            // Wait for the transaction to be confirmed
            const data = await waitForTransaction({ hash });
            if (data.status === "success") {
                // Show success message and update the UI accordingly
                showSnackbar("Transaction Successful", 'success');
                handleActiveStep(2); // Move to the next step in the process
                setTimeout(() => {
                    handleSuccess(assetData); // Handle success actions
                    handleModalClose(); // Close the modal
                    handleActiveStep(-1); // Reset the active step
                }, 1000);
                return { data }; // Return transaction data
            } else {
                // Handle transaction failure
                handleModalClose(); // Close the modal
                handleActiveStep(-1); // Reset the active step
                showSnackbar("Transaction Failed", 'error'); // Show failure message
                return; // Exit the function
            }
        }
    } catch (error) {
        console.error('Error in posting a new project:', error); // Log any errors
        throw error; // Propagate the error
    }
}

export { PostNewProject };
