import React, { useState, useEffect, useRef } from "react";
import { Link, useOutletContext } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Grid, Box, InputBase, MenuItem, IconButton, Typography, Select, ListItemText, ImageListItem, InputLabel, FormControl, Badge } from "@mui/material";
import { Search, ViewComfy, KeyboardArrowDown, ChevronRight } from "@mui/icons-material";
import { GetApi, GetApiParam } from "../../Api/Api";
import { PROJECT_STATUS, ASSIGNED_PROJECT_STATUS, STATUS_OF_PROJECT } from "../../constants";
import Cards from "../../Components/Cards/Cards";
import CardSkeleton from "../../Components/Cards/CardSkeleton";
import CommonBackdropLoader from "../../Components/Common/CommonBackdropLoader";
import "./Projects.css";

const Projects = () => {

  // variable to manage ecosystem or private
  const isProductionPrivate = true;

  const sidebarOpen = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [assetData, setAssetData] = useState("");
  const [reviewStatusData, setReviewStatusData] = useState("");
  const [categoryData, setCategoryData] = useState();
  const [serverTime, setServerTime] = useState();
  const [serverDate, setServerDate] = useState();
  const [serverClockTime, setServerClock] = useState(null);
  const [categoryTypeVal, setCategoryTypeVal] = useState("All");
  const [currentSlide, setCurrentSlide] = useState(0); // Current slide index
  const [currentSlideSub, setCurrentSlideSub] = useState(0); // Current slide index
  const [currentSlideReview, setCurrentSlideReview] = useState(0); //Current slide index
  const [currentSlideVoted, setCurrentSlideVoted] = useState(0); //Current slide index
  const [currentSlideRejected, setCurrentSlideRejected] = useState(0); //Current slide index
  const [bookmark, setBookmark] = useState(); // for Bookmark
  const [isReview, setIsReview] = useState(false);
  const [isVoted, setIsVoted] = useState(false);
  const [isDraftCarouselVisible, setIsDraftCarouselVisible] = useState({
    inDraft: true,
    inTokenize: true,
    inSubmitted: true,
    inReview: true,
    inVote: true,
    inPublish: true,
    inLive: true,
    inRejected: true,
    inAuction: true
  });

  // Reference to the carousel component
  const carouselRef = useRef(null);
  const carouselRefSub = useRef(null);
  const carouselRefReview = useRef(null);
  const carouselRefVoted = useRef(null);
  const carouselRefRejected = useRef(null);

  const serverTimeInterval = useRef(null)

  // fetching data from local storage
  const localData = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : "";

  // Initial api call's on page render
  useEffect(() => {
    async function getDraftData() {
      setLoading(true);
      try {
        const payloadData = {
          user_id: localData?.id,
        };
        const res = await GetApiParam("/proposer/getAssetsList/", payloadData);
        if (res?.data) {
          setLoading(false);
          setAssetData(res.data?.data);
          setServerTime(res.data?.server_time);
          setServerDate(res.data?.server_date);
          setTimer(res.data);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    async function getCategoryData() {
      try {
        const res = await GetApi("/proposer/getCategory/");
        setCategoryData(res?.data?.data);
      } catch (error) {
        console.log(error);
      }
    }
    async function getReviewConfirmationData() {
      try {
        const payloadData = {
          user_id: localData?.id,
        };
        const res = await GetApiParam("/user/getAssignProject", payloadData);
        setReviewStatusData(res?.data?.data);
      } catch (error) {
        console.log(error);
      }
    }
    async function getBookmarkData() {
      try {
        const payloadData = {
          user_id: localData?.id,
        };
        const res = await GetApiParam("/proposer/getBookmark/", payloadData);
        setBookmark(res?.data?.data ? res?.data?.data : []);
      } catch (error) {
        console.log(error);
      }
    }
    if (localData?.id) {
      // Api to get InvestmentTypeData
      const timer = setTimeout(() => {
        getBookmarkData()
        getReviewConfirmationData();
        getDraftData();
        getCategoryData();
      }, 100);
      return () => clearTimeout(timer);
    }

  }, [sidebarOpen]);

  const handleChangeCategory = (event) => {
    setCategoryTypeVal(event.target.value);
    // Reset the current slide to 0
    setCurrentSlide(0);
    setCurrentSlideSub(0);
    setCurrentSlideReview(0);
    setCurrentSlideVoted(0);
    setCurrentSlideRejected(0);
    // Scroll to the first slide
    if (
      carouselRef.current &&
      carouselRefSub.current &&
      carouselRefReview.current &&
      carouselRefVoted.current &&
      carouselRefRejected.current

    ) {
      carouselRef.current.goToSlide(0);
      carouselRefSub.current.goToSlide(0);
      carouselRefReview.current.goToSlide(0);
      carouselRefVoted.current.goToSlide(0);
      carouselRefRejected.current.goToSlide(0)
    }
  };

  // Filter functionality for the dropdown
  useEffect(() => {
    async function getFilterData() {
      try {
        if (categoryTypeVal.length > 0 || categoryTypeVal === "All") {
          var categoryId = "";
          categoryData?.map((val, ind) => {
            if (val.title === categoryTypeVal) {
              categoryId = val.id;
            }
          });

          const localData = JSON.parse(localStorage.getItem("user_data"));

          let filterDataPayload = {
            user_id: localData?.id,
          };
          if (categoryId && categoryId != "All") {
            filterDataPayload.category_id = categoryId;
          } else {
            delete filterDataPayload.category_id;
          }

          const res = await GetApiParam(
            "/proposer/getAssetsList/",
            filterDataPayload
          );

          if (res?.data?.data) {
            setAssetData(res?.data?.data);
          }

          const resSearchProject = await GetApiParam("/user/getAssignProject", filterDataPayload);

          if (resSearchProject?.data?.data) {
            setReviewStatusData(resSearchProject?.data?.data);
          }

          // when user will select All for both the filter
          // if (categoryTypeVal === "All") {
          //   const res = await GetApiParam(
          //     "/proposer/getAssetsList/",
          //     filterDataPayload
          //   );
          //   if (res?.data?.data) {
          //     setAssetData(res?.data?.data);
          //   }
          // }
        }
      } catch (error) {
        console.log(error);
      }
    }
    const timer = setTimeout(() => {
      getFilterData();
    }, 100);
    return () => clearTimeout(timer);
  }, [categoryTypeVal, categoryData]);

  // functionality for Timer
  function setTimer(res) {
    // initialize server clock
    if (res?.data?.length > 0) {
      const serverClockTime = new Date(res?.server_date);
      var timestamp = res?.server_time.split(":");
      serverClockTime.setHours(timestamp[0]);
      serverClockTime.setMinutes(timestamp[1]);
      serverClockTime.setSeconds(timestamp[2]);
      setServerClock(serverClockTime);
    }
  }

  useEffect(() => {
    serverTimeInterval.current = setInterval(() => {
      if (serverClockTime) {
        serverClockTime.setSeconds(serverClockTime.getSeconds() + 1);
        setServerClock(serverClockTime);
        const event = new CustomEvent("clocktime", {
          detail: serverClockTime,
          bubbles: true,
        });
        document.dispatchEvent(event);
      }
    }, 1000);

  }, [serverClockTime])

  /**
   * add book marks
   * @param {*} asset_id
   * @returns
   */
  const bookmarkVal = (asset_id) => {
    return bookmark?.some(
      (item) => item.asset_id === asset_id && item.status === 1
    );
  };

  const projectSearch = async (toSearch) => {
    const payloadData = {
      asset_title: toSearch,
      user_id: localData?.id,
    };

    var categoryId = "";
    categoryData?.map((val, ind) => {
      if (val.title === categoryTypeVal) {
        categoryId = val.id;
      }
    });

    if (categoryId && categoryId != "All") {
      payloadData.category_id = categoryId;
    } else {
      delete payloadData.category_id;
    }

    if (toSearch.length > 2) {
      try {
        const res = await GetApiParam("/proposer/getAssetsList/", payloadData);
        const resSearchProject = await GetApiParam("/user/getAssignProject", payloadData);
        setReviewStatusData(resSearchProject?.data?.data);
        setAssetData(res?.data?.data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const payloadData = {
          user_id: localData?.id,
        };
        const res = await GetApiParam("/proposer/getAssetsList/", payloadData);
        const resSearchProject = await GetApiParam("/user/getAssignProject", payloadData);
        setAssetData(res?.data?.data);
        setReviewStatusData(resSearchProject?.data?.data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  // Product slider
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktopXXl: {
      breakpoint: { max: 3000, min: 2000 },
      items: 5,
    },
    desktopXl: {
      breakpoint: { max: 2000, min: 1400 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1400, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1023, min: 601 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };

  // payload for Draft see all
  const payloadDraft = {
    user_id: localData ? localData.id : "",
    status: PROJECT_STATUS.DRAFT,
  };

  // payload for Submmited see all
  const payloadSubmitted = {
    user_id: localData ? localData.id : "",
    status: PROJECT_STATUS.SUBMITTED,
  };
  // payload for tokenize see all
  const payloadTokenize = {
    user_id: localData ? localData.id : "",
    status: PROJECT_STATUS.TOKENIZE_ASSET,
  };
  /**
  * Getting status values from Asset Data to apply condition for displaying
  */
  useEffect(() => {
    if (reviewStatusData?.length) {
      let review = false;
      let voted = false;
      for (let i = 0; i < reviewStatusData?.length; i++) {
        const votedStatus = reviewStatusData[i]?.dicMemberData?.some((items) => items.status === ASSIGNED_PROJECT_STATUS.APPROVED || items.status === ASSIGNED_PROJECT_STATUS.REJECTED);
        const reviewStatus = reviewStatusData[i]?.dicMemberData?.some((items) => items.status === ASSIGNED_PROJECT_STATUS.INPROGRESS || items.status === ASSIGNED_PROJECT_STATUS.REVIEWED || items.status === ASSIGNED_PROJECT_STATUS.NOT_REVIEWED);

        if (votedStatus) {
          voted = true
        }
        if (reviewStatus) {
          review = true
        }
      }
      if (voted) {
        setIsVoted(true)
      }
      if (review) {
        setIsReview(true)
      }
    }
  }, [reviewStatusData])

  /**
 * Functions to set the toogler 
 */

  const toggleDraftCarouselVisibility = (type) => {
    let carouselObj = { ...isDraftCarouselVisible }
    console.log('carouselObj', carouselObj);
    carouselObj[type] = !carouselObj[type];
    console.log('carouselObj', carouselObj[type]);
    setIsDraftCarouselVisible(carouselObj);
  };
  return (
    <>
      {/* hide for private race-ecosystem */}
      {!isProductionPrivate ?
        <Box className='coming-soon-my-project'>
          <Typography>
            Coming Soon
          </Typography>
        </Box>
        :
        <>
          {/* hide for race-ecosystem */}
          <Box className="myProject-page project-page" >
            <Box className="myProject-drafts-section" mt={3}>
              <Box className="filters-wrap" mb={4} >
                <Grid spacing={1} container>
                  <Grid item xs={12} sm={6}>
                    <Box className="filterSearch-form" width={"100%"}>
                      <Box component={"span"} className="search-icon">
                        <Search />
                      </Box>
                      <InputBase
                        className="filterSearch"
                        placeholder="Search projects"
                        onChange={(e) => projectSearch(e.target.value)}
                        inputProps={{ "aria-label": "search transactions" }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box className="filterCategory-menu-wrap">
                      <Box className="filters-group-wrap">
                        <Box className="select-group" ml={1.5}>
                          {/* new one code.. */}

                          <FormControl
                            className="select-rounded"
                            sx={{ minWidth: 150 }}
                            size="small"
                          >
                            <InputLabel id="demo-controlled-open-select-label">
                              Category
                              <ViewComfy />
                            </InputLabel>
                            <Select
                              labelId="demo-controlled-open-select-label"
                              value={categoryTypeVal}
                              onChange={handleChangeCategory}
                              label="Category"
                            >
                              <MenuItem value="All">All</MenuItem>
                              {categoryData?.map((value, ind) => {
                                return (
                                  <MenuItem key={ind} value={value.title}>
                                    <ListItemText>{value.title === "Asset" ? "Luxury Cars" : value.title}</ListItemText>
                                    <Typography className="menu-icon">
                                      <ImageListItem>
                                        <img
                                          src={`${process.env.REACT_APP_IMAGE_URL}${value.category_icon?.path}`}
                                          srcSet={`${process.env.REACT_APP_IMAGE_URL}${value.category_icon?.path}`}
                                          alt={value.category_icon?.original_name}
                                          loading="lazy"
                                        />
                                      </ImageListItem>
                                    </Typography>
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Box>
                        {/* <Box className="dropdown-group" ml={1.5}>
                          <Button
                            endIcon={<SwapVert />}
                            className="btn-rounded btn-outline"
                          >
                            Sort By: Deadline
                          </Button>
                        </Box> */}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>

            {/* For Draft Projects */}
            <Box className="myProject-submittedPro-section" >
              <Box
                className={`small-heading ${isDraftCarouselVisible.inDraft ? "" : 'open-section-project-page'}`}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Box className="left" display="flex" alignItems="center" zIndex={'2'}>
                  <IconButton className="expand-sections-btn" onClick={() => toggleDraftCarouselVisibility('inDraft')} size="small">
                    {isDraftCarouselVisible.inDraft ? <KeyboardArrowDown /> : <ChevronRight />}
                  </IconButton>
                  <Badge
                    badgeContent={(!loading && assetData?.length > 0) ? assetData.filter((element) => element.status === PROJECT_STATUS.DRAFT).length : 0}
                    color="primary"
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    className="project-counts-badge"
                  >
                    <Typography className="title" component={"h4"}>
                      Drafts
                    </Typography>
                  </Badge>
                </Box>
                {isDraftCarouselVisible.inDraft ?
                  <Box className="right" pr={{ md: 10, xs: 0 }}>
                    <Link
                      to="/user/completeprojects"
                      state={payloadDraft}
                      className="seeAll"
                    >
                      {assetData &&
                        assetData?.some((values) => values.status === PROJECT_STATUS.DRAFT) && (assetData?.filter((values) => values.status === PROJECT_STATUS.DRAFT)?.length) > 3
                        ? "See All"
                        : ""}
                    </Link>
                  </Box>
                  : null}
              </Box>
              {isDraftCarouselVisible.inDraft && (
                <>
                  {!loading && assetData?.length > 0 && assetData?.some((item) => (item.status === PROJECT_STATUS.DRAFT)) ? (
                    <Box className="draftsProducts-wrap">
                      <Carousel
                        className="productThumb-slider space-24"
                        swipeable={false}
                        draggable={false}
                        responsive={responsive}
                        ref={carouselRef}
                        afterChange={(previousSlide, { currentSlide }) => {
                          setCurrentSlide(currentSlide);
                        }}
                        // Set the initial slide to the currentSlide state
                        initialSlide={currentSlide}
                      >
                        {(() => {
                          const projects = [];
                          for (let i = 0; i < assetData?.length; i++) {
                            if (assetData[i].status === PROJECT_STATUS.DRAFT) {
                              const galleryimg = [];
                              // Adding Cover Photo into slider
                              if (assetData[i]?.asset_coverphoto) {
                                galleryimg.push(
                                  `${process.env.REACT_APP_IMAGE_URL}${assetData[i].asset_coverphoto?.path}`
                                );
                              }

                              // Inserting gellery Images Into Slider
                              assetData[i]?.asset_gallery.map((val, ind) => {
                                if (val.title === "gellary") {
                                  galleryimg.push(
                                    `${process.env.REACT_APP_IMAGE_URL}${val?.images?.path}`
                                  );
                                }
                              });
                              projects.push(
                                <Box className="slide-item">
                                  <Cards
                                    id={assetData[i]?.id}
                                    category_id={assetData[i]?.category_id}
                                    title={assetData[i]?.asset_title}
                                    subTitle={assetData[i]?.asset_address}
                                    address={assetData[i]?.owner_information?.owner_address}
                                    investType={
                                      assetData[i]?.investment_type?.investment_name
                                    }
                                    isLoan={assetData[i]?.assets_category?.title}
                                    isDraft={true}
                                    isClock={false}
                                    isPending={
                                      assetData[i]?.status === "Pending" ||
                                        assetData[i]?.status === "inreview"
                                        ? true
                                        : false
                                    }
                                    isDelete={false}
                                    isSaved={false}
                                    imgSrc={galleryimg}
                                    isSaveDeal={false}
                                    link={`/project-details/${assetData[i]?.id}`}
                                    investment_expiry_time={
                                      assetData[i]?.investment_expiry_time
                                    }
                                    server_date={serverDate}
                                    server_time={serverTime}
                                    // projectCallback={(e) => projectDetails(e)}
                                    assetDataDetail={assetData[i]}
                                    projectProgress={assetData[i]?.project_process}
                                    investment_type_id={
                                      assetData[i]?.investment_type_id
                                    }
                                  />
                                </Box>
                              );
                            }
                          }
                          return projects;
                        })()}
                      </Carousel>
                      {/* </Slider> */}
                    </Box>
                  ) : loading ? (
                    <Box className="draftsProducts-wrap">
                      <Carousel
                        className="productThumb-slider space-24"
                        swipeable={false}
                        draggable={false}
                        responsive={responsive}
                        ref={carouselRef}
                        afterChange={(previousSlide, { currentSlide }) => {
                          setCurrentSlide(currentSlide);
                        }}
                        // Set the initial slide to the currentSlide state
                        initialSlide={currentSlide}
                      >
                        {Array(4)
                          .fill()
                          .map((item, index) => (
                            <Box className="slide-item">
                              <CardSkeleton key={index} />
                            </Box>
                          ))}
                      </Carousel>
                    </Box>
                  ) : (
                    <Box style={{ marginLeft: '32px' }}>
                      <Typography className="title" component={"h4"}>
                        No Projects Found
                      </Typography>
                    </Box>
                  )}
                </>
              )}
            </Box>

            {/* For Tokenize Projects */}
            <Box className="myProject-submittedPro-section" >
              <Box
                className={`small-heading ${isDraftCarouselVisible.inTokenize ? "" : 'open-section-project-page'}`}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Box className="left" display="flex" alignItems="center" zIndex={'2'}>
                  <IconButton className="expand-sections-btn" onClick={() => toggleDraftCarouselVisibility('inTokenize')} size="small">
                    {isDraftCarouselVisible.inTokenize ? <KeyboardArrowDown /> : <ChevronRight />}
                  </IconButton>
                  <Badge
                    badgeContent={(!loading && assetData?.length > 0) ? assetData.filter((element) => (element.status === PROJECT_STATUS.SUBMITTED && element.project_status === PROJECT_STATUS.TOKENIZE_ASSET)).length : 0}
                    color="primary"
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    className="project-counts-badge"
                  >
                    <Typography className="title" component={"h4"}>
                      Tokenized Projects
                    </Typography>
                  </Badge>
                </Box>
                {/* {isDraftCarouselVisible.inTokenize ?
                  <Box className="right" pr={10}>
                    <Link
                      to="/user/completeprojects"
                      state={payloadTokenize}
                      className="seeAll"
                    >
                      {assetData &&
                        assetData?.some(
                          (values) => values.project_status === PROJECT_STATUS.TOKENIZE_ASSET
                        ) && (assetData?.filter((values) => values.project_status === PROJECT_STATUS.TOKENIZE_ASSET)?.length) > 3
                        ? "See All"
                        : ""}
                    </Link>
                  </Box>
                  : null} */}
              </Box>
              {isDraftCarouselVisible.inTokenize && (
                <>
                  {!loading && assetData?.length > 0 && assetData?.some((item) => (item.status === PROJECT_STATUS.SUBMITTED && item.project_status === PROJECT_STATUS.TOKENIZE_ASSET)) ? (
                    <Box className="myPorject-submittedPro-wrap">
                      <Carousel
                        className="productThumb-slider space-24"
                        swipeable={false}
                        draggable={false}
                        responsive={responsive}
                        ref={carouselRefSub}
                        afterChange={(previousSlide, { currentSlideSub }) => {
                          setCurrentSlideSub(currentSlideSub);
                        }}
                        // Set the initial slide to the currentSlide state
                        initialSlide={currentSlideSub}
                      >
                        {(() => {
                          const projects = [];
                          for (let i = 0; i < assetData?.length; i++) {
                            if (!assetData[i].other_category) {
                              if (
                                assetData[i].status === PROJECT_STATUS.SUBMITTED &&
                                assetData[i].project_status === PROJECT_STATUS.TOKENIZE_ASSET
                              ) {
                                const galleryimg = [];

                                // Adding Cover Photo into slider
                                if (assetData[i]?.asset_coverphoto) {
                                  galleryimg.push(
                                    `${process.env.REACT_APP_IMAGE_URL}${assetData[i].asset_coverphoto?.path}`
                                  );
                                }

                                // Inserting gellery Images Into Slider
                                assetData[i]?.asset_gallery.map((val, ind) => {
                                  if (val.title === "gellary") {
                                    galleryimg.push(
                                      `${process.env.REACT_APP_IMAGE_URL}${val?.images?.path}`
                                    );
                                  }
                                });
                                projects.push(
                                  <Box className="slide-item">
                                    <Cards
                                      id={assetData[i]?.id}
                                      title={assetData[i]?.asset_title}
                                      category_id={assetData[i]?.category_id}
                                      subTitle={assetData[i]?.asset_address}
                                      address={assetData[i]?.owner_information?.owner_address}
                                      investType={
                                        assetData[i]?.investment_type?.investment_name
                                      }
                                      isLoan={assetData[i]?.assets_category?.title}
                                      isDraft={false}
                                      isClock={false}
                                      isPending={
                                        assetData[i]?.status === "Pending" ||
                                          assetData[i]?.status === "inreview"
                                          ? true
                                          : false
                                      }
                                      isDelete={
                                        assetData[i]?.status === "Rejected"
                                          ? true
                                          : false
                                      }
                                      isSaved={false}
                                      imgSrc={galleryimg}
                                      isSaveDeal={false}
                                      link={`/project-details/${assetData[i]?.id}`}
                                      investment_expiry_time={
                                        assetData[i]?.investment_expiry_time
                                      }
                                      server_date={serverDate}
                                      server_time={serverTime}
                                      bookMarkStatus={bookmarkVal(assetData[i]?.id)}
                                      assetDataDetail={assetData[i]}
                                    />
                                  </Box>
                                );
                              }
                            }
                          }
                          return projects;
                        })()}
                      </Carousel>
                      {/* </Slider> */}
                    </Box>
                  ) : loading ? (
                    <Box className="draftsProducts-wrap">
                      <Carousel
                        className="productThumb-slider space-24"
                        swipeable={false}
                        draggable={false}
                        responsive={responsive}
                        ref={carouselRef}
                        afterChange={(previousSlide, { currentSlide }) => {
                          setCurrentSlide(currentSlide);
                        }}
                        // Set the initial slide to the currentSlide state
                        initialSlide={currentSlide}
                      >
                        {Array(4)
                          .fill()
                          .map((item, index) => (
                            <Box className="slide-item">
                              <CardSkeleton key={index} />
                            </Box>
                          ))}
                      </Carousel>
                    </Box>
                  ) : (
                    <Box style={{ marginLeft: '32px' }}>
                      <Typography className="title" component={"h4"}>
                        No Projects Found
                      </Typography>
                    </Box>
                  )}
                </>
              )}
            </Box>

            {/* For Submitted Projects */}
            <Box className="myProject-submittedPro-section" >
              <Box
                className={`small-heading ${isDraftCarouselVisible.inSubmitted ? "" : 'open-section-project-page'}`}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Box className="left" display="flex" alignItems="center" zIndex={'2'}>
                  <IconButton className="expand-sections-btn" onClick={() => toggleDraftCarouselVisibility('inSubmitted')} size="small">
                    {isDraftCarouselVisible.inSubmitted ? <KeyboardArrowDown /> : <ChevronRight />}
                  </IconButton>
                  <Badge
                    badgeContent={(!loading && assetData?.length > 0) ? assetData.filter((element) => (element.status === PROJECT_STATUS.SUBMITTED && element.project_status === PROJECT_STATUS.SUBMITTED && element.project_status !== PROJECT_STATUS.TOKENIZE_ASSET)).length : 0}
                    color="primary"
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    className="project-counts-badge"
                  >
                    <Typography className="title" component={"h4"}>
                      Submitted Projects
                    </Typography>
                  </Badge>
                </Box>
                {isDraftCarouselVisible.inSubmitted ?
                  <Box className="right" pr={{ md: 10, xs: 0 }}>
                    <Link
                      to="/user/completeprojects"
                      state={payloadSubmitted}
                      className="seeAll"
                    >
                      {assetData &&
                        assetData?.some(
                          (values) => values.status === PROJECT_STATUS.SUBMITTED && values.project_status !== PROJECT_STATUS.TOKENIZE_ASSET
                        ) && (assetData?.filter((values) => values.status === PROJECT_STATUS.SUBMITTED && values.project_status === PROJECT_STATUS.SUBMITTED && values.project_status !== PROJECT_STATUS.TOKENIZE_ASSET)?.length) > 3
                        ? "See All"
                        : ""}
                    </Link>
                  </Box>
                  : null}
              </Box>
              {isDraftCarouselVisible.inSubmitted && (
                <>
                  {!loading && assetData?.length > 0 && assetData?.some((item) => (item.status === PROJECT_STATUS.SUBMITTED && item.project_status === PROJECT_STATUS.SUBMITTED)) ? (
                    <Box className="myPorject-submittedPro-wrap">
                      <Carousel
                        className="productThumb-slider space-24"
                        swipeable={false}
                        draggable={false}
                        responsive={responsive}
                        ref={carouselRefSub}
                        afterChange={(previousSlide, { currentSlideSub }) => {
                          setCurrentSlideSub(currentSlideSub);
                        }}
                        // Set the initial slide to the currentSlide state
                        initialSlide={currentSlideSub}
                      >
                        {(() => {
                          const projects = [];
                          for (let i = 0; i < assetData?.length; i++) {
                            if (!assetData[i].other_category) {
                              if (
                                assetData[i].status === PROJECT_STATUS.SUBMITTED &&
                                assetData[i].project_status === PROJECT_STATUS.SUBMITTED
                              ) {
                                const galleryimg = [];

                                // Adding Cover Photo into slider
                                if (assetData[i]?.asset_coverphoto) {
                                  galleryimg.push(
                                    `${process.env.REACT_APP_IMAGE_URL}${assetData[i].asset_coverphoto?.path}`
                                  );
                                }

                                // Inserting gellery Images Into Slider
                                assetData[i]?.asset_gallery.map((val, ind) => {
                                  if (val.title === "gellary") {
                                    galleryimg.push(
                                      `${process.env.REACT_APP_IMAGE_URL}${val?.images?.path}`
                                    );
                                  }
                                });
                                projects.push(
                                  <Box className="slide-item">
                                    <Cards
                                      id={assetData[i]?.id}
                                      title={assetData[i]?.asset_title}
                                      category_id={assetData[i]?.category_id}
                                      subTitle={assetData[i]?.asset_address}
                                      address={assetData[i]?.owner_information?.owner_address}
                                      investType={
                                        assetData[i]?.investment_type?.investment_name
                                      }
                                      isLoan={assetData[i]?.assets_category?.title}
                                      isDraft={false}
                                      isClock={false}
                                      isPending={
                                        assetData[i]?.status === "Pending" ||
                                          assetData[i]?.status === "inreview"
                                          ? true
                                          : false
                                      }
                                      isDelete={
                                        assetData[i]?.status === "Rejected"
                                          ? true
                                          : false
                                      }
                                      isSaved={false}
                                      imgSrc={galleryimg}
                                      isSaveDeal={false}
                                      link={`/project-details/${assetData[i]?.id}`}
                                      investment_expiry_time={
                                        assetData[i]?.investment_expiry_time
                                      }
                                      server_date={serverDate}
                                      server_time={serverTime}
                                      bookMarkStatus={bookmarkVal(assetData[i]?.id)}
                                      assetDataDetail={assetData[i]}
                                    />
                                  </Box>
                                );
                              }
                            }
                          }
                          return projects;
                        })()}
                      </Carousel>
                      {/* </Slider> */}
                    </Box>
                  ) : loading ? (
                    <Box className="draftsProducts-wrap">
                      <Carousel
                        className="productThumb-slider space-24"
                        swipeable={false}
                        draggable={false}
                        responsive={responsive}
                        ref={carouselRef}
                        afterChange={(previousSlide, { currentSlide }) => {
                          setCurrentSlide(currentSlide);
                        }}
                        // Set the initial slide to the currentSlide state
                        initialSlide={currentSlide}
                      >
                        {Array(4)
                          .fill()
                          .map((item, index) => (
                            <Box className="slide-item">
                              <CardSkeleton key={index} />
                            </Box>
                          ))}
                      </Carousel>
                    </Box>
                  ) : (
                    <Box style={{ marginLeft: '32px' }}>
                      <Typography className="title" component={"h4"}>
                        No Projects Found
                      </Typography>
                    </Box>
                  )}
                </>
              )}
            </Box>

            {/* For in review Projects */}
            <Box className="myProject-submittedPro-section" >
              <Box
                className={`small-heading ${isDraftCarouselVisible.inReview ? "" : 'open-section-project-page'}`}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Box className="left" display="flex" alignItems="center" zIndex={'2'}>
                  <IconButton className="expand-sections-btn" onClick={() => toggleDraftCarouselVisibility('inReview')} size="small">
                    {isDraftCarouselVisible.inReview ? <KeyboardArrowDown /> : <ChevronRight />}
                  </IconButton>
                  <Badge
                    badgeContent={(!loading && reviewStatusData?.length > 0) ? reviewStatusData.filter((element) => element?.assetDetails.project_status === 3).length : 0}
                    color="primary"
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    className="project-counts-badge"
                  >
                    <Typography className="title" component={"h4"}>
                      {" "}
                      InReview Projects
                    </Typography>
                  </Badge>
                </Box>
              </Box>
              {isDraftCarouselVisible.inReview && (
                <>
                  {!loading && reviewStatusData?.length > 0 && isReview ? (
                    <Box className="myPorject-submittedPro-wrap">
                      {/* <Slider {...settings} > */}
                      <Carousel
                        className="productThumb-slider space-24"
                        swipeable={false}
                        draggable={false}
                        responsive={responsive}
                        ref={carouselRefReview}
                        afterChange={(previousSlide, { currentSlideReview }) => {
                          setCurrentSlideSub(currentSlideReview);
                        }}
                        // Set the initial slide to the currentSlide state
                        initialSlide={currentSlideReview}
                      >
                        {(() => {
                          const projects = [];
                          for (let i = 0; i < reviewStatusData?.length; i++) {
                            if (!reviewStatusData[i]?.other_category) {
                              if (reviewStatusData[i]?.assetDetails.project_status === 3) {
                                /**
                                 * Checking Condition
                                 */
                                let projectStatus =
                                  reviewStatusData[i].status !==
                                  ASSIGNED_PROJECT_STATUS.APPROVED &&
                                  reviewStatusData[i].status !==
                                  ASSIGNED_PROJECT_STATUS.REJECTED;

                                let dicMemberCount = reviewStatusData[
                                  i
                                ].dicMemberData.every(
                                  (val) =>
                                    val.status !== ASSIGNED_PROJECT_STATUS.APPROVED &&
                                    val.status !== ASSIGNED_PROJECT_STATUS.REJECTED
                                );

                                if (projectStatus && dicMemberCount) {
                                  const galleryimg = [];

                                  // Adding Cover Photo into slider
                                  // if (assetData[i]?.asset_coverphoto) {
                                  //   galleryimg.push(
                                  //     `${process.env.REACT_APP_IMAGE_URL}${assetData[i].asset_coverphoto?.path}`
                                  //   );
                                  // }

                                  // Adding Cover Photo into slider 
                                  reviewStatusData[i]?.assetDetails?.asset_gallery.forEach((val, ind) => {
                                    if (val.title === "gellary" && val.image_order === 1) {
                                      galleryimg.push(
                                        `${process.env.REACT_APP_IMAGE_URL}${val?.images?.path}`
                                      );
                                    }
                                  });


                                  // Inserting gellery Images Into Slider
                                  reviewStatusData[i]?.assetDetails?.asset_gallery.map(
                                    (val, ind) => {
                                      if (val.title === "gellary" && ind > 0) {
                                        galleryimg.push(
                                          `${process.env.REACT_APP_IMAGE_URL}${val?.images?.path}`
                                        );
                                      }
                                    }
                                  );
                                  projects.push(
                                    <Box className="slide-item">
                                      <Cards
                                        id={reviewStatusData[i]?.assetDetails?.id}
                                        title={
                                          reviewStatusData[i]?.assetDetails?.asset_title
                                        }
                                        category_id={reviewStatusData[i]?.assetDetails?.category_id}
                                        subTitle={
                                          reviewStatusData[i]?.assetDetails
                                            .asset_address
                                        }

                                        address={reviewStatusData[i]?.assetDetails?.owner_information?.owner_address}
                                        investType={
                                          reviewStatusData[i]?.assetDetails
                                            ?.investment_type?.investment_name
                                        }
                                        isLoan={
                                          reviewStatusData[i]?.assetDetails
                                            ?.assets_category?.title
                                        }
                                        isDraft={false}
                                        isClock={false}
                                        isPending={false}
                                        isDelete={false}
                                        isSaved={false}
                                        imgSrc={galleryimg}
                                        isSaveDeal={false}
                                        link={`/project-details-inreview/${reviewStatusData[i]?.asset_id}`}
                                        investment_expiry_time={
                                          reviewStatusData[i]?.assetDetails
                                            ?.investment_expiry_time
                                        }
                                        server_date={serverDate}
                                        server_time={serverTime}
                                        bookMarkStatus={bookmarkVal(reviewStatusData[i]?.assetDetails?.id)}
                                        state="inReview"
                                        assetDataDetail={reviewStatusData[i]?.assetDetails}
                                      />
                                    </Box>
                                  );
                                }
                              }
                            }
                          }
                          return projects;
                        })()}
                      </Carousel>
                    </Box>
                  ) : loading ? (
                    <Box className="draftsProducts-wrap">
                      <Carousel
                        className="productThumb-slider space-24"
                        swipeable={false}
                        draggable={false}
                        responsive={responsive}
                        ref={carouselRefReview}
                        afterChange={(previousSlide, { currentSlide }) => {
                          setCurrentSlide(currentSlide);
                        }}
                        // Set the initial slide to the currentSlide state
                        initialSlide={currentSlide}
                      >
                        {Array(4)
                          .fill()
                          .map((item, index) => (
                            <Box className="slide-item">
                              <CardSkeleton key={index} />
                            </Box>
                          ))}
                      </Carousel>
                    </Box>
                  ) : (
                    <Box style={{ marginLeft: '32px' }}>
                      <Typography className="title" component={"h4"}>
                        No Projects Found
                      </Typography>
                    </Box>
                  )}
                </>
              )}
            </Box>

            {/* For in vote Projects */}
            <Box className="myProject-submittedPro-section" >
              <Box
                className={`small-heading ${isDraftCarouselVisible.inVote ? "" : 'open-section-project-page'}`}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Box className="left" display="flex" alignItems="center" zIndex={'2'}>
                  <IconButton className="expand-sections-btn" onClick={() => toggleDraftCarouselVisibility('inVote')} size="small">
                    {isDraftCarouselVisible.inVote ? <KeyboardArrowDown /> : <ChevronRight />}
                  </IconButton>
                  <Badge
                    badgeContent={(!loading && reviewStatusData?.length > 0) ? reviewStatusData.filter((element) => (!element?.assetDetails?.is_publish) && !element?.assetDetails?.is_onboard && element?.assetDetails?.status !== PROJECT_STATUS.REJECTED && element?.assetDetails.project_status === 4).length : 0}
                    color="primary"
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    className="project-counts-badge"
                  >
                    <Typography className="title" component={"h4"}>
                      {" "}
                      In-Vote Projects
                    </Typography>
                  </Badge>
                </Box>
              </Box>
              {isDraftCarouselVisible.inVote && (
                <>
                  {!loading && reviewStatusData?.length > 0 ? (
                    <Box className="myPorject-submittedPro-wrap">
                      <Carousel
                        className="productThumb-slider space-24"
                        swipeable={false}
                        draggable={false}
                        responsive={responsive}
                        ref={carouselRefVoted}
                        afterChange={(previousSlide, { currentSlideVoted }) => {
                          setCurrentSlideSub(currentSlideVoted);
                        }}
                        // Set the initial slide to the currentSlide state
                        initialSlide={currentSlideVoted}
                      >
                        {(() => {
                          const projects = [];
                          for (let i = 0; i < reviewStatusData?.length; i++) {
                            if (!reviewStatusData[i]?.other_category) {

                              let userStatus = false
                              let votingStatus = false

                              /**
                               * Logic to check the Live and inVoting Status of Projects
                               */
                              if (reviewStatusData[i]?.dicMemberData?.length >= reviewStatusData[i]?.dicMember?.length) {
                                userStatus = reviewStatusData[i]?.dicMemberData?.some(
                                  (elem) => elem.status === ASSIGNED_PROJECT_STATUS.APPROVED
                                    || elem.status === ASSIGNED_PROJECT_STATUS.REJECTED
                                );
                              }
                              if ((!reviewStatusData[i]?.assetDetails?.is_publish) && !reviewStatusData[i]?.assetDetails?.is_onboard && reviewStatusData[i]?.assetDetails?.status !== PROJECT_STATUS.REJECTED && reviewStatusData[i]?.assetDetails.project_status === 4) {
                                const galleryimg = [];

                                // Adding Cover Photo into slider
                                // if (reviewStatusData[i]?.asset_coverphoto) {
                                //   galleryimg.push(
                                //     `${process.env.REACT_APP_IMAGE_URL}${reviewStatusData[i].asset_coverphoto?.path}`
                                //   );
                                // }

                                // Adding Cover Photo into slider 
                                reviewStatusData[i]?.assetDetails?.asset_gallery.forEach((val, ind) => {
                                  if (val.title === "gellary" && val.image_order === 1) {
                                    galleryimg.push(
                                      `${process.env.REACT_APP_IMAGE_URL}${val?.images?.path}`
                                    );
                                  }
                                });


                                // Inserting gellery Images Into Slider
                                reviewStatusData[i]?.assetDetails?.asset_gallery.map(
                                  (val, ind) => {
                                    if (val.title === "gellary" && ind > 0) {
                                      galleryimg.push(
                                        `${process.env.REACT_APP_IMAGE_URL}${val?.images?.path}`
                                      );
                                    }
                                  }
                                );
                                projects.push(
                                  <Box className="slide-item">
                                    <Cards
                                      id={reviewStatusData[i]?.assetDetails?.id}
                                      title={
                                        reviewStatusData[i]?.assetDetails?.asset_title
                                      }
                                      category_id={reviewStatusData[i]?.assetDetails?.category_id}
                                      subTitle={
                                        reviewStatusData[i]?.assetDetails
                                          .asset_address
                                      }
                                      address={reviewStatusData[i]?.assetDetails?.owner_information?.owner_address}
                                      investType={
                                        reviewStatusData[i]?.assetDetails
                                          ?.investment_type?.investment_name
                                      }
                                      isLoan={
                                        reviewStatusData[i]?.assetDetails
                                          ?.assets_category?.title
                                      }
                                      isDraft={false}
                                      isClock={false}
                                      isPending={false}
                                      isDelete={false}
                                      isSaved={false}
                                      imgSrc={galleryimg}
                                      isSaveDeal={false}
                                      link={`/project-details/${reviewStatusData[i]?.asset_id}`}
                                      investment_expiry_time={
                                        reviewStatusData[i]?.assetDetails
                                          ?.investment_expiry_time
                                      }
                                      server_date={serverDate}
                                      server_time={serverTime}
                                      bookMarkStatus={bookmarkVal(reviewStatusData[i]?.id)}
                                      status={reviewStatusData[i]?.assetDetails?.status}
                                      is_publish={reviewStatusData[i]?.assetDetails?.is_publish}
                                      is_voted={true}
                                      is_onboard={reviewStatusData[i]?.assetDetails?.is_onboard}
                                      assetDataDetail={reviewStatusData[i]?.assetDetails}
                                    />
                                  </Box>
                                );
                              }
                            }
                          }
                          if (projects?.length)
                            return projects;
                          else {
                            return <Box ml={'45px'}>
                              <Typography className="title" component={"h4"}>
                                No Projects Found
                              </Typography>
                            </Box>
                          }
                        })()}
                      </Carousel>
                    </Box>
                  ) : loading ? (
                    <Box className="draftsProducts-wrap">
                      <Carousel
                        className="productThumb-slider space-24"
                        swipeable={false}
                        draggable={false}
                        responsive={responsive}
                        ref={carouselRefVoted}
                        afterChange={(previousSlide, { currentSlide }) => {
                          setCurrentSlide(currentSlide);
                        }}
                        initialSlide={currentSlide}
                      >
                        {Array(4)
                          .fill()
                          .map((item, index) => (
                            <Box className="slide-item">
                              <CardSkeleton key={index} />
                            </Box>
                          ))}
                      </Carousel>
                    </Box>
                  ) : (
                    <Box style={{ marginLeft: '32px' }}>
                      <Typography className="title" component={"h4"}>
                        No Projects Found
                      </Typography>
                    </Box>
                  )}
                </>
              )}
            </Box>

            {/* For Approve Projects */}
            <Box className="myProject-submittedPro-section" >
              <Box
                className={`small-heading ${isDraftCarouselVisible.inPublish ? "" : 'open-section-project-page'}`}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Box className="left" display="flex" alignItems="center" zIndex={'2'}>
                  <IconButton className="expand-sections-btn" onClick={() => toggleDraftCarouselVisibility('inPublish')} size="small">
                    {isDraftCarouselVisible.inPublish ? <KeyboardArrowDown /> : <ChevronRight />}
                  </IconButton>
                  <Badge
                    badgeContent={(!loading && reviewStatusData?.length > 0) ? reviewStatusData.filter((element) => (element?.assetDetails?.is_publish) && !element?.assetDetails?.is_onboard && element?.assetDetails?.status !== PROJECT_STATUS.REJECTED).length : 0}
                    color="primary"
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    className="project-counts-badge"
                  >
                    <Typography className="title" component={"h4"}>
                      {" "}
                      Approved  Projects
                    </Typography>
                  </Badge>
                </Box>
              </Box>
              {isDraftCarouselVisible.inPublish && (
                <>
                  {!loading && reviewStatusData?.length > 0 ? (
                    <Box className="myPorject-submittedPro-wrap">
                      <Carousel
                        className="productThumb-slider space-24"
                        swipeable={false}
                        draggable={false}
                        responsive={responsive}
                        ref={carouselRefVoted}
                        afterChange={(previousSlide, { currentSlideVoted }) => {
                          setCurrentSlideSub(currentSlideVoted);
                        }}
                        // Set the initial slide to the currentSlide state
                        initialSlide={currentSlideVoted}
                      >
                        {(() => {
                          const projects = [];
                          for (let i = 0; i < reviewStatusData?.length; i++) {
                            if (!reviewStatusData[i]?.other_category) {

                              let userStatus = false
                              let votingStatus = false

                              /**
                               * Logic to check the Live and inVoting Status of Projects
                               */
                              if (reviewStatusData[i]?.dicMemberData?.length >= reviewStatusData[i]?.dicMember?.length) {
                                userStatus = reviewStatusData[i]?.dicMemberData?.some(
                                  (elem) => elem.status === ASSIGNED_PROJECT_STATUS.APPROVED
                                    || elem.status === ASSIGNED_PROJECT_STATUS.REJECTED
                                );
                              }

                              if ((reviewStatusData[i]?.assetDetails?.is_publish) && !reviewStatusData[i]?.assetDetails?.is_onboard && reviewStatusData[i]?.assetDetails?.status !== PROJECT_STATUS.REJECTED) {
                                const galleryimg = [];

                                // Adding Cover Photo into slider
                                // if (reviewStatusData[i]?.asset_coverphoto) {
                                //   galleryimg.push(
                                //     `${process.env.REACT_APP_IMAGE_URL}${reviewStatusData[i].asset_coverphoto?.path}`
                                //   );
                                // }

                                // Adding Cover Photo into slider 
                                reviewStatusData[i]?.assetDetails?.asset_gallery.forEach((val, ind) => {
                                  if (val.title === "gellary" && val.image_order === 1) {
                                    galleryimg.push(
                                      `${process.env.REACT_APP_IMAGE_URL}${val?.images?.path}`
                                    );
                                  }
                                });

                                // Inserting gellery Images Into Slider
                                reviewStatusData[i]?.assetDetails?.asset_gallery.map(
                                  (val, ind) => {
                                    if (val.title === "gellary" && ind > 0) {
                                      galleryimg.push(
                                        `${process.env.REACT_APP_IMAGE_URL}${val?.images?.path}`
                                      );
                                    }
                                  }
                                );
                                projects.push(
                                  <Box className="slide-item">
                                    <Cards
                                      id={reviewStatusData[i]?.assetDetails?.id}
                                      title={
                                        reviewStatusData[i]?.assetDetails?.asset_title
                                      }
                                      category_id={reviewStatusData[i]?.assetDetails?.category_id}
                                      subTitle={
                                        reviewStatusData[i]?.assetDetails
                                          .asset_address
                                      }
                                      address={reviewStatusData[i]?.assetDetails?.owner_information?.owner_address}
                                      investType={
                                        reviewStatusData[i]?.assetDetails
                                          ?.investment_type?.investment_name
                                      }
                                      isLoan={
                                        reviewStatusData[i]?.assetDetails
                                          ?.assets_category?.title
                                      }
                                      isDraft={false}
                                      isClock={false}
                                      isPending={false}
                                      isDelete={false}
                                      isSaved={false}
                                      imgSrc={galleryimg}
                                      isSaveDeal={false}
                                      link={`/project-details/${reviewStatusData[i]?.asset_id}`}
                                      investment_expiry_time={
                                        reviewStatusData[i]?.assetDetails
                                          ?.investment_expiry_time
                                      }
                                      server_date={serverDate}
                                      server_time={serverTime}
                                      bookMarkStatus={bookmarkVal(reviewStatusData[i]?.id)}
                                      status={reviewStatusData[i]?.assetDetails?.status}
                                      is_publish={reviewStatusData[i]?.assetDetails?.is_publish}
                                      is_voted={true}
                                      is_onboard={reviewStatusData[i]?.assetDetails?.is_onboard}
                                      assetDataDetail={reviewStatusData[i]?.assetDetails}
                                    />
                                  </Box>
                                );
                              }
                            }
                          }
                          if (projects?.length)
                            return projects;
                          else {
                            return <Box ml={'45px'}>
                              <Typography className="title" component={"h4"}>
                                No Projects Found
                              </Typography>
                            </Box>
                          }
                        })()}
                      </Carousel>
                    </Box>
                  ) : loading ? (
                    <Box className="draftsProducts-wrap">
                      <Carousel
                        className="productThumb-slider space-24"
                        swipeable={false}
                        draggable={false}
                        responsive={responsive}
                        ref={carouselRefVoted}
                        afterChange={(previousSlide, { currentSlide }) => {
                          setCurrentSlide(currentSlide);
                        }}
                        initialSlide={currentSlide}
                      >
                        {Array(4)
                          .fill()
                          .map((item, index) => (
                            <Box className="slide-item">
                              <CardSkeleton key={index} />
                            </Box>
                          ))}
                      </Carousel>
                    </Box>
                  ) : (
                    <Box style={{ marginLeft: '32px' }}>
                      <Typography className="title" component={"h4"}>
                        No Projects Found
                      </Typography>
                    </Box>
                  )}
                </>
              )}
            </Box>

            {/* For Live Projects */}
            <Box className="myProject-submittedPro-section" >
              <Box
                className={`small-heading ${isDraftCarouselVisible.inLive ? "" : 'open-section-project-page'}`}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Box className="left" display="flex" alignItems="center" zIndex={'2'}>
                  <IconButton className="expand-sections-btn" onClick={() => toggleDraftCarouselVisibility('inLive')} size="small">
                    {isDraftCarouselVisible.inLive ? <KeyboardArrowDown /> : <ChevronRight />}
                  </IconButton>
                  <Badge
                    badgeContent={(!loading && reviewStatusData?.length > 0) ? reviewStatusData.filter((element) => (element?.assetDetails?.is_onboard) && element?.assetDetails?.status !== PROJECT_STATUS.REJECTED).length : 0}
                    color="primary"
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    className="project-counts-badge"
                  >
                    <Typography className="title" component={"h4"}>
                      {" "}
                      Live Projects
                    </Typography>
                  </Badge>
                </Box>
              </Box>

              {isDraftCarouselVisible.inLive && (
                <>
                  {!loading && reviewStatusData?.length > 0 ? (
                    <Box className="myPorject-submittedPro-wrap">
                      <Carousel
                        className="productThumb-slider space-24"
                        swipeable={false}
                        draggable={false}
                        responsive={responsive}
                        ref={carouselRefVoted}
                        afterChange={(previousSlide, { currentSlideVoted }) => {
                          setCurrentSlideSub(currentSlideVoted);
                        }}
                        // Set the initial slide to the currentSlide state
                        initialSlide={currentSlideVoted}
                      >
                        {(() => {
                          const projects = [];
                          for (let i = 0; i < reviewStatusData?.length; i++) {
                            if (!reviewStatusData[i]?.other_category) {

                              let userStatus = false
                              let votingStatus = false

                              /**
                               * Logic to check the Live and inVoting Status of Projects
                               */
                              if (reviewStatusData[i]?.dicMemberData?.length >= reviewStatusData[i]?.dicMember?.length) {
                                userStatus = reviewStatusData[i]?.dicMemberData?.some(
                                  (elem) => elem.status === ASSIGNED_PROJECT_STATUS.APPROVED
                                    || elem.status === ASSIGNED_PROJECT_STATUS.REJECTED
                                );
                              }
                              if ((reviewStatusData[i]?.assetDetails?.is_onboard) && reviewStatusData[i]?.assetDetails?.status !== PROJECT_STATUS.REJECTED) {
                                const galleryimg = [];

                                // Adding Cover Photo into slider
                                // if (reviewStatusData[i]?.asset_coverphoto) {
                                //   galleryimg.push(
                                //     `${process.env.REACT_APP_IMAGE_URL}${reviewStatusData[i].asset_coverphoto?.path}`
                                //   );
                                // }

                                // Adding Cover Photo into slider 
                                reviewStatusData[i]?.assetDetails?.asset_gallery.forEach((val, ind) => {
                                  if (val.title === "gellary" && val.image_order === 1) {
                                    galleryimg.push(
                                      `${process.env.REACT_APP_IMAGE_URL}${val?.images?.path}`
                                    );
                                  }
                                });


                                // Inserting gellery Images Into Slider
                                reviewStatusData[i]?.assetDetails?.asset_gallery.map(
                                  (val, ind) => {
                                    if (val.title === "gellary" && ind > 0) {
                                      galleryimg.push(
                                        `${process.env.REACT_APP_IMAGE_URL}${val?.images?.path}`
                                      );
                                    }
                                  }
                                );
                                projects.push(
                                  <Box className="slide-item">
                                    <Cards
                                      id={reviewStatusData[i]?.assetDetails?.id}
                                      title={
                                        reviewStatusData[i]?.assetDetails?.asset_title
                                      }
                                      category_id={reviewStatusData[i]?.assetDetails?.category_id}
                                      subTitle={
                                        reviewStatusData[i]?.assetDetails
                                          .asset_address
                                      }
                                      address={reviewStatusData[i]?.assetDetails?.owner_information?.owner_address}
                                      investType={
                                        reviewStatusData[i]?.assetDetails
                                          ?.investment_type?.investment_name
                                      }
                                      isLoan={
                                        reviewStatusData[i]?.assetDetails
                                          ?.assets_category?.title
                                      }
                                      isDraft={false}
                                      isClock={false}
                                      isPending={false}
                                      isDelete={false}
                                      isSaved={false}
                                      imgSrc={galleryimg}
                                      isSaveDeal={false}
                                      link={`/project-details/${reviewStatusData[i]?.asset_id}`}
                                      investment_expiry_time={
                                        reviewStatusData[i]?.assetDetails
                                          ?.investment_expiry_time
                                      }
                                      server_date={serverDate}
                                      server_time={serverTime}
                                      bookMarkStatus={bookmarkVal(reviewStatusData[i]?.id)}
                                      status={reviewStatusData[i]?.assetDetails?.status}
                                      is_publish={reviewStatusData[i]?.assetDetails?.is_publish}
                                      is_voted={true}
                                      is_onboard={reviewStatusData[i]?.assetDetails?.is_onboard}
                                      assetDataDetail={reviewStatusData[i]?.assetDetails}
                                      is_released={true}
                                    />
                                  </Box>
                                );
                              }
                            }
                          }
                          if (projects?.length)
                            return projects;
                          else {
                            return <Box ml={'45px'}>
                              <Typography className="title" component={"h4"}>
                                No Projects Found
                              </Typography>
                            </Box>
                          }
                        })()}
                      </Carousel>
                    </Box>
                  ) : loading ? (
                    <Box className="draftsProducts-wrap">
                      <Carousel
                        className="productThumb-slider space-24"
                        swipeable={false}
                        draggable={false}
                        responsive={responsive}
                        ref={carouselRefVoted}
                        afterChange={(previousSlide, { currentSlide }) => {
                          setCurrentSlide(currentSlide);
                        }}
                        initialSlide={currentSlide}
                      >
                        {Array(4)
                          .fill()
                          .map((item, index) => (
                            <Box className="slide-item">
                              <CardSkeleton key={index} />
                            </Box>
                          ))}
                      </Carousel>
                    </Box>
                  ) : (
                    <Box sx={{ marginLeft: '32px' }}>
                      <Typography className="title" component={"h4"}>
                        No Projects Found
                      </Typography>
                    </Box>
                  )}
                </>
              )}
            </Box>

            {/* For Rejected Projects By Proposer or DIC */}
            <Box className="myProject-submittedPro-section" >
              <Box
                className={`small-heading ${isDraftCarouselVisible.inRejected ? "" : 'open-section-project-page'}`}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Box className="left" display="flex" alignItems="center" zIndex={'2'}>
                  <IconButton className="expand-sections-btn" onClick={() => toggleDraftCarouselVisibility('inRejected')} size="small">
                    {isDraftCarouselVisible.inRejected ? <KeyboardArrowDown /> : <ChevronRight />}
                  </IconButton>
                  <Badge
                    badgeContent={(!loading && assetData?.length > 0) ? assetData.filter((element) => element.status === PROJECT_STATUS.REJECTED).length : 0}
                    color="primary"
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    className="project-counts-badge"
                  >
                    <Typography className="title" component={"h4"}>
                      {" "}
                      Rejected  Projects
                    </Typography>
                  </Badge>
                </Box>
              </Box>
              {isDraftCarouselVisible.inRejected && (
                <>
                  {!loading && assetData?.length > 0 && assetData?.some((item) => (item.status === PROJECT_STATUS.REJECTED)) ? (
                    <Box className="myPorject-submittedPro-wrap">
                      <Carousel
                        className="productThumb-slider space-24"
                        swipeable={false}
                        draggable={false}
                        responsive={responsive}
                        ref={carouselRefRejected}
                        afterChange={(previousSlide, { currentSlideRejected }) => {
                          setCurrentSlideSub(currentSlideRejected);
                        }}
                        // Set the initial slide to the currentSlide state
                        initialSlide={currentSlideRejected}
                      >
                        {(() => {
                          const projects = [];
                          for (let i = 0; i < assetData?.length; i++) {
                            if (!assetData[i]?.other_category) {
                              if (assetData[i].status === PROJECT_STATUS.REJECTED) {
                                const galleryimg = [];

                                // Adding Cover Photo into slider
                                if (assetData[i]?.asset_coverphoto) {
                                  galleryimg.push(
                                    `${process.env.REACT_APP_IMAGE_URL}${assetData[i].asset_coverphoto?.path}`
                                  );
                                }



                                // Adding Cover Photo into slider 
                                assetData[i]?.asset_gallery.forEach((val, ind) => {
                                  if (val.title === "gellary" && val.image_order === 1) {
                                    galleryimg.push(
                                      `${process.env.REACT_APP_IMAGE_URL}${val.images.path}`
                                    );
                                  }
                                });


                                // Inserting gellery Images Into Slider
                                assetData[i]?.asset_gallery.map(
                                  (val, ind) => {
                                    if (val.title === "gellary" && ind > 0) {
                                      galleryimg.push(
                                        `${process.env.REACT_APP_IMAGE_URL}${val?.images?.path}`
                                      );
                                    }
                                  }
                                );
                                projects.push(
                                  <Box className="slide-item">
                                    <Cards
                                      id={assetData[i]?.id}
                                      title={
                                        assetData[i]?.asset_title
                                      }
                                      subTitle={
                                        assetData[i]
                                          ?.asset_address
                                      }
                                      investType={
                                        assetData[i]?.investment_type?.investment_name
                                      }
                                      address={assetData[i]?.owner_information?.owner_address}
                                      isLoan={
                                        assetData[i]
                                          ?.assets_category?.title
                                      }
                                      isDraft={false}
                                      isClock={false}
                                      isPending={false}
                                      isDelete={false}
                                      isSaved={false}
                                      imgSrc={galleryimg}
                                      isSaveDeal={false}
                                      link={`/project-details/${assetData[i]?.id}`}
                                      investment_expiry_time={
                                        assetData[i]?.investment_expiry_time
                                      }
                                      server_date={serverDate}
                                      server_time={serverTime}
                                      status={assetData[i]?.status}
                                      is_publish={assetData[i]?.is_publish}
                                      is_voted={true}
                                      assetDataDetail={assetData[i]}
                                    />
                                  </Box>
                                );
                              }
                            }
                          }
                          return projects;
                        })()}
                      </Carousel>
                    </Box>
                  ) : loading ? (
                    <Box className="draftsProducts-wrap">
                      <Carousel
                        className="productThumb-slider space-24"
                        swipeable={false}
                        draggable={false}
                        responsive={responsive}
                        ref={carouselRefRejected}
                        afterChange={(previousSlide, { currentSlide }) => {
                          setCurrentSlide(currentSlide);
                        }}
                        initialSlide={currentSlide}
                      >
                        {Array(4)
                          .fill()
                          .map((item, index) => (
                            <Box className="slide-item">
                              <CardSkeleton key={index} />
                            </Box>
                          ))}
                      </Carousel>
                    </Box>
                  ) : (
                    <Box style={{ marginLeft: '32px' }}>
                      <Typography className="title" component={"h4"}>
                        No Projects Found
                      </Typography>
                    </Box>
                  )}
                </>
              )}
            </Box>

            {/* For defaulted Projects By Proposer or DIC */}
            <Box className="myProject-submittedPro-section" >
              <Box
                className={`small-heading ${isDraftCarouselVisible.inAuction ? "" : 'open-section-project-page'}`}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Box className="left" display="flex" alignItems="center" zIndex={'2'}>
                  <IconButton className="expand-sections-btn" onClick={() => toggleDraftCarouselVisibility('inAuction')} size="small">
                    {isDraftCarouselVisible.inAuction ? <KeyboardArrowDown /> : <ChevronRight />}
                  </IconButton>
                  <Badge
                    badgeContent={(!loading && assetData?.length > 0) ? assetData.filter((element) => element.project_status === STATUS_OF_PROJECT.INAUCTION && element.is_onboard && element.is_publish).length : 0}
                    color="primary"
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    className="project-counts-badge"
                  >
                    <Typography className="title" component={"h4"}>
                      {" "}
                      Defaulted  Projects
                    </Typography>
                  </Badge>
                </Box>
              </Box>
              {isDraftCarouselVisible.inAuction && (
                <>
                  {!loading && assetData?.length > 0 && assetData?.some((item) => (item.project_status === STATUS_OF_PROJECT.INAUCTION && item.is_onboard && item.is_publish)) ? (
                    <Box className="myPorject-submittedPro-wrap">
                      <Carousel
                        className="productThumb-slider space-24"
                        swipeable={false}
                        draggable={false}
                        responsive={responsive}
                        ref={carouselRefRejected}
                        afterChange={(previousSlide, { currentSlideRejected }) => {
                          setCurrentSlideSub(currentSlideRejected);
                        }}
                        // Set the initial slide to the currentSlide state
                        initialSlide={currentSlideRejected}
                      >
                        {(() => {
                          const projects = [];
                          for (let i = 0; i < assetData?.length; i++) {
                            if (!assetData[i]?.other_category) {
                              if (assetData[i].project_status === STATUS_OF_PROJECT.INAUCTION && assetData[i].is_onboard === true && assetData[i].is_publish === true) {
                                const galleryimg = [];

                                // Adding Cover Photo into slider
                                if (assetData[i]?.asset_coverphoto) {
                                  galleryimg.push(
                                    `${process.env.REACT_APP_IMAGE_URL}${assetData[i].asset_coverphoto?.path}`
                                  );
                                }



                                // Adding Cover Photo into slider 
                                assetData[i]?.asset_gallery.forEach((val, ind) => {
                                  if (val.title === "gellary" && val.image_order === 1) {
                                    galleryimg.push(
                                      `${process.env.REACT_APP_IMAGE_URL}${val.images.path}`
                                    );
                                  }
                                });


                                // Inserting gellery Images Into Slider
                                assetData[i]?.asset_gallery.map(
                                  (val, ind) => {
                                    if (val.title === "gellary" && ind > 0) {
                                      galleryimg.push(
                                        `${process.env.REACT_APP_IMAGE_URL}${val?.images?.path}`
                                      );
                                    }
                                  }
                                );
                                projects.push(
                                  <Box className="slide-item">
                                    <Cards
                                      id={assetData[i]?.id}
                                      title={
                                        assetData[i]?.asset_title
                                      }
                                      subTitle={
                                        assetData[i]
                                          ?.asset_address
                                      }
                                      investType={
                                        assetData[i]?.investment_type?.investment_name
                                      }
                                      address={assetData[i]?.owner_information?.owner_address}
                                      isLoan={
                                        assetData[i]
                                          ?.assets_category?.title
                                      }
                                      isDraft={false}
                                      isClock={false}
                                      isPending={false}
                                      isDelete={false}
                                      isSaved={false}
                                      imgSrc={galleryimg}
                                      isSaveDeal={false}
                                      link={`/project-details/${assetData[i]?.id}`}
                                      investment_expiry_time={
                                        assetData[i]?.investment_expiry_time
                                      }
                                      server_date={serverDate}
                                      server_time={serverTime}
                                      status={assetData[i]?.status}
                                      project_status={assetData[i]?.project_status}
                                      assetDataDetail={assetData[i]}
                                      is_auction={true}
                                    />
                                  </Box>
                                );
                              }
                            }
                          }
                          return projects;
                        })()}
                      </Carousel>
                    </Box>
                  ) : loading ? (
                    <Box className="draftsProducts-wrap">
                      <Carousel
                        className="productThumb-slider space-24"
                        swipeable={false}
                        draggable={false}
                        responsive={responsive}
                        ref={carouselRefRejected}
                        afterChange={(previousSlide, { currentSlide }) => {
                          setCurrentSlide(currentSlide);
                        }}
                        initialSlide={currentSlide}
                      >
                        {Array(4)
                          .fill()
                          .map((item, index) => (
                            <Box className="slide-item">
                              <CardSkeleton key={index} />
                            </Box>
                          ))}
                      </Carousel>
                    </Box>
                  ) : (
                    <Box style={{ marginLeft: '32px' }}>
                      <Typography className="title" component={"h4"}>
                        No Projects Found
                      </Typography>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Box>
        </>
      }
      {/* full page loader */}
      <CommonBackdropLoader loading={loading} />
    </>
  );
};
export default Projects;