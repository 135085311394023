import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Checkbox, Stack, Typography, Alert, Button, Collapse, Backdrop, CircularProgress, IconButton } from "@mui/material";
import { Message, RateReview, Close, HowToVote, Check } from "@mui/icons-material";
import { GetApiParam, updateApi } from "../../Api/Api";
import { useSnackbar } from "../../Contexts/SnackbarContext";
import { postApi } from "../../Api/Api";
import { INVESTMENT_TYPE, ASSIGNED_PROJECT_STATUS, CATEGORY_TYPE_ID, CAPITAL_TYPE_ID } from "../../constants";
import ModalContent from "../Modals/ModalContent";
import SetRTs from "../Modals/Components/SetStackDic";
import TransactionProgressModal from "../../Components/SmartContract/TransactionProgressModal";
import SetRulesModal from "../Modals/Components/SetRulesModal";

export default function CheckReview({
  details,
  data,
  assetId,
  editable,
  listingType,
  readOnly,
  blockchain_id,
  asset_signing_document,
  template_data
}) {
  const navigate = useNavigate();
  const [reviews, setReviews] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(true);
  const [openSnackbarDocs, setOpenSnackbarDocs] = useState(true);
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [openVotingModal, setOpenVotingModal] = useState(false);
  const [openReviewSuggestionModal, setReviewSuggestionModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalFirst, setOpenModalFirst] = useState(false);
  const [allModalClosed, setAllModalClosed] = useState(false);
  const [assetData, setAssetData] = useState([]);
  const [allMemberRevied, setAllMemberReviewed] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLeadCheckboxChecked, setIsLeadCheckboxChecked] = useState(false); // for the extra checkbox added
  const [isLeadConfirmCheckboxChecked, setIsLeadConfirmCheckboxChecked] = useState(false); // for the confirm checkbox added
  const [isSetRules, setIsSetRules] = useState(false); // for the confirm checkbox added
  const [forSuggestProjectDetails, setForSuggestProjectDetails] = useState(false); // state for handling button trigger
  const [proposerApproved, setProposerApproved] = useState(false); // state to manage approvel from praposer for changes in price.

  const [presetSuggestReview, setPresetSuggestReview] = useState(false); // state to check wheter it is prior checked.
  const [presetConfirmReview, setPresetConfirmReview] = useState(false); // state to check wheter it is prior checked.
  const [updateReviewData, setUpdatedReviewData] = useState([]);
  const [disAllowedCountries, setDisAllowedCountries] = useState('');
  const [allowedCountries, setAllowedCountries] = useState('');
  const [justShowSuggestions, setJustShowSuggestions] = useState(false);

  // state that hold payload data for suggest changes by dic
  const [suggestedDataPayload, setSuggestedDataPayload] = useState('')
  // state to handle suggestions progress modal open close
  const [openTransactionModal, setOpenTransactionModal] = useState(false);
  const [openCountryModal, setOpenCountryModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isRepaymentSet, setIsRepaymentSet] = useState(false);

  /**
   * Snackbar for notification
   */
  const { showSnackbar } = useSnackbar();

  /**
   * Declearing Button name into variables
   */
  let primaryBtnText = "Message Project Proposer";
  let secondaryBtnText = "Complete Review";
  let ternoryBtnText = "Start Review ";
  let ternaryBtnText = "Save Review";
  let requestTemBtnText = "Request admin to create template";

  let userData = localStorage.getItem("user_data_dic")
    ? JSON.parse(localStorage.getItem("user_data_dic"))
    : ""; //Getting  user data from local storage


  useEffect(() => {
    // getting checkbox status
    async function getCheckBoxData() {
      try {
        const payloadData = {
          asset_id: assetId,
          committee_member_id: userData.id ? userData.id : "",
        };
        const res = await GetApiParam(
          "committee/getAssignedAssets",
          payloadData
        );
        if (res.data?.data?.length && res.data?.data[0]) {
          setAssetData(res.data?.data[0]);
          const parsedData = res?.data?.data[0].checklist_data
            ? res?.data?.data[0].checklist_data
            : [];

          setIsLeadCheckboxChecked(res.data?.data[0]?.suggest_review);
          setIsLeadConfirmCheckboxChecked(res.data?.data[0]?.confirm_review);
          setPresetSuggestReview(res.data?.data[0]?.suggest_review);
          setIsRepaymentSet(res.data?.data[0]?.is_repayment_set);
          // setPresetConfirmReview(res.data?.data[0]?.confirm_review);
          setIsSetRules(res.data?.data[0]?.rules ? res.data?.data[0]?.rules : false)
          setAllowedCountries(res.data?.data[0]?.allow_country ? res.data?.data[0]?.allow_country : '')
          setDisAllowedCountries(res.data?.data[0]?.dis_allow_country ? res.data?.data[0]?.dis_allow_country : '')
          const reviewPayload = {
            investment_type_id:
              res?.data?.data[0].assetDetails?.investment_type_id,
            category_id: res?.data?.data[0].assetDetails?.category_id,
            user_id: userData.id ?? userData.id,
          };

          const checkResponse = await GetApiParam(
            "/committee/assetChecklist",
            reviewPayload
          );

          if (checkResponse?.data?.data) {
            const updatedArray = checkResponse?.data?.data?.map((item1) => {
              // Find the matching item in array2 based on id
              const matchingItem =
                parsedData?.length &&
                parsedData?.find((item2) => item2.id === item1.id);

              // If a matching item is found in array2, update the properties
              if (matchingItem) {
                return {
                  ...item1, // Copy the properties from the original item
                  checked: matchingItem.checked, // Update the name property
                };
              }

              // If no matching item is found, return the original item
              return item1;
            });
            // Set the updated array as the new state
            // setArray1(updatedArray);
            setReviews(updatedArray);
            setOpen(true);
          }
        }
      } catch (error) {
        console.log(error);
      }
      // setOpen(false)
    }
    // getting response of proposer
    async function getSuggestReview() {
      try {
        const localData = JSON.parse(localStorage.getItem("user_data_dic"));
        if (localData.id) {
          const overviewPayload = {
            asset_id: assetId,
            proposer_id: data?.user_id,
            // committee_member_id: localData.id
          };
          const res = await GetApiParam(
            "/committee/getSuggestReview",
            overviewPayload
          );
          if (res?.data?.data?.length && res?.data?.data[0]) {
            setUpdatedReviewData(res?.data?.data[0])
            if (res?.data?.data[0].status === 2) {
              setPresetConfirmReview(true)
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    setTimeout(() => {
      getCheckBoxData();
      getSuggestReview();
    }, 100);
  }, []);

  /**
   * Voting logic when every dic Member Reviewed Project.
   */
  useEffect(() => {
    if (assetData?.dicMemberData?.length >= assetData?.dicMember?.length) {
      const memberReviewStatus = assetData?.dicMemberData?.every(
        (elem) => elem.status !== 0
          && elem.status !== 5
      );
      setAllMemberReviewed(memberReviewStatus);
    }
  }, [assetData]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };
  const handleCloseSnackbarDocs = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbarDocs(false);
  };

  const reviewModal = () => {
    setOpenReviewModal(true);
  };
  const votingModal = () => {
    setOpenVotingModal(true);
  };

  const handleChat = () => {
    navigate("/dic/chats", {
      state: {
        receiverId: data?.user_details.id,
        assetId: data?.id,
        dicId: userData.id ? userData.id : "",
      },
    });
  };

  const handleChange = (itemIndex) => {
    setReviews((prevState) => {
      const updatedState = [...prevState];
      updatedState[itemIndex].checked = !updatedState[itemIndex].checked;
      return updatedState;
    });
  };

  /**
   * Onchange function for the Lead Checkbox
   */
  const handleChangeLeadCheckbox = () => {
    handleClickReviewChip();
  };

  /**
   * Onchange function for the Lead Confirm Checkbox
   */
  const handleChangeLeadConfirmCheckbox = () => {

    if (!isLeadConfirmCheckboxChecked && isLeadCheckboxChecked && !presetConfirmReview) {
      showSnackbar("Waiting for the response from Proposer.", "warning");
    }
    else if (isLeadConfirmCheckboxChecked && !isLeadCheckboxChecked && !presetConfirmReview) {
      setIsLeadConfirmCheckboxChecked(!isLeadConfirmCheckboxChecked)
    }
    else if (!isLeadConfirmCheckboxChecked && isLeadCheckboxChecked && presetConfirmReview) {
      setIsLeadConfirmCheckboxChecked(!isLeadConfirmCheckboxChecked)
    }
  };

  // Check if any checkbox is checked or not
  const anyCheckboxChecked = () => {
    const values = reviews?.map((items) => items.checked);
    return values.some((value) => value === true);
  };

  // Check if all checkbox is checked or not
  const areAllCheckboxesChecked = () => {
    const values = reviews?.map((items) => {
      return { checked: items.checked };
    });
    let count = 0;
    values.forEach((value) => {
      if (value.checked === true) count++;
    });
    return count === reviews?.length;
  };

  // Generate payload using checkedCheckboxes state
  const generatePayload = () => {
    return {
      checklist_data: reviews,
      status: 5,
      // Sending Status of checkboxes to the Backend
      suggest_review: isLeadCheckboxChecked,
      confirm_review: isLeadConfirmCheckboxChecked,
    };
  };

  const handleReviewSuggestionModal = (vals) => {
    setReviewSuggestionModal(vals)
  }

  useEffect(() => {
    if (anyCheckboxChecked() === true) {
      handleCloseSnackbar();
    } else {
      setOpenSnackbar(true);
    }
  }, [anyCheckboxChecked()]);

  const saveReviews = async () => {
    try {
      const res = await updateApi(
        `/committee/updateChecklist/${assetData.id}`,
        generatePayload()
      );
      if (res?.data?.code === 200) {
        // navigate(-1)
        showSnackbar("Review Saved Successfully.", "success", 2000);
        // navigate("#");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleStartReview = async () => {
    const localData = JSON.parse(localStorage.getItem("user_data_dic"));
    if (localData.id) {
      const reviewBody = {
        committee_member_id: localData?.id,
        asset_id: assetId,
      };
      const resAssigned = await postApi("/committee/assignProject", reviewBody);
      if (resAssigned?.data?.code === 200) {
        navigate(`/dic/project-details/${assetId}`, {
          state: {
            id: "1",
          },
        });
        window.location.reload(true);
      } else {
        showSnackbar(`${resAssigned?.data?.message}`, "error");
      }
    } else {
      showSnackbar("Member id is missing.", "error");
    }
  };

  const handleStartReviewBtn = () => {
    setOpenModal(true);
  }
  /**
   * Onclick function for the suggest Review Chip
   */
  const handleClickReviewChip = () => {
    setReviewSuggestionModal(true);
    setForSuggestProjectDetails(false);
  };

  const handleShowSuggestedData = () => {
    setReviewSuggestionModal(true);
    setForSuggestProjectDetails(false);
    setJustShowSuggestions(true)
  }

  // Callback function to receive data from the child component
  const receiveDataFromModalContent = async (dataFromModal) => {
    if (dataFromModal?.Button === "Submit project details") {
      const suggestReviewPayload = {
        asset_id: assetId,
        committee_member_id: userData?.id,
        proposer_id: data?.user_id,
      };

      /**
       * For Art (Parcel)
       */
      if (
        assetData?.assetDetails?.investment_type?.investment_name ===
        INVESTMENT_TYPE.PARCEL && assetData?.assetDetails?.category_id === CATEGORY_TYPE_ID.ART
      ) {
        suggestReviewPayload["number_of_parcel"] =
          dataFromModal?.data?.number_of_parcel;
        suggestReviewPayload["minimum_investment"] =
          dataFromModal?.data?.loan_amount_to;
        suggestReviewPayload["amount"] =
          dataFromModal?.data?.loan_amount_from;
        suggestReviewPayload["claim_percentage"] = dataFromModal.data.claim_percentage;
      }

      /**
      * For Art (Sale)
      */
      if (assetData?.assetDetails?.investment_type?.investment_name ===
        INVESTMENT_TYPE.SALE) {
        suggestReviewPayload["deposit_amount"] =
          dataFromModal?.data?.loan_amount_to;
        suggestReviewPayload["amount"] =
          dataFromModal?.data?.loan_amount_from;
        // suggestReviewPayload["investment_duration"] =
        //   dataFromModal?.data?.investment_duration; // only from v2
      }

      /**
       * For Gold and Watch (Loan)
       */
      if (assetData?.assetDetails?.investment_type?.investment_name ===
        INVESTMENT_TYPE.LOAN) {
        suggestReviewPayload[assetData?.assetDetails?.category_id === CATEGORY_TYPE_ID.GOLD ? "gold_weight" : "watch_brand"] = dataFromModal.data.asset_field1;
        suggestReviewPayload[assetData?.assetDetails?.category_id === CATEGORY_TYPE_ID.GOLD ? "gold_quality" : "watch_model"] = dataFromModal.data.asset_field2;
        suggestReviewPayload[assetData?.assetDetails?.category_id === CATEGORY_TYPE_ID.GOLD ? "gold_estimated" : "watch_estimated"] = dataFromModal.data.asset_field3;
        suggestReviewPayload[assetData?.assetDetails?.category_id === CATEGORY_TYPE_ID.GOLD ? "gold_condition" : "watch_condition"] = dataFromModal.data.asset_field4;
        suggestReviewPayload["loan_amount_from"] = dataFromModal.data.loan_amount_from;
        suggestReviewPayload["loan_amount_to"] = dataFromModal.data.loan_amount_to;
        suggestReviewPayload["loan_roi_from"] = dataFromModal.data.loan_roi_from;
        suggestReviewPayload["loan_roi_to"] = dataFromModal.data.loan_roi_to;
        suggestReviewPayload["loan_duration_from"] = dataFromModal.data.loan_duration_from;
        suggestReviewPayload["loan_duration_to"] = dataFromModal.data.loan_duration_to;
        suggestReviewPayload["loan_iop"] = dataFromModal.data.loan_iop;
        suggestReviewPayload["minimum_investment"] = dataFromModal.data.loan_minimum_investment;
        suggestReviewPayload["loan_repay_day"] = dataFromModal.data.loan_repay_day;
        suggestReviewPayload["claim_percentage"] = dataFromModal.data.claim_percentage;
        suggestReviewPayload["thresholdDays"] = dataFromModal.data.thresholdDays;
        suggestReviewPayload["emiBufferDays"] = dataFromModal.data.emiBufferDays;
        suggestReviewPayload["offerExpiryTime"] = dataFromModal.data.offerExpiryTime;
      }

      /**
       * For Real EState / LLC / SPV (Fraction)
       */
      if (assetData?.assetDetails?.investment_type?.investment_name ===
        INVESTMENT_TYPE.FRACTION && assetData?.assetDetails?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
        suggestReviewPayload["fractionalize_project_size"] = dataFromModal.data.fractionalize_project_size;
        suggestReviewPayload["fractionalize_total_price"] = dataFromModal.data.fractionalize_total_price;
        suggestReviewPayload["fractionalize_duration_of_project"] = dataFromModal.data.fractionalize_duration_of_project;
        suggestReviewPayload["capital_info"] = dataFromModal.data.capital_info;
        suggestReviewPayload["loan_repay_day"] = dataFromModal.data.loan_repay_day;
        suggestReviewPayload["claim_percentage"] = dataFromModal.data.claim_percentage;
        suggestReviewPayload["thresholdDays"] = dataFromModal.data.thresholdDays;
        suggestReviewPayload["emiBufferDays"] = dataFromModal.data.emiBufferDays;
      }

      /**
       * For Funds (Fraction)
       */
      if (assetData?.assetDetails?.investment_type?.investment_name ===
        INVESTMENT_TYPE.FRACTION && assetData?.assetDetails?.category_id === CATEGORY_TYPE_ID.FUNDS) {

        suggestReviewPayload["fractionalize_total_price"] = dataFromModal.data.fractionalize_total_price;
        suggestReviewPayload["fractionalize_minimum_investment"] = dataFromModal.data.fractionalize_minimum_investment;
        suggestReviewPayload["fractionalize_duration_of_project"] = dataFromModal.data.fractionalize_duration_of_project;
        suggestReviewPayload["capital_info"] = dataFromModal.data.shared_data;
        suggestReviewPayload["claim_percentage"] = dataFromModal.data.claim_percentage;
        // for close fund
        suggestReviewPayload["investment_duration"] = dataFromModal.data.investment_duration;
        suggestReviewPayload["management_fees"] = dataFromModal.data.management_fees;
        suggestReviewPayload["token_lock_period"] = dataFromModal.data.token_lock_period;
        suggestReviewPayload["carried_interest"] = dataFromModal.data.carried_interest;

      }

      setSuggestedDataPayload(suggestReviewPayload);
      setOpenTransactionModal(true);
    }
    if (dataFromModal?.Button === "Suggest Changes") {
      setForSuggestProjectDetails(false);
    }
    if (dataFromModal?.Button === "Confirm Project") {
      if (!isLeadCheckboxChecked) {
        setIsLeadCheckboxChecked(!isLeadCheckboxChecked);
      }
      if (!isLeadConfirmCheckboxChecked) {
        setIsLeadConfirmCheckboxChecked(!isLeadConfirmCheckboxChecked);
      }
    }
  };

  /**
   * Call when blockchain transaction for suggestion by dic is confirm
   */
  const confirmSuggestions = async () => {
    setOpenReviewModal(false);
    setOpenVotingModal(false);
    setReviewSuggestionModal(false);

    /**
    * Calling Api to update new changes for project details
    */
    if (suggestedDataPayload?.watch_estimated || suggestedDataPayload?.gold_estimated) {
      try {
        const res = await postApi(
          "/committee/suggestReview",
          // suggestReviewPayload
          suggestedDataPayload
        );
        if (res.data.code === 200 && res.data.status === true) {
          const payload = {
            suggest_review: true,
            status: ASSIGNED_PROJECT_STATUS.INPROGRESS
          }
          const res = await updateApi(`/committee/updateChecklist/${assetData.id}`, payload);
          if (res?.data?.code === 200) {
            if (!presetSuggestReview) {
              setIsLeadCheckboxChecked(!isLeadCheckboxChecked);
              setPresetSuggestReview(true)
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      const payload = {
        suggest_review: true,
        status: ASSIGNED_PROJECT_STATUS.INPROGRESS
      }
      try {
        const res = await updateApi(`/committee/updateChecklist/${assetData.id}`, payload);
        if (res?.data?.code === 200) {
          if (!presetSuggestReview) {
            setIsLeadCheckboxChecked(!isLeadCheckboxChecked);
            setPresetSuggestReview(true)
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  /**
    * Handle User Response and open second modal
    * @param {Object} data 
    */
  const userResponse = (data) => {
    setOpenModal(false);
    // setOpenModalFirst(true);
    navigate(`/dic/project-details/${assetId}`, {
      state: {
        id: "1",
      },
    });
    window.location.reload(true);
  };

  /**
  * Function to handle model close
  */
  const handleClose = () => {
    setOpenModal(false);
  };

  /**
    * Onclick function for closing second Modal 
    */
  const handleModalCloseFirst = () => {
    setOpenModalFirst(false);
    setAllModalClosed(true);
  };

  // Both the modal closed and user filled all the required data then calling start review function
  useEffect(() => {
    if (allModalClosed) {
      // handleStartReview()
    }
  }, [allModalClosed])

  /**
   * Function to handle contract progress modal status transaction
   */
  const handleModalClose = () => {
    setOpenTransactionModal(false)
  }

  const handleOpenCountry = () => {
    setOpenCountryModal(true);
  }

  const handleCloseCountry = () => {
    setOpenCountryModal(false)
  }

  const handleCheckedRules = async (allowed, disAllowed) => {
    setIsSetRules(true);
    try {
      const res = await updateApi(
        `/committee/updateChecklist/${assetData.id}`,
        {
          "rules": true,
          "allow_country": allowed,
          "dis_allow_country": disAllowed,
          "status": 5
        }
      );
      if (res?.data?.code === 200) {
        showSnackbar("Review Saved Successfully.", "success", 2000);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const requestCreateTemplate = async (asset_id) => {
    setLoading(true)
    const res = await postApi("/committee/addTemplateRequest", { "asset_id": asset_id });
    if (res) {
      setLoading(false)
      showSnackbar("Sent Request Successfully.", "success")
    }
  }

  return (
    <>
      {!readOnly ? (
        <Box>
          <Box style={{ position: "relative" }} mt={2}>
            {assetData.status == 0 || assetData.status == 5 ? (
              <>
                <Button
                  className="rc-invest-btn"
                  variant="contained"
                  color="primary"
                  startIcon={<Message />}
                  onClick={handleChat}
                >
                  {" "}
                  {primaryBtnText}{" "}
                </Button>
                { // check for agreement docs or templates
                  ((assetData?.assetDetails?.template_data?.length === 0 || assetData?.assetDetails?.template_data?.length && assetData?.assetDetails?.template_data[0]?.template_ids?.length == 0) && assetData?.assetDetails?.category_id === CATEGORY_TYPE_ID.FUNDS) ?
                    <>
                      {asset_signing_document && asset_signing_document?.length > 0 ?
                        <Button
                          onClick={() => requestCreateTemplate(assetData?.id)}
                          disabled={loading}
                          className="rc-invest-btn"
                          sx={{ bgcolor: "#9d350d !important" }}
                          variant="contained"
                          color="secondary"
                          startIcon={<RateReview />}
                        >
                          {requestTemBtnText}
                        </Button>
                        :
                        <Box className="dic-alert-box" sx={{ width: "100%" }}>
                          <Collapse in={openSnackbarDocs}>
                            <Alert
                              variant="filled"
                              severity="error"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="red"
                                  size="small"
                                  onClick={handleCloseSnackbarDocs}
                                  className="review-icon"
                                >
                                  <Close className="review-icon" />
                                </IconButton>
                              }
                              sx={{ mb: 2 }}
                            >
                              Request Proposer to select template or upload a document
                            </Alert>
                          </Collapse>
                        </Box>
                      }
                    </>
                    :
                    (areAllCheckboxesChecked() && isLeadCheckboxChecked && isLeadConfirmCheckboxChecked && ((assetData?.assetDetails?.investment_type?.investment_name ===
                      INVESTMENT_TYPE.LOAN || (assetData?.assetDetails?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE && (assetData?.assetDetails?.capital_info?.filter((item) => (item?.capital_type?.id === CAPITAL_TYPE_ID.SENIOR_DEBT || item?.capital_type?.id === CAPITAL_TYPE_ID.JUNIOR_DEBT)).length > 0))) ? isRepaymentSet : true)) || (areAllCheckboxesChecked() && !isLeadCheckboxChecked && !isLeadConfirmCheckboxChecked && !assetData?.is_lead)
                      ? (
                        <Button
                          onClick={reviewModal}
                          className="rc-invest-btn"
                          sx={{ bgcolor: "#064165 !important" }}
                          variant="contained"
                          color="secondary"
                          startIcon={<RateReview />}
                        >
                          {secondaryBtnText}
                        </Button>
                      ) : (
                        <Button
                          onClick={saveReviews}
                          disabled={
                            anyCheckboxChecked() || assetData.status == 5 || isLeadCheckboxChecked || isSetRules || isRepaymentSet
                              ? false
                              : true
                          }
                          className="rc-invest-btn"
                          sx={{ bgcolor: "#064165 !important" }}
                          variant="contained"
                          color="secondary"
                          startIcon={<RateReview />}
                        >
                          {ternaryBtnText}
                        </Button>
                      )}
              </>
            ) : null}

            {assetData.status == 1 ? (
              <>
                <Alert
                  variant="filled"
                  icon={<Check fontSize="inherit" />}
                  className="review-complete"
                >
                  {" "}
                  Review Completed !{" "}
                </Alert>
                {/* Checking for the condition where all commitie member revied project or not */}
                {allMemberRevied ? (
                  <Button
                    onClick={votingModal}
                    className="rc-invest-btn"
                    sx={{ bgcolor: "#064165 !important" }}
                    variant="contained"
                    color="secondary"
                    startIcon={<HowToVote />}
                  >
                    {"Vote for Project"}
                  </Button>
                ) : (
                  <Button
                    disabled
                    className="rc-invest-btn"
                    sx={{ bgcolor: "#064165 !important" }}
                    variant="contained"
                    color="secondary"
                    startIcon={<HowToVote />}
                  >
                    {"Vote for Project"}
                  </Button>
                )}
              </>
            ) : null}
            {assetData.status == 2 || assetData.status == 3 ? (
              <>
                <Alert
                  variant="filled"
                  icon={<Check fontSize="inherit" />}
                  className="review-complete"
                >
                  {" "}
                  Vote Completed !
                </Alert>
              </>
            ) : null}
          </Box>

          {assetData.status == 0 && (assetData?.assetDetails?.category_id === CATEGORY_TYPE_ID.FUNDS ? assetData?.assetDetails?.template_data?.length > 0 && assetData?.assetDetails?.template_data?.length && assetData?.assetDetails?.template_data[0]?.template_ids?.length > 0 : true) ? (
            <Box className="dic-alert-box" sx={{ width: "100%" }}>
              <Collapse in={openSnackbar}>
                <Alert
                  variant="filled"
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="red"
                      size="small"
                      onClick={handleCloseSnackbar}
                      className="review-icon"
                    >
                      <Close className="review-icon" />
                    </IconButton>
                  }
                  sx={{ mb: 2 }}
                >
                  Please make sure to check off the Review Checklist items
                  before completing the Review
                </Alert>
                {/* <SnackbarAlert message={'Please make sure to check off the Review Checklist items before completing the Review'} severity={'error'}/> */}
              </Collapse>
            </Box>
          ) : null}
          {assetData?.assetDetails?.category_id === CATEGORY_TYPE_ID.FUNDS && (assetData?.assetDetails?.template_data?.length === 0 || assetData?.assetDetails?.template_data?.length && assetData?.assetDetails?.template_data[0]?.template_ids?.length === 0) ? null :
            <Box className="pd-sidebar-assetDetails">
              <Stack
                className="pd-sidebar-heading"
                direction="row"
                spacing={1}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography className="title" component={"h4"}>
                  Review Checklist
                </Typography>
              </Stack>
              {open && (
                <Box className="pd-sidebar-detailsList" mt={0.5}>
                  {reviews?.map((val, ind) => {
                    return (
                      <>
                        {/* checking the condition for all checkbox are reviwed or not */}
                        {assetData?.status === 1 ||
                          assetData?.status === 2 ||
                          assetData?.status === 3 ? (
                          <Stack
                            className="detailsListCheckBox"
                            direction="row"
                            spacing={1}
                            justifyContent="space-between"
                            alignItems="center"
                            key={ind}
                          >
                            <Typography className="title">
                              {val.checklist.title}
                            </Typography>
                            <Checkbox
                              sx={{
                                color: "#94A3B8",
                                "&.Mui-checked": {
                                  color: "#10B981",
                                },
                                "&.MuiCheckbox-root": {
                                  borderColor: "#94A3B8",
                                },
                              }}
                              // onChange={() => handleChange(ind)}
                              checked={val.checked || false}
                            />
                          </Stack>
                        ) : (
                          <Stack
                            className="detailsListCheckBox"
                            direction="row"
                            spacing={1}
                            justifyContent="space-between"
                            alignItems="center"
                            key={ind}
                          >
                            <Typography className="title">
                              {val.checklist.title}
                            </Typography>
                            <Checkbox
                              sx={{
                                color: "#94A3B8",
                                "&.Mui-checked": {
                                  color: "#10B981",
                                },
                                "&.MuiCheckbox-root": {
                                  borderColor: "#94A3B8",
                                },
                              }}
                              onChange={() => handleChange(ind)}
                              checked={val.checked || false}
                            />
                          </Stack>
                        )}
                      </>
                    );
                  })}
                  {/* Conditionally rendring Extra check list in the case of Lead */}
                  {assetData?.is_lead && (
                    <>
                      {(data?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE || data?.category_id === CATEGORY_TYPE_ID.FUNDS) ?
                        <Stack
                          className="detailsListCheckBox"
                          direction="row"
                          spacing={1}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography className="title">
                            Set Rules (Optional)
                          </Typography>
                          <Checkbox
                            sx={{
                              color: "#94A3B8",
                              "&.Mui-checked": {
                                color: "#10B981",
                              },
                              "&.MuiCheckbox-root": {
                                borderColor: "#94A3B8",
                              },
                            }}
                            disabled={assetData?.assetDetails?.is_publish}
                            onChange={
                              assetData?.assetDetails?.is_publish ? () => { } : handleOpenCountry
                            }
                            checked={isSetRules}
                          />
                        </Stack> : null
                      }
                      <Stack
                        className="detailsListCheckBox"
                        direction="row"
                        spacing={1}
                        justifyContent="space-between"
                        alignItems="center"
                      // key={ind + 1}
                      >
                        <Typography className="title">
                          Suggest Project {assetData?.assetDetails?.investment_type?.investment_name === "Parcelling" ? "Parcel" : assetData?.assetDetails?.investment_type?.investment_name} Term
                        </Typography>
                        {/* <Chip
                      style={{ backgroundColor: "#DAC88A", cursor: "pointer" }}
                      onClick={handleClickReviewChip}
                      label={" Suggest Review"}
                    /> */}
                        <Checkbox
                          sx={{
                            color: "#94A3B8",
                            "&.Mui-checked": {
                              color: "#10B981",
                            },
                            "&.MuiCheckbox-root": {
                              borderColor: "#94A3B8",
                            },
                          }}
                          onChange={
                            (!presetSuggestReview && !isLeadCheckboxChecked) && handleChangeLeadCheckbox
                          }
                          checked={isLeadCheckboxChecked}
                        />
                      </Stack>
                      <Stack
                        className="detailsListCheckBox"
                        direction="row"
                        spacing={1}
                        justifyContent="space-between"
                        alignItems="center"
                      // key={ind + 1}
                      >
                        <Typography className="title">
                          Confirm Project {assetData?.assetDetails?.investment_type?.investment_name === "Parcelling" ? "Parcel" : assetData?.assetDetails?.investment_type?.investment_name} Term
                        </Typography>
                        {/* <Chip
                      style={{ backgroundColor: "#DAC88A", cursor: "pointer" }}
                      onClick={handleClickReviewChip}
                      label={" Suggest Review"}
                    /> */}
                        <Checkbox
                          sx={{
                            color: "#94A3B8",
                            "&.Mui-checked": {
                              color: "#10B981",
                            },
                            "&.MuiCheckbox-root": {
                              borderColor: "#94A3B8",
                            },
                          }}
                          onChange={(presetConfirmReview && isLeadConfirmCheckboxChecked || isLeadCheckboxChecked) && handleChangeLeadConfirmCheckbox}
                          checked={
                            isLeadConfirmCheckboxChecked
                          }
                        />
                      </Stack>

                      {(assetData?.assetDetails?.investment_type?.investment_name ===
                        INVESTMENT_TYPE.LOAN || (assetData?.assetDetails?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE && (assetData?.assetDetails?.capital_info?.filter((item) => (item?.capital_type?.id === CAPITAL_TYPE_ID.SENIOR_DEBT || item?.capital_type?.id === CAPITAL_TYPE_ID.JUNIOR_DEBT)).length > 0))) ?
                        <Stack
                          className="detailsListCheckBox"
                          direction="row"
                          spacing={1}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography className="title">
                            Set Repayment Configuration
                          </Typography>
                          {!isRepaymentSet ?
                            <Button className="btn-rounded" disabled={assetData?.assetDetails?.is_publish} onClick={() => navigate('/dic/suggest-review', {
                              state: {
                                asset_id: assetId
                              }
                            })} style={{ height: '28px', width: '95px', fontSize: '12px' }}>Click To Set</Button> : null
                          }
                          <Checkbox
                            checked={isRepaymentSet ? true : false}
                            sx={{
                              color: "#94A3B8",
                              "&.Mui-checked": {
                                color: "#10B981",
                              },
                              "&.MuiCheckbox-root": {
                                borderColor: "#94A3B8",
                              },
                            }}
                            disabled
                          // onChange={}
                          // checked={}
                          />
                        </Stack> : null
                      }
                    </>
                  )}
                  {
                    // <>
                    //   <Stack
                    //     className="detailsListCheckBox"
                    //     direction="row"
                    //     spacing={1}
                    //     justifyContent="space-between"
                    //     alignItems="center"
                    //   >
                    //     <Typography className="title">
                    //       {presetSuggestReview && presetConfirmReview ? "Suggested" : ""} Project Term
                    //     </Typography>
                    //     <Info style={{ color: 'white', margin: '8px' }} onClick={handleShowSuggestedData} />
                    //   </Stack>
                    // </>
                  }
                </Box>
              )}
            </Box>
          }
        </Box>
      ) : (
        <Box>
          <Box style={{ position: "relative" }} mt={2}>
            <>
              <Button
                className="rc-invest-btn"
                variant="contained"
                color="primary"
                // startIcon={<Message />}
                onClick={handleStartReviewBtn}
              >
                {" "}
                {ternoryBtnText}{" "}
              </Button>
            </>
          </Box>
        </Box>
      )}
      <ModalContent
        assetData={assetData}
        data={data}
        assetId={assetId}
        openVotingModal={openVotingModal}
        setOpenVotingModal={setOpenVotingModal}
        openReviewModal={openReviewModal}
        setOpenReviewModal={setOpenReviewModal}
        openReviewSuggestionModal={openReviewSuggestionModal}
        setReviewSuggestionModal={handleReviewSuggestionModal}
        checkboxPayload={generatePayload()}
        sendDataToCheckReview={receiveDataFromModalContent}
        forSuggestProjectDetails={forSuggestProjectDetails}
        setForSuggestProjectDetails={setForSuggestProjectDetails}
        updateReviewData={updateReviewData}
        justShowSuggestions={justShowSuggestions}
        setJustShowSuggestions={setJustShowSuggestions}
      />

      {/* Race Token Modal */}
      {openModal ? <SetRTs
        openRTs={openModal}
        handleClose={handleClose}
        userResponse={userResponse}
        assetId={assetId}
      /> : null}

      {/* Wallet Connect Modal */}
      {openCountryModal && !isSetRules ? <SetRulesModal
        openAllowCountry={openCountryModal}
        handleCloseCountry={handleCloseCountry}
        assetId={assetId}
        proposalId={blockchain_id}
        assetData={assetData}
        setIsSetRules={handleCheckedRules}
        allowedCountries={allowedCountries}
        disAllowedCountries={disAllowedCountries}
      />
        : null}


      {/* Transaction progress modal for dic suggestions(lead) */}
      {openTransactionModal ? <TransactionProgressModal identity={'dic-suggestions'} confirmStake={confirmSuggestions} propData={assetData?.assetDetails} suggestedDataPayload={suggestedDataPayload} handleModalClose={handleModalClose} openTransactionModal={openTransactionModal} /> : null}

      {/* full page loader */}
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="primary" />
        </Backdrop>
      )}
    </>
  );
}
