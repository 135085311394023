// calculateEMI.js

import { readContract } from "@wagmi/core";
import convertToDecimal from "../Common/convertToDecimal";
import convertToTwoDecimal from "../Common/convertToTwoDecimal";

/**
 * Calculate emi for the loan
 * @param {String} contract_address contract address
 * @param {Object} contract_abi  contract abi
 * @param {Number} loan_amount loan amount
 * @param {Number} loan_duration loan duration
 * @param {Number} loan_ior loan interest rate
 * @param {Number} loan_iop loan interest only period
 * @returns emi amount
 */
async function calculateEMI(contract_address, contract_abi, loan_amount, loan_duration, loan_ior, loan_iop) {

    try {
        const emi = await readContract({
            address: contract_address,
            abi: contract_abi,
            functionName: "calculateEMI",
            args: [
                convertToDecimal(loan_amount),
                Number(convertToTwoDecimal(loan_ior)),
                Number(loan_duration),
                Number(loan_iop)
            ]
        })
        return emi;
    } catch (error) {
        console.error('Error calling calculate EMI:', error);
        throw error; // propagate the error
    }
}

export { calculateEMI };
