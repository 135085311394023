// dicSuggestions.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import convertToDecimal from "../Common/convertToDecimal"
import { CATEGORY_TYPE_ID, INVESTMENT_TYPE_ID, CAPITAL_TYPE_ID, SUB_CATEGORY_TYPE_ID } from "../../constants";
import convertToTwoDecimal from "../Common/convertToTwoDecimal";

/**
 * DIC edit deal term for assets
 * @param {String} address user wallet address
 * @param {Object} suggestedDataPayload edited data by dic
 * @param {Object} assetData asset's details
 * @param {proposalId} proposalId asset's blockchain id
 * @param {Function} showSnackbar show success/failure message
 * @param {Function} handleActiveStep handle active step
 * @param {Function} handleModalClose handle modal close
 * @param {Function} handleSuccess handle confirmation of transaction success
 * @returns data or error
 */

async function dicSuggestions(address, contract_address, contract_abi, suggestedDataPayload, assetData, proposalId, showSnackbar, handleActiveStep, handleModalClose, handleSuccess) {
    try {

        /**
         * Common function for all category and investment
         * @param {String} functionName smart contract method name
         * @param {Object} suggestionsPayload payload for method
         * @returns 
         */
        const setSuggestions = async (functionName, suggestionsPayload) => {
            let args = [
                proposalId, // asset's blockchain id
                suggestionsPayload
            ]
            if (functionName === "setSPVSuggestion" || functionName === "setFundSuggestion") {
                args = [
                    proposalId, // asset's blockchain id
                    ...suggestionsPayload
                ]
            }
            console.log('args', args);

            const { request } = await prepareWriteContract({
                address: contract_address,
                abi: contract_abi, // contract abi
                account: address,
                functionName: functionName,
                args: args
            })

            return request
        }
        if (assetData?.investment_type?.id === INVESTMENT_TYPE_ID.LOAN) {
            let minPercentage = Math.ceil(parseFloat(suggestedDataPayload?.loan_roi_from) * 100)
            let maxPercentage = Math.ceil(parseFloat(suggestedDataPayload?.loan_roi_to) * 100)
            let claim_percentage = Math.ceil(parseFloat(suggestedDataPayload?.claim_percentage * 100))
            const SuggestionCollateral = {
                projectId: Number(assetData?.id),
                assetId: Number(assetData?.assets_category?.blockchain_id), // category id- blockchain
                investmentId: Number(assetData?.investment_type?.blockchain_id), // investment id -blockchain
                minAmount: convertToDecimal(suggestedDataPayload?.loan_amount_from), // from loan amount
                maxAmount: convertToDecimal(suggestedDataPayload?.loan_amount_to), // to loan amount
                minInvestment: convertToDecimal(suggestedDataPayload?.minimum_investment),
                minPercentage: Number(minPercentage), // from rate of interest
                maxPercentage: Number(maxPercentage), // to rate of interest
                minDuration: Number(suggestedDataPayload?.loan_duration_from), // from loan duration
                maxDuration: Number(suggestedDataPayload?.loan_duration_to), // to loan duration
                debtToken: {
                    tokenName: 'Loan',
                    tokenSymbol: 'L',
                    interestOnlyPeriod: Number(suggestedDataPayload?.loan_iop), // to loan duration
                    paymentDate: Number(suggestedDataPayload?.loan_repay_day), // to loan duration
                    claimedPercentage: Number(claim_percentage),
                    thresholdDays: daysInSecond(suggestedDataPayload?.thresholdDays || 24),
                    emiBufferDays: Number(suggestedDataPayload?.emiBufferDays || 0),
                    offerExpiryTime: daysInSecond(suggestedDataPayload?.offerExpiryTime || 3)
                }
            }
            const setCollateralSuggestion = await setSuggestions('setLoanSuggestion', SuggestionCollateral);
            const { hash } = await writeContract(setCollateralSuggestion)
            if (hash) {
                const data = await waitForTransaction({ hash: hash })
                if (data.status === "success") {
                    handleActiveStep(2);
                    showSnackbar("Transaction Successful", 'success')
                    setTimeout(() => {
                        handleSuccess()
                        handleModalClose();
                        handleActiveStep(-1)
                        return { data }
                    }, 1000)
                } else {
                    handleModalClose();
                    handleActiveStep(-1)
                    showSnackbar("Transaction Failed", 'error')
                    return
                }
            }
        } else if (assetData?.investment_type?.id === INVESTMENT_TYPE_ID.SALE) {
            let SuggestionSale = {
                projectId: Number(assetData?.id),
                assetId: Number(assetData?.assets_category?.blockchain_id), // category id- blockchain
                investmentId: Number(assetData?.investment_type?.blockchain_id), // investment id -blockchain
                salePrice: convertToDecimal(parseFloat(suggestedDataPayload?.amount).toFixed(2)), // sale price
                depositAmount: convertToDecimal(parseFloat(suggestedDataPayload?.deposit_amount).toFixed(2)), // deposit amount
                // investmentTime: monthsInSecond(parseFloat(suggestedDataPayload?.investment_duration)) // only from v2
            }

            const setSaleSuggestion = await setSuggestions('setSaleSuggestion', SuggestionSale);
            const { hash } = await writeContract(setSaleSuggestion)
            if (hash) {
                const data = await waitForTransaction({ hash: hash })
                if (data.status === "success") {
                    handleActiveStep(2);
                    showSnackbar("Transaction Successful", 'success')
                    setTimeout(() => {
                        handleSuccess();
                        handleModalClose();
                        handleActiveStep(-1)
                        return { data }
                    }, 1000)
                } else {
                    handleModalClose();
                    handleActiveStep(-1)
                    showSnackbar("Transaction Failed", 'error')
                    return
                }
            }
        } else if (assetData?.investment_type?.id === INVESTMENT_TYPE_ID.PARCEL) {
            let claim_percentage = convertToTwoDecimal(suggestedDataPayload?.claim_percentage ? parseFloat(suggestedDataPayload?.claim_percentage).toFixed(2) : 0)
            const SuggestionParcel = {
                projectId: Number(assetData?.id),
                assetId: Number(assetData?.assets_category?.blockchain_id), // category id- blockchain
                investmentId: Number(assetData?.investment_type?.blockchain_id), // investment id -blockchain
                saleAmount: convertToDecimal(parseFloat(suggestedDataPayload?.amount).toFixed(2)), // parcel total sale price
                minInvestment: convertToDecimal(parseFloat(suggestedDataPayload?.minimum_investment).toFixed(2)),// minimum investment 
                noOfParcel: Number(parseInt(suggestedDataPayload?.number_of_parcel)),// number of parcels - constant
                claimedPercentage: Number(claim_percentage)
            }
            const setParcelSuggestion = await setSuggestions('setParcelSuggestion', SuggestionParcel);
            const { hash } = await writeContract(setParcelSuggestion)
            if (hash) {
                const data = await waitForTransaction({ hash: hash })
                if (data.status === "success") {
                    showSnackbar("Transaction Successful", 'success');
                    handleActiveStep(2);
                    setTimeout(() => {
                        handleSuccess();
                        handleModalClose();
                        handleActiveStep(-1)
                        return { data }
                    }, 1000)
                } else {
                    handleModalClose();
                    handleActiveStep(-1)
                    showSnackbar("Transaction Failed", 'error')
                    return
                }
            }
        } else if (assetData?.investment_type?.id === INVESTMENT_TYPE_ID.FRACTION && assetData?.assets_category?.id === CATEGORY_TYPE_ID.FUNDS) {
            let SuggestionFund = []

            if (assetData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS) {
                let claim_percentage = Math.ceil(parseFloat(suggestedDataPayload?.claim_percentage >= 0 ? suggestedDataPayload?.claim_percentage * 100 : assetData?.claim_percentage * 100))
                SuggestionFund = [{
                    projectId: Number(assetData?.id), // Asset id / Project id (Backend)
                    assetId: Number(assetData?.asset_sub_category_info?.blockchain_id), // Asset category id (Asset category id of blockchain)
                    investmentId: Number(assetData?.investment_type?.blockchain_id), // asset investment type id (Asset investment id of blockchain)
                    tokenName: assetData?.tokenName,
                    tokenSymbol: assetData?.tokenSymbol,
                    fundSize: convertToDecimal(suggestedDataPayload.fractionalize_total_price), // totalRaise
                    initialDuration: Number(yearsInSecond(suggestedDataPayload?.fractionalize_duration_of_project)), // 
                    investmentDuration: Number(daysInSecond(suggestedDataPayload?.investment_duration)), // 
                    managementFees: Number(Math.ceil(suggestedDataPayload?.management_fees * 100)), // 
                    managementAddress: assetData?.managementAddress, // for now it is proposer address
                    carriedInterest: Number(Math.ceil(suggestedDataPayload?.carried_interest * 100)), // 
                    minInvestment: convertToDecimal(suggestedDataPayload.fractionalize_minimum_investment), // totalRaise
                    claimedPercentage: Number(claim_percentage),
                    tokenLockPeriod: Number(daysInSecond(suggestedDataPayload?.token_lock_period)),
                }]
            } else {
                let classA = '', classB = '';
                let HurdleRate = {
                    isHurdleRate: true,
                    prefferdPercentage: [],
                    LPPercentage: [],
                    GPPercentage: [],
                };
                suggestedDataPayload?.capital_info?.forEach((capitals, index) => {
                    if (index === 0) {
                        HurdleRate?.prefferdPercentage.push(Number(Math.ceil(parseFloat(capitals?.rate_of_return * 100))));
                        HurdleRate?.LPPercentage.push(parseFloat(capitals?.lp_percentage) * 100);
                        HurdleRate?.GPPercentage.push((100 - parseFloat(capitals?.lp_percentage)) * 100);
                        classA = {
                            tokenName: "Class A",
                            tokenSymbol: 'A',
                            minInvestment: convertToDecimal(capitals?.minimum_investment),
                            totalRaise: convertToDecimal(capitals?.total_raise),
                        }
                    } else if (index === 1) {
                        HurdleRate?.prefferdPercentage.push(Number(Math.ceil(parseFloat(capitals?.rate_of_return * 100))));
                        HurdleRate?.LPPercentage.push(parseFloat(capitals?.lp_percentage) * 100);
                        HurdleRate?.GPPercentage.push((100 - parseFloat(capitals?.lp_percentage)) * 100);
                        classB = {
                            tokenName: "Class B",
                            tokenSymbol: 'B',
                            minInvestment: convertToDecimal(capitals?.minimum_investment),
                            totalRaise: convertToDecimal(capitals?.total_raise),
                        }
                    }
                })
                let equityParam = [];
                if (classA && classB) {
                    equityParam = [classA, classB]
                } else if (classA) {
                    equityParam = [classA]
                } else if (classA) {
                    equityParam = [classB]
                }
                let claim_percentage = Math.ceil(parseFloat(suggestedDataPayload?.claim_percentage ? suggestedDataPayload?.claim_percentage * 100 : assetData?.claim_percentage * 100))

                SuggestionFund = [{
                    projectId: Number(assetData?.id),
                    assetId: Number(assetData?.assets_category?.blockchain_id), // category id- blockchain
                    investmentId: Number(assetData?.investment_type?.blockchain_id), // investment id -blockchain
                    totalRaise: convertToDecimal(suggestedDataPayload?.fractionalize_total_price), // total raise amount
                    duration: Number(yearsInSecond(suggestedDataPayload?.fractionalize_duration_of_project)), // duration of loan amount
                    claimedPercentage: Number(claim_percentage),
                    tokenLockPeriod: Number(daysInSecond(suggestedDataPayload?.token_lock_period)),
                    fundType: equityParam,
                    hurdleParam: HurdleRate
                }]
            }

            const setFundSuggestion = await setSuggestions('setFundSuggestion', SuggestionFund);
            const { hash } = await writeContract(setFundSuggestion)
            if (hash) {
                const data = await waitForTransaction({ hash: hash })
                if (data.status === "success") {
                    showSnackbar("Transaction Successful", 'success');
                    handleActiveStep(2);
                    setTimeout(() => {
                        handleSuccess();
                        handleModalClose();
                        handleActiveStep(-1)
                        return { data }
                    }, 1000)
                } else {
                    handleModalClose();
                    handleActiveStep(-1)
                    showSnackbar("Transaction Failed", 'error')
                    return
                }
            }
        } else if (assetData?.investment_type?.id === INVESTMENT_TYPE_ID.FRACTION && assetData?.assets_category?.id === CATEGORY_TYPE_ID.REAL_ESTATE) {
            let senior = suggestedDataPayload?.capital_info?.filter((items) => items?.capital_type?.id === CAPITAL_TYPE_ID.SENIOR_DEBT);
            let junior = suggestedDataPayload?.capital_info?.filter((items) => items?.capital_type?.id === CAPITAL_TYPE_ID.JUNIOR_DEBT);
            let equity = suggestedDataPayload?.capital_info?.filter((items) => items?.capital_type?.id === CAPITAL_TYPE_ID.EQUITY);

            let debtToken = [];
            let equityToken = {};
            let totalDebt = 0;
            let totalEquity = 0;
            let equityPercentage = 0;

            if (senior?.length && junior?.length) {
                totalDebt = parseInt(senior[0]?.total_raise) + parseInt(junior[0]?.total_raise)
                debtToken = [
                    {
                        tokenName: senior[0]?.tokenName ? senior[0]?.tokenName : "Senior Debt",
                        tokenSymbol: senior[0]?.tokenSymbol ? senior[0]?.tokenSymbol : 'S',
                        totalRaise: convertToDecimal(senior[0]?.total_raise),
                        minInvestment: convertToDecimal(senior[0]?.minimum_investment),
                        percentage: Math.ceil(parseFloat(senior[0]?.senior_ror_to * 100)),
                        duration: senior[0]?.senior_duration_to * 12,
                        interestOnlyPeriod: senior[0]?.iop_senior,
                        amortizationPeriod: (senior[0]?.amortization_duration) * 12,
                        titledCompanyAddress: assetData?.managementAddress,
                        claimedPercentage: Number(Math.ceil(parseFloat(suggestedDataPayload?.claim_percentage) * 100)),
                    },
                    {
                        tokenName: junior[0]?.tokenName ? junior[0]?.tokenName : "Junior Debt",
                        tokenSymbol: junior[0]?.tokenSymbol ? junior[0]?.tokenSymbol : 'J',
                        totalRaise: convertToDecimal(junior[0]?.total_raise),
                        minInvestment: convertToDecimal(junior[0]?.minimum_investment),
                        percentage: Math.ceil(parseFloat(junior[0]?.junior_ror_to * 100)),
                        duration: junior[0]?.junior_duration_to * 12,
                        interestOnlyPeriod: junior[0]?.iop_junior,
                        amortizationPeriod: (junior[0]?.amortization_duration) * 12,
                        titledCompanyAddress: assetData?.managementAddress,
                        claimedPercentage: Number(Math.ceil(parseFloat(suggestedDataPayload?.claim_percentage) * 100)),
                    }
                ]
            } else if (senior?.length) {
                totalDebt = parseInt(senior[0]?.total_raise)
                debtToken = [
                    {
                        tokenName: senior[0]?.tokenName ? senior[0]?.tokenName : "Senior Debt",
                        tokenSymbol: senior[0]?.tokenSymbol ? senior[0]?.tokenSymbol : 'S',
                        totalRaise: convertToDecimal(senior[0]?.total_raise),
                        minInvestment: convertToDecimal(senior[0]?.minimum_investment),
                        percentage: Math.ceil(parseFloat(senior[0]?.senior_ror_to * 100)),
                        duration: senior[0]?.senior_duration_to * 12,
                        interestOnlyPeriod: senior[0]?.iop_senior,
                        amortizationPeriod: (senior[0]?.amortization_duration) * 12,
                        titledCompanyAddress: assetData?.managementAddress,
                        claimedPercentage: Number(Math.ceil(parseFloat(suggestedDataPayload?.claim_percentage) * 100)),
                    }
                ]
            } else if (junior?.length) {
                totalDebt = parseInt(junior[0]?.total_raise)
                debtToken = [
                    {
                        tokenName: "Senior Debt Token",
                        tokenSymbol: 'SDT',
                        totalRaise: 0,
                        minInvestment: 0,
                        percentage: 0,
                        duration: 0,
                        interestOnlyPeriod: 0,
                        amortizationPeriod: 0,
                        titledCompanyAddress: assetData?.managementAddress,
                        claimedPercentage: Number(Math.ceil(parseFloat(suggestedDataPayload?.claim_percentage) * 100)),
                    },
                    {
                        tokenName: junior[0]?.tokenName ? junior[0]?.tokenName : "Junior Debt",
                        tokenSymbol: junior[0]?.tokenSymbol ? junior[0]?.tokenSymbol : 'J',
                        totalRaise: convertToDecimal(junior[0]?.total_raise),
                        minInvestment: convertToDecimal(junior[0]?.minimum_investment),
                        percentage: Math.ceil(parseFloat(junior[0]?.junior_ror_to * 100)),
                        duration: junior[0]?.junior_duration_to * 12,
                        interestOnlyPeriod: junior[0]?.iop_junior,
                        amortizationPeriod: (junior[0]?.amortization_duration) * 12,
                        titledCompanyAddress: assetData?.managementAddress,
                        claimedPercentage: Number(Math.ceil(parseFloat(suggestedDataPayload?.claim_percentage) * 100)),
                    }
                ]
            }

            if (equity?.length) {
                totalEquity = parseFloat(equity[0]?.total_raise ? equity[0]?.total_raise : 0)
                equityToken =
                {
                    tokenName: equity[0]?.tokenName ? equity[0]?.tokenName : "Limited Partner",
                    tokenSymbol: equity[0]?.tokenSymbol ? equity[0]?.tokenSymbol : 'LP',
                    totalRaise: convertToDecimal(equity[0]?.total_raise),
                    minInvestment: convertToDecimal(equity[0]?.minimum_investment),
                    equityPercentage: Number(Math.ceil(parseFloat(equity[0]?.equity * 100))),
                    cashOnYield: 24,
                    titledCompanyAddress: assetData?.managementAddress,
                    claimedPercentage: Number(Math.ceil(parseFloat(suggestedDataPayload?.claim_percentage) * 100)),
                }
                equityPercentage = Math.ceil(parseFloat(equity[0]?.equity * 100))
            } else {
                equityToken =
                {
                    tokenName: "Limited Partner",
                    tokenSymbol: 'LP',
                    totalRaise: 0,
                    minInvestment: 0,
                    equityPercentage: 0,
                    cashOnYield: 0,
                    titledCompanyAddress: assetData?.managementAddress,
                    claimedPercentage: Number(Math.ceil(parseFloat(suggestedDataPayload?.claim_percentage) * 100)),
                }
            }

            let HurdleRate = {
                isHurdleRate: false,
                isResetHurdle: false,
                minIRRPercent: [],
                maxIRRPercent: [],
                partners: [],
                carriedPercentage: 0,
                gpPercentage: 0,
            };
            if (assetData?.is_hurdle_rate && equity?.length) {
                let irrData = JSON.parse(assetData?.spv_rate_of_return);
                let partnersData = JSON.parse(assetData?.spv_partners);
                const minIRRPercent = irrData?.length > 0 ? irrData?.map(item => Math.ceil(parseFloat(item.min) * 100)) : [];
                const maxIRRPercent = irrData?.length > 0 ? irrData?.map(item => Math.ceil(parseFloat(item.max) * 100)) : [];
                const partners = partnersData?.length > 0 ? partnersData?.map(item => [Math.ceil(parseFloat(item.lp)) * 100, Math.ceil((100 - parseFloat(item.lp)) * 100)]) : [];
                HurdleRate = {
                    isHurdleRate: Boolean(assetData.is_hurdle_rate),
                    isResetHurdle: false,
                    minIRRPercent: minIRRPercent,
                    maxIRRPercent: maxIRRPercent,
                    partners: partners,
                    carriedPercentage: parseFloat(assetData?.carried_interest) ? Math.ceil(parseFloat(assetData?.carried_interest) * 100) : 0,
                    gpPercentage: parseFloat(assetData?.spv_lp_percentage) ? Math.ceil(parseFloat(assetData?.spv_lp_percentage) * 100) : 0,
                };
            }
            let debtDetail = {
                thresholdDays: daysInSecond(suggestedDataPayload?.thresholdDays || 24),
                emiBufferDays: Number(suggestedDataPayload?.emiBufferDays || 0),
                paymentDate: Number(suggestedDataPayload?.loan_repay_day),
            }
            const SuggestionSPV = [{
                projectId: Number(assetData?.id),
                assetId: Number(assetData?.assets_category?.blockchain_id), // category id- blockchain
                investmentId: Number(assetData?.investment_type?.blockchain_id), // investment id -blockchain
                totalCapital: convertToDecimal(suggestedDataPayload?.fractionalize_project_size), // project size 
                totalRaise: convertToDecimal(suggestedDataPayload?.fractionalize_total_price), // total raise amount
                totalDebtAmt: convertToDecimal(totalDebt),
                totalEquityAmt: convertToDecimal(totalEquity),
                proposerPercentage: Number(10000 - equityPercentage),
                equityPercentage: Number(equityPercentage),
                projectDuration: Number(suggestedDataPayload?.fractionalize_duration_of_project * 12), // duration of loan amount,
                debtToken,
                debtDetail,
                equityToken,
                hurdleParam: HurdleRate
            },
            ]

            const setSPVSuggestion = await setSuggestions('setSPVSuggestion', SuggestionSPV);
            const { hash } = await writeContract(setSPVSuggestion)
            if (hash) {
                const data = await waitForTransaction({ hash: hash })
                if (data.status === "success") {
                    showSnackbar("Transaction Successful", 'success');
                    handleActiveStep(2);
                    setTimeout(() => {
                        handleSuccess();
                        handleModalClose();
                        handleActiveStep(-1)
                        return { data }
                    }, 1000)
                } else {
                    handleModalClose();
                    handleActiveStep(-1)
                    showSnackbar("Transaction Failed", 'error')
                    return
                }
            }
        }
    } catch (error) {
        console.error('Error in calling set suggestion methods:', error);
        throw error; // propagate the error
    }
}

export { dicSuggestions };

const second = 86400;

const yearsInSecond = (years) => {
    return parseInt(years) * second * 365
}
const monthsInSecond = (month) => {
    return parseInt(month) * second * 30
}
const daysInSecond = (day) => {
    return parseInt(day) * second
}