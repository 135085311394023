import React, { useState, useEffect } from 'react';
import OtpInput from 'react-otp-input';
import { ArrowBack } from '@mui/icons-material';
import { Box, Grid, Typography, Button, FormGroup, Stack } from '@mui/material';
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { postApi } from '../../Api/Api';
import { NewLogo } from '../../Components/Images/Images'
import { useSnackbar } from '../../Contexts/SnackbarContext';
import CommonBackdropLoader from '../../Components/Common/CommonBackdropLoader';
import Cookies from "js-cookie";

export default function OtpVerification() {
   // Importing necessary dependencies and custom hooks
   const { showSnackbar } = useSnackbar();

   // State variables for user data and OTP handling
   const [firstName, setFirstName] = useState('');
   const [email, setEmail] = useState("")
   const [phoneNumber, setPhoneNumber] = useState("")
   const [id, setId] = useState("")
   const [otp, setOtp] = useState('');
   const [displayOtp, setDisplayOtp] = useState('')
   const [otpTimer, setOtpTimer] = useState(0)
   const [isDisabled, setIsDisabled] = useState(false)
   const [loading, setLoading] = useState(false);


   // Navigation and location handling
   const navigate = useNavigate();
   const location = useLocation();
   const state = location?.state;

   /**
    * useEffect to handle initial data loading and redirection
    * Redirects to the registration page if email is not present in state
    * Sets initial values based on state data
    * Sets the initial OTP timer countdown (5 minutes)
    */
   useEffect(() => {
      if (!state?.data?.email) {
         navigate('/register');
      }
      setEmail((state?.data?.email));
      setFirstName(state?.data?.first_name);
      setId(state?.data?.id);
      setPhoneNumber(state?.data?.phone_number)
      // setDisplayOtp(state?.data?.otp);
      setOtpTimer(60 * 1); //timer countdown of otp
   }, [state])

   // Function to handle changes in OTP input
   const handleChange = (otp) => {
      setOtp(otp);
   }

   /**
    * Function to handle form submission
    * Validates the OTP length and sends a request to verify the OTP
    * Displays success or error message based on the response
    * Redirects to the login page after 2 seconds if verification is successful
    */
   const handleSubmit = () => {
      if (otp.length === 6) {
         let body = {
            user_id: id,
            otp: otp
         }
         setLoading(true)
         postApi(`/user/otp_verify`, body).then((res) => {
            if (res?.data?.code === 200) {
               showSnackbar(res?.data?.message, 'success');
               setTimeout(async () => { // redirect to login page after 2 sec after success message
                  if (state.signedWith) {
                     const loginRes = await postApi(`/user/login/`, { wallet_address: state.signedWith });
                     if (loginRes?.data?.code === 200) {
                        localStorage.setItem("login", true);
                        localStorage.setItem("user_data", JSON.stringify(loginRes?.data?.data));
                        // localStorage.setItem("token", loginRes.data.data.token);
                        Cookies.set("token", loginRes.data.data.token, {
                           expires: new Date(Date.now() + 2 * 60 * 60 * 1000), // 2 hours
                           secure: true,       // Only sent over HTTPS
                           sameSite: 'Strict'  // Prevent CSRF
                        });
                        if (loginRes.data?.data?.kycStatus === false) {
                           window.history.replaceState({}, "", "/");
                           navigate("/", { state: { from: "/login" } });
                        } else { //kyc is done already
                           window.history.replaceState({}, "", "/");
                           navigate("/")
                        }
                     }
                  } else {
                     navigate('/login', { state: res?.data });
                  }
               }, 2000);
            } else { // something went wrong user or backend side
               showSnackbar(res?.data?.message, 'error');
            }
            setLoading(false)
         })
      } else {
         showSnackbar("Please enter 6 digit OTP", 'error');
         setLoading(false)
      }
   }

   /**
    * Function to resend the OTP link
    * Prevents multiple clicks within the OTP timer duration
    * Sends a request to resend OTP and updates the UI accordingly
    */
   const resendLink = (event) => {
      event.preventDefault();
      if (otpTimer > 0) {
         return
      }
      event.preventDefault();
      postApi(`/user/resendOtp`, { email: email }).then((res) => {
         if (res?.data?.code === 200) {
            // setIsDisabled(false);
            showSnackbar("OTP re-sent successfully", 'success');
            // setDisplayOtp(res?.data?.data?.otp);
            setOtpTimer(60 * 1); //timer countdown of otp
         } else { // something went wrong user or backend side
            showSnackbar(res?.data?.message, 'error');
         }
      })
   }

   // Function to format the time in minutes and seconds
   const formatTime = (time) => {
      const minutes = Math.floor(time / 60);
      const seconds = time % 60;
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
   };

   /**
    * useEffect to handle the countdown of the OTP timer
    * Decrements the timer every second and cleans up the timer when the component unmounts
    */
   useEffect(() => {
      if (otpTimer > 0) {
         const timer = setInterval(() => {
            setOtpTimer(otpTimer - 1);
         }, 1000);

         // Clean up the timer when the component unmounts
         return () => clearInterval(timer);
      }
   }, [otpTimer]);

   // CSS styles for disabled state
   const disabledCss = {
      color: '#ccc',
      pointerEvents: 'none', /* Disable pointer events on the link */
      cursor: 'not-allowed',
   }

   function maskPhoneNumber(phoneNumber) {
      const firstPart = phoneNumber.slice(0, 2);
      const lastPart = phoneNumber.slice(-2);
      const maskedMiddle = '*'.repeat(phoneNumber.length - 4);
      return firstPart + maskedMiddle + lastPart;
   }

   return (
      <>
         <Box className='otpVerification-page auth-page' >
            < Box className='auth-section' >
               <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                     <Box className='auth-left'>
                        <Box className='content'>
                           <Box className='heading-wrap' textAlign={'left'}>
                              <Typography component="h1" className='title'>Supercharged Investments Powered By The Blockchain</Typography>
                              <Typography component="p" className='sub-title'>Lower fees • Simple process • Crypto & fiat friendly</Typography>
                           </Box>
                        </Box>
                        {/* Race logo */}
                        <Box className="auth-logo">
                           <Link to="/">
                              <Box component="img" src={NewLogo} alt="Race logo" />
                           </Link>
                        </Box>
                     </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                     <Box className='auth-right'>
                        <Box className="backIcon-btn">
                           <Link to={-1}><ArrowBack /></Link>
                        </Box>
                        {/* Start Form */}
                        <Box className='auth-form-wrap'>
                           <Box className='heading-wrap'>
                              <Typography component="h1" className='title'>OTP Verification</Typography>
                              <Typography component="p" className='sub-title'>Hello {firstName ? firstName : "John"},</Typography>
                              <Typography component="p" className='sub-title'>Thank you for signing up. We’ve sent an OTP to your email address {email ? email : "email@email.com"} {phoneNumber ? `and phone number ${maskPhoneNumber(phoneNumber)}` : null}. Please enter the OTP below to activate your account.</Typography>
                           </Box>
                           <Box className='auth-form' mt={4}>
                              <FormGroup className='authFormGroup-otp' sx={{ flexDirection: 'row', mb: 4 }}>
                                 <OtpInput
                                    value={otp}
                                    renderInput={(props) => <input className='form-control' {...props} />}
                                    onChange={handleChange}
                                    numInputs={6}
                                    isInputNum={true}
                                    inputType='password'
                                    className={'form-control'}
                                    inputStyle={'form-control'}
                                    shouldAutoFocus={true}
                                 />
                              </FormGroup>
                              <Stack className="alreadyAc-des" direction="row" justifyContent={'space-between'} mb={3} >
                                 <Box>
                                    Did not receive the OTP? <Link to='#' style={otpTimer > 0 ? disabledCss : {}} onClick={resendLink} >RESEND </Link>
                                 </Box>
                                 <Typography>{formatTime(otpTimer)}</Typography>
                              </Stack>
                              <Box className='btn-wrap'>
                                 <Button variant="contained" type="submit" fullWidth className='btn-rounded btn-blue-600' onClick={() => handleSubmit()}>Submit</Button>
                              </Box>
                           </Box>
                        </Box>
                        {/* End Form */}
                     </Box>
                  </Grid>
               </Grid>
            </Box >
         </Box >
         <CommonBackdropLoader loading={loading} />

      </>
   );
}