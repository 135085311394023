export function formatNumber(number) {
    return number.toLocaleString('en-US');
}

export function formatNumberToPercentage(number) {
    return number.toLocaleString('en-US', {
        style: 'percent'
    });
}

export function formatNumberWithDecimal(number){
    return  new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number);
}



