import React, { useState, useEffect } from 'react'
import { Backdrop, Box, CircularProgress, Divider, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import DropdownBox from '../InvestorOnly/Component/DropdownBox'
import { useSelector, useDispatch } from "react-redux";
import { GetApiParam, updateApi } from '../Api/Api';
import { updateStep } from "../features/auth/tooltipSlice"

export default function AssetDetails({ details, isLoan, isParcel, isSale, isFraction, isFundFraction }) {

    const [tolPosition, setTolPosition] = useState(0);

    const step = useSelector((state) => {
        if (isLoan) {
            return state.tooltipSlice.loan_step_number
        } else if (isParcel) {
            return state.tooltipSlice.parcel_step_number
        } else if (isSale) {
            return state.tooltipSlice.sale_step_number
        } else if (isFraction) {
            return state.tooltipSlice.re_step_number
        } else if (isFundFraction) {
            return state.tooltipSlice.funds_step_number
        }
    });
    const isActive = useSelector((state) => state.tooltipSlice.active_how_it_work);

    const [loading, setLoading] = useState(false);
    const [loadingApi, setLoadingApi] = useState(false);

    // Get the dispatch function from the Redux store
    const dispatch = useDispatch();

    const localData = localStorage.getItem("user_data")
        ? JSON.parse(localStorage.getItem("user_data"))
        : "";

    useEffect(() => {
        if ((tolPosition > 12 || tolPosition <= 11) && isLoan) {
            setLoading(false)
        } else if ((tolPosition > 9 || tolPosition <= 8) && isParcel) {
            setLoading(false)
        } else if ((tolPosition > 8 || tolPosition <= 7) && isSale) {
            setLoading(false)
        } else if ((tolPosition > 10 || tolPosition <= 9) && isFraction) {
            setLoading(false)
        } else if ((tolPosition > 8 || tolPosition <= 7) && isFundFraction) {
            setLoading(false)
        }
    }, [tolPosition])

    useEffect(() => {
        async function getStep() {
            try {
                setLoadingApi(true)
                const data = await GetApiParam("/user/currentTooltipPosition", { "user_id": localData.id });
                if (data?.data?.data && isActive) {
                    if (data.data?.data?.loan_step_number == 12 && isLoan) {
                        setTolPosition(data.data?.data?.loan_step_number == null ? 0 : data.data.data.loan_step_number)
                        setLoading(true)
                        scrollToPosition("scroll-to-assetdetails");
                    } else if ((data.data?.data?.parcel_step_number == 9) && isParcel) {
                        setLoading(true)
                        scrollToPosition("scroll-to-assetdetails");
                        setTolPosition(data.data?.data?.parcel_step_number == null ? 0 : data.data.data.parcel_step_number)
                    }
                    else if ((data.data?.data?.sale_step_number == 8) && isSale) {
                        setTolPosition(data.data?.data?.sale_step_number == null ? 0 : data.data.data.sale_step_number)
                        setLoading(true)
                        scrollToPosition("scroll-to-assetdetails");
                    }
                    else if ((data.data?.data?.re_step_number == 10) && isFraction) {
                        setTolPosition(data.data?.data?.re_step_number == null ? 0 : data.data.data.re_step_number)
                        setLoading(true)
                        scrollToPosition("scroll-to-assetdetails");
                    } else if ((data.data?.data?.funds_step_number == 8) && isFundFraction) {
                        setTolPosition(data.data?.data?.funds_step_number == null ? 0 : data.data.data.funds_step_number)
                        setLoading(true)
                        scrollToPosition("scroll-to-assetdetails");
                    }
                }
                setLoadingApi(false)
            } catch (error) {
                console.log('error', error)
                setLoadingApi(false)
            }
        }
        const timer = setTimeout(() => {
            if (localData && localData?.id) {
                getStep();
            }
        }, 100);
        return () => clearTimeout(timer)
    }, [step, isActive])

    async function setStep(tolPositionData) {
        try {
            let body = { "loan_step_number": tolPositionData };
            if (isParcel) {
                body = { "parcel_step_number": tolPositionData };
            } else if (isSale) {
                body = { "sale_step_number": tolPositionData };
            } else if (isFraction) {
                body = { "re_step_number": tolPositionData };
            } else if (isFundFraction) {
                body = { "funds_step_number": tolPositionData };
            }
            const data = await updateApi("/user/updateUser/" + localData?.id, body);
            if (data?.data?.data) {
                setLoading(false)
                setTolPosition(0)
            }

        } catch (error) {
            console.log('error', error)
        }
    }

    const scrollToPosition = (findId) => {
        setTimeout(() => {
            const element = document.getElementById(`${findId}`);
            if (element) {
                const offset = 0;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth"
                });
            }
        }, 200);
    }

    const handleTolPosition = async (data) => {
        await setStep(tolPosition + data)
        if (isLoan)
            dispatch(updateStep({ key: 'loan_step_number', value: tolPosition + data }));
        if (isSale)
            dispatch(updateStep({ key: 'sale_step_number', value: tolPosition + data }));
        if (isParcel)
            dispatch(updateStep({ key: 'parcel_step_number', value: tolPosition + data }));
        if (isFraction)
            dispatch(updateStep({ key: 're_step_number', value: tolPosition + data }));
        if (isFundFraction)
            dispatch(updateStep({ key: 'funds_step_number', value: tolPosition + data }));

        if (data < 0) {
            scrollToPosition("pd-sidebar-priceoverview");
        } else {
            if (isSale || isParcel) {
                scrollToPosition("scroll-to-document");
            } else {
                scrollToPosition("scroll-to-dealterm");
            }
        }
    }

    return (
        <>
            {details && loading && details?.length ?
                <Box className="pd-sidebar-assetDetails" id="scroll-to-assetdetails" sx={{
                    position: 'relative', backgroundColor: "#06487E",
                    color: "white", borderRadius: "8px", zIndex: "98",
                    padding: '4px'
                }} >
                    <DropdownBox render={
                        <Box >
                            <Stack className="pd-sidebar-heading" direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                                <Typography className="title" component={'h4'}>Asset Details</Typography>
                            </Stack>

                            <Box className="pd-sidebar-detailsList" mt={0.5}>
                                {
                                    details?.map((val, ind) => {
                                        return (
                                            <Stack className="detailsList" direction="row" spacing={1} justifyContent="space-between" alignItems="center" key={ind}>
                                                <Typography className='title'>{val.title}</Typography>
                                                <Typography className='subtitle'>{val.value}</Typography>
                                            </Stack>
                                        )
                                    })
                                }
                            </Box>
                        </Box>

                    }
                        setState={handleTolPosition} description={"The specifications of the assets are provided for your reference"} position={"left"}
                    />
                </Box>
                :
                <Box className="pd-sidebar-assetDetails" id="scroll-to-assetdetails">
                    <Stack className="pd-sidebar-heading" direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                        <Typography className="title" component={'h4'}>Asset Details</Typography>
                    </Stack>

                    <Box className="pd-sidebar-detailsList" mt={0.5}>
                        {
                            details?.map((val, ind) => {
                                return (
                                    <Stack className="detailsList" direction="row" spacing={1} justifyContent="space-between" alignItems="center" key={ind}>
                                        <Typography className='title'>{val.title}</Typography>
                                        <Typography className='subtitle'>{val.value}</Typography>
                                    </Stack>
                                )
                            })
                        }
                    </Box>
                    <Divider style={{ marginTop: '24px' }} />
                </Box>
            }
            {loading &&
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => 97 }}
                    open={loading}
                >
                </Backdrop>
            }
            {loadingApi &&
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => 97 }}
                    open={loadingApi}
                >
                    <CircularProgress />
                </Backdrop>
            }
        </>
    )
}

AssetDetails.propTypes = {
    details: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        value: PropTypes.string
    })),
    editable: PropTypes.bool
}
