import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ViewComfyAltIcon from '@mui/icons-material/ViewComfyAlt';
import RepeatIcon from '@mui/icons-material/Repeat';
import { Link } from "react-router-dom";
import "./ServiceProviderDashboard.css";
import { Grid, Typography, TextField ,IconButton, Modal} from "@mui/material";
import SkillChip from "../Components/Chips/SkillChip/SkillChip";
import CloseIcon from "@mui/icons-material/Close";
import { calculateDaysSinceCreation, validAmountCheck } from "../../Utils/utils";
import { postApi, GetApiParam } from "../../Api/Api";
import { getUserDataFromLocalStorage } from "../../Services/localstorage.service";
import SnackbarAlert from "../../Components/Common/SnackbarAlert";
import { useSnackbar } from "../../Contexts/SnackbarContext";
import SpTransactionProgressModal from "../SmartContracts/SpTransactionProgressModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "var(--blue-900)",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderWidth: "0px",
  borderRadius: "16px",
  "@media (max-width: 600px)": {
    width: "350px", // Set a different height for small screens
  },
  // Additional media queries for other screen sizes if needed
  "@media (min-width: 601px) and (max-width: 900px)": {
    // Custom styles for devices between 601px and 900px width
    width: "580px",
  },
  "@media (min-width: 901px) and (max-width: 1200px)": {
    // Custom styles for devices between 901px and 1200px width
    width: "750px",
    maxHeight: "300px",
    overflow: "auto",
  },
  // Specify the maximum width for large screens
  "@media (min-width: 1201px)": {
    maxWidth: "1043px",
    maxHeight: "525px",
    overflow: "auto",
  },
};

const SavedRequestDrawer = ({ open ,onClose, drawerData }) => {
  const { showSnackbar } = useSnackbar();
  const [openDrawer ,setOpenDrawer]= useState(false)
  const [state, setState] = useState({right: false,});
  const [profilestate, setProfileState] = useState(false);
  const [blockchainProfileState, setBlockchainProfileState] = useState(false);
  const localData = getUserDataFromLocalStorage();

  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [openModal, setOpenModal] = useState(false);
  const [quoteAmount, setQuoteAmount] = useState('');
  const [quoteAmountError, setQuoteAmountError] = useState('');
  const [openTransactionModal, setOpenTransactionModal] = useState(false);

  useEffect(() => {
    // setState({ ...state, [anchor]: open });
    if(open){
      setOpenDrawer(open)
    }
  }, [open]);

  /**
   * Checking for profile that user created profile or not
   */
  useEffect(() => {
    async function profileCheck() {
      try {
        const profileCheckResponce = await GetApiParam(
          "/services/checkProfile",
          { user_id: localData?.id ? localData?.id : "" }
        );
        setProfileState(
          profileCheckResponce?.data?.code === 200
            ? profileCheckResponce?.data?.profile
            : ""
        );
        setBlockchainProfileState(profileCheckResponce?.data?.profileOnBlockChain);
      } catch (error) {
        console.log(error);
      }
    }
    profileCheck();
  }, []);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const sendOffer = () => {
    const err = validAmountCheck(quoteAmount);
    if(err){
      setQuoteAmountError(err);
      return;
    }
    setOpenTransactionModal(true);
    return;
    const userData = getUserDataFromLocalStorage();
    const payload = {
      project_id: drawerData?.id,
      spv_id: userData.id,
      offer_amount: quoteAmount
    }
    postApi(`/services/spvOffer`, payload).then((res) => {
      if (res?.status === 200) {
          if(res?.data?.code === 200){
            showSnackbar("Submitted Successfully", "success");
            setOpenModal(false);
            setQuoteAmount('');
            setQuoteAmountError('');
          }else if(res?.data?.code === 201){
            showSnackbar(res?.data?.message, "warning");
            setOpenModal(false);
            setQuoteAmount('');
            setQuoteAmountError('');
          }else{
            showSnackbar(res?.data?.message, "error");
            setOpenModal(false);
            setQuoteAmount('');
            setQuoteAmountError('');
          }
      } else { // something went wrong user or backend side
        showSnackbar("Something went wrong", "error");
      }
    })
  }
  const handleClose = (event, reason) => {
    setOpenSnackbar(false);
  };
  const submitRequest = () => {
    setOpenModal(true);
  }
  const redirectTonBording = () => {
    if(profilestate === true && blockchainProfileState === false){
      showSnackbar("We've updated app version, please migrate your profile data from your profile section, before submit offer", "warning");
    }else{
      showSnackbar("Please create profile first", "warning");
    }
  }

  const confirmStake = async () => {
    setOpenTransactionModal(false)
    setOpenModal(false);
  }

  const handleModalClose = () => {
    setOpenTransactionModal(false);
  }

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 650,
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      className="save-req-draw"
      id="scroller-wrapper"
    >
      <Box className="top-view">
        <Box>
    
          <IconButton
            aria-label="delete"
            variant="contained"
            className="btn-golden btn-rounded btn-large closeIconProposer"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box>
          <Link to={`/service-provider/view-single-request/${drawerData?.id}`}>
            <Button
              variant="contained"
              startIcon={<RemoveRedEyeIcon />}
              className="btn-golden btn-rounded btn-large"
            >
              View Full Request
            </Button>
          </Link>
        </Box>
      </Box>

      <Box className="real-estate-container">
        <Typography className="font-28 text-white">
          {drawerData?.project_title}
        </Typography>
        <Typography className="font-14 text-white">
          Search more Auditing service requests | Posted {calculateDaysSinceCreation(drawerData?.createdAt)} ago
        </Typography>
        {profilestate && blockchainProfileState ?
        <Button
          className="btn-blue-500 btn-rounded btn-large submit-app-btn"
          variant="contained"
          startIcon={<ArrowForwardIcon />}
          onClick={submitRequest}
        >
          Submit application for this request
        </Button>
        :<Button
        className="btn-blue-500 btn-rounded btn-large submit-app-btn"
        variant="contained"
        startIcon={<ArrowForwardIcon />}
        onClick={redirectTonBording}
      >
        Submit application for this request
      </Button>}
        <SnackbarAlert open={openSnackbar} message="Submitted Successfully" severity="success" onClose={handleClose} />
        <Modal
          open={openModal}
          onClose={()=>{
            setOpenModal(false);
            setQuoteAmount('');
            setQuoteAmountError('');
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="send-custom-offer-container send-offer"
        >
          <Box sx={style}>
            <Box className="headContent cross-view-single">
              <IconButton onClick={()=>{
                setOpenModal(false);
                setQuoteAmount('');
                setQuoteAmountError('');
              }}>
                <CloseIcon className="cross-icon-modal" />
              </IconButton>
            </Box>
            <Typography id="modal-modal-title" component="h2" mb={4}>
              Send a custom offer
            </Typography>
            <Box className="step1-container ">
              <Grid container spacing={2}>
                <Grid item md={12} sm={12} xs={12}>
                  <Box className="left-div">
                    <Box className="input-box" mb={3}>
                      <TextField
                        id="outlined-basic"
                        label="Quotation"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        placeholder="Quotation"
                        value={"$" + quoteAmount}
                        onChange={(e) => {
                          setQuoteAmount(
                            e.target.value.substring(1)
                          )
                          const err = validAmountCheck(e.target.value.substring(1));
                          if(err)setQuoteAmountError(err);
                          else setQuoteAmountError('');
                        }
                        }
                      />
                      {quoteAmountError && <p style={{color: 'red'}}>{quoteAmountError}</p>}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box className="send-offer" textAlign={'center'}>
              <Button onClick={sendOffer} variant="contained" className="submit-btn" >
                Send Offer
              </Button>
            </Box>
          </Box>
        </Modal>
        <Typography className="text-white font-14" mt={2}>
          {drawerData?.describe_services}
        </Typography>
      </Box>
      <Box className="categoryCard" mt={2}>
        <Grid container spacing={2}>
          <Grid item md={4} sm={6} xs={12}>
            <Box className="category-card">
              <MonetizationOnIcon className="Icon" />
              <Box pt={3}>
                <Typography className="Heading font-24">
                  ${drawerData && drawerData?.budget_from} - ${drawerData && drawerData?.budget_to}
                </Typography>
                <Typography className="SubHeading  font-18">
                {drawerData?.budget === "Project Budget"
                                    ? "Fixed-price"
                                    : drawerData?.budget}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Box className="category-card">
              <ViewComfyAltIcon className="Icon" />
              <Box pt={3}>
                <Typography className="Heading font-24">
                  {drawerData && drawerData?.level_of_experience}
                </Typography>
                <Typography className="SubHeading  font-18">
                  Experience Level
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Box className="category-card">
              <RepeatIcon className="Icon" />
              <Box pt={3}>
                <Typography className="Heading font-24">
                  {drawerData?.work_time} Month
                </Typography>
                <Typography className="SubHeading  font-18">
                  Project Duration
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="comapany-profile-skills" mt={3}>
        <Typography component="h4" className="title font-18">
          Skills & Expertise
        </Typography>

        <Box mt={2}>
          <Box className="skill-section">
            <Grid container spacing={2} mt={1}>
              {drawerData?.skills ? 
              drawerData?.skills?.map((item, index) => {
                return <Grid xs="auto" ml={2} > <SkillChip key={index} label={item.skill_name} />  </Grid>;
              })
              : drawerData?.skillData?.map((item, index) => {
                return <Grid xs="auto" ml={2} > <SkillChip key={index} label={item.skill_name} />  </Grid>;
              })}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
  return (
    <Box className="save-request-drawer-main">
      <div>
        {["right"].map((anchor) => (
          <React.Fragment key={anchor}>
            <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>
            <Drawer
              anchor={anchor}
              open={openDrawer}
              onClose={onClose}
            >
              {list(anchor)}
            </Drawer>
          </React.Fragment>
        ))}
      </div>
      {openTransactionModal ? <SpTransactionProgressModal identity={'send-offer'} confirmStake={confirmStake} propData={{blockchain_id: drawerData?.blockchain_id, offerAmount: quoteAmount, contract_version: drawerData?.contract_version }} handleModalClose={handleModalClose} openTransactionModal={openTransactionModal} /> : null}
    </Box>
  );
};

export default SavedRequestDrawer;
