import React, { useEffect, useState } from 'react';
import ReactImageGallery from 'react-image-gallery';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import VideoThumbnail from 'react-video-thumbnail';
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { GetApiParam, updateApi } from "../Api/Api";
import DropdownBox from "../InvestorOnly/Component/DropdownBox";
import { updateStep } from "../features/auth/tooltipSlice";
import { Box, Typography, useMediaQuery, Backdrop } from '@mui/material';
import { GetContractAddress } from '../Components/Common/GetContractAddress';
import { CONTRACT_NAME } from '../constants';

export default function GallerySlider({ images, coverPhoto, nft_id, isOnboard, isLoan, isParcel, isSale, isFraction, isFundFraction, assetData, isTokenize }) {
    const [data, setData] = useState([]);
    const [tolPosition, setTolPosition] = useState(0);
    const [nftAddress, setNftAddress] = useState('');

    const [alphanumericNftID, setAlphanumericNftID] = useState('');

    const step = useSelector((state) => {
        if (isLoan) {
            return state.tooltipSlice.loan_step_number
        } else if (isParcel) {
            return state.tooltipSlice.parcel_step_number
        } else if (isSale) {
            return state.tooltipSlice.sale_step_number
        } else if (isFraction) {
            return state.tooltipSlice.re_step_number
        } else if (isFundFraction) {
            return state.tooltipSlice.funds_step_number
        }
    }
    );
    const isActive = useSelector((state) => state.tooltipSlice.active_how_it_work);

    const [loadingTooltip, setLoadingToolTip] = useState(false);
    const [step2, setStep2] = useState(false);
    const isXs = useMediaQuery('(max-width:600px)');

    const navigate = useNavigate()
    const dispatch = useDispatch()

    // const localData 
    const localData = localStorage.getItem("user_data")
        ? JSON.parse(localStorage.getItem("user_data"))
        : ""; //Getting  user data from local storage

    useEffect(() => {
        // Combine images and cover photo
        let combinedData = [];
        images?.forEach((val) => {
            combinedData.push({
                original: `${process.env.REACT_APP_IMAGE_URL}${val.images.path}`,
                thumbnail: val?.images?.type === 'mp4' ? `${process.env.REACT_APP_IMAGE_URL}${images[0]?.images.path}` : `${process.env.REACT_APP_IMAGE_URL}${val.images.path}`,
                isVideo: val?.images?.type === 'mp4' ? true : false,
            });
        });

        if (coverPhoto) {
            combinedData.unshift({
                original: `${process.env.REACT_APP_IMAGE_URL}${coverPhoto.path}`,
                thumbnail: `${process.env.REACT_APP_IMAGE_URL}${coverPhoto.path}`,
                isVideo: false,
            });
        }

        setData(combinedData);
    }, [images, coverPhoto]);

    const renderThumbnail = (item) => {
        if (item.isVideo) {
            return (
                <div className="video-thumbnail">
                    <VideoThumbnail
                        videoUrl={item.original}
                        style={{ width: '100%', height: '100%' }}
                        snapshotAtTime={2} // Adjust the time as needed
                    />
                    <div className="play-icon-overlay">
                        <PlayCircleOutlineIcon fontSize="large" />
                    </div>
                </div>
            );
        }

        return <img src={item.thumbnail} alt="" />;
    };

    const renderItem = (item) => {
        if (item.isVideo) {
            return (
                <div className="video-wrapper">
                    <video controls width="100%" height="auto" style={{ marginBottom: '40px' }} className='image-gallery-image'>
                        <source src={item.original} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            );
        }

        return (
            <div className="image-wrapper">
                <img src={item.original} alt="" height='auto' width="100%" className='image-gallery-image' />
            </div>
        );
    };

    useEffect(() => {
        if ((tolPosition > 18 || tolPosition < 17) && isLoan) {
            setLoadingToolTip(false)
            setStep2(false)
        } else if ((tolPosition > 13 || tolPosition < 12) && isParcel) {
            setLoadingToolTip(false)
            setStep2(false)
        } else if ((tolPosition > 12 || tolPosition < 11) && isSale) {
            setLoadingToolTip(false)
            setStep2(false)
        } else if ((tolPosition > 15 || tolPosition < 14) && isFraction) {
            setLoadingToolTip(false)
            setStep2(false)
        } else if ((tolPosition > 13 || tolPosition < 12) && isFundFraction) {
            setLoadingToolTip(false)
            setStep2(false)
        }
    }, [tolPosition])

    useEffect(() => {
        async function getStep() {
            try {
                const data = await GetApiParam("/user/currentTooltipPosition", { "user_id": localData.id });
                if (data?.data?.data && isActive) {
                    if (data?.data?.data) {
                        if (isLoan) {
                            if (data.data?.data?.loan_step_number == 17) {
                                setLoadingToolTip(true)
                                setTolPosition(data.data?.data?.loan_step_number == null ? 0 : data.data.data.loan_step_number)
                            }
                            else if (data.data?.data?.loan_step_number == 18) {
                                setStep2(true)
                                setTolPosition(data.data?.data?.loan_step_number == null ? 0 : data.data.data.loan_step_number)
                            }
                        } else if (isParcel) {
                            if (data.data?.data?.parcel_step_number == 12) {
                                setLoadingToolTip(true)
                                setTolPosition(data.data?.data?.parcel_step_number == null ? 0 : data.data.data.parcel_step_number)
                            }
                            else if (data.data?.data?.parcel_step_number == 13) {
                                setStep2(true)
                                setTolPosition(data.data?.data?.parcel_step_number == null ? 0 : data.data.data.parcel_step_number)
                            }
                        }
                        else if (isSale) {
                            if (data.data?.data?.sale_step_number == 11) {
                                setLoadingToolTip(true)
                                setTolPosition(data.data?.data?.sale_step_number == null ? 0 : data.data.data.sale_step_number)
                            }
                            else if (data.data?.data?.sale_step_number == 12) {
                                setStep2(true)
                                setTolPosition(data.data?.data?.sale_step_number == null ? 0 : data.data.data.sale_step_number)
                            }
                        }
                        else if (isFraction) {
                            if (data.data?.data?.re_step_number == 14) {
                                setLoadingToolTip(true)
                                setTolPosition(data.data?.data?.re_step_number == null ? 0 : data.data.data.re_step_number)
                            }
                            else if (data.data?.data?.re_step_number == 15) {
                                setStep2(true)
                                setTolPosition(data.data?.data?.re_step_number == null ? 0 : data.data.data.re_step_number)
                            }
                        } else if (isFundFraction) {
                            if (data.data?.data?.funds_step_number == 12) {
                                setLoadingToolTip(true)
                                setTolPosition(data.data?.data?.funds_step_number == null ? 0 : data.data.data.funds_step_number)
                            }
                            else if (data.data?.data?.funds_step_number == 13) {
                                setStep2(true)
                                setTolPosition(data.data?.data?.funds_step_number == null ? 0 : data.data.data.funds_step_number)
                            }
                        }
                    }
                }
            } catch (error) {
                console.log('error', error)
            }
        }
        // Call getStep initially
        const timer = setTimeout(() => {
            if (localData && localData?.id) {
                getStep();
            }
        }, 100);
        return () => clearTimeout(timer)
    }, [step, isActive])

    async function setStep(tolPositionData) {
        try {
            let body = { "loan_step_number": tolPositionData };
            if (isParcel) {
                body = { "parcel_step_number": tolPositionData };
            } else if (isSale) {
                body = { "sale_step_number": tolPositionData };
            } else if (isFraction) {
                body = { "re_step_number": tolPositionData };
            } else if (isFundFraction) {
                body = { "funds_step_number": tolPositionData };
            }
            const data = await updateApi("/user/updateUser/" + localData?.id, body);
            if (data?.data?.data) {
                if (isLoan) {
                    if (data.data?.data?.loan_step_number == 17) {
                        setLoadingToolTip(true)
                        setTolPosition(data.data?.data?.loan_step_number == null ? 0 : data.data.data.loan_step_number)
                    }
                    else if (data.data?.data?.loan_step_number == 18) {
                        setStep2(true)
                        setTolPosition(data.data?.data?.loan_step_number == null ? 0 : data.data.data.loan_step_number)
                    }
                } else if (isParcel) {
                    if (data.data?.data?.parcel_step_number == 12) {
                        setLoadingToolTip(true)
                        setTolPosition(data.data?.data?.parcel_step_number == null ? 0 : data.data.data.parcel_step_number)
                    }
                    else if (data.data?.data?.parcel_step_number == 13) {
                        setStep2(true)
                        setTolPosition(data.data?.data?.parcel_step_number == null ? 0 : data.data.data.parcel_step_number)
                    }
                }
                else if (isSale) {
                    if (data.data?.data?.sale_step_number == 11) {
                        setLoadingToolTip(true)
                        setTolPosition(data.data?.data?.sale_step_number == null ? 0 : data.data.data.sale_step_number)
                    }
                    else if (data.data?.data?.sale_step_number == 12) {
                        setStep2(true)
                        setTolPosition(data.data?.data?.sale_step_number == null ? 0 : data.data.data.sale_step_number)
                    }
                }
                else if (isFraction) {
                    if (data.data?.data?.re_step_number == 14) {
                        setLoadingToolTip(true)
                        setTolPosition(data.data?.data?.re_step_number == null ? 0 : data.data.data.re_step_number)
                    }
                    else if (data.data?.data?.re_step_number == 15) {
                        setStep2(true)
                        setTolPosition(data.data?.data?.re_step_number == null ? 0 : data.data.data.re_step_number)
                    }
                } else if (isFundFraction) {
                    if (data.data?.data?.funds_step_number == 12) {
                        setLoadingToolTip(true)
                        setTolPosition(data.data?.data?.funds_step_number == null ? 0 : data.data.data.funds_step_number)
                    }
                    else if (data.data?.data?.funds_step_number == 13) {
                        setStep2(true)
                        setTolPosition(data.data?.data?.funds_step_number == null ? 0 : data.data.data.funds_step_number)
                    }
                }
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    const handleTolPosition = (data) => {

        if (isLoan)
            dispatch(updateStep({ key: 'loan_step_number', value: tolPosition + data }));
        if (isSale)
            dispatch(updateStep({ key: 'sale_step_number', value: tolPosition + data }));
        if (isParcel)
            dispatch(updateStep({ key: 'parcel_step_number', value: tolPosition + data }));
        if (isFraction)
            dispatch(updateStep({ key: 're_step_number', value: tolPosition + data }));
        if (isFundFraction)
            dispatch(updateStep({ key: 'funds_step_number', value: tolPosition + data }));

        if (data < 0) {
            if (step2) {
                setStep2(false)
            } else {
                setLoadingToolTip(false)
            }
        } else {
            if ((tolPosition >= 18 && isLoan) || (tolPosition >= 13 && isParcel) || (tolPosition >= 12 && isSale) || (tolPosition >= 15 && isFraction) || (tolPosition >= 13 && isFundFraction)) {
                dispatch(updateStep({ key: 'active_how_it_work', value: false }));
            }
        }
        if (step2 && data > 0) {
            setTolPosition(6)
        } else {
            setTolPosition(pre => pre + data)
        }
        if (step2 && data > 0) {
            setStep(6)
            setStep2(false);
            setLoadingToolTip(false)
        } else {
            setStep(tolPosition + data)
        }
    }

    useEffect(() => {
        // Function to convert a number to an 8-character alphanumeric string
        const convertToAlphanumeric = (num) => {
            // Convert the number to a string
            let numStr = num.toString();

            // Pad the string with leading zeros if necessary to make it 8 characters long
            numStr = numStr.padStart(8, '0');

            // Define a list of alphanumeric characters
            const alphanumeric = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

            // Function to get a random alphanumeric character
            const getRandomAlphanumeric = () => {
                return alphanumeric[Math.floor(Math.random() * alphanumeric.length)];
            };

            // Convert the padded number string to an alphanumeric string
            let alphaStr = '';
            for (let i = 0; i < numStr.length; i++) {
                // Get a random alphanumeric character based on the digit
                alphaStr += getRandomAlphanumeric();
            }

            setAlphanumericNftID(alphaStr)
        }
        if (nft_id) {
            convertToAlphanumeric(nft_id)
        }
    }, [nft_id])

    useEffect(() => {
        async function getNftAddress() {
            try {
                const response = await GetContractAddress(assetData, assetData?.contract_version, isTokenize ? CONTRACT_NAME.TOKENIZE_NFT : CONTRACT_NAME.NFT);
                if (response && response?.length) {
                    setNftAddress(response[0].address)
                }
            } catch (error) {
                console.log(error?.message)
            }
        }
        if (nft_id && assetData) {
            getNftAddress()
        }
    }, [nft_id, assetData])

    return (
        <Box className="details-thumbnails-slider" id="scroll-to-gallery" sx={{
            zIndex: isOnboard && (loadingTooltip || step2) ? 98 : 1
            , position: 'relative',
            color: "white",
        }}>
            {
                isOnboard && step2 ?
                    <Box>
                        <Typography style={{ marginLeft: "8px", marginBottom: "10px", cursor: "pointer", wordBreak: "break-all" }}>
                            <DropdownBox render={<span style={{ marginLeft: "8px", marginBottom: "10px", cursor: "pointer", zIndex: 102, position: 'relative' }}> <Link to={`${process.env.REACT_APP_NFT_URL}token/${nftAddress}/instance/${nft_id}?tab=metadata`} target="_blank">NFT Id: {alphanumericNftID}</Link></span>
                            }
                                setState={handleTolPosition} description={"The unique ID assigned to each tokenized asset"} position={"bottom"}
                                last={true}
                            />
                            <span style={{ marginLeft: "8px", marginBottom: "10px", cursor: "pointer" }}> <Link to={`${process.env.REACT_APP_NFT_URL}token/${nftAddress}/instance/${nft_id}?tab=metadata`} target="_blank">NFT Contract Address: {nftAddress}</Link>
                            </span>
                        </Typography>
                    </Box> :
                    isOnboard && loadingTooltip ?
                        <Box>
                            <Typography style={{ marginLeft: "8px", marginBottom: "10px", cursor: "pointer", wordBreak: "break-all", }}><Link to={`${process.env.REACT_APP_NFT_URL}token/${nftAddress}/instance/${nft_id}?tab=metadata`} target="_blank">NFT Id: {alphanumericNftID}</Link>
                                <DropdownBox render={
                                    <span style={{ marginLeft: "8px", marginBottom: "10px", cursor: "pointer", zIndex: 102, position: 'relative' }}><Link to={`${process.env.REACT_APP_NFT_URL}token/${nftAddress}/instance/${nft_id}?tab=metadata`} target="_blank">NFT Contract Address: {nftAddress}</Link></span>

                                }
                                    setState={handleTolPosition} description={"Unique Address of the tokenized asset on the RACE chain, you can check the provenance of the asset through it’s address"} position={"bottom"}
                                />
                            </Typography>
                        </Box>
                        :
                        isOnboard ?
                            <Typography style={{ marginLeft: "8px", marginBottom: "10px", cursor: "pointer", wordBreak: "break-all", }}><Link to={`${process.env.REACT_APP_NFT_URL}token/${nftAddress}/instance/${nft_id}?tab=metadata`} target="_blank">NFT Id: {alphanumericNftID}</Link>&emsp; <Link to={`${process.env.REACT_APP_NFT_URL}token/${nftAddress}/instance/${nft_id}?tab=metadata`} target="_blank">NFT Contract Address: {nftAddress}</Link></Typography>
                            : null
            }

            {isTokenize ? <Typography style={{ marginLeft: "8px", marginBottom: "10px", cursor: "pointer", wordBreak: "break-all", }}><Link to={`${process.env.REACT_APP_NFT_URL}token/${nftAddress}/instance/${nft_id}?tab=metadata`} target="_blank">NFT Id: {alphanumericNftID}</Link>&emsp; <Link to={`${process.env.REACT_APP_NFT_URL}token/${nftAddress}/instance/${nft_id}?tab=metadata`} target="_blank">NFT Contract Address: {nftAddress}</Link></Typography> : null}

            {
                data && data.length > 0 ? (
                    <ReactImageGallery
                        items={data}
                        autoPlay={false}
                        showThumbnails={true}
                        thumbnailPosition={isXs ? 'bottom' : 'left'} // Set the position based on screen size
                        infinite={false}
                        showPlayButton={false}
                        lazyLoad={true}
                        useBrowserFullscreen={true}
                        originalHeight="426px"
                        originalWidth="200px"
                        showIndex={true}
                        disableThumbnailScroll={false}
                        showNav={false}
                        showBullets={true}
                        showFullscreenButton={true}
                        slideOnThumbnailOver={false}
                        disableThumbnailSwipe={false}
                        renderItem={renderItem}
                        renderThumbInner={renderThumbnail} // Use custom render for thumbnails
                    />
                ) : (
                    <Box>
                        <Typography variant="h6" sx={{ textAlign: 'center' }}>
                            No Images available
                        </Typography>
                    </Box>
                )
            }

            {
                (loadingTooltip || step2) ?
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => 97 }}
                        open={loadingTooltip || step2}
                    >
                    </Backdrop> : null
            }
        </Box >
    );
}
