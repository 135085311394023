import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Grid, Typography, Button, TextField, FormControl, InputAdornment, } from "@mui/material";
import { KeyboardArrowRight, AttachMoney } from "@mui/icons-material";
import { useForm } from "react-hook-form";

// Import custom components for this page
import { NewLogo } from "../../../Components/Images/Images";
import LinearProgressCompo from "./Components/LinearProgressCompo";
import "./Components/Questionnaire.css";
import { GetApi } from "../../../Api/Api";


export default function LuxuryCarDetails(props) {

    const [carType, setCarType] = useState([])
    const [carCondition, setCarCondition] = useState([])

    // Destructuring props to get functions and data required for the component
    const { handleNext, handleback, getProposalData, updateProposalData, saveProposal, getProgress } = props;

    // Retrieve current proposal data and progress
    var propData = getProposalData();
    let currentProgress = getProgress();


    // useForm hook to handle form validation and submission
    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        mode: 'onChange',
        defaultValues: {
            car_condition: propData?.carInformation?.car_condition ? propData?.carInformation?.car_condition : "",
            type_of_car: propData?.carInformation?.type_of_car ? propData?.carInformation?.type_of_car : "",
            // watch_scope_of_delivery: propData?.carInformation?.watch_scope_of_delivery ? propData?.carInformation?.watch_scope_of_delivery : "",
        }
    });

    /**
     * Calling Api for getting the deopdown values from API.
     */
    useEffect(() => {
        async function getWatchInventory() {
            try {
                // getting response from api
                const res = await GetApi(`/user/carInventoryData`);
                // setting data
                if (res?.data?.data?.length) {

                    const typeOfCar = res?.data?.data[0]?.type_of_car;

                    const carCondition = res?.data?.data[0]?.car_condition;


                    const formattedOptionsType = typeOfCar.map(option => ({
                        value: option,
                        label: option
                    }));

                    const formattedOptionsWatchCondition = carCondition.map(option => ({
                        value: option,
                        label: option
                    }));


                    setCarType([
                        { value: '', label: 'Select Type Of Car' }, // Default option
                        ...formattedOptionsType // Spread the options from API
                    ]);

                    setCarCondition([
                        { value: '', label: 'Select Condition' }, // Default option
                        ...formattedOptionsWatchCondition // Spread the options from API
                    ]);
                }
            } catch (error) {
                console.log(error)
            }
        }
        getWatchInventory()
    }, [])


    /**
     * Function setting the values in case of back and next in dropdown
     */
    useEffect(() => {
        // if (watchScope?.length && propData?.carInformation?.watch_scope_of_delivery) {
        //     setValue('watch_scope_of_delivery', propData?.carInformation?.watch_scope_of_delivery)
        // }

        if (carType?.length && propData?.carInformation?.type_of_car) {
            setValue('type_of_car', propData?.carInformation?.type_of_car)
        }

        if (carCondition?.length && propData?.carInformation?.car_condition) {
            setValue('car_condition', propData?.carInformation?.car_condition)
        }
    }, [carType, carCondition])

    /**
     * Function to handle form submission, updating 'propData' with the form data, and proceeding to the next step or saving and exiting.
     * @param {Object} data - The form data.
     * @param {Object} event - The form submission event.
     */
    const onSubmit = (data, event) => {
        const buttonText = event.nativeEvent.submitter.innerText;
        // Update 'propData' with the form data
        if (propData.carInformation) {
            propData.carInformation = { ...propData.carInformation, ...data };
        } else {
            propData.carInformation = data;
        }

        // Set watch model as asset title
        propData.asset_title = data.car_model;

        // Set watch address to null
        propData.asset_address = '';

        // Convert string to numeric for watch estimated value
        const stringWithoutCurrency = data?.car_estimated.replace(/[^0-9.]/g, ''); // Removes non-numeric characters
        const value = parseFloat(stringWithoutCurrency, 10); // Parses the string as a float
        propData.carInformation.car_estimated = value;

        // Update the main page 
        updateProposalData(propData);

        // Proceed to the next step or save and exit based on the button clicked
        if (buttonText.includes("Save and Exit")) {
            SaveAndExit();
        } else {
            handleNext();
        }
    }

    /**
     * Function to save the current proposal data and exit the current step/page.
     */
    const SaveAndExit = () => {
        saveProposal();
    }


    return (
        <Box className="projectProposer questionnaire-page">
            <Box className="questionnaire-section">
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Box className="auth-left">
                            {/* left-title */}
                            <Box className="quests-left-title">
                                <Typography component="h1" className="title">
                                    Next, we need some details of your assets
                                </Typography>
                            </Box>
                            {/* Logo */}
                            <Box className="auth-logo">
                                <Link to="/">
                                    <Box component="img" src={NewLogo} alt="Race logo" />
                                </Link>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Box className="auth-right quests-right-contents-box" component="form"
                            onSubmit={handleSubmit(onSubmit)}>
                            <Box textAlign={"right"}>
                                <Box component="span" className="icon" ml={1}>
                                    <Button id="my-button" className="btn-rounded btn-text-white btn-blue-600 saveAndExit" type="submit">
                                        Save and Exit
                                    </Button>
                                </Box>
                            </Box>
                            {/*Watch information Form starts from here */}
                            <Box className="questionnaire6 qsn-middle-content" sx={{ pt: 4, mb: 5 }} >
                                <Box className="auth-form" mt={4} >
                                    <Box mb={'30px'}>
                                        <FormControl fullWidth>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                label="Type Of Luxury Cars"
                                                id="outlined-select-country-native"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{
                                                    native: true
                                                }}
                                                name='type_of_car'
                                                {...register("type_of_car", {
                                                    required: "Type of Luxury Cars is required.",
                                                })}
                                                className='stIf-input'
                                                error={Boolean(errors.type_of_car)}
                                                helperText={errors.type_of_car?.message}
                                                sx={{ input: { color: '#64748B !important' } }}
                                            >
                                                {carType && carType?.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </FormControl>

                                    </Box>
                                    <Grid container spacing={1.5}>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <Box className="form-group label-field">
                                                <TextField
                                                    defaultValue={propData?.carInformation?.car_brand}
                                                    autoComplete="on"
                                                    type="text"
                                                    id="outlined-required"
                                                    InputLabelProps={{ shrink: true }}
                                                    placeholder="Enter Brand Here"
                                                    label={'Brand'}
                                                    {...register("car_brand", {
                                                        required: "Brand is required.",
                                                    })}
                                                    error={Boolean(errors.car_brand)}
                                                    helperText={errors.car_brand?.message}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <Box className="form-group label-field">
                                                <TextField
                                                    defaultValue={propData?.carInformation?.car_estimated?.toLocaleString("en-US")}
                                                    autoComplete="on"
                                                    id="outlined-required"
                                                    label="Estimated value at purchase"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <AttachMoney />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    {...register("car_estimated", {
                                                        required: "Estimated Value is required.",
                                                        validate: (value) => {
                                                            // Remove commas for validation and check if it's greater than 0
                                                            const numericValue = parseFloat(value.replace(/,/g, ""));
                                                            if (isNaN(numericValue) || numericValue <= 0) {
                                                                return "Please enter a valid estimated value greater than 0.";
                                                            }
                                                            return true;
                                                        },
                                                    })}
                                                    error={Boolean(errors.car_estimated)}
                                                    onInput={(event) => {
                                                        // Remove non-numeric characters except for a dot
                                                        const numericValue = event.target.value.replace(/[^\d.]/g, "");
                                                        if (numericValue) {
                                                            // Format the value with commas
                                                            event.target.value = Number(numericValue).toLocaleString("en-US");
                                                        }
                                                    }}
                                                    helperText={errors.car_estimated?.message}
                                                />
                                            </Box>
                                        </Grid>


                                    </Grid>
                                    <Grid container spacing={1.5}>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <Box className="form-group label-field">
                                                <TextField
                                                    defaultValue={propData?.carInformation?.car_model}
                                                    autoComplete="on"
                                                    type="text"
                                                    id="outlined-required"
                                                    InputLabelProps={{ shrink: true }}
                                                    placeholder="Enter Model Here"
                                                    label='Model'
                                                    {...register('car_model', {
                                                        required: 'Model is required'
                                                    })}
                                                    error={Boolean(errors.car_model)}
                                                    helperText={errors.car_model?.message}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <Box className="form-group label-field">
                                                <TextField
                                                    defaultValue={propData?.carInformation?.car_vin_number}
                                                    autoComplete="on"
                                                    type="text"
                                                    id="outlined-required"
                                                    InputLabelProps={{ shrink: true }}
                                                    placeholder="Enter VIN Number Here"
                                                    label='VIN Number'
                                                    {...register("car_vin_number", {
                                                        required: 'VIN Number is required',
                                                        // pattern: {
                                                        //     value: /^[A-HJ-NPR-Z0-9]{17}$/,
                                                        //     message: 'Invalid VIN Number format'
                                                        // }
                                                    })}
                                                    error={Boolean(errors.car_vin_number)}
                                                    helperText={errors.car_vin_number?.message}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    {/* <Box mb={'30px'}>
                                        <FormControl fullWidth>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                label="Scope of Delivery"
                                                id="outlined-select-country-native"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{
                                                    native: true
                                                }}
                                                name='watch_scope_of_delivery'
                                                {...register("watch_scope_of_delivery", {
                                                    required: "Scope of Delivery is required.",
                                                })}
                                                error={Boolean(errors.watch_scope_of_delivery)}
                                                helperText={errors.watch_scope_of_delivery?.message}
                                                className='stIf-input'
                                                sx={{ input: { color: '#64748B !important' } }}
                                            >
                                                {watchScope?.map(option => (
                                                    <option key={option.value} value={option.value}>{option.label}</option>
                                                ))}
                                            </TextField>
                                        </FormControl>
                                    </Box> */}
                                    <Box>
                                        <FormControl fullWidth>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                label="Condition"
                                                id="outlined-select-country-native"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{
                                                    native: true
                                                }}
                                                name='condition'
                                                {...register("car_condition", {
                                                    required: "Condition is required.",
                                                })}
                                                error={Boolean(errors.car_condition)}
                                                helperText={errors.car_condition?.message}
                                                className='stIf-input'
                                                sx={{ input: { color: '#64748B !important' } }}
                                            >
                                                {carCondition?.map((condition) => (
                                                    <option key={condition.value} value={condition.value}>
                                                        {condition.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </FormControl>
                                    </Box>
                                </Box>
                            </Box>
                            {/*Watch information Form ends here */}
                            <Box>
                                <Box className="questionnaire-progress" >
                                    <LinearProgressCompo value={currentProgress} />
                                </Box>
                                <Box
                                    sx={{ display: "flex", justifyContent: "space-between" }}
                                    mt={3}
                                >
                                    <Box textAlign={"left"} className="quests6-btn-box">
                                        <Button className='btn-rounded back-btn' onClick={handleback}>Back</Button>
                                    </Box>
                                    <Box textAlign={"right"} className="quests-btn-box">
                                        <Button
                                            id="my-button"
                                            // onClick={handleNext}
                                            endIcon={<KeyboardArrowRight />}
                                            className="btn-rounded btn-text-white btn-blue-600"
                                            type="submit"
                                        >
                                            Next
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box >
    );
}
