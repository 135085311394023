import { ethers } from "ethers";

/**
 * Function to convert a number to number * 10^2 using ethers.js
 * @param {Number} number
 * @returns {String} Converted number in Wei (10^2)
 */
export default function convertToTwoDecimal(number) {
    number = parseFloat(number).toFixed(2)
    const weiValue = ethers.parseUnits(number.toString(), 2);
    return weiValue.toString();
}