import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom";
import { tableCellClasses } from '@mui/material/TableCell';
import { Chip, TableCell, Button, Box, Paper, TableRow, TableHead, TableContainer, Table, TableBody, Typography } from '@mui/material';
import { CATEGORY_TYPE_ID, CONTRACT_NAME, SUB_CATEGORY_TYPE_ID } from '../../constants.js'
import { postApi } from '../../Api/Api.js';
import TransactionProgressModal from '../../Components/SmartContract/TransactionProgressModal.jsx';
import { formatNumber } from '../../Components/Common/USFormat.js';
import { spaceXFundTokenTotalSupply } from '../../Components/SmartContract/spaceXFundTokenTotalSupply.js';
import { viewListing } from '../../Components/SmartContract/viewListing.js';
import { GetContractAddress } from '../../Components/Common/GetContractAddress.js';
import './Transactions.css'
// import { getDecimalFromAddress } from '../../Components/Common/getDecimalFromAddress.js';
import { useSnackbar } from '../../Contexts/SnackbarContext.jsx';
// import { spaceXFundTokenBalanceOf } from '../../Components/SmartContract/spaceXFundTokenBalanceOf.js';
import { getFundEscrowWithdrawCount } from '../../Components/SmartContract/getFundEscrowWithdrawCount.js';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#05131B',
        color: '#94A3B8',
        borderBottom: '0px',
        '&:first-of-type': {
            borderRadius: '16px 0px 0px 16px',
        },
        '&:last-of-type': {
            borderRadius: '0px 16px 16px 0px',
        },
    },

    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: theme.palette.common.white,
        borderBottom: '0px'
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
}));


/**
 * @param {string} date  Date String
 * @returns  {string} Formatted Date String in MM/DD/YYYY format
 */
// const formatDate = (date) => {
//     let d = new Date(date);
//     let formattedDate = `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
//     return formattedDate;
// }

const FundWithdrawal = ({ setLoading }) => {

    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();
    const [offersData, setOffersData] = useState([])
    const [offersDataNew, setOffersDataNew] = useState([])
    const [openModalWithdrawEscrow, setOpenModalWithdrawEscrow] = useState(false);
    const [propData, setPropData] = useState();

    let u = localStorage.getItem('user_data');
    let User = JSON.parse(u);

    /**
     * function to get offers
     */
    const getOffers = async (param) => {
        try {
            setLoading(true)
            let res = await postApi('/proposer/proposerFundWithdrawal', param);
            if (res?.data?.data?.length) {
                setOffersData(res.data.data);
            } else {
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
        }
    }

    useEffect(() => {
        try {
            if (User.id) {
                getOffers({ investor_id: User.id });
            }
        } catch (error) {
            console.error(error)
        }
    }, [User.id])  // <-- Add User.id and getOffers as dependencies    

    /**
    * Function to handle modal close
    */
    const handleCloseWithdrawAmount = (data) => {

        if (data?.warning) {
            navigate('/user/transactions', { replace: true })
        }
        setOpenModalWithdrawEscrow(false);
    }

    /**
     * Function call after transaction confirm
     */
    const confirmWithdrawAmount = async (data, asset_id) => {
        try {
            getOffers({ investor_id: User.id, category_id: CATEGORY_TYPE_ID.FUNDS });
        } catch (error) {

        }
    }

    /**
   * Function call after transaction confirm
   */
    const openFundConfirmWithdrawAmount = async (data, asset_id) => {

        try {
            getOffers({ investor_id: User.id, category_id: CATEGORY_TYPE_ID.FUNDS });
        } catch (error) {
            console.error(error)
        }
    }


    /**
     * Function call after transaction confirm
     */
    const handleWithdrawAmount = (row) => {
        setPropData(row);
        setOpenModalWithdrawEscrow(true)
    }

    /**
     * function to handle status
     * @param {Object} row 
     * @returns 
     */
    const getStatusComponent = (row) => {
        return (
            <>
                {/* {row?.disable_btn ?
                    <>
                        <Chip label={'Withdrawn'} sx={{ color: '#13202D', bgcolor: '#34D399', borderRadius: '8px' }} />
                    </>
                    :
                    <Button
                        onClick={() => handleWithdrawAmount(row)}
                        className="status-button"
                        disabled={row?.disable_btn ? true : false}
                        sx={{ color: 'gray', bgcolor: 'lightgray', borderRadius: '8px' }} // Updated color and bgcolor to gray
                    >
                        Withdrawal
                    </Button>
                } */}
                <Chip label={'Withdrawn'} sx={{ color: '#13202D', bgcolor: '#34D399', borderRadius: '8px' }} />
            </>
        );
    };

    const getSpaceXToken = async (address, list_data) => {
        try {
            let offers = [];
            const contractCache = {}; // Cache to store contract address and ABI

            for (let i = 0; i < list_data.length; i++) {
                let totalToken = 0;
                const newData = list_data[i];
                const subCategory = newData?.asset_sub_category;

                let cacheKey;
                let marketplaceKey;
                let tokenKey;

                if (subCategory === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS) {
                    cacheKey = `${newData?.contract_version}_CLOSE_FUNDS`;
                    marketplaceKey = CONTRACT_NAME.CLOSE_FUNDS_MARKETPLACE;
                    tokenKey = CONTRACT_NAME.CLOSE_FUND_TOKEN;
                } else {
                    cacheKey = `${newData?.contract_version}_FUNDS`;
                    marketplaceKey = CONTRACT_NAME.FUNDS_MARKETPLACE;
                    tokenKey = CONTRACT_NAME.OPEN_FUND_TOKEN;
                }

                // Check if the contract data is already cached
                if (!contractCache[cacheKey]) {
                    const response = await GetContractAddress(newData, newData?.contract_version);
                    contractCache[cacheKey] = response.reduce((acc, item) => {
                        if (item.contract_name === marketplaceKey) {
                            acc.marketplace = item;
                        } else if (item.contract_name === tokenKey) {
                            acc.token = item;
                        }
                        return acc;
                    }, {});
                }

                const contracts = contractCache[cacheKey];
                const contractAddress = contracts.marketplace?.address;
                const contractAbi = contracts.marketplace?.abi;
                const spaceXTokenAbi = contracts.token?.abi;

                if (spaceXTokenAbi && contractAbi && contractAddress) {
                    let listingData = await viewListing(address, contractAddress, contractAbi, {
                        listing_id: newData?.listingId,
                        category_id: newData?.category_id,
                        asset_category_id: subCategory,
                    });

                    if (listingData) {
                        let tokenAddress;
                        if (subCategory === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS) {
                            tokenAddress = listingData?.fundType?.tokenAddress;
                        } else if (listingData?.fundType?.length) {
                            tokenAddress = listingData?.fundType[parseInt(newData?.class) === 1 ? 0 : 1]?.tokenAddress; // class 1->0,class->2,1 
                        }
                        if (tokenAddress) {
                            if (subCategory === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS) {
                                let totalSupply = await spaceXFundTokenTotalSupply(address, tokenAddress, spaceXTokenAbi);
                                // let spaceXToken = await spaceXFundTokenBalanceOf(address, tokenAddress, spaceXTokenAbi);
                                // console.log('close fund token', spaceXToken, totalSupply);
                                // if (spaceXToken > 0 && totalSupply > 0) {
                                //     totalToken += spaceXToken;
                                // }
                                let withdrawCount = await getFundEscrowWithdrawCount(address, tokenAddress, spaceXTokenAbi);
                                console.log('close fund token--->', i + 1, 'Enable', newData?.withdrawCount > withdrawCount && totalSupply > 0);
                                if (withdrawCount < newData?.withdrawCount && totalSupply > 0) {
                                    totalToken = 1;
                                }
                            } else {
                                let totalSupply = await spaceXFundTokenTotalSupply(address, tokenAddress, spaceXTokenAbi);
                                let withdrawCount = await getFundEscrowWithdrawCount(address, tokenAddress, spaceXTokenAbi);
                                console.log('open fund token--->', i + 1, 'Enable', newData?.withdrawCount > withdrawCount && totalSupply > 0);
                                if (withdrawCount < newData?.withdrawCount && totalSupply > 0) {
                                    totalToken = 1;
                                }
                            }
                        }
                    }

                    if (totalToken > 0) {
                        offers.push({ ...newData, disable_btn: false });
                    } else {
                        offers.push({ ...newData, disable_btn: true });
                    }
                }
            }

            setOffersDataNew([...offers]);
            setLoading(false);
        } catch (error) {
            console.log('fund-withdrawal', error);
            showSnackbar('Error in fetching data', 'error');
            setLoading(false);
        }
    };

    let userData = JSON.parse(localStorage.getItem("user_data"));
    useEffect(() => {
        if ((offersData?.length) && userData?.wallet_address) {
            setOffersDataNew(offersData?.map((item) => { return { ...item, "disable_btn": true } }))
            getSpaceXToken(userData?.wallet_address, offersData)
        }
    }, [offersData])

    return (
        <>
            <Box className="filter-panel" mt={4}>
                <Box className="fp-left">
                    <Box className="heading-left">
                        {/* Changed from Fund WithDrawal to yield Withdrwal after client discussion */}
                        <Typography component={"h6"}>
                            Yield Withdrawal
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <TableContainer component={Paper} className='TableContainer'>
                <Table aria-label="customized table">
                    <TableHead className='TableHeader'>

                        <TableRow>
                            <StyledTableCell width={'20%'}>Project Name</StyledTableCell>
                            <StyledTableCell width={'16%'} align="left">Fund Type</StyledTableCell>
                            <StyledTableCell width={'16%'} align="left">Fund Class</StyledTableCell>
                            <StyledTableCell width={'16%'} align="left">Investment Amount</StyledTableCell>
                            <StyledTableCell width={'16%'} align="left">Received Amount</StyledTableCell>
                            {/* <StyledTableCell width={'16%'} align="center">Action</StyledTableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {offersDataNew?.map((row, i) => (
                            <StyledTableRow className='TableRow' key={`${row.asset_title}${i}`}>
                                <StyledTableCell component="th" scope="row">
                                    <Link to={`/project-details/${row.asset_id}`}>{row.asset_title}</Link>
                                </StyledTableCell>
                                <StyledTableCell align="left">{row?.asset_sub_category_name ? row?.asset_sub_category_name : '-'}</StyledTableCell>
                                <StyledTableCell align="left">{row?.class ? `Class ${parseFloat(row?.class) === 1 ? 'A' : 'B'}` : '-'}</StyledTableCell>
                                <StyledTableCell align="left">${formatNumber(parseFloat(row.balance_sum ? row.balance_sum : 0))}</StyledTableCell>
                                <StyledTableCell align="left">{`$${formatNumber(parseFloat(row?.return_amount ? row?.return_amount : 0))}`}</StyledTableCell>
                                {/* <StyledTableCell align="center">{row.status == OFFER_STATUS.COMPLETED ? formatDate(row.updatedAt) : '-'}</StyledTableCell> */}
                                {/* <StyledTableCell align="center">{
                                    getStatusComponent(row)
                                }</StyledTableCell> */}
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer >

            {/* code for pay remaining payment smart contract modal */}
            {openModalWithdrawEscrow ? <TransactionProgressModal identity={propData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ? 'space-x-withdraw-escrow' : 'open-fund-withdraw-escrow'} confirmStake={propData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ? confirmWithdrawAmount : openFundConfirmWithdrawAmount} propData={propData} handleModalClose={handleCloseWithdrawAmount} openTransactionModal={openModalWithdrawEscrow} /> : null}

        </>
    )
}

export default FundWithdrawal
