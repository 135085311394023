import React, { useState } from 'react';
import {
    Box,
    Chip,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Button
} from "@mui/material";
import Pagination from '@mui/material/Pagination';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from "react-router-dom";
// import { useSnackbar } from "../../../Contexts/SnackbarContext";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import RuleFolderIcon from '@mui/icons-material/RuleFolder';
// import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import config from '../../../Config/Config';
import { formatDate } from '../../../Utils/utils';
// import { updateApi } from "../../../Api/Api";
import ConfirmModal from "../../../Components/Modal/Confirm/Confirm";
import SpTransectionProgressModal from "../../../ServiceProvider/SmartContracts/SpTransactionProgressModal"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#053863",
      color: "#fff",
      borderBottom: "0px",
      "&:first-of-type": {
        borderRadius: "0px 0px 0px 0px",
      },
      "&:last-of-type": {
        borderRadius: "0px 0px 0px 0px",
      },
    },

    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      color: '#fff',
      borderBottom: "0px",
    },
}));

export default function JobList({ Title, fetchData, jobList, paginationData, currentPage, setCurrentPage, setItemsPerPage }) {
    const navigate = useNavigate();
    // const [ contractId, setContractId ] = useState();
    const [ contractBlockchainId, setContractBlockchainId ] = useState();
    const [ contractBlockchainIdVersion, setContractBlockchainIdVersion ] = useState();
    // const { showSnackbar } = useSnackbar();
    const [ openModal, setOpenModal ] = useState(false);
    const [openTransactionModal, setOpenTransactionModal] = useState(false);


    /**
   * Function for confirm staking
   * @param {*} data 
   */
    const confirmStake = (data) => {
      fetchData();
    }

    /**
     * Function to close Transection Modal
     */
    const handleModalClose = (data) => {
      setOpenTransactionModal(false);
    }
    const handleAction = (action) => {
      // let status = '';
      // if(action === 'confirm'){
      //   status = 5;
        // updateApi(`/services/contractStatusUpdate/${contractId}`, { status }).then((res) => {
        //   if (res?.status === 200) {
        //     if(res.data.code === 200){
        //       showSnackbar(res?.data?.message, 'success');
        //       setOpenModal(false);
        //       fetchData();
        //     }else{
        //       showSnackbar(res?.data?.message, 'error');
        //     }
        //   }
        // })
      // }else{
      //   status = 7;
      // }
    }
    const handlePageChange = (event, page) => {
      setCurrentPage(page);
    };
    const handelRowClick = (status, id) => {
      if(status === 3 || status === 4 || status === 5 || status === 6 || status === 7 ){
        navigate(`/service-provider-dashboard/view-job/${id}`,{state: id})
      }
      if(status === 1 || status === 2){
        navigate(`/service-provider-dashboard/view-contract/${id}`,{state: id})
      }
    }
    return (
        <>
            <Box>
                <TableContainer component={Paper} className="TableContainer">
                    <Typography className="font-22 text-white" mb={2}>
                        {Title}
                    </Typography>
                    <Table aria-label="customized table">
                    <TableHead className="TableHeader">
                      <TableRow>
                        <StyledTableCell>Project</StyledTableCell>
                        <StyledTableCell >

                        </StyledTableCell>
                        <StyledTableCell sx={{textAlign: 'center'}}>
                          Order Date
                        </StyledTableCell>
                        <StyledTableCell sx={{textAlign: 'center'}}>Due On</StyledTableCell>
                        <StyledTableCell sx={{textAlign: 'center'}}>Total</StyledTableCell>
                        <StyledTableCell sx={{textAlign: 'center'}}>Status</StyledTableCell>
                        <StyledTableCell sx={{textAlign: 'center'}}>Action</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {jobList && jobList.length > 0 ? jobList.map((row) => (
                        <TableRow
                          key={row.id}
                          className='cursor-pointer'
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row"
                            sx={{ color: '#fff', borderBottom: 'none' }}
                            onClick={()=>handelRowClick(row.status, row.id)}
                          >
                            {row.title}
                          </TableCell>
                          <TableCell sx={{ color: '#fff', borderBottom: 'none', cursor: "pointer" }}
                            onClick={()=>handelRowClick(row.status, row.id)}
                          >
                            <Chip
                              sx={{
                                marginTop: 2,
                                marginBottom: 2,
                                color: "#fff",
                                bgcolor: "#1771BD",
                                borderRadius: "8px",
                              }}
                              label="Custom Order"
                            />
                          </TableCell>
                          <TableCell sx={{ color: '#fff', borderBottom: 'none', textAlign: 'center' }}
                            onClick={()=>handelRowClick(row.status, row.id)}
                          >{row?.createdAt ? formatDate(row.createdAt, 'MMM dd, h:mm a') : ''}</TableCell>
                          <TableCell sx={{ color: '#fff', borderBottom: 'none', textAlign: 'center' }}
                            onClick={()=>handelRowClick(row.status, row.id)}
                          >{row?.delivery_date ? formatDate(row.delivery_date, 'MMM d, yyyy') : ''}</TableCell>
                          <TableCell sx={{ color: '#fff', borderBottom: 'none', textAlign: 'right'}}
                            onClick={()=>handelRowClick(row.status, row.id)}
                          >${row.project_fees}</TableCell>
                          <TableCell sx={{ color: '#fff', borderBottom: 'none', cursor: "pointer", textAlign: 'center'}}
                            onClick={()=>handelRowClick(row.status, row.id)}
                          >
                            <Chip
                              sx={{
                                marginTop: 2,
                                marginBottom: 2,
                                color: "#fff",
                                bgcolor: config.CONTRACT_STATUS.CONTRACT_STATUS_COLOR[config.CONTRACT_STATUS[row.status]],
                                borderRadius: "8px",
                              }}
                              label={ config.CONTRACT_STATUS[row.status] }
                            />
                          </TableCell>
                          <TableCell sx={{ color: '#fff', borderBottom: 'none', textAlign: 'center' }}>
                            
                            {/* <Tooltip title={row.status === 1 || row.status === 2 ? "Project not started yet" : "Time Tracking"} arrow>
                              <TimerOutlinedIcon sx={{marginLeft: "15px", cursor: 'pointer'}} onClick={()=>{
                                if(row.status === 1 || row.status === 2){
                                  navigate(`/service-provider-dashboard/view-contract/${row.id}`)
                                }else{
                                  navigate(`/service-provider-dashboard/view-job/${row.id}?tab=timeTracking`);
                                }
                              }}/>
                            </Tooltip> */}
                            {
                              row?.status === 6 && row?.stake_amount_status === 1 ? 
                              <Button className='confirmOrderButton' onClick={() => {
                                setContractBlockchainId(row.blockchain_id);
                                setContractBlockchainIdVersion(row?.contract_version);
                                setOpenTransactionModal(true);
                              }} variant="contained">
                                Withdraw stake balance
                              </Button> : 
                              <Tooltip title={row.status === 1 || row.status === 2 ? "Project not started yet" :"Delivery"} arrow>
                                <RuleFolderIcon sx={{marginRight: "15px", cursor: 'pointer'}} onClick={()=>{
                                  // setContractId(row.id);
                                  // setOpenModal(true);
                                  if(row.status === 1 || row.status === 2){
                                    // navigate(`/service-provider-dashboard/view-contract/${row.id}`)
                                  }else{
                                    navigate(`/service-provider-dashboard/view-job/${row.id}?tab=3`);
                                  }
                                }}/>
                              </Tooltip>
                            }
                          </TableCell>
                        </TableRow>
                      )) : (null)}
                    </TableBody>
                  </Table>
                  {paginationData?.totalCount > 0 ?
                  <Box className="pagination-container">
                    <Pagination
                      count={paginationData.pageSize === 'all' ? 1 : Math.ceil(paginationData?.totalCount/Number(paginationData.pageSize))}
                      page={currentPage}
                      onChange={handlePageChange}
                      variant="outlined"
                      shape="rounded"
                      showFirstButton
                      showLastButton
                    />
                  </Box>:  <Typography className="font-14 font-readexpro" display={'flex'} justifyContent={'center'} color={'#9CA3AF'} mt={1}>
                      Record not available
                    </Typography> }
                </TableContainer>
            </Box>
            <ConfirmModal title={'Confirmation'} description={'Are You Sure Want To Confirm It!'} isOpen={openModal} setIsOpenModal={setOpenModal} handleAction={handleAction} />
            {openTransactionModal ? <SpTransectionProgressModal identity={'withdraw-staked-balance'} confirmStake={confirmStake} propData={{ contractBlockchainId: contractBlockchainId, contract_version: contractBlockchainIdVersion }} handleModalClose={handleModalClose} openTransactionModal={openTransactionModal} /> : null}
        </>
    )
}
