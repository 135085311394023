import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { GetContractAddress } from '../Components/Common/GetContractAddress';
import { CONTRACT_NAME } from '../constants';
import { viewAnnualIRR } from '../Components/SmartContract/viewAnnualIRR';
import { formatNumber } from '../Components/Common/USFormat';

export default function EquityDistributionDetails({ assetData }) {
    const [distributionData, setDistributionData] = useState()
    // getting repayment data by listing id
    useEffect(() => {
        async function getAnnualIRR(assetData) {
            const responseToken = await GetContractAddress(assetData, assetData?.contract_version, CONTRACT_NAME.SPV_EQUITY_DISTRIBUTION);
            if (responseToken && responseToken?.length) {
                let tokenAddress = responseToken[0].address;
                let tokenAbi = responseToken[0].abi;
                let data = await viewAnnualIRR(tokenAddress, tokenAbi, assetData?.listing_id);

                setDistributionData(data)
            }
        }
        if (assetData?.listing_id) {
            getAnnualIRR(assetData);
        }
    }, [assetData])

    return (
        <Box className="pd-sidebar-dealTerms" id="scroll-to-dealterm">
            <Grid container spacing={2} className="pd-sidebar-heading" justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography className="title" component={'h4'}>Distribution Details</Typography>
                </Grid>
            </Grid>

            <Box className="pd-sidebar-detailsList">
                {
                    distributionData && distributionData?.totalDistributedAmt > 0 ?
                        <Box className='detailsList'>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography className='font-15' style={{ fontWeight: '500' }}>Total Distribution</Typography>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4}>
                                    <Typography className='title'>Distributed Amt</Typography>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4}>
                                    <Typography className='title'>Investor Amt</Typography>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4}>
                                    <Typography className='title'>Proposer Amt</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={4} sm={4} md={4}>
                                    <Typography className='title'>${formatNumber(Number(distributionData?.totalDistributedAmt) / 1e18)}</Typography>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4}>
                                    <Typography className='title'>${formatNumber(Number(distributionData?.totalInvestorsAmt) / 1e18)}</Typography>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4}>
                                    <Typography className='title'>${formatNumber(Number(distributionData?.totalProposerAmt) / 1e18)}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Divider style={{ marginBottom: '16px' }} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography className='font-15' style={{ fontWeight: '500' }}>Yearly Distribution</Typography>
                                </Grid>
                                <Grid item xs={2} sm={2} md={2}>
                                    <Typography className='title'>Year</Typography>
                                </Grid>
                                <Grid item xs={3.8} sm={3.8} md={3.8}>
                                    <Typography className='title'>Investor Amt</Typography>
                                </Grid>
                                <Grid item xs={3.8} sm={3.8} md={3.8}>
                                    <Typography className='title'>Proposer Amt</Typography>
                                </Grid>
                                <Grid item xs={2.4} sm={2.4} md={2.4}>
                                    <Typography className='title'>Total IRR</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Divider style={{ marginBottom: '8px' }} />
                                </Grid>
                            </Grid>
                            {distributionData?.totalIRR?.length > 0 && distributionData?.totalIRR?.map((item, index) =>
                                <Grid container spacing={1}>
                                    <Grid item xs={2} sm={2} md={2}>
                                        <Typography className='title'>{new Date(Number(distributionData.startedAt[index]) * 1000).getFullYear()}</Typography>
                                    </Grid>
                                    <Grid item xs={3.8} sm={3.8} md={3.8}>
                                        <Typography className='title'>${formatNumber(Number(distributionData?.investorsAmt[index]) / 1e18)}</Typography>
                                    </Grid>
                                    <Grid item xs={3.8} sm={3.8} md={3.8}>
                                        <Typography className='title'>${formatNumber(Number(distributionData?.proposerAmt[index]) / 1e18)}</Typography>
                                    </Grid>
                                    <Grid item xs={2.4} sm={2.4} md={2.4}>
                                        <Typography className='title'>{formatNumber(parseFloat(Number(item) / 1e18).toFixed(2))}%</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Divider style={{ marginBottom: '8px' }} />
                                    </Grid>
                                </Grid>
                            )
                            }
                        </Box>
                        : <>
                            <Typography>No distribution data is available.</Typography>
                            <Divider style={{ marginTop: '16px' }} />
                        </>
                }
            </Box>
        </Box>
    );
}
